import { TimeZone } from '@energybox/react-ui-library/dist/types';

export enum Actions {
  INIT = '@@app/INIT',
  INIT_WITH_TOKEN = '@@app/INIT_WITH_TOKEN',

  ACCESS_TOKEN_UPDATED = '@@app/ACCESS_TOKEN_UPDATED',
  ACCESS_TOKEN_CLEARED = '@@app/ACCESS_TOKEN_CLEARED',

  LOGIN_SUCCESS = '@@app/LOGIN_SUCCESS',
  LOGIN_LOADING = '@@app/LOGIN_LOADING',
  LOGIN_ERROR = '@@app/LOGIN_ERROR',

  CURRENT_USER_SUCCESS = '@app/CURRENT_USER_SUCCESS',
  CURRENT_USER_ERROR = '@app/CURRENT_USER_ERROR',
  CURRENT_USER_LOADING = '@app/CURRENT_USER_LOADING',

  LOGOUT = '@@app/LOGOUT',

  GET_TIMEZONES_SUCCESS = '@app/GET_TIMEZONES_SUCCESS',
  GET_TIMEZONES_ERROR = '@app/GET_TIMEZONES_ERROR',
  GET_TIMEZONES_LOADING = '@app/GET_TIMEZONES_LOADING',

  SET_SITE_TIMEZONE_MINUTES_OFFSET = '@app/SET_SITE_TIMEZONE_MINUTES_OFFSET',

  CHANGE_ORGANIZATION_SUCCESS = '@@app/CHANGE_ORGANIZATION_SUCCESS',
  CHANGE_ORGANIZATION_ERROR = '@@app/CHANGE_ORGANIZATION_ERROR',
  CHANGE_ORGANIZATION_LOADING = '@@app/CHANGE_ORGANIZATION_LOADING',
}

export const initApp = () => ({
  type: Actions.INIT,
});

export const initAppWithToken = ({ accessToken }) => ({
  type: Actions.INIT_WITH_TOKEN,
  payload: { accessToken },
});

export const logout = () => ({
  type: Actions.LOGOUT,
});

export const login = (email: string, password: string) => ({
  type: 'API_POST',
  path: '/api/v1/auth/login',
  payload: { email, password },
  loading: Actions.LOGIN_LOADING,
  success: Actions.LOGIN_SUCCESS,
  error: Actions.LOGIN_ERROR,
});

export const getTimeZones = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/timezones',
  success: Actions.GET_TIMEZONES_SUCCESS,
  error: Actions.GET_TIMEZONES_ERROR,
  loading: Actions.GET_TIMEZONES_LOADING,
});

export const setSiteTimezone = (siteTimezone: TimeZone) => (dispatch: any) => {
  dispatch({
    type: Actions.SET_SITE_TIMEZONE_MINUTES_OFFSET,
    payload: siteTimezone,
  });
};

export const switchOrganization = (id: number) => ({
  type: 'API_POST',
  path: `/api/v1/auth/switch/organization/${id}`,
  loading: Actions.CHANGE_ORGANIZATION_LOADING,
  success: Actions.CHANGE_ORGANIZATION_SUCCESS,
  error: Actions.CHANGE_ORGANIZATION_ERROR,
});

export const getCurrentUser = () => ({
  type: 'API_GET',
  path: '/api/v1/auth/user',
  success: Actions.CURRENT_USER_SUCCESS,
  error: Actions.CURRENT_USER_ERROR,
  loading: Actions.CURRENT_USER_LOADING,
});
