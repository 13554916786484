import { Actions as AnalyticsActions } from '../actions/analytics';
import { Actions as SiteActions } from '../actions/sites';
import { Actions as EquipmentActions } from '../actions/equipment';
import { Actions as SensorActions } from '../actions/sensors';
import { Actions as SopActions } from '../actions/sops';
import { Actions as CertActions } from '../actions/certifications';
import { Actions as TemperatureRecordActions } from '../actions/temperatureRecords';
import {
  toggleError,
  toggleSuccess,
  toggleLoading,
} from '../utils/reducerFetchingStates';

export type EquipmentTempCheckFetchStatuses = {
  site: boolean;
  equipment: boolean;
  sensors: boolean;
  tempCheck: boolean;
  tempSop: boolean;
  certifications: boolean;
  reportConfig: boolean;
};

export type EquipmentTempCheckReport = {
  fetched: EquipmentTempCheckFetchStatuses;
  loading: EquipmentTempCheckFetchStatuses;
  errors: EquipmentTempCheckFetchStatuses;
};

export const initialState: EquipmentTempCheckReport = {
  fetched: {
    site: false,
    equipment: false,
    sensors: false,
    tempCheck: false,
    tempSop: false,
    certifications: false,
    reportConfig: false,
  },
  loading: {
    site: false,
    equipment: false,
    sensors: false,
    tempCheck: false,
    tempSop: false,
    certifications: false,
    reportConfig: false,
  },
  errors: {
    site: false,
    equipment: false,
    sensors: false,
    tempCheck: false,
    tempSop: false,
    certifications: false,
    reportConfig: false,
  },
};

export default (state: EquipmentTempCheckReport = initialState, action) => {
  switch (action.type) {
    case SiteActions.SITE_LOADING:
      return toggleLoading('site', state);

    case SiteActions.SITE_ERROR:
      return toggleError('site', state);

    case SiteActions.SITE_SUCCESS:
      return toggleSuccess('site', state);

    case AnalyticsActions.SITE_TEMP_CHECK_LOADING:
      return toggleLoading('tempCheck', state);

    case AnalyticsActions.SITE_TEMP_CHECK_ERROR:
      return toggleError('tempCheck', state);

    case AnalyticsActions.SITE_TEMP_CHECK_SUCCESS:
      return toggleSuccess('tempCheck', state);

    case EquipmentActions.GET_EQUIPMENT_BY_SITE_ID_LOADING:
      return toggleLoading('equipment', state);

    case EquipmentActions.GET_EQUIPMENT_BY_SITE_ID_ERROR:
      return toggleError('equipment', state);

    case EquipmentActions.GET_EQUIPMENT_BY_SITE_ID_SUCCESS:
      return toggleSuccess('equipment', state);

    case SensorActions.SENSORS_BY_EQUIPMENT_ID_LOADING:
      return toggleLoading('sensors', state);

    case SensorActions.SENSORS_BY_EQUIPMENT_ID_ERROR:
      return toggleError('sensors', state);

    case SensorActions.SENSORS_BY_EQUIPMENT_ID_SUCCESS:
      return toggleSuccess('sensors', state);

    case SopActions.RESOLVE_SOP_BY_RESOURCE_ID_LOADING:
      return toggleLoading('tempSop', state);

    case SopActions.RESOLVE_SOP_BY_RESOURCE_ID_ERROR:
      return toggleError('tempSop', state);

    case SopActions.RESOLVE_SOP_BY_RESOURCE_ID_SUCCESS:
      return toggleSuccess('tempSop', state);

    case CertActions.SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_LOADING:
      return toggleLoading('certifications', state);

    case CertActions.SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_ERROR:
      return toggleError('certifications', state);

    case CertActions.SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_SUCCESS:
      return toggleSuccess('certifications', state);

    case TemperatureRecordActions.GET_REPORT_SUCCESS:
      return toggleSuccess('reportConfig', state);

    case TemperatureRecordActions.GET_REPORT_LOADING:
      return toggleLoading('reportConfig', state);

    case TemperatureRecordActions.GET_REPORT_ERROR:
      return toggleError('reportConfig', state);

    default:
      return state;
  }
};
