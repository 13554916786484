import { Equipment, Site } from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { ReportTimeFormats } from '../../../hooks/utils';
import { StorageReportFetchStatuses } from '../../../reducers/storageReport';
import { ReportHeadingTitle } from '../../../types/shared';
import ReportHeading from '../ReportHeading';
import ReportMeasurableElement from '../ReportMeasurableElement';
import ReportPageBreakControl from '../ReportPageBreakControl';
import StorageReportAmbientConditionsContainer from '../StorageReportAmbientConditionsContainer';
import StorageReportConsumptionChartsContainer from '../StorageReportConsumptionChartsContainer';
import StorageReportConsumptionInfoContainer from '../StorageReportConsumptionInfoContainer';
import StorageReportDoorsContainer from '../StorageReportDoorsContainer';
import StorageReportHVACLoadChartsContainer from '../StorageReportHVACLoadChartsContainer';

type Props = {
  site: Site;
  reportTimeFormats: ReportTimeFormats;
  equipments: Equipment[];
  fetchedStatuses: StorageReportFetchStatuses;
};

class StorageReportPage extends React.Component<Props> {
  private headerRef: React.RefObject<HTMLDivElement>;
  private consumptionInfoRef: React.RefObject<HTMLDivElement>;
  private consumptionChartsRef: React.RefObject<HTMLDivElement>;
  private ambientConditionsRef: React.RefObject<HTMLDivElement>;
  private doorsRef: React.RefObject<HTMLDivElement>;
  private emptyRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
    this.consumptionInfoRef = React.createRef();
    this.consumptionChartsRef = React.createRef();
    this.ambientConditionsRef = React.createRef();
    this.doorsRef = React.createRef();
    this.emptyRef = React.createRef();
  }

  renderHVACLoadCharts = () => {
    const { reportTimeFormats } = this.props;
    const { fromDateISO, toDateISO } = reportTimeFormats;
    const AC_TYPE_ID = 40432;

    const HVACLoadCharts = this.props.equipments
      .filter((equipment: Equipment) => equipment.typeId === AC_TYPE_ID)
      .map((equipment: Equipment) => {
        if (!fromDateISO || !toDateISO) {
          return null;
        }

        return (
          <ReportMeasurableElement
            setRef={(this[`hvac${equipment.id}`] = React.createRef())}
            key={equipment.id}
          >
            <StorageReportHVACLoadChartsContainer
              site={this.props.site}
              equipments={this.props.equipments}
              equipmentId={equipment.id}
              fromDateISO={fromDateISO}
              toDateISO={toDateISO}
            />
          </ReportMeasurableElement>
        );
      });
    return HVACLoadCharts;
  };

  createHeaderInfoColumn = (site: Site) => {
    return [
      {
        fieldName: 'Organization',
        fieldValue: site?.organization?.title || global.NOT_AVAILABLE,
      },
      { fieldName: 'Site', fieldValue: site?.title },
      { fieldName: 'Address', fieldValue: site?.address },
    ];
  };

  render() {
    const { site, reportTimeFormats, equipments, fetchedStatuses } = this.props;
    const {
      fromDateInBrowserTimezone,
      toDateInBrowserTimezone,
      fromDateISO,
      toDateISO,
      reportDifferenceInDays,
    } = reportTimeFormats;

    if (!site || !fromDateISO || !toDateISO) return <div />;

    const reportHeader = (
      <ReportMeasurableElement setRef={this.headerRef} key="heading">
        <ReportHeading
          title={ReportHeadingTitle.STORAGE}
          leftColumnContent={this.createHeaderInfoColumn(site)}
          fromDate={fromDateInBrowserTimezone}
          toDate={toDateInBrowserTimezone}
        />
      </ReportMeasurableElement>
    );

    return (
      <ReportPageBreakControl
        fetchedStatuses={fetchedStatuses}
        headerForNewPages={reportHeader}
        headerRef={this.headerRef}
      >
        {reportHeader}

        <ReportMeasurableElement
          setRef={this.consumptionInfoRef}
          key="consumptioninfo"
        >
          <StorageReportConsumptionInfoContainer
            site={site}
            reportDifferenceInDays={reportDifferenceInDays || 0}
            equipments={equipments}
          />
        </ReportMeasurableElement>

        <ReportMeasurableElement
          setRef={this.consumptionChartsRef}
          key="conscharts"
        >
          <StorageReportConsumptionChartsContainer
            equipments={equipments}
            siteId={site.id}
          />
        </ReportMeasurableElement>

        <ReportMeasurableElement
          setRef={this.ambientConditionsRef}
          key="ambient"
        >
          <StorageReportAmbientConditionsContainer
            site={site}
            fromDateISO={fromDateISO}
            toDateISO={toDateISO}
          />
        </ReportMeasurableElement>

        <ReportMeasurableElement setRef={this.doorsRef} key="doors">
          <StorageReportDoorsContainer
            site={site}
            reportTimeFormats={reportTimeFormats}
          />
        </ReportMeasurableElement>

        {!!this.props.equipments ? (
          this.renderHVACLoadCharts()
        ) : (
          <ReportMeasurableElement setRef={this.emptyRef} key="empty">
            <div />
          </ReportMeasurableElement>
        )}
      </ReportPageBreakControl>
    );
  }
}

export default StorageReportPage;
