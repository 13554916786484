import React from 'react';
import styles from './EnergyTariffTooltip.module.css';
import { EnergyTariff } from '@energybox/react-ui-library/dist/types';
import { createEnergyCostText } from '../../utils/sop';

type Props = {
  value: number;
  energyTariffSop: EnergyTariff;
};
const EnergyTariffTooltip = ({ value, energyTariffSop }: Props) => (
  <p className={styles.tooltipEnergyTariff}>
    {createEnergyCostText(value, energyTariffSop)}
  </p>
);

export default EnergyTariffTooltip;
