export enum Actions {
  EQUIPMENT_REPORT_CERTIFICATIONS_SUCCESS = '@@certs/EQUIPMENT_REPORT_CERTIFICATIONS_SUCCESS',
  EQUIPMENT_REPORT_CERTIFICATIONS_LOADING = '@@certs/EQUIPMENT_REPORT_CERTIFICATIONS_LOADING',
  EQUIPMENT_REPORT_CERTIFICATIONS_ERROR = '@@certs/EQUIPMENT_REPORT_CERTIFICATIONS_ERROR',
  SKIP_EQUIPMENT_REPORT_CERTIFICATIONS = '@@certs/SKIP_EQUIPMENT_REPORT_CERTIFICATIONS',

  GET_CERTIFICATION_BY_SENSOR_ID_SUCCESS = '@@certs/GET_CERTIFICATION_BY_SENSOR_ID_SUCCESS',
  GET_CERTIFICATION_BY_SENSOR_ID_LOADING = '@@certs/GET_CERTIFICATION_BY_SENSOR_ID_LOADING',
  GET_CERTIFICATION_BY_SENSOR_ID_ERROR = '@@certs/GET_CERTIFICATION_BY_SENSOR_ID_ERROR',

  SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_SUCCESS = '@@certs/SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_SUCCESS',
  SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_LOADING = '@@certs/SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_LOADING',
  SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_ERROR = '@@certs/SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_ERROR',
}

export const getCertificationBySensorId = (sensorId: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/certifications/device/${sensorId}`,
  loading: {
    type: Actions.GET_CERTIFICATION_BY_SENSOR_ID_LOADING,
    sensorId,
  },
  success: {
    type: Actions.GET_CERTIFICATION_BY_SENSOR_ID_SUCCESS,
    sensorId,
  },
  error: {
    type: Actions.GET_CERTIFICATION_BY_SENSOR_ID_ERROR,
    sensorId,
  },
});

export const setSiteTempCheckReportCertFetchSuccess = (
  siteId: number | string
) => ({
  type: Actions.SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_SUCCESS,
  siteId,
});

export const setSiteTempCheckReportCertFetchLoading = (
  siteId: number | string
) => ({
  type: Actions.SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_LOADING,
  siteId,
});

export const skipEquipmentReportCertifications = () => ({
  type: Actions.SKIP_EQUIPMENT_REPORT_CERTIFICATIONS,
});
