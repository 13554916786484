import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import withTheme from './withTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as Sentry from '@sentry/browser';
import { unregister } from './registerServiceWorker';
import App from './containers/App';
import { initApp, initAppWithToken } from './actions/app';
import './styles';
import '@energybox/react-ui-library/dist/root.css';

export const FLAG_HIDE_ON_PRODUCTION =
  process.env.REACT_APP_SERVICE_ENV !== 'production';

// Enable this line to persist the redux-store in localStorage
// store.subscribe(() => saveState(store.getState()));

try {
  const fragment = window.location.hash.substr(1);
  const params = new URLSearchParams(fragment);
  if (params.has('accessToken')) {
    const accessToken = params.get('accessToken');
    store.dispatch(initAppWithToken({ accessToken }));
  } else {
    store.dispatch(initApp());
  }
} catch (err) {
  // access to window is blocked?
}

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://ba7f1198082a447493f2c513e83a3d5d@sentry.io/1793372',
  });
}

const AppWithTheme = withTheme(App);

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AppWithTheme />
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root')
);

unregister();
