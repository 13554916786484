import {
  ColdStorageRecommendations,
  Site,
  SopTypes,
} from '@energybox/react-ui-library/dist/types';
import format from 'date-fns/format';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCoolerEnergyRecommendations } from '../../../actions/analytics';
import ColdStorageReduction from '../../../components/GenericReport/ColdStorageReduction';
import { useSopByResourceId } from '../../../hooks/sop';
import { ApplicationState } from '../../../reducers';

type OwnProps = {
  site: Site;
  fromDate: Date;
  toDate: Date;
};

interface Props extends OwnProps {
  getCoolerEnergyRecommendations: (
    orgId: number,
    fromDate: string,
    toDate: string,
    siteId: number
  ) => void;
  organizationId?: number;
  fetchedColdStorageReductionRecommendations: boolean;
  coldStorageReductionRecommendations: ColdStorageRecommendations[];
}

const ColdStorageReductionContainer: React.FC<Props> = ({
  site,
  getCoolerEnergyRecommendations,
  organizationId,
  fetchedColdStorageReductionRecommendations,
  coldStorageReductionRecommendations,
  fromDate,
  toDate,
}) => {
  const { sop: energySop } = useSopByResourceId(
    site.id,
    SopTypes.ENERGY_TARIFF
  );

  useEffect(() => {
    if (
      !!organizationId &&
      !!site &&
      !fetchedColdStorageReductionRecommendations
    ) {
      const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
      const formattedToDate = format(toDate, 'yyyy-MM-dd');

      getCoolerEnergyRecommendations(
        organizationId,
        formattedFromDate,
        formattedToDate,
        site.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedColdStorageReductionRecommendations,
    organizationId,
    getCoolerEnergyRecommendations,
    site,
  ]);

  const getSavingsTotal = () => {
    if (!!energySop) {
      const total = coldStorageReductionRecommendations.reduce(
        (sum, current) => {
          return sum + current.savings;
        },
        0
      );

      return total * energySop.tariff;
    }
    return 0;
  };

  if (
    !site ||
    !fetchedColdStorageReductionRecommendations ||
    !coldStorageReductionRecommendations
  )
    return <div />;

  return (
    <ColdStorageReduction
      coolingEquipmentRecommendations={coldStorageReductionRecommendations}
      currency={energySop ? energySop.currencyCode : undefined}
      tariff={energySop ? energySop.tariff : 0}
      totalSavings={getSavingsTotal()}
    />
  );
};

const mapStateToProps = ({
  app,
  analytics,
  genericReport,
}: ApplicationState) => ({
  organizationId: app.currentOrganizationId,
  fetchedColdStorageReductionRecommendations:
    genericReport.fetched.coldStorageRecommendations,
  coldStorageReductionRecommendations: analytics.coldStorageRecommendations,
});

const mapDispatchToProps = {
  getCoolerEnergyRecommendations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColdStorageReductionContainer);
