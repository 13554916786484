import { Site } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import EnergyReportConsumptionInfo from '../EnergyReportConsumptionInfo';

type Props = {
  fromDate: Date;
  toDate: Date;
  site: Site;
  analyticsEnergy: number | undefined;
  prevTimePeriodAnalyticsEnergy: number | undefined;
  reportNumberOfDays: number | undefined;
};

const EnergyReportConsumptionInfoContainer = ({
  fromDate,
  toDate,
  site,
  analyticsEnergy,
  prevTimePeriodAnalyticsEnergy,
  reportNumberOfDays,
}: Props) => {
  return (
    <EnergyReportConsumptionInfo
      site={site}
      totalConsumption={analyticsEnergy}
      prevTotalConsumption={prevTimePeriodAnalyticsEnergy}
      fromDate={fromDate}
      toDate={toDate}
      reportNumberOfDays={reportNumberOfDays}
    />
  );
};

const mapStateToProps = ({ app, energyReport, energy }: ApplicationState) => ({
  analyticsEnergy: energy.analyticsEnergySelectedTimePeriod?.data?.total_kwh,
  prevTimePeriodAnalyticsEnergy: energy.totalConsumption?.prevPeriod,
});

export default connect(mapStateToProps)(EnergyReportConsumptionInfoContainer);
