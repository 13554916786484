import { Comment } from '@energybox/react-ui-library/dist/types';
import { mapValues } from '@energybox/react-ui-library/dist/utils';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/comments';

export type Comments = {
  commentsByResourceId: CommentsByResourceId;
  isLoading: boolean;
};

export type CommentsByResourceId = {
  [resourceId: string]: Comment[];
};

const normalizeCommentFromApiResponse = commentPayload => ({
  comment: commentPayload.comment,
  commentType: commentPayload.commentType && commentPayload.commentType.key,
  createdAt: commentPayload.createdAt,
  from: commentPayload.from,
  id: commentPayload.id,
  orgId: commentPayload.orgId,
  resourceId: commentPayload.resourceId,
  resourceType: commentPayload.resourceType && commentPayload.resourceType.key,
  siteId: commentPayload.siteId,
  subComments: commentPayload.subComments,
  to: commentPayload.to,
  updatedAt: commentPayload.updatedAt,
  userId: commentPayload.userId,
  username: commentPayload.username,
  value: commentPayload.value,
  valueType: commentPayload.valueType && commentPayload.valueType.key,
  valueSubtype: commentPayload.valueSubtype && commentPayload.valueSubtype.key,
});

const initialState = {
  commentsByResourceId: {},
  isLoading: false,
};

const comments = (state: Comments = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_DOWNSTREAM_COMMENTS_SUCCESS:
      return pipe(
        assocPath(
          ['commentsByResourceId', action.resourceId],
          mapValues(action.payload, normalizeCommentFromApiResponse)
        ),
        assoc('isLoading', false)
      )(state);

    case Actions.GET_DOWNSTREAM_COMMENTS_LOADING:
      return assoc('isLoading', true, state);

    case Actions.GET_DOWNSTREAM_COMMENTS_ERROR:
      return assoc('isLoading', false, state);

    default:
      return state;
  }
};

export default comments;
