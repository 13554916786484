import React from 'react';
import styles from './SectionTitle.module.css';

type Props = {
  className?: string;
  title: string;
  description?: string;
};

const SectionTitle: React.FC<Props> = ({ className, title, description }) => {
  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default SectionTitle;
