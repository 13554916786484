export type SelectedComponentsByPage = {
  [pageNo: string]: SelectedComponents;
};

export type SelectedComponent = GenericReportComponent | null;
export type SelectedComponents = SelectedComponent[];

export enum GenericReportComponent {
  COLD_STORAGE_RECOMMENDATIONS = 'coldStorageRecommendations',
  UNNECESSARY_DOOR_ACCESS = 'unnecessaryDoorAccess',
  TEMPERATURE_COMFORT_RECOMMENDATIONS = 'temperatureComfortRecommendations',
  ENERGY_TRENDS = 'energyTrends',
}
