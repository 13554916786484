import { CurrentOrganization } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions } from '../actions/organizations';

export type Organizations = {
  currentOrganization?: CurrentOrganization;
};

const initialState: Organizations = {};

const normalizeCurrentOrganization = payload => ({
  _entity: payload._entity,
  country: payload.country,
  createdAt: payload.createdAt,
  description: payload.description,
  id: payload.id,
  legacyId: payload.legacyId,
  logo: payload.logo,
  title: payload.title,
  updatedAt: payload.updatedAt,
});

const organizations = (state: Organizations = initialState, action: any) => {
  switch (action.type) {
    case Actions.CURRENT_ORGANIZATION_SUCCESS: {
      return R.assoc(
        'currentOrganization',
        normalizeCurrentOrganization(action.payload),
        state
      );
    }

    default:
      return state;
  }
};

export default organizations;
