import { SopTypes } from '@energybox/react-ui-library/dist/types';

const apiBase = '/api/v1/sop';

export enum Actions {
  RESOLVE_SOP_BY_RESOURCE_ID_SUCCESS = '@sops/RESOLVE_SOP_BY_RESOURCE_ID_SUCCESS',
  RESOLVE_SOP_BY_RESOURCE_ID_ERROR = '@sops/RESOLVE_SOP_BY_RESOURCE_ID_ERROR',
  RESOLVE_SOP_BY_RESOURCE_ID_LOADING = '@sops/RESOLVE_SOP_BY_RESOURCE_ID_LOADING',
}

export const resolveSopByResourceIdAndSopType = (
  id: string | number,
  sopType: SopTypes
) => ({
  type: 'API_GET',
  path: `${apiBase}/resolve/resource/${id}?type=${sopType}`,
  success: { type: Actions.RESOLVE_SOP_BY_RESOURCE_ID_SUCCESS, id, sopType },
  error: { type: Actions.RESOLVE_SOP_BY_RESOURCE_ID_ERROR, id, sopType },
  loading: { type: Actions.RESOLVE_SOP_BY_RESOURCE_ID_LOADING, id, sopType },
});
