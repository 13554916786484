import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

export const accent = {
  basePlus90: '#E5F5F7',
  basePlus75: '#BFE7EB',
  basePlus50: '#7FD0D7',
  basePlus25: '#3FB8C3',
  main: '#00A1AF',
  baseMinus10: '#0597A4',
  baseMinus25: '#0C8895',
  baseMinus50: '#196F7B',
  baseMinus75: '#265661',
};

export const ambient = {
  basePlus90: '#EAEBEC',
  basePlus75: '#CCCED1',
  basePlus50: '#999EA3',
  basePlus25: '#666E75',
  main: '#333E48',
  baseMinus10: '#2D3740',
  baseMinus25: '#262E36',
  baseMinus50: '#191F24',
  baseMinus75: '#0C0F12',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 16,
    body1: {
      color: ambient.main,
    },
    body2: {
      color: ambient.main,
    },
  },
  palette: {
    text: {
      primary: ambient.main,
    },
    primary: {
      light: accent.basePlus50,
      main: accent.main,
      dark: accent.baseMinus75,
    },
    secondary: {
      light: ambient.basePlus50,
      main: ambient.main,
      dark: ambient.baseMinus50,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        color: accent.main,
        fontSize: '14px',
      },
    },
    MuiFormLabel: {
      root: {
        // stay this color even when focused
        color: `${accent.main} !important`,
        fontSize: '14px',
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          // stay this color even when hovering
          borderBottom: '1px solid #00A1AF !important',
        },
        '&::after': {
          borderBottom: '2px solid #00A1AF',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
      },
    },
  },
});

function withTheme(Component) {
  function WithTheme(props) {
    // MuiThemeProvider makes the theme available down the React tree
    return (
      <MuiThemeProvider theme={theme}>
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithTheme;
}

export default withTheme;
