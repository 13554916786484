import {
  Site,
  SopTypes,
  UnnecessaryDoorAccessRecommendations,
} from '@energybox/react-ui-library/dist/types';
import format from 'date-fns/format';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getUnnecessaryDoorAccess } from '../../../actions/analytics';
import DoorAccess from '../../../components/GenericReport/DoorAccess';
import { useSopByResourceId } from '../../../hooks/sop';
import { ApplicationState } from '../../../reducers';

type OwnProps = {
  site: Site;
  fromDate: Date;
  toDate: Date;
};

interface Props extends OwnProps {
  organizationId?: number;
  getUnnecessaryDoorAccess: (
    orgId: number | string,
    fromDate: string,
    toDate: string,
    siteId: number | string
  ) => void;
  fetchedUnnecessaryDoorAccess: boolean;
  unnecessaryDoorAccessRecommendations: UnnecessaryDoorAccessRecommendations[];
}

const DoorAccessContainer: React.FC<Props> = ({
  site,
  organizationId,
  getUnnecessaryDoorAccess,
  fetchedUnnecessaryDoorAccess,
  unnecessaryDoorAccessRecommendations,
  fromDate,
  toDate,
}) => {
  const { sop: energySop } = useSopByResourceId(
    site.id,
    SopTypes.ENERGY_TARIFF
  );
  const { sop: doorSop } = useSopByResourceId(
    site.id,
    SopTypes.DOOR_OPENED_MAX_DURATION
  );

  useEffect(() => {
    if (!!organizationId && !!site && !fetchedUnnecessaryDoorAccess) {
      const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
      const formattedToDate = format(toDate, 'yyyy-MM-dd');

      getUnnecessaryDoorAccess(
        organizationId,
        formattedFromDate,
        formattedToDate,
        site.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedUnnecessaryDoorAccess,
    organizationId,
    getUnnecessaryDoorAccess,
    site,
  ]);

  if (!fetchedUnnecessaryDoorAccess || !unnecessaryDoorAccessRecommendations)
    return <div />;

  return (
    <DoorAccess
      doorAccessRecommendations={unnecessaryDoorAccessRecommendations}
      energySop={energySop}
      doorSop={doorSop}
    />
  );
};

const mapStateToProps = ({
  app,
  analytics,
  genericReport,
}: ApplicationState) => ({
  organizationId: app.currentOrganizationId,
  fetchedUnnecessaryDoorAccess: genericReport.fetched.unnecessaryDoorAccess,
  unnecessaryDoorAccessRecommendations: analytics.unnecessaryDoorAccess,
});

export default connect(mapStateToProps, { getUnnecessaryDoorAccess })(
  DoorAccessContainer
);
