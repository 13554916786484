import { Actions as SiteActions } from '../actions/sites';
import {
  toggleError,
  toggleLoading,
  toggleSuccess,
} from '../utils/reducerFetchingStates';

export type InspectionReportFetchStatusFlags = {
  site: boolean;
};

export type InspectionReportInfo = {
  fetched: InspectionReportFetchStatusFlags;
  loading: InspectionReportFetchStatusFlags;
  errors: InspectionReportFetchStatusFlags;
};

const initialFlags: InspectionReportFetchStatusFlags = {
  site: false,
};

export const initialState: InspectionReportInfo = {
  fetched: { ...initialFlags },
  loading: { ...initialFlags },
  errors: { ...initialFlags },
};

export default (state: InspectionReportInfo = initialState, action) => {
  switch (action.type) {
    case SiteActions.SITE_LOADING:
      return toggleLoading('site', state);

    case SiteActions.SITE_ERROR:
      return toggleError('site', state);

    case SiteActions.SITE_SUCCESS:
      return toggleSuccess('site', state);

    default:
      return state;
  }
};
