import { PieSection } from '@energybox/react-ui-library/dist/types';
import {
  formatNumberString,
  KWH_UNIT,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { useSiteCountryCode } from '../../../hooks/sites';
import styles from './DonutChartLegend.module.css';

type Props = {
  siteId: number | string;
  data: PieSection[];
  showAbsoluteConsumption?: boolean;
};

const DonutChartLegend = ({ siteId, data, showAbsoluteConsumption }: Props) => {
  const countryCode = useSiteCountryCode(siteId);

  const total = data.reduce((acc, datum) => acc + datum.count, 0);
  if (total === 0) {
    return <div />;
  }
  return (
    <div
      className={
        showAbsoluteConsumption
          ? styles.absoluteConsumptionLegend
          : styles.legend
      }
    >
      {data
        .sort((a, b) => b.count - a.count)
        .map((datum: PieSection) => (
          <span
            key={datum.label}
            className={
              showAbsoluteConsumption
                ? styles.absoluteConsumptionLegendInner
                : styles.legendEntryInner
            }
          >
            <span>
              <span
                className={styles.legendDot}
                style={{ backgroundColor: datum.chartColor }}
              />
              <span
                className={
                  showAbsoluteConsumption
                    ? styles.absoluteConsumptionLegendText
                    : styles.legendText
                }
                style={{ color: datum.chartColor }}
              >
                {datum.label}
              </span>
            </span>
            <span
              className={
                showAbsoluteConsumption
                  ? styles.absoluteConsumptionLegendTextCentered
                  : styles.legendText
              }
              style={{ color: datum.chartColor }}
            >
              {(datum.count / total) * 100
                ? ((datum.count / total) * 100).toFixed(0) + '%'
                : '-'}
            </span>
            {showAbsoluteConsumption && (
              <span className={styles.absoluteConsumptionLegendText}>
                {formatNumberString(datum.count, {
                  countryCode,
                  numDecimals: 0,
                  unit: KWH_UNIT,
                })}
              </span>
            )}
          </span>
        ))}
    </div>
  );
};

export default DonutChartLegend;
