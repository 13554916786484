import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';
import {
  Locale,
  TimeFormat,
  MapTimeFormat,
  CurrentUser,
  TemperatureUnit,
} from '@energybox/react-ui-library/dist/types';
import { UserRole } from '@energybox/react-ui-library/dist/types';
import { getUserPreferenceTemperatureUnit } from '@energybox/react-ui-library/dist/utils/temperature';
import { AreaUnitLabels } from '@energybox/react-ui-library/dist/types/Global';
import { getUserPreferenceAreaUnit } from '@energybox/react-ui-library/dist/utils/util';

export const useCurrentUser = () => {
  return useSelector<ApplicationState, CurrentUser | undefined>(
    ({ app }) => app.currentUser
  );
};

export const useUserRole = () => {
  return useSelector<ApplicationState, UserRole | undefined>(({ app }) => {
    return (app.currentUser || {}).role;
  });
};

export function useAppLocale() {
  const locale: Locale = useSelector<ApplicationState, Locale>(
    ({ app }) => app.locale
  );
  return locale;
}

export const useIs24HrTimeFormat = () => {
  const timeFormat = useSelector<ApplicationState, string>(({ app }) => {
    return app.locale.timeFormat;
  });

  return timeFormat === MapTimeFormat[TimeFormat.HOUR_24];
};

export const useIs12HrTimeFormat = () => {
  const timeFormat = useSelector<ApplicationState, string>(({ app }) => {
    return app.locale.timeFormat;
  });

  return timeFormat === MapTimeFormat[TimeFormat.HOUR_12];
};

export const useTemperatureUnit = () => {
  const currentUser = useCurrentUser();

  if (!currentUser) return TemperatureUnit.F;
  return getUserPreferenceTemperatureUnit(currentUser);
};

export const useAreaUnit = () => {
  const currentUser = useCurrentUser();

  if (!currentUser) return AreaUnitLabels.FT2;
  return getUserPreferenceAreaUnit(currentUser);
};
