import * as R from 'ramda';
import { InspectionDataFieldsByKey } from '@energybox/react-ui-library/dist/types';
import { Actions } from '../actions/inspection';

export type InspectionDataFields = {
  [component: string]: InspectionDataFieldsByKey[];
} & {
  unpaired_sensors?: {
    errors: number;
    warnings: number;
    result: string;
    sensors: InspectionDataFieldsByKey[];
  };
};

export type RawInspectionReport = {
  key: number;
  report_date: string;
  report_version: number;
  reportFetchTime: Date;
  data: InspectionDataFields;
};

export type InspectionReportBySiteId = {
  [siteId: number]: RawInspectionReport;
};

export type InspectionReportIsLoadingBySiteId = {
  [siteId: number]: boolean;
};

export type Inspection = {
  inspectionReportBySiteId: InspectionReportBySiteId;
  inspectionReportIsLoadingBySiteId: InspectionReportIsLoadingBySiteId;
};

const initialState = {
  inspectionReportBySiteId: {},
  inspectionReportIsLoadingBySiteId: {},
};

const inspection = (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SITE_INSPECTION_REPORT_LOADING: {
      return R.assocPath(
        ['inspectionReportIsLoadingBySiteId', action.siteId],
        true
      )(state);
    }

    case Actions.GET_SITE_INSPECTION_REPORT_ERROR: {
      return R.dissocPath(['inspectionReportIsLoadingBySiteId', action.siteId])(
        state
      );
    }

    case Actions.GET_SITE_INSPECTION_REPORT_SUCCESS: {
      return R.pipe(
        R.dissocPath(['inspectionReportIsLoadingBySiteId', action.siteId]),
        R.assoc(
          'inspectionReportBySiteId',
          R.mergeRight(R.view(R.lensProp('inspectionReportBySiteId'), state), {
            [action.payload.key]: action.payload,
          })
        )
      )(state);
    }

    default:
      return state;
  }
};

export default inspection;
