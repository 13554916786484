import { EquipmentApiFilter } from '@energybox/react-ui-library/dist/types';

export enum Actions {
  GET_EQUIPMENT_SUCCESS = '@@equipment/GET_EQUIPMENT_SUCCESS',
  GET_EQUIPMENT_LOADING = '@@equipment/GET_EQUIPMENT_LOADING',
  GET_EQUIPMENT_ERROR = '@@equipment/GET_EQUIPMENT_ERROR',

  GET_EQUIPMENT_BY_SITE_ID_ERROR = '@@equipment/GET_EQUIPMENT_BY_SITE_ID_ERROR',
  GET_EQUIPMENT_BY_SITE_ID_LOADING = '@@equipment/GET_EQUIPMENT_BY_SITE_ID_LOADING',
  GET_EQUIPMENT_BY_SITE_ID_SUCCESS = '@@equipment/GET_EQUIPMENT_BY_SITE_ID_SUCCESS',
}

const setEquipmentFilter = (filter?: EquipmentApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.equipmentTypeIds && filter.equipmentTypeIds.length > 0) {
    queryParams.set('equipmentTypeIds', filter.equipmentTypeIds.join(','));
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.ids) {
    queryParams.set('ids', filter.ids.join(','));
  }

  queryParams.set('withPath', 'true');

  return queryParams.toString();
};

export const getEquipment = (equipmentId: number) => ({
  type: 'API_GET',
  path: `/api/v1/equipment/${equipmentId}`,
  loading: { type: Actions.GET_EQUIPMENT_LOADING, equipmentId },
  success: { type: Actions.GET_EQUIPMENT_SUCCESS, equipmentId },
  error: { type: Actions.GET_EQUIPMENT_ERROR, equipmentId },
});

export const getEquipmentBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/equipment?${setEquipmentFilter({ siteIds: [+siteId] })}`,
  loading: {
    type: Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_EQUIPMENT_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_EQUIPMENT_BY_SITE_ID_ERROR,
    siteId,
  },
});
