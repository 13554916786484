import { Actions as SitesActions } from '../actions/sites';
import { Actions as SpacesActions } from '../actions/spaces';
import { Actions as EquipmentActions } from '../actions/equipment';
import { Actions as SensorActions } from '../actions/sensors';
import { Actions as AppActions } from '../actions/app';

export type Loading = {
  equipment: boolean;
  sites: boolean;
  app: boolean;
  failedLogin?: string;
};

const initialState = {
  equipment: false,
  sites: false,
  app: false,
};

export default (state: Loading = initialState, action: any) => {
  switch (action.type) {
    case AppActions.LOGIN_LOADING:
      return {
        ...state,
        app: true,
      };

    case AppActions.LOGIN_SUCCESS:
    case AppActions.LOGIN_ERROR:
      return {
        ...state,
        app: false,
        failedLogin: action.payload ? action.payload.message : '',
      };

    case EquipmentActions.GET_EQUIPMENT_LOADING: {
      return {
        ...state,
        equipment: true,
      };
    }
    case EquipmentActions.GET_EQUIPMENT_SUCCESS:
    case EquipmentActions.GET_EQUIPMENT_ERROR:
      return {
        ...state,
        equipment: false,
      };

    case SitesActions.SITES_LOADING: {
      return {
        ...state,
        sites: true,
      };
    }

    case SitesActions.SITES_SUCCESS:
    case SitesActions.SITES_ERROR:
      return {
        ...state,
        sites: false,
      };

    case SpacesActions.SPACES_LOADING: {
      return {
        ...state,
        spaces: true,
      };
    }

    case SpacesActions.SPACES_SUCCESS:
    case SpacesActions.SPACES_ERROR:
      return {
        ...state,
        spaces: false,
      };

    case SensorActions.SENSORS_LOADING: {
      return {
        ...state,
        sensors: true,
      };
    }

    case SensorActions.SENSORS_SUCCESS:
    case SensorActions.SENSORS_ERROR:
      return {
        ...state,
        sensors: false,
      };

    default: {
      return state;
    }
  }
};
