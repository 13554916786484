import { global, toFahrenheit } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { useTemperatureUnit } from '../hooks/useAppDetails';
import { getTempDifferenceInFahrenheit } from '@energybox/react-ui-library/dist/utils';
import reportStyles from '../views/report/ReportPage.module.css';
import { TemperatureUnit } from '@energybox/react-ui-library/dist/types';

type Props = {
  temperatureInCelsius: number | null;
  displayTemperatureDifference?: boolean;
};

const TemperatureCell: React.FC<Props> = ({
  temperatureInCelsius,
  displayTemperatureDifference,
}) => {
  const tempUnit = useTemperatureUnit();

  const formatTemperature = () => {
    if (
      temperatureInCelsius === null ||
      (temperatureInCelsius !== null && isNaN(temperatureInCelsius))
    ) {
      return global.NOT_AVAILABLE;
    }

    switch (tempUnit) {
      case TemperatureUnit.F:
        return displayTemperatureDifference
          ? `${+getTempDifferenceInFahrenheit(temperatureInCelsius).toFixed(
              1
            )} ${tempUnit}`
          : `${+toFahrenheit(temperatureInCelsius).toFixed(1)} ${tempUnit}`;

          case TemperatureUnit.C:
        return temperatureInCelsius === 0
          ? `0 ${tempUnit}`
          : `${+temperatureInCelsius.toFixed(1)} ${tempUnit}`;

      default:
        return global.NOT_AVAILABLE;
    }
  };

  return (
    <div
      className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
    >
      {formatTemperature()}
    </div>
  );
};

export default TemperatureCell;
