import React from 'react';
import { connect } from 'react-redux';
import * as Routes from '../../routes';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ApplicationState } from '../../reducers';

import TopBarContainer from './TopBarContainer';
import Navigation from '../../components/Navigation';
import BaseContentContainer from '../../components/layout/BaseContentContainer';
import ShowSitePageContainer from '../../views/sites/ShowSitePageContainer/ShowSitePageContainer';
import SitesPage from '../../views/sites/SitesPage';

import EnergyReportPageContainer from '../../views/report/EnergyReportPageContainer';
import StorageReportPageContainer from '../../views/report/StorageReportPageContainer';
import GenericReportPageContainer from '../../views/report/GenericReportPageContainer';
import TemperatureRecordReportPageContainer from '../../views/report/TemperatureRecordReportPageContainer';
import EquipmentReportPageContainer from '../../views/report/EquipmentReportPageContainer';
import EquipmentTempCheckReportPageContainer from '../../views/report/EquipmentTempCheckReportPageContainer';
import ReportErrorPageContainer from '../../views/report/ReportErrorPageContainer';
import EnergyTrackerReportPageContainer from '../../views/report/EnergyTrackerReportPageContainer/EnergyTrackerReportPageContainer';

import styles from './DefaultLayout.module.css';

import SiteInspectionReportPdfContainer from '../../views/report/SiteInspectionReportPageContainer/SiteInspectionReportPdfContainer';

type Props = {
  isInitialised: boolean;
  userIsInitialized: boolean;
};

function DefaultLayout({ isInitialised, userIsInitialized }: Props) {
  if (isInitialised === false || userIsInitialized === false) {
    return null;
  }
  const noNavigation = window.location!.pathname.includes('report');

  return (
    <div className={noNavigation ? '' : styles.root}>
      <Navigation noNavigation={noNavigation} />
      <main className={styles.content}>
        <TopBarContainer noNavigation={noNavigation} />
        <React.Suspense
          fallback={
            <BaseContentContainer isLoading>
              <span />
            </BaseContentContainer>
          }
        >
          <Switch className={styles.fullFrame}>
            <Route exact path={Routes.SITES} component={SitesPage} />

            <Route
              path={Routes.GENERIC_REPORT_CONTAINER_PAGE}
              render={({ match }) =>
                match && <GenericReportPageContainer {...match.params} />
              }
            />

            <Route
              path={Routes.TEMPERATURE_RECORD_REPORT_CONTAINER_PAGE}
              render={({ match }) =>
                match && (
                  <TemperatureRecordReportPageContainer {...match.params} />
                )
              }
            />

            <Route
              path={Routes.STORAGE_REPORT_CONTAINER_PAGE}
              render={({ match }) =>
                match && <StorageReportPageContainer {...match.params} />
              }
            />

            <Route
              path={Routes.ENERGY_REPORT_CONTAINER_PAGE}
              render={({ match }) =>
                match && <EnergyReportPageContainer {...match.params} />
              }
            />

            <Route
              path={Routes.EQUIPMENT_REPORT_CONTAINER_PAGE}
              render={({ match }) =>
                match && <EquipmentReportPageContainer {...match.params} />
              }
            />

            <Route
              exact
              path={Routes.SITE_PAGE}
              render={({ match }) =>
                match && <ShowSitePageContainer {...match.params} />
              }
            />

            <Route
              path={Routes.SITE_TEMP_CHECK_CONTAINER_PAGE}
              render={({ match }) =>
                match && (
                  <EquipmentTempCheckReportPageContainer {...match.params} />
                )
              }
            />

            <Route
              path={Routes.SITE_INSPECTION_REPORT_CONTAINER_PDF + '/display'}
              render={({ match }) =>
                match && (
                  <SiteInspectionReportPdfContainer {...match.params} debug />
                )
              }
            />

            <Route
              path={Routes.SITE_INSPECTION_REPORT_CONTAINER_PDF}
              render={({ match }) =>
                match && <SiteInspectionReportPdfContainer {...match.params} />
              }
            />

            <Route
              path={Routes.ENERGY_TRACKER_REPORT_CONTAINER_PAGE}
              render={({ match }) =>
                match && <EnergyTrackerReportPageContainer {...match.params} />
              }
            />

            <Route
              exact
              path={Routes.ERROR_PAGE}
              component={ReportErrorPageContainer}
            />

            <Redirect exact from="/" to={Routes.SITES} />
          </Switch>
        </React.Suspense>
      </main>
    </div>
  );
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  isInitialised: app.isInitialised,
  userIsInitialized: app.userIsInitialized,
});

export default connect(mapStateToProps)(DefaultLayout);
