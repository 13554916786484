import { SearchBox, Table } from '@energybox/react-ui-library/dist/components';
import { Site } from '@energybox/react-ui-library/dist/types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSites } from '../../../actions/sites';
import BaseContentContainer from '../../../components/layout/BaseContentContainer';
import { ApplicationState } from '../../../reducers';
import * as Routes from '../../../routes';
import styles from './SitesPage.module.css';

const hasSubstr = (s: string, q: string) =>
  (s || '').toLowerCase().indexOf((q || '').toLowerCase()) !== -1;

type Props = {
  path: string;
  sites: Site[];
  getSites: () => void;
  child?: any;
};

type State = {
  query: string;
  isNotificationLoading: boolean;
  notifications: any;
};

const createFullAddress = (site: Site) =>
  `${site.street || ''} ${site.city || ''} ${site.country ||
    ''} ${site.postalCode || ''}`;

class SitesPage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      isNotificationLoading: true,
      notifications: {},
    };
  }

  componentDidMount() {
    this.props.getSites();
  }

  handleSearchChange = query => {
    this.setState({ query });
  };
  // @ts-ignore
  sumValues = obj => Object.values(obj).reduce((a: number, b: number) => a + b);

  render() {
    const { sites } = this.props;
    const { query } = this.state;

    const filteredSites =
      query && query.length >= 3
        ? sites.filter((site: Site) =>
            hasSubstr(`${site.title}${site.address}`, query)
          )
        : sites;

    const columns = [
      {
        width: '35%',
        header: 'Site Name',
        cellContent: (site: Site) => (
          <Link to={`${Routes.SITES}/${site.id}`}>{site.title}</Link>
        ),
      },
      {
        width: '45%',
        header: 'Location',
        cellContent: (site: Site) => site.address || createFullAddress(site),
      },
    ];

    return (
      <div className={styles.root}>
        <BaseContentContainer fillFrame>
          <div className={styles.headerContainer}>
            <div className={styles.headerTitle}>
              {sites.length === 1 ? '1 site' : `${sites.length} sites`}
            </div>
            <SearchBox
              placeholder="Quick Search"
              onChange={this.handleSearchChange}
              query={query}
              width="12.5rem"
              widthActive="12.5rem"
              error={filteredSites.length === 0}
            />
          </div>
          <Table
            columns={columns}
            data={filteredSites.sort((s1, s2) =>
              s1.title.localeCompare(s2.title)
            )}
            renderLimit={25}
          />
        </BaseContentContainer>
      </div>
    );
  }
}

const mapStateToProps = ({ sites }: ApplicationState) => ({
  sites: sites.sites,
});
export default connect(mapStateToProps, { getSites })(SitesPage);
