import React from 'react';
import { Colors } from '../../utils/colors';

type Props = {
  text: React.ReactNode;
};
const BigNumberHeading = ({ text }: Props) => {
  return (
    <div
      style={{
        fontSize: '1.5rem',
        marginBottom: '1rem',
        color: Colors.AMBIENT,
      }}
    >
      {text}
    </div>
  );
};

export default BigNumberHeading;
