import { Actions as AnalyticActions } from '../actions/analytics';
import { Actions as EnergyActions } from '../actions/energy';
import { Actions as OperationalSampleActions } from '../actions/operationalSamples';
import {
  toggleLoading,
  toggleSuccess,
  toggleError,
} from '../utils/reducerFetchingStates';

export type StorageReport = {
  errors: StorageReportFetchStatuses;
  loading: StorageReportFetchStatuses;
  fetched: StorageReportFetchStatuses;
};

export type StorageReportFetchStatuses = {
  doorEntries: DoorEntriesFetchStatus;
  analytics: EnergyBySiteEquipmentTypeFetchStatuses;
  energyByEquipmentId: boolean;
  ambientDots: boolean;
};

export type EnergyBySiteEquipmentTypeFetchStatuses = {
  currentPeriod: boolean;
  prevPeriod: boolean;
};

export type DoorEntriesFetchStatus = {
  currentPeriod: boolean;
  prevPeriod: boolean;
};

const initialState: StorageReport = {
  errors: {
    doorEntries: {
      currentPeriod: false,
      prevPeriod: false,
    },
    analytics: {
      currentPeriod: false,
      prevPeriod: false,
    },
    energyByEquipmentId: false,
    ambientDots: false,
  },
  loading: {
    doorEntries: {
      currentPeriod: false,
      prevPeriod: false,
    },
    analytics: {
      currentPeriod: false,
      prevPeriod: false,
    },
    energyByEquipmentId: false,
    ambientDots: false,
  },
  fetched: {
    doorEntries: {
      currentPeriod: false,
      prevPeriod: false,
    },
    analytics: {
      currentPeriod: false,
      prevPeriod: false,
    },
    energyByEquipmentId: false,
    ambientDots: false,
  },
};

export default (state: StorageReport = initialState, action) => {
  switch (action.type) {
    case OperationalSampleActions.DOOR_READINGS_LOADING:
      return toggleLoading(['doorEntries', action.period], state);

    case OperationalSampleActions.DOOR_READINGS_ERROR:
      return toggleError(['doorEntries', action.period], state);

    case OperationalSampleActions.DOOR_READINGS_SUCCESS:
      return toggleSuccess(['doorEntries', action.period], state);

    case AnalyticActions.AMBIENT_DOT_LOADING:
      return toggleLoading('ambientDots', state);

    case AnalyticActions.AMBIENT_DOT_ERROR:
      return toggleError('ambientDots', state);

    case AnalyticActions.AMBIENT_DOT_SUCCESS:
      return toggleSuccess('ambientDots', state);

    case EnergyActions.ENERGY_BY_EQUIPMENTS_LOADING:
      return toggleLoading('energyByEquipmentId', state);

    case EnergyActions.ENERGY_BY_EQUIPMENTS_ERROR:
      return toggleError('energyByEquipmentId', state);

    case EnergyActions.ENERGY_BY_EQUIPMENTS_SUCCESS:
      return toggleSuccess('energyByEquipmentId', state);

    case EnergyActions.ANALYTICS_ENERGY_REPORT_PREV_PERIOD_LOADING:
      return toggleLoading(['analytics', 'prevPeriod'], state);

    case EnergyActions.ANALYTICS_ENERGY_REPORT_PREV_PERIOD_ERROR:
      return toggleError(['analytics', 'prevPeriod'], state);

    case EnergyActions.ANALYTICS_ENERGY_REPORT_PREV_PERIOD_SUCCESS:
      return toggleSuccess(['analytics', 'prevPeriod'], state);

    case EnergyActions.ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_LOADING:
      return toggleLoading(['analytics', 'currentPeriod'], state);

    case EnergyActions.ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_ERROR:
      return toggleError(['analytics', 'currentPeriod'], state);

    case EnergyActions.ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_SUCCESS:
      return toggleSuccess(['analytics', 'currentPeriod'], state);

    default:
      return state;
  }
};
