import {
  Button,
  InputField,
} from '@energybox/react-ui-library/dist/components';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/app';
import Logo from '../../components/icons/energybox/Logo';
import { ApplicationState } from '../../reducers';
import LoginBackground from '../../static/patterns/login_background.jpg';
import styles from './LoginPage.module.css';

type Props = {
  login: (email: string, password: string) => void;
  isLoading: boolean;
  failedLogin?: string;
};

type State = {
  email: string;
  password: string;
};

class LoginPage extends React.Component<Props, State> {
  state = {
    email: '',
    password: '',
  };

  handleChange = (name: keyof State) => (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => this.setState({ [name]: ev.target.value } as any);

  validateForm = () => {
    const { email, password } = this.state;
    return email !== '' && password !== '';
  };

  handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.login(email, password);
  };

  render() {
    const { email, password } = this.state;
    const { isLoading, failedLogin } = this.props;

    const formValid = this.validateForm();

    return (
      <React.Fragment>
        <header className={styles.header}>
          <Logo type="inverted" />
          <h1>Energybox</h1>
        </header>
        <section
          style={{ backgroundImage: `url(${LoginBackground})` }}
          className={styles.wrapper}
        >
          <form className={styles.form} onSubmit={this.handleLogin}>
            <div className={styles.formInnerWrapper}>
              <h2>Welcome</h2>

              <div className={styles.loginText}>Login to your account</div>

              <div className={styles.inputFields}>
                <div className={styles.inputFieldContainer}>
                  <InputField
                    name="email"
                    id="email"
                    disabled={isLoading}
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleChange('email')}
                  />
                </div>

                <div className={styles.inputFieldContainer}>
                  <InputField
                    name="password"
                    id="password"
                    disabled={isLoading}
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={this.handleChange('password')}
                  />
                </div>
              </div>

              <div
                className={classNames(
                  styles.formField,
                  styles.loginButtonWrapper
                )}
              >
                <Button
                  fullWidth
                  type="submit"
                  disabled={isLoading || formValid === false}
                  // isLoading={isLoading}
                >
                  Login
                </Button>
              </div>

              {failedLogin && (
                <div className={styles.errorMessage}>{failedLogin}</div>
              )}
            </div>
          </form>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ app, loading }: ApplicationState) => ({
  app: app,
  isLoading: loading.app,
  failedLogin: loading.failedLogin,
});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
