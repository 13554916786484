import {
  AnalyticsEnergyByEquipmentId,
  AnalyticsEnergyResponse,
  EnergyByEquipmentId,
  EnergyByEquipmentIdResponse,
  EnergyByFeatureNotificationId,
  EnergyBySiteEquipmentGroupResponse,
  EnergyBySiteEquipmentTypeResponse,
  EnergyDashboardBySiteId,
  EnergyData,
  EnergyReportThisMonthByEquipmentIdResponse,
  EnergySensorsByEquipmentId,
  EquipmentPowerBySiteId,
  EquipmentType,
  IsEnergyDashboardLoadingBySiteId,
  IsTotalConsumptionLoadingBySiteId,
  OrgEnergyByPeriod,
  TotalConsumption,
  TotalConsumptionBySiteId,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  normalizeTSEnergyByEquipmentId,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions } from '../actions/energy';
import { Actions as TSEnergyActions } from '../actions/energyTS';

type IsLoadingByResourceId = {
  [resourceId: string]: boolean;
};

export type Energy = {
  energyBySiteEquipmentType: EnergyBySiteEquipmentTypeResponse;
  energyByFeatureNotificationId: EnergyByFeatureNotificationId;
  equipmentPowerBySiteId: EquipmentPowerBySiteId;
  energySensorsByEquipmentId: EnergySensorsByEquipmentId;
  isLoading: boolean;
  energyDashboardBySiteId: EnergyDashboardBySiteId;
  isEnergyDashboardLoadingBySiteId: IsEnergyDashboardLoadingBySiteId;
  totalConsumptionBySiteId: TotalConsumptionBySiteId;
  isTotalConsumptionLoadingBySiteId: IsTotalConsumptionLoadingBySiteId;
  analyticsEnergySelectedTimePeriod?: AnalyticsEnergyResponse;
  analyticsEnergyLastMonth?: AnalyticsEnergyResponse;
  analyticsEnergyTwoMonthsAgo?: AnalyticsEnergyResponse;
  analyticsEnergyThreeMonthsAgo?: AnalyticsEnergyResponse;
  equipmentTypes: EquipmentType[];
  energyOverWeeks: EnergyData[];
  energyBySiteEquipmentGroup: EnergyBySiteEquipmentGroupResponse;
  energyReportThisMonthByEquipmentId: EnergyReportThisMonthByEquipmentIdResponse;
  totalConsumption: TotalConsumption;
  analyticsEnergyByEquipmentId: AnalyticsEnergyByEquipmentId;
  energyByEquipmentId: EnergyByEquipmentId | EnergyByEquipmentIdResponse;
  orgEnergyByPeriod?: OrgEnergyByPeriod;
  isLoadingByResourceId: IsLoadingByResourceId;
};

const initialState: Energy = {
  energyBySiteEquipmentGroup: {},
  energyBySiteEquipmentType: {},
  energyReportThisMonthByEquipmentId: {},
  energyByFeatureNotificationId: {},
  equipmentPowerBySiteId: {},
  energySensorsByEquipmentId: {},
  isLoading: false,
  energyDashboardBySiteId: {},
  isEnergyDashboardLoadingBySiteId: {},
  totalConsumptionBySiteId: {},
  isTotalConsumptionLoadingBySiteId: {},
  analyticsEnergySelectedTimePeriod: undefined,
  analyticsEnergyLastMonth: undefined,
  analyticsEnergyTwoMonthsAgo: undefined,
  analyticsEnergyThreeMonthsAgo: undefined,
  equipmentTypes: [],
  energyOverWeeks: [],
  totalConsumption: {
    prevPeriod: 0,
    currentPeriod: 0,
  },
  analyticsEnergyByEquipmentId: {},
  energyByEquipmentId: {},
  isLoadingByResourceId: {},
};

export default (state: Energy = initialState, action: any) => {
  switch (action.type) {
    case Actions.SITE_ENERGY_BY_WEEKS_SUCCESS:
      return R.pipe(R.assoc('energyOverWeeks', action.payload))(state);

    case Actions.ORG_ENERGY_BY_REPORTING_PERIOD_SUCCESS:
      return R.assocPath(
        ['orgEnergyByPeriod', action.reportingPeriod],
        action.payload.data.energy_equipment_type,
        state
      );

    case Actions.SITE_ENERGY_SELECTED_TIME_PERIOD_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['analyticsEnergySelectedTimePeriod', 'data'],
          action.payload.data
        ),
        R.assocPath(
          ['analyticsEnergySelectedTimePeriod', 'fromDate'],
          action.fromDate
        )
      )(state);

    case Actions.SITE_ENERGY_LAST_MONTH_SUCCESS:
      return R.pipe(
        R.assocPath(['analyticsEnergyLastMonth', 'data'], action.payload.data),
        R.assocPath(['analyticsEnergyLastMonth', 'fromDate'], action.fromDate)
      )(state);

    case Actions.SITE_ENERGY_2_MONTHS_AGO_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['analyticsEnergyTwoMonthsAgo', 'data'],
          action.payload.data
        ),
        R.assocPath(
          ['analyticsEnergyTwoMonthsAgo', 'fromDate'],
          action.fromDate
        )
      )(state);

    case Actions.SITE_ENERGY_3_MONTHS_AGO_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['analyticsEnergyThreeMonthsAgo', 'data'],
          action.payload.data
        ),
        R.assocPath(
          ['analyticsEnergyThreeMonthsAgo', 'fromDate'],
          action.fromDate
        )
      )(state);

    case Actions.SITE_ENERGY_SELECTED_TIME_PERIOD_ERROR:
      return R.pipe(
        R.assocPath(
          ['analyticsEnergySelectedTimePeriod', 'fromDate'],
          action.fromDate
        )
      )(state);

    case Actions.SITE_ENERGY_LAST_MONTH_ERROR:
      return R.pipe(
        R.assocPath(['analyticsEnergyLastMonth', 'fromDate'], action.fromDate)
      )(state);

    case Actions.SITE_ENERGY_2_MONTHS_AGO_ERROR:
      return R.pipe(
        R.assocPath(
          ['analyticsEnergyTwoMonthsAgo', 'fromDate'],
          action.fromDate
        )
      )(state);

    case Actions.SITE_ENERGY_3_MONTHS_AGO_ERROR:
      return R.pipe(
        R.assocPath(
          ['analyticsEnergyThreeMonthsAgo', 'fromDate'],
          action.fromDate
        )
      )(state);

    case Actions.ANALYTICS_ENERGY_REPORT_PREV_PERIOD_SUCCESS: {
      const totalConsumptionPrevPeriod = action.payload.data.total_kwh;

      return R.assocPath(
        ['totalConsumption', 'prevPeriod'],
        totalConsumptionPrevPeriod,
        state
      );
    }

    case Actions.ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_SUCCESS: {
      const consumptionProperty = action.payload.data.by_space;
      const energyByEquipmentId = {};
      const totalConsumption = action.payload.data.total_kwh;

      ([] as any)
        .concat(...Object.values(consumptionProperty))
        .forEach(consumptionData => {
          energyByEquipmentId[consumptionData.equipmentId] = {
            energyTotal: consumptionData.consumption,
            equipmentId: consumptionData.equipmentId,
          };
        });
      // }

      return R.pipe(
        R.assocPath(['totalConsumption', 'currentPeriod'], totalConsumption),
        R.assoc('analyticsEnergyByEquipmentId', energyByEquipmentId)
      )(state);
    }

    case Actions.ENERGY_BY_EQUIPMENTS_SUCCESS:
      return R.assoc(
        'energyByEquipmentId',
        mapArrayToObject(action.payload, 'equipmentId'),
        state
      );

    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_LOADING:
    case Actions.ENERGY_BY_EQUIPMENT_ID_LOADING:
      return R.assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        true,
        state
      );

    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_ERROR:
    case Actions.ENERGY_BY_EQUIPMENT_ID_ERROR:
      return R.assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        false,
        state
      );

    case Actions.ENERGY_BY_EQUIPMENT_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['energyByEquipmentId', action.equipmentId],
          action.payload
        ),
        R.assocPath(['isLoadingByResourceId', action.equipmentId], false)
      )(state);

    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['energyByEquipmentId', action.equipmentId],
          normalizeTSEnergyByEquipmentId(action.payload)
        ),
        R.assocPath(['isLoadingByResourceId', action.equipmentId], false)
      )(state);

    case Actions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR:
      return R.assocPath(
        ['energySensorsByEquipmentId', action.equipmentId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR:
      return R.assocPath(
        ['energySensorsByEquipmentId', action.equipmentId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_SUCCESS:
      return R.pipe(
        R.assocPath(['energySensorsByEquipmentId', action.equipmentId], {
          isLoading: false,
          sensors: action.payload,
        })
      )(state);

    default:
      return state;
  }
};
