import {
  generateEpochFromDate,
  getDateLast15MinuteStep,
} from '@energybox/react-ui-library/dist/utils';
import {
  endOfDay,
  startOfDay,
  startOfMonth as startOfMonthFn,
  subDays,
} from 'date-fns';

export function getPreviousMonthsEpoch(
  startOfMonthEpoch: number,
  howMany: number
) {
  let previousMonths: { to: number; from: number }[] = [];
  let currentEndMonth = startOfMonthEpoch;
  for (let i = 0; i < howMany; i++) {
    const endOfLastMonth = endOfDay(subDays(currentEndMonth * 1000, 1));
    const startOfLastMonth = new Date(
      endOfLastMonth.getFullYear(),
      endOfLastMonth.getMonth()
    );
    const from = generateEpochFromDate(startOfLastMonth);
    const to = generateEpochFromDate(endOfLastMonth);
    previousMonths.unshift({ from, to });
    currentEndMonth = from;
  }
  return previousMonths;
}

export function createNotificationDateFilter() {
  return Object.values(createTimeFilterMapping());
}

export function createDashboardDateFilter() {
  const desiredFilters = [
    'Last 7 days',
    'Last 30 days',
    'This month',
    'Last month',
  ];
  return Object.values(createTimeFilterMapping()).filter(({ title }) => {
    return desiredFilters.includes(title);
  });
}

export function createTimeFilterMapping() {
  const now = getDateLast15MinuteStep();
  const startOfToday = startOfDay(now);
  const yesterday = endOfDay(subDays(new Date(), 1));
  const startOfMonth = new Date(now.getFullYear(), now.getMonth());
  const endOfLastMonth = endOfDay(subDays(startOfMonth, 1));
  const startOfLastMonth = startOfMonthFn(endOfLastMonth);

  return {
    last7Days: {
      title: 'Last 7 days',
      fromDate: subDays(startOfToday, 7),
      toDate: yesterday,
    },
    last30Days: {
      title: 'Last 30 days',
      fromDate: subDays(startOfToday, 30),
      toDate: yesterday,
    },
    thisMonth: {
      title: 'This month',
      fromDate: startOfMonth,
      toDate: yesterday,
    },
    lastMonth: {
      title: 'Last month',
      fromDate: startOfLastMonth,
      toDate: endOfLastMonth,
    },
  };
}
