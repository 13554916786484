import { Equipment, Site } from '@energybox/react-ui-library/dist/types';
import format from 'date-fns/format';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dontGetEnergyByEquipmentIds,
  getAnalyticsEnergyConsumptionCurrentPeriod,
  getAnalyticsEnergyConsumptionPreviousPeriod,
  getEnergyByEquipmentIds,
} from '../../../actions/energy';
import { getEquipmentBySiteId } from '../../../actions/equipment';
import { getSite } from '../../../actions/sites';
import { getSpacesBySiteId } from '../../../actions/spaces';
import { useReportTimeFormats } from '../../../hooks/utils';
import { ApplicationState } from '../../../reducers';
import StorageReportPage from '../StorageReportPage';

type Props = {
  siteId: string;
  fromDate: string;
  toDate: string;
};

const StorageReportPageContainer: React.FC<Props> = ({
  siteId,
  fromDate,
  toDate,
}) => {
  const dispatch = useDispatch();
  const [
    attemptedToFetchEnergyByEquipmentIds,
    setAttemptedToFetchEnergyByEquipmentIds,
  ] = useState(false);

  //** useSelector **//
  const site = useSelector<ApplicationState, Site | undefined>(({ sites }) => {
    return sites.sitesById[siteId];
  });

  const equipments = useSelector<ApplicationState, Equipment[] | undefined>(
    ({ equipment }) => equipment.equipmentBySiteId[siteId]
  );

  const storageReportFetchStatus = useSelector(
    ({ storageReport }: ApplicationState) => {
      return storageReport.fetched;
    }
  );
  const {
    analytics: fetchedAnalytics,
    energyByEquipmentId: fetchedEnergyByEquipmentId,
  } = storageReportFetchStatus;
  //** end useSelector **//

  const reportTimeFormats = useReportTimeFormats(
    fromDate,
    toDate,
    site?.timeZone
  );
  const {
    fromDateInBrowserTimezone,
    toDateInBrowserTimezone,
    fromDateISO,
    fromDateUnixTimestamp,
    toDateISO,
    toDateUnixTimestamp,
    prevFromDateUnixTimestamp,
    prevToDateUnixTimestamp,
  } = reportTimeFormats;

  const siteIdNumber = parseInt(siteId);

  //** useEffect **//
  useEffect(() => {
    dispatch(getSite(siteIdNumber));
    dispatch(getSpacesBySiteId(siteIdNumber));
    dispatch(getEquipmentBySiteId(siteIdNumber));
  }, [siteIdNumber]);

  useEffect(() => {
    if (!fetchedAnalytics.currentPeriod) {
      dispatch(
        getAnalyticsEnergyConsumptionCurrentPeriod(
          fromDate,
          toDate,
          siteIdNumber
        )
      );
    }
  }, [siteIdNumber, fromDate, toDate, fetchedAnalytics.currentPeriod]);

  useEffect(() => {
    if (
      !fetchedAnalytics.prevPeriod &&
      prevFromDateUnixTimestamp &&
      prevToDateUnixTimestamp
    ) {
      const prevPeriodFromDate = format(
        prevFromDateUnixTimestamp * 1000,
        'yyyy-MM-dd'
      );
      const prevPeriodToDate = format(
        prevToDateUnixTimestamp * 1000,
        'yyyy-MM-dd'
      );
      dispatch(
        getAnalyticsEnergyConsumptionPreviousPeriod(
          prevPeriodFromDate,
          prevPeriodToDate,
          siteIdNumber
        )
      );
    }
  }, [
    siteIdNumber,
    prevFromDateUnixTimestamp,
    prevToDateUnixTimestamp,
    fetchedAnalytics.prevPeriod,
  ]);

  useEffect(() => {
    if (
      !!equipments &&
      !fetchedEnergyByEquipmentId &&
      !attemptedToFetchEnergyByEquipmentIds &&
      fromDateUnixTimestamp &&
      toDateUnixTimestamp
    ) {
      const AC_TYPE_ID = 40432;
      const HVACs = equipments.filter(
        (equipment: Equipment) => equipment.typeId === AC_TYPE_ID
      );
      if (HVACs.length > 0) {
        dispatch(
          getEnergyByEquipmentIds(
            HVACs,
            fromDateUnixTimestamp,
            toDateUnixTimestamp
          )
        );
        setAttemptedToFetchEnergyByEquipmentIds(true);
      } else {
        dispatch(dontGetEnergyByEquipmentIds());
      }
    }
  }, [
    fromDateUnixTimestamp,
    toDateUnixTimestamp,
    equipments,
    fetchedEnergyByEquipmentId,
    attemptedToFetchEnergyByEquipmentIds,
  ]);
  //** end useEffect **//

  if (!site || !equipments || !fromDateISO || !toDateISO) return <div />;

  return (
    <StorageReportPage
      site={site}
      reportTimeFormats={reportTimeFormats}
      equipments={equipments}
      fetchedStatuses={storageReportFetchStatus}
    />
  );
};

export default StorageReportPageContainer;
