import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getOperationalSamplesBySensorId} from '../actions/operationalSamples';
import {ApplicationState} from '../reducers';
import {OperationalSample} from "@energybox/react-ui-library/dist/types";


export const useGetOperationalSamples = (
  sensorId: string,
  from: string,
  to: string,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      sensorId: sensorId,
      from: from,
      to: to,
    };

    dispatch(getOperationalSamplesBySensorId(params));
  }, [sensorId, from, to, dispatch]);

  const operationalSamplesBySensorId = useSelector<
    ApplicationState,
    OperationalSample[] | undefined
  >(({ operationalSamples }) => {
    return operationalSamples.operationalSamplesBySensorId[sensorId];
  });

  const isLoading = useSelector<ApplicationState, boolean>(
    ({operationalSamples}) => {
      return operationalSamples.isLoadingBySensorId[sensorId] || false;
    }
  );

  return { operationalSamplesBySensorId, isLoading };
};
