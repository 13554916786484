import { Site } from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';

import React, { useMemo } from 'react';
import GrayInfoBox from '../../../components/GrayInfoBox';
import { GrayInfoBoxData } from '../../../components/GrayInfoBox/GrayInfoBox';
import SectionTitle from '../../../components/SectionTitle';
import { SF_SITE_TO_LOCKERS } from '../../../constants/storeFriendlySiteIdToLockers';
import { BigNumberDelta } from '../BigNumbers';

type Props = {
  isStoreFriendlyOrg: boolean;
  doorEntries: number;
  prevDoorEntries: number;
  numberOfDays: number;
  site: Site;
};

const StorageReportDoorStats = ({
  isStoreFriendlyOrg,
  site,
  doorEntries,
  prevDoorEntries,
  numberOfDays,
}: Props) => {
  const siteHasStorageLockers = !!SF_SITE_TO_LOCKERS[site.id];

  const grayInfoBoxData = useMemo(() => {
    const previousPeriodComparisonPercent = !prevDoorEntries
      ? null
      : Number(((doorEntries / prevDoorEntries) * 100 - 100).toFixed(1));

    const data = [
      {
        name: 'Total No. of Access',
        value: doorEntries || 0,
      },
      isStoreFriendlyOrg
        ? {
            name: 'Access per Storage Unit',
            value: siteHasStorageLockers
              ? Number((doorEntries / SF_SITE_TO_LOCKERS[site.id]).toFixed(2))
              : global.NOT_AVAILABLE,
          }
        : null,
      {
        name: `Compared to Previous ${numberOfDays} Days`,
        value: isDefined(previousPeriodComparisonPercent) ? (
          <BigNumberDelta
            fontSize={'1.5rem'}
            number={previousPeriodComparisonPercent}
          />
        ) : (
          global.NOT_AVAILABLE
        ),
      },
    ];

    return data.filter(d => d !== null) as GrayInfoBoxData[];
  }, [doorEntries, prevDoorEntries, siteHasStorageLockers]);

  return (
    <div>
      <SectionTitle title="Main Entrance Access Overview" />

      <GrayInfoBox data={grayInfoBoxData} />
    </div>
  );
};

export default StorageReportDoorStats;
