import {
  ACConsumptionRecommendations,
  ACConsumptionRecommendationsAPIResponse,
  ColdStorageRecommendations,
  ColdStorageRecommendationsAPIResponse,
  SiteTemperatureCheckAnalytics,
  UnnecessaryDoorAccessRecommendations,
  UnnecessaryDoorAccessRecommendationsAPIResponse,
} from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions } from '../actions/analytics';

export type Analytics = {
  ambientDots: AmbientDotReport;
  coldStorageRecommendations: ColdStorageRecommendations[];
  unnecessaryDoorAccess: UnnecessaryDoorAccessRecommendations[];
  temperatureComfortRecommendations: ACConsumptionRecommendations[];
  tempCheck: SiteTemperatureCheckAnalytics;
  organizationCO2Report: OrganizationCO2Report;
  organizationEnergySummaryReport: OrganizationEnergySummaryReport;
  organizationEnergyPdf: OrganizationEnergyPdf;
  organizationNonOperatingHour: OrganizationNOHEfficiency;
  monthlyNonOperatingHour: MonthlyNOHEfficiency;
};

export type OrganizationCO2Report = {
  [organizationId: number]: {
    isLoading: boolean;
    data: object | undefined;
    reportVersion: number | undefined;
    reportFetchTime: Date;
    reportKey: string | undefined;
  };
};

export type OrganizationEnergySummaryReport = {
  [organizationId: number]: {
    isLoading: boolean;
    data: object | undefined;
    reportVersion: number | undefined;
    reportFetchTime: Date;
    reportKey: string | undefined;
  };
};

export type OrganizationEnergyPdf = {
  [organizationId: number]: {
    isLoading: boolean;
    data: object | undefined;
    reportVersion: number | undefined;
    reportFetchTime: Date;
    reportKey: string | undefined;
  };
};

export type OrganizationNOHEfficiency = {
  [organizationId: number]: {
    isLoading: boolean;
    data: object | undefined;
    reportVersion: number | undefined;
    reportFetchTime: Date;
    reportKey: string | undefined;
  };
};

export type MonthlyNOHEfficiency = {
  [organizationId: number]: {
    isLoading: boolean;
    data: object | undefined;
    reportVersion: number | undefined;
    reportFetchTime: Date;
    reportKey: string | undefined;
  };
};

export type AmbientDotReport = {
  [sensorId: string]: {
    temperature: AmbientDotData;
    humidity: AmbientDotData;
  };
};

export type AmbientDotData = {
  mean: number;
  max: number;
  min: number;
};

const initialState: Analytics = {
  ambientDots: {},
  coldStorageRecommendations: [],
  unnecessaryDoorAccess: [],
  temperatureComfortRecommendations: [],
  tempCheck: {
    by_report: {},
  },
  organizationCO2Report: {},
  organizationEnergySummaryReport: {},
  organizationEnergyPdf: {},
  organizationNonOperatingHour: {},
  monthlyNonOperatingHour: {},
};

const KWH_PER_M2_TARGET = 500;

const Analytics = (state: Analytics = initialState, action: any) => {
  switch (action.type) {
    case Actions.AMBIENT_DOT_SUCCESS:
      return R.assoc('ambientDots', action.payload.data, state);

    case Actions.UNNECESSARY_DOOR_ACCESS_SUCCESS: {
      const formattedUnnecessaryDoorAccessData = (Object as any)
        .values(action.payload.data)
        .filter(recommendation => recommendation.site_id === action.siteId)
        .sort((a, b) => b.energy_savings_kwh - a.energy_savings_kwh)
        .map(
          (
            unnecessaryDoorAccessRecommendation: UnnecessaryDoorAccessRecommendationsAPIResponse,
            idx
          ) => ({
            idx: idx + 1,
            equipmentName: unnecessaryDoorAccessRecommendation.equipment_title,
            additionalEnergy:
              unnecessaryDoorAccessRecommendation.minute_access_kwh,
            duration: unnecessaryDoorAccessRecommendation.unnecessary_duration,
            energyWasted:
              unnecessaryDoorAccessRecommendation.energy_savings_kwh,
          })
        );

      return R.assoc(
        'unnecessaryDoorAccess',
        formattedUnnecessaryDoorAccessData,
        state
      );
    }

    case Actions.COOLER_ENERGY_RECOMMENDATIONS_SUCCESS:
      const formattedCoolerReductionRecommendationData = (Object as any)
        .values(action.payload.data)
        .filter(recommendation => recommendation.site_id === action.siteId)
        .sort((a, b) => b.percent_below_compliance - a.percent_below_compliance)
        .map(
          (
            coldStorageRecommendation: ColdStorageRecommendationsAPIResponse,
            idx: number
          ) => ({
            idx: idx + 1,
            equipmentName: coldStorageRecommendation.equipment_title,
            timeOverCooled: coldStorageRecommendation.percent_below_compliance,
            settingsAdjustment: coldStorageRecommendation.adjustment,
            savings: coldStorageRecommendation.energy_savings_kwh,
          })
        );

      return R.assoc(
        'coldStorageRecommendations',
        formattedCoolerReductionRecommendationData,
        state
      );

    case Actions.TEMPERATURE_COMFORT_RECOMMENDATIONS_SUCCESS:
      const formattedTemperatureComfortRecommendations = (Object as any)
        .values(action.payload.data)
        .filter(recommendation => recommendation.site_id === action.siteId)
        .sort((a, b) => b.energy_savings_kwh - a.energy_savings_kwh)
        .map(
          (
            temperatureComfortRecommendation: ACConsumptionRecommendationsAPIResponse,
            idx: number
          ) => {
            return {
              idx: idx + 1,
              equipmentName: temperatureComfortRecommendation.equipment_title,
              dissatisfiedCustomers:
                temperatureComfortRecommendation.percent_dissatisfied,
              settingsAdjustment: temperatureComfortRecommendation.adjustment,
              savings: temperatureComfortRecommendation.energy_savings_kwh,
            };
          }
        );

      return R.assoc(
        'temperatureComfortRecommendations',
        formattedTemperatureComfortRecommendations,
        state
      );

    case Actions.SITE_TEMP_CHECK_SUCCESS: {
      const analytics: SiteTemperatureCheckAnalytics = R.pathOr(
        undefined,
        ['payload', 'data'],
        action
      );
      if (analytics === undefined) return state;
      return R.assoc('tempCheck', analytics, state);
    }

    case Actions.START_ORGANIZATION_CO2_REPORT_LOADING:
      return R.assocPath(
        ['organizationCO2Report', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_ORGANIZATION_CO2_REPORT_SUCCESS:
      return R.assocPath(
        ['organizationCO2Report', action.organizationId],
        {
          // isLoading: false,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_ORGANIZATION_CO2_REPORT_ERROR:
      return R.assocPath(
        ['organizationCO2Report', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_ORGANIZATION_CO2_REPORT_LOADING:
      return R.assocPath(
        ['organizationCO2Report', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_ORGANIZATION_CO2_REPORT_SUCCESS:
      const {
        data: co2ReportData,
        report_version: co2ReportVersion,
      } = action.payload;
      const co2ReportKey = co2ReportVersion < 1 ? action.timestamp : undefined;
      return R.assocPath(
        ['organizationCO2Report', action.organizationId],
        {
          isLoading: co2ReportVersion < 1,
          data: co2ReportData,
          reportVersion: co2ReportVersion,
          reportFetchTime: new Date(),
          reportKey: co2ReportKey,
        },
        state
      );

    case Actions.GET_ORGANIZATION_CO2_REPORT_ERROR:
      return R.assocPath(
        ['organizationCO2Report', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING:
      return R.assocPath(
        ['organizationEnergySummaryReport', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS:
      return R.assocPath(
        ['organizationEnergySummaryReport', action.organizationId],
        {
          isLoading: true,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR:
      return R.assocPath(
        ['organizationEnergySummaryReport', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING:
      return R.assocPath(
        ['organizationEnergySummaryReport', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS:
      const {
        data: energySummaryReportData,
        report_version: energySummaryReportVersion,
      } = action.payload;
      const energySummaryReportKey =
        energySummaryReportVersion < 1 ? action.timestamp : undefined;
      return R.assocPath(
        ['organizationEnergySummaryReport', action.organizationId],
        {
          isLoading: energySummaryReportVersion < 1,
          data: energySummaryReportData,
          reportVersion: energySummaryReportVersion,
          reportFetchTime: new Date(),
          reportKey: energySummaryReportKey,
        },
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR:
      return R.assocPath(
        ['organizationEnergySummaryReport', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_PDF_LOADING:
      return R.assocPath(
        ['organizationEnergyPdf', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_PDF_SUCCESS:
      return R.assocPath(
        ['organizationEnergyPdf', action.organizationId],
        {
          isLoading: true,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_PDF_ERROR:
      return R.assocPath(
        ['organizationEnergyPdf', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_PDF_LOADING:
      return R.assocPath(
        ['organizationEnergyPdf', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_PDF_SUCCESS:
      const {
        data: energyPdfReportData,
        report_version: energyPdfReportVersion,
      } = action.payload;
      const energyPdfReportKey =
        energyPdfReportVersion < 1 ? action.timestamp : undefined;
      return R.assocPath(
        ['organizationEnergyPdf', action.organizationId],
        {
          isLoading: energyPdfReportVersion < 1,
          data: energyPdfReportData,
          reportVersion: energyPdfReportVersion,
          reportFetchTime: new Date(),
          reportKey: energyPdfReportKey,
          targetLevel: KWH_PER_M2_TARGET,
        },
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_PDF_ERROR:
      return R.assocPath(
        ['organizationEnergyPdf', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.START_ORG_NOH_LOADING:
      return R.assocPath(
        ['organizationNonOperatingHour', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_ORG_NOH_SUCCESS:
      return R.assocPath(
        ['organizationNonOperatingHour', action.organizationId],
        {
          isLoading: true,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_ORG_NOH_ERROR:
      return R.assocPath(
        ['organizationNonOperatingHour', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_ORG_NOH_LOADING:
      return R.assocPath(
        ['organizationNonOperatingHour', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_ORG_NOH_SUCCESS:
      const {
        data: orgNOHReportData,
        report_version: orgNOHReportVersion,
      } = action.payload;
      const orgNOHReportKey =
        orgNOHReportVersion < 1 ? action.timestamp : undefined;
      return R.assocPath(
        ['organizationNonOperatingHour', action.organizationId],
        {
          isLoading: orgNOHReportVersion < 1,
          data: orgNOHReportData,
          reportVersion: orgNOHReportVersion,
          reportFetchTime: new Date(),
          reportKey: orgNOHReportKey,
        },
        state
      );

    case Actions.GET_ORG_NOH_ERROR:
      return R.assocPath(
        ['organizationNonOperatingHour', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.START_MONTHLY_NOH_LOADING:
      return R.assocPath(
        ['monthlyNonOperatingHour', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_MONTHLY_NOH_SUCCESS:
      return R.assocPath(
        ['monthlyNonOperatingHour', action.organizationId],
        {
          isLoading: true,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_MONTHLY_NOH_ERROR:
      return R.assocPath(
        ['monthlyNonOperatingHour', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_MONTHLY_NOH_LOADING:
      return R.assocPath(
        ['monthlyNonOperatingHour', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_MONTHLY_NOH_SUCCESS:
      const {
        data: monthlyNOHReportData,
        report_version: monthlyNOHReportVersion,
      } = action.payload;
      const monthlyNOHReportKey =
        monthlyNOHReportVersion < 1 ? action.timestamp : undefined;
      return R.assocPath(
        ['monthlyNonOperatingHour', action.organizationId],
        {
          isLoading: monthlyNOHReportVersion < 1,
          data: monthlyNOHReportData,
          reportVersion: monthlyNOHReportVersion,
          reportFetchTime: new Date(),
          reportKey: monthlyNOHReportKey,
        },
        state
      );

    case Actions.GET_MONTHLY_NOH_ERROR:
      return R.assocPath(
        ['monthlyNonOperatingHour', action.organizationId, 'isLoading'],
        false,
        state
      );

    default:
      return state;
  }
};

export default Analytics;
