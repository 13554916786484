import { EnergyLineChart } from '@energybox/react-ui-library/dist/components';
import {
  Comment,
  Equipment,
  EnergyPowerApiResponse,
  FlatNotification,
  Site,
  UsersById,
  ValueType,
} from '@energybox/react-ui-library/dist/types';
import {
  isDefined,
  generateXTicks,
  processNotificationsByTypes,
} from '@energybox/react-ui-library/dist/utils';

import React, { useMemo } from 'react';
import { useAppLocale, useCurrentUser } from '../../hooks/useAppDetails';
import { CHART_WIDTH_FOR_PDF } from '../../utils/charts';
import { filterAndProcessComments } from '../../utils/comments';
import EquipmentChartAnalyticsContainer, {
  EquipmentChartType,
} from '../EquipmentReport/EquipmentChartAnalyticsContainer';
import SensorInfoContainer from '../EquipmentReport/SensorInfoContainer';
import styles from './EquipmentEnergyChartContainer.module.css';

type Props = {
  site: Site;
  equipment: Equipment;
  usersById: UsersById;
  fromDateISO: string;
  toDateISO: string;
  equipmentEnergy: EnergyPowerApiResponse | undefined;
  prevEquipmentEnergy: EnergyPowerApiResponse | undefined;
  notifications: FlatNotification[] | undefined;
  comments: Comment[] | undefined;
};

const EquipmentEnergyChartContainer: React.FC<Props> = ({
  site,
  equipment,
  usersById,
  fromDateISO,
  toDateISO,
  equipmentEnergy,
  prevEquipmentEnergy,
  notifications,
  comments,
}) => {
  const currentUser = useCurrentUser();
  const locale = useAppLocale();

  const fromDateUnixTimestampInMilliseconds = useMemo(() => {
    return Date.parse(fromDateISO);
  }, [fromDateISO]);
  const fromDateUnixTimestamp = fromDateUnixTimestampInMilliseconds / 1000;

  const toDateUnixTimestampInMilliseconds = useMemo(() => {
    return Date.parse(toDateISO);
  }, [toDateISO]);
  const toDateUnixTimestamp = toDateUnixTimestampInMilliseconds / 1000;

  const xTicks = useMemo(() => {
    return generateXTicks(
      fromDateUnixTimestampInMilliseconds,
      toDateUnixTimestampInMilliseconds,
      { ianaTimeZone: site.timeZone }
    );
  }, [fromDateUnixTimestamp, toDateUnixTimestamp]);

  const processedData = useMemo(() => {
    if (!equipmentEnergy) return undefined;
    const { readings, spilloverPoint } = equipmentEnergy;

    const processedReadings = [...readings];
    if (isDefined(spilloverPoint)) {
      processedReadings.push(spilloverPoint);
    }

    return processedReadings.map(d => ({
      timestamp: d.timestamp * 1000,
      value: d.power,
    }));
  }, [equipmentEnergy]);

  const processedNotifications = useMemo(() => {
    if (!currentUser || !notifications) return undefined;
    return processNotificationsByTypes(notifications, currentUser);
  }, [notifications]);

  const processedComments = useMemo(() => {
    if (!currentUser || !comments) return undefined;
    return filterAndProcessComments(
      comments,
      [equipment.id],
      currentUser,
      usersById,
      ValueType.ENERGY
    );
  }, [currentUser, comments]);

  if (!processedData) return null;
  return (
    <div>
      <div className={styles.chartTitle}>Energy</div>

      <EquipmentChartAnalyticsContainer
        site={site}
        fromDateInMilliseconds={fromDateUnixTimestampInMilliseconds}
        toDateInMilliseconds={toDateUnixTimestampInMilliseconds}
        chartType={EquipmentChartType.ENERGY}
        data={equipmentEnergy}
        prevData={prevEquipmentEnergy}
      />

      <SensorInfoContainer />

      {/* Chart width will look too long on localhost, but need this width for pdf to render chart correctly */}
      <div style={{ width: CHART_WIDTH_FOR_PDF }}>
        <EnergyLineChart
          isReport
          isLoading={false}
          locale={locale}
          ianaTimeZoneCode={site.timeZone}
          fromDate={fromDateUnixTimestampInMilliseconds}
          toDate={toDateUnixTimestampInMilliseconds}
          ticks={xTicks}
          data={processedData}
          notifications={processedNotifications}
          comments={processedComments}
          hideThresholdLinesCheckbox
          hideNotificationsCheckbox
          hideCommentsCheckbox
          //don't need these for equipment report
          //but need base values
          thresholdLines={[]}
          zoomIn={() => {}}
          refAreaStart={''}
          refAreaEnd={''}
          updateRefAreaStart={() => {}}
          updateRefAreaEnd={() => {}}
        />
      </div>
    </div>
  );
};

export default EquipmentEnergyChartContainer;
