import React from 'react';
import format from 'date-fns/format';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Line,
} from 'recharts';
import { TemperatureUnit } from '@energybox/react-ui-library/dist/types';

import { CombinedWeatherAndEnergyData } from '../../../types/weather';
import { CHART_WIDTH_FOR_PDF } from '../../../utils/charts';
import SectionTitle from '../../../components/SectionTitle';

type Props = {
  combinedData?: CombinedWeatherAndEnergyData[];
  hideSectionTitle?: boolean;
  tempUnit: TemperatureUnit;
};

type ChartPeriodLabel = {
  index: number;
  dateRange: string;
  fromDate: string;
  toDate: string;
  isLastTick: boolean;
};

interface ProcessedChartData extends CombinedWeatherAndEnergyData {
  periodLabelJSON: string;
}

const EnergyReportWeatherAndEnergyChart: React.FC<Props> = ({
  combinedData,
  hideSectionTitle,
  tempUnit,
}) => {
  if (!combinedData) return <div />;
  const processedData: ProcessedChartData[] = combinedData.map(
    (tick, index, array) => {
      const period: ChartPeriodLabel = {
        index,
        dateRange: tick.legend,
        fromDate: tick.fromDate,
        toDate: tick.toDate,
        isLastTick: index === array.length - 1,
      };
      return {
        ...tick,
        periodLabelJSON: JSON.stringify(period),
      };
    }
  );

  const BAR_COLOR = '#2BCBBF';
  const MIN_TEMP_COLOR = '#616CF9';
  const AVG_TEMP_COLOR = '#CCCC28';
  const MAX_TEMP_COLOR = '#FF407B';

  return (
    <div>
      {!hideSectionTitle && (
        <SectionTitle title="Weather and Energy Consumption Over Weeks" />
      )}

      {/* Chart width will look too long on localhost, but need this width for pdf to render chart correctly */}
      <div
        style={{
          marginTop: '2rem',
          position: 'relative',
          width: CHART_WIDTH_FOR_PDF,
        }}
      >
        <div style={{ position: 'absolute', top: '-30px', left: '30px' }}>
          kWh
        </div>
        <div style={{ position: 'absolute', top: '-30px', right: '45px' }}>
          {tempUnit}
        </div>
        <div
          style={{
            transform: 'rotate(-90deg)',
            position: 'absolute',
            left: '-80px',
            top: '45%',
          }}
        >
          Energy Consumption
        </div>
        <div
          style={{
            transform: 'rotate(-90deg)',
            position: 'absolute',
            right: '-25px',
            top: '45%',
          }}
        >
          Temperature
        </div>
        <ResponsiveContainer height={355} width={'100%'}>
          <ComposedChart data={processedData}>
            <CartesianGrid vertical={false} />
            <YAxis
              tick={{ fontSize: '14px', fill: 'black' }}
              axisLine={{ fill: 'black' }}
              tickLine={false}
              orientation="left"
              yAxisId={0}
            />
            <YAxis
              tick={{ fontSize: '14px', fill: 'black' }}
              axisLine={{ fill: 'black' }}
              tickLine={false}
              orientation="right"
              yAxisId={1}
            />
            <XAxis
              dataKey="periodLabelJSON"
              tick={renderLabel}
              axisLine={{ fill: 'black' }}
              tickLine={false}
              interval={0}
              position="outsideRight"
              height={55}
            />
            <Bar
              dataKey="totalConsumption"
              fill={BAR_COLOR}
              barSize={60}
              legend="none"
              isAnimationActive={false}
            />
            <Line
              dataKey="max_temperature"
              stroke={MAX_TEMP_COLOR}
              strokeWidth={3}
              dot={false}
              yAxisId={1}
              isAnimationActive={false}
            />
            <Line
              dataKey="min_temperature"
              stroke={MIN_TEMP_COLOR}
              dot={false}
              yAxisId={1}
              strokeWidth={3}
              isAnimationActive={false}
            />
            <Line
              dataKey="average_temperature"
              stroke={AVG_TEMP_COLOR}
              dot={false}
              yAxisId={1}
              strokeWidth={3}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '14px',
          marginTop: '1.5rem',
          marginLeft: '5%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: '2rem',
            color: 'var(--ambient-base)',
          }}
        >
          <hr
            style={{
              borderColor: MIN_TEMP_COLOR,
              backgroundColor: MIN_TEMP_COLOR,
              height: '2px',
              width: '15px',
              marginRight: '0.5rem',
            }}
          />
          Minimum Temperature
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: '2rem',
            color: 'var(--ambient-base)',
          }}
        >
          <hr
            style={{
              borderColor: AVG_TEMP_COLOR,
              backgroundColor: AVG_TEMP_COLOR,
              width: '15px',
              marginRight: '0.5rem',
              height: '2px',
            }}
          />
          Average Temperature
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: '2rem',
            color: 'var(--ambient-base)',
          }}
        >
          <hr
            style={{
              borderColor: MAX_TEMP_COLOR,
              backgroundColor: MAX_TEMP_COLOR,
              width: '15px',
              marginRight: '0.5rem',
              height: '2px',
            }}
          />
          Maximum Temperature
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            color: 'var(--ambient-base)',
          }}
        >
          <div
            style={{
              borderColor: BAR_COLOR,
              backgroundColor: BAR_COLOR,
              height: '15px',
              width: '15px',
              marginRight: '0.5rem',
            }}
          />
          Energy Consumption
        </div>
      </div>
    </div>
  );
};

const renderLabel = props => {
  const { x, y, payload } = props;
  const label: ChartPeriodLabel = JSON.parse(payload.value);
  const fromDate: number = parseInt(format(new Date(label.fromDate), 'd'));
  const toDate: number = parseInt(format(new Date(label.toDate), 'd'));

  let shouldShowMonth: boolean = false;
  let month: string = '';

  if (
    (fromDate <= 14 && toDate >= 14) ||
    (fromDate > 14 && label.index === 0) ||
    (toDate < 14 && label.isLastTick)
  ) {
    shouldShowMonth = true;
  }

  if (shouldShowMonth) {
    if (label.isLastTick) month = format(new Date(label.toDate), 'MMMM');
    else month = format(new Date(label.fromDate), 'MMMM');
  }

  return (
    <text transform={`translate(${x},${y + 15})`}>
      <tspan textAnchor="middle">{label.dateRange}</tspan>
      {shouldShowMonth && (
        <tspan x={0} dy="2.1rem" textAnchor="middle">
          {month}
        </tspan>
      )}
    </text>
  );
};

export default EnergyReportWeatherAndEnergyChart;
