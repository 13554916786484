import {
  DoorOpenDurationSop,
  EnergyTariff,
  UnnecessaryDoorAccessRecommendations,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  isDefined,
  KWH_UNIT,
} from '@energybox/react-ui-library/dist/utils';

import React, { useMemo } from 'react';
import GrayInfoBox from '../../GrayInfoBox';
import ItalicText from '../../ItalicText';
import RecommendationText from '../../RecommendationText';
import SectionTitle from '../../SectionTitle';
import styles from './DoorAccess.module.css';
import DoorAccessTable from './DoorAccessTable';

type Props = {
  doorAccessRecommendations: UnnecessaryDoorAccessRecommendations[];
  energySop?: EnergyTariff | null;
  doorSop?: DoorOpenDurationSop | null;
};

const displayDoorDurationTime = (seconds?: number) => {
  if (!seconds) return '3 Minutes';
  if (seconds < 60) {
    return `${seconds} Seconds`;
  } else {
    const minutes = Math.floor(seconds / 60);
    const moduloSeconds = seconds % 60;
    return `${minutes} Minutes${
      moduloSeconds ? ` ${moduloSeconds} Seconds` : ''
    }`;
  }
};

const DoorAccess = ({
  doorAccessRecommendations,
  energySop,
  doorSop,
}: Props) => {
  const doorDuration = doorSop ? doorSop.duration : undefined;
  const currency = energySop ? energySop.currencyCode : undefined;

  const totalEnergyWasted = doorAccessRecommendations.reduce(
    (sum, next) => sum + next.energyWasted,
    0
  );

  const totalPotentialSavings = !!energySop
    ? totalEnergyWasted * energySop.tariff
    : 0;

  const savingsText = !!energySop
    ? `* If our recommendations are fully implemented`
    : '* Please configure a SOP with information about your energy tariffs to enable savings calculations';

  const grayInfoBoxData = useMemo(() => {
    return [
      {
        name: 'Piece(s) of Equipment',
        value: doorAccessRecommendations.length,
      },
      {
        name: 'Total Energy Wasted',
        value: !isDefined(totalEnergyWasted)
          ? global.NOT_AVAILABLE
          : `${Number(totalEnergyWasted.toFixed(1))} ${KWH_UNIT}`,
      },
      {
        name: 'Total Estimated Savings*',
        value: `${
          !isDefined(totalPotentialSavings)
            ? global.NOT_AVAILABLE
            : Number(totalPotentialSavings.toFixed(2))
        } ${currency}`,
      },
    ];
  }, [
    doorAccessRecommendations,
    totalEnergyWasted,
    totalPotentialSavings,
    currency,
  ]);

  return (
    <div className={styles.content}>
      <div className={styles.headerContainer}>
        <div className={styles.headerText}>
          <SectionTitle
            title={`Reduce Unnecessary (More Than ${displayDoorDurationTime(
              doorDuration
            )}) Door Access`}
            description="The system has noticed the following refrigeration equipment to be wasting energy due to the 
            door being left open longer than the recommended protocol. The longer the door is open, the more 
            cold air escapes (replaced by warm air), the more energy is wasted."
          />
        </div>
      </div>

      <GrayInfoBox data={grayInfoBoxData} />

      <DoorAccessTable
        doorAccessRecommendations={doorAccessRecommendations}
        currency={currency}
        tariff={energySop}
      />
      <RecommendationText
        text="To reduce this waste, set up Energybox’s alerts to either alarm 
        your staff through sound or via email if the door of an equipment is 
        left open for too long."
      />
      <ItalicText text={savingsText} />
    </div>
  );
};

export default DoorAccess;
