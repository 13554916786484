import {
  EnergyDataForSankeyChart,
  FormattedEnergyConsumptionData,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import {
  DonutChartLegend,
  RechartsDonutChart,
} from '../../../components/Charts/Donut';
import SankeyChart from '../../../components/Charts/SankeyChart';
import SectionTitle from '../../../components/SectionTitle';
import { decorateDataWithColors } from '../../../utils/colorForDonutType';

type Props = {
  siteId: number;
  consumptionData: FormattedEnergyConsumptionData[];
  sankeyData: EnergyDataForSankeyChart[];
};

const StorageReportConsumptionCharts = ({
  siteId,
  consumptionData,
  sankeyData,
}: Props) => {
  if (!consumptionData || !sankeyData) return <div />;
  let formattedConsumptionData: FormattedEnergyConsumptionData[] = [];

  if (consumptionData.length > 7) {
    const otherData = consumptionData
      .sort((a, b) => b.count - a.count)
      .slice(7);

    let othersTotal = 0;

    otherData.forEach(_data => {
      othersTotal = othersTotal + _data.count;
    });

    const others = {
      count: othersTotal,
      label: 'Others',
      type: 'Others',
      unit: '',
    };

    formattedConsumptionData = consumptionData.slice(0, 7);

    formattedConsumptionData.push(others);
  } else {
    formattedConsumptionData = consumptionData;
  }

  const sortedData = formattedConsumptionData
    .sort((a, b) => b.count - a.count)
    .map(d => ({ ...d, unit: '' }));
  const sortedDataWithChartColors = decorateDataWithColors(sortedData);

  if (consumptionData.length === 0 && sankeyData.length === 0) {
    return <div />;
  }
  //return empty in first place if consumptionData count is 0
  const total = consumptionData.reduce((acc, datum) => acc + datum.count, 0);
  if (total === 0) {
    return <div />;
  }

  return (
    <div>
      <SectionTitle title="Energy Consumption Overview" />

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'center',
          marginTop: '2rem',
          marginBottom: '2rem',
        }}
      >
        <RechartsDonutChart data={sortedDataWithChartColors} report />
        <DonutChartLegend
          siteId={siteId}
          data={sortedDataWithChartColors}
          showAbsoluteConsumption
        />
      </div>

      <div>
        <SankeyChart className="sankey" data={sankeyData} report />
      </div>
    </div>
  );
};

export default StorageReportConsumptionCharts;
