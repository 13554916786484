import React from 'react';

type ReportMeasurableElementProps = {
  setRef: any;
  currentTotalHeight?: number;
  pageHeight?: number;
  updatePage?: () => void;
  key?: number | string;
  shouldStartNewPage?: boolean;
};

type ReportMeasurableElementState = {
  update: boolean;
};

export default class ReportMeasurableElement extends React.Component<
  ReportMeasurableElementProps,
  ReportMeasurableElementState
> {
  private childrenWithProps: any;
  constructor(props) {
    super(props);
    this.state = {
      update: false,
    };
    this.childrenWithProps = [];
  }

  updateWrapper = () => {
    this.setState({ update: true });
    if (!!this.props.updatePage) {
      this.props.updatePage();
    }
  };

  render() {
    const {
      key,
      setRef,
      currentTotalHeight = 0,
      pageHeight,
      shouldStartNewPage,
    } = this.props;
    if (!pageHeight) {
      return <div data-testid="emptyDiv" />;
    }

    const heightTillPageBreak = pageHeight - currentTotalHeight;

    // @ts-ignore
    this.childrenWithProps = React.Children.map(this.props.children, child => {
      // @ts-ignore
      return React.cloneElement(child, {
        updateWrapper: () => {
          this.updateWrapper();
        },
      });
    });

    return (
      <div
        key={key || ''}
        data-testid={'measurableWrapper'}
        ref={setRef}
        style={
          shouldStartNewPage
            ? //50px is there as an edge case where heightTillPageBreak exactly matches 0
              {
                marginTop: `${heightTillPageBreak || 50}px`,
              }
            : {}
        }
      >
        {
          // @ts-ignore
          this.childrenWithProps
        }
      </div>
    );
  }
}
