import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  Comment,
  CommentTypeLabel,
  CurrentUser,
  Sensor,
  Site,
  User,
  UsersById,
  ValueType,
  ValueTypeLabel,
} from '@energybox/react-ui-library/dist/types';
import {
  formatIanaTimeZone,
  formattedCommentValueString,
  global,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';

import React, { useMemo } from 'react';
import { useAppLocale, useCurrentUser } from '../../../hooks/useAppDetails';
import styles from './CommentUserLogs.module.css';

type Props = {
  site: Site;
  comments: Comment[] | undefined;
  usersById: UsersById;
  sensors: Sensor[];
};

type ProcessedCommentData = {
  valueDate: string;
  createdAtDate: string;
  valueString: string;
  commentTypeLabel: string;
  comment: string;
  valueType: ValueType;
  resourceName: string | undefined;
  username: string;
};

const CommentUserLogs: React.FC<Props> = ({
  site,
  sensors,
  comments,
  usersById,
}) => {
  const countryCode = site.country;
  const ianaTimeZoneCode = site.timeZone;

  const currentUser = useCurrentUser();
  const locale = useAppLocale();
  const sensorsById = useMemo(() => {
    return mapArrayToObject(sensors);
  }, [sensors]);

  const processComments = (comments: Comment[], currentUser: CurrentUser) => {
    return comments.map(c => ({
      valueDate: c.from,
      createdAtDate: c.createdAt,
      valueString: formattedCommentValueString(
        c.value,
        c.valueType,
        currentUser,
        countryCode
      ),
      valueType: c.valueType,
      comment: c.comment,
      commentTypeLabel: CommentTypeLabel[c.commentType],
      resourceName: determineResourceName(
        c.valueType,
        sensorsById[c.resourceId]
      ),
      username: c.username || global.NOT_AVAILABLE,
    }));
  };

  const processedData: ProcessedCommentData[] | undefined = useMemo(() => {
    if (!currentUser || !comments) return undefined;
    return processComments(comments, currentUser);
  }, [currentUser, comments, usersById]);

  const columns: Columns<ProcessedCommentData>[] = useMemo(() => {
    return [
      {
        width: '15%',
        header: <span className={styles.tableHeader}>Date/Time</span>,
        cellContent: ({ valueDate }) => {
          const dateString = formatIanaTimeZone(
            Date.parse(valueDate),
            locale.dateFormat,
            ianaTimeZoneCode
          );
          const timeString = formatIanaTimeZone(
            Date.parse(valueDate),
            locale.timeFormat,
            ianaTimeZoneCode,
            true
          );
          return (
            <div>
              <div>{dateString}</div>
              <div className={styles.paddingTop}>{timeString}</div>
            </div>
          );
        },
      },
      {
        width: '20%',
        header: <span className={styles.tableHeader}>Reading</span>,
        cellContent: ({ resourceName, valueString }) => (
          <div>
            <div>{resourceName || global.NOT_AVAILABLE}</div>
            <div className={styles.paddingTop}>{valueString}</div>
          </div>
        ),
      },
      {
        width: '20%',
        header: <span className={styles.tableHeader}>Comment Type</span>,
        cellContent: ({ commentTypeLabel }) => (
          <div>{commentTypeLabel || global.NOT_AVAILABLE}</div>
        ),
      },
      {
        width: '45%',
        header: <span className={styles.tableHeader}>Comment Details</span>,
        cellContent: ({ username, comment, createdAtDate }) => (
          <div>
              <div className={styles.semiBold}>
                {username}
              </div>
            <div className={styles.paddingTop}>{comment}</div>
            <div className={styles.paddingTop}>
              <span className={styles.smallGreyText}>
                {formatIanaTimeZone(
                  Date.parse(createdAtDate),
                  locale.fullDateTimeFormat,
                  ianaTimeZoneCode,
                  true
                )}
              </span>
            </div>
          </div>
        ),
      },
    ];
  }, [comments, usersById]);

  if (!processedData || processedData.length === 0) return null;
  return (
    <div className={styles.root}>
      <Table
        highlightAlternateRows
        className={styles.table}
        columns={columns}
        data={processedData}
      />
    </div>
  );
};

const determineResourceName = (
  valueType: ValueType,
  sensor: Sensor | undefined
) => {
  if (valueType === ValueType.ENERGY) {
    return ValueTypeLabel.ENERGY;
  }
  if (sensor) {
    return sensor.title;
  }
  return undefined;
};

export default CommentUserLogs;
