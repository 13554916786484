import { Comment } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDownstreamComments } from '../actions/comments';
import { ApplicationState } from '../reducers';

export const useGetDownstreamComments = (
  resourceId: number,
  fromISO: string | undefined,
  toISO: string | undefined
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (fromISO && toISO) {
      dispatch(getDownstreamComments(resourceId, { from: fromISO, to: toISO }));
    }
  }, [resourceId, fromISO, toISO, dispatch]);

  const comments = useSelector<ApplicationState, Comment[] | undefined>(
    ({ comments }) => {
      return comments.commentsByResourceId[resourceId];
    }
  );

  return comments;
};
