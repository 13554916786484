import {
  ACConsumptionRecommendations,
  EnergyTariff,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  isDefined,
  KWH_UNIT,
} from '@energybox/react-ui-library/dist/utils';

import React, { useMemo } from 'react';
import GrayInfoBox from '../../GrayInfoBox';
import ItalicText from '../../ItalicText';
import RecommendationText from '../../RecommendationText';
import SectionTitle from '../../SectionTitle';
import styles from './ACConsumption.module.css';
import ACConsumptionTable from './ACConsumptionTable';

type Props = {
  ACConsumptionRecommendations: ACConsumptionRecommendations[];
  sop?: EnergyTariff | null;
};

const ACConsumption = ({ ACConsumptionRecommendations, sop }: Props) => {
  const totalEnergyWasted = ACConsumptionRecommendations.reduce(
    (sum, next) => sum + next.savings,
    0
  );

  const totalSavings = !!sop ? totalEnergyWasted * sop.tariff : 0;

  const grayInfoBoxData = useMemo(() => {
    return [
      {
        name: 'Piece(s) of Equipment',
        value: ACConsumptionRecommendations.length,
      },
      {
        name: 'Total Energy Wasted',
        value: !isDefined(totalEnergyWasted)
          ? global.NOT_AVAILABLE
          : `${Number(totalEnergyWasted.toFixed(1))} ${KWH_UNIT}`,
      },
      {
        name: 'Total Estimated Savings*',
        value: `${
          !isDefined(totalSavings)
            ? global.NOT_AVAILABLE
            : Number(totalSavings.toFixed(2))
        } ${sop ? sop.currencyCode : undefined}`,
      },
    ];
  }, [ACConsumptionRecommendations, totalEnergyWasted, totalSavings, sop]);

  const savingsText = `* If our recommendations are fully implemented`;

  return (
    <div className={styles.styles}>
      <div className={styles.headerContainer}>
        <div className={styles.headerText}>
          <SectionTitle
            title="Energy Savings: Recommendations for AC"
            description="The system has noticed the following spaces associated 
            with the following HVACs are either too cold or too hot. This is causing  
            discomfort in customer within the space and in some case resulting in 
            unnecessary expenses."
          />
        </div>
      </div>

      <GrayInfoBox data={grayInfoBoxData} />

      <ACConsumptionTable
        currency={sop ? sop.currencyCode : undefined}
        ACConsumptionRecommendations={ACConsumptionRecommendations.slice(0, 5)}
        tariff={sop}
      />

      <RecommendationText
        text="Make adjustments to your HVAC  settings as per the recommendation and 
        use the Energybox Platform’s  customer comfort notification feature to get 
        immediate notification on HVAC setting recommendation."
      />

      <ItalicText text={savingsText} />
    </div>
  );
};

export default ACConsumption;
