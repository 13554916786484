import { PieSection } from '@energybox/react-ui-library/dist/types';

const colors = [
  '#2BCBBF',
  '#616CF9',
  '#CCCE00',
  '#ED6087',
  '#F8C737',
  '#9650CC',
  '#FF9248',
  '#024984',
];

// This function assumes the data has first been sorted wrt count
export const decorateDataWithColors = (data: PieSection[]) => {
  if (data.length === colors.length) {
    return data.map((consumptionEntry, index) => ({
      ...consumptionEntry,
      chartColor: colors[index],
    }));
  } else {
    return data.map((consumptionEntry, index) => {
      if (index === data.length - 1) {
        return {
          ...consumptionEntry,
          chartColor: colors[colors.length - 1],
        };
      } else {
        return {
          ...consumptionEntry,
          chartColor: colors[index],
        };
      }
    });
  }
};
