import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSiteTempCheck } from '../../actions/analytics';
import { ApplicationState } from '../../reducers';

export const useGetSiteTempCheck = (
  siteId: number | undefined,
  fromDate: string, // 'yyyy-MM-dd'
  toDate: string
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!siteId) return;
    dispatch(getSiteTempCheck(siteId, fromDate, toDate));
  }, [siteId, fromDate, toDate, dispatch]);

  const siteTempCheck = useSelector(({ analytics }: ApplicationState) => {
    return analytics.tempCheck;
  });
  return siteTempCheck;
};
