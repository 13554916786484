import { DateFilterTimePeriod } from '../reducers/dateFilter';

export enum Actions {
  DATE = '@@dateFilter/DATE',
}

export const setDateFilter = (dateFilter: DateFilterTimePeriod) => ({
  type: Actions.DATE,
  payload: dateFilter,
});
