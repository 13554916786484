import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { Colors } from '../../utils/colors';
import { BigNumberDelta } from '../../views/report/BigNumbers';
import BigNumberHeading from '../BigNumberHeading';

type Props = {
  unit?: string;
  value: number | null;
  headerText: React.ReactNode;
  color?: Colors;
  decimals?: number;
  isDelta?: boolean;
};

const BigNumberWithUnitAndHeading = ({
  unit,
  value,
  headerText,
  color,
  decimals,
  isDelta,
}: Props) => {
  let displayValue;
  if (isDefined(value)) {
    displayValue = isDefined(decimals) ? value.toFixed(decimals) : value;
  } else {
    displayValue = global.NOT_AVAILABLE;
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <BigNumberHeading text={headerText} />

      {isDelta ? (
        displayValue === global.NOT_AVAILABLE ? (
          <div>{global.NOT_AVAILABLE}</div>
        ) : (
          <BigNumberDelta number={+displayValue} />
        )
      ) : (
        <div
          style={{
            fontSize: '2rem',
            fontWeight: 'bold',
            color: color || Colors.BLACK,
          }}
        >
          {displayValue}
          {!!unit && value !== null && value >= 0 ? <span> {unit}</span> : null}
        </div>
      )}
    </div>
  );
};

export default BigNumberWithUnitAndHeading;
