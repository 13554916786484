export enum Service {
  iam = 'SERVICE_IAM',
  sentinel = 'SERVICE_SENTINELS',
  tsd = 'SERVICE_TSD_ROOT',
  cdn = 'SERVICE_CDN',
  analytics = 'SERVICE_ANALYTICS',
  reports = 'SERVICE_REPORTS',
  weather = 'SERVICE_WEATHER',
  timeSeries = 'SERVICE_TIMESERIES',
  comments = 'SERVICE_COMMENTS',
}

export function getConfig() {
  const serviceUrls = {
    [Service.iam]:
      process.env.REACT_APP_API_BASE_URL || 'https://iam.staging.energybox.com',
    [Service.sentinel]:
      process.env.REACT_APP_SERVICE_SENTINEL_BASE_URL ||
      'https://sentinel.staging.energybox.com',
    [Service.cdn]:
      process.env.REACT_APP_SERVICE_CDN_BASE_URL ||
      'https://cdn.staging.energybox.com',
    [Service.analytics]:
      process.env.REACT_APP_SERVICE_ANALYTICS_ROOT ||
      'https://analytics.staging.energybox.com',
    [Service.reports]:
      process.env.REACT_APP_SERVICE_REPORTS_ROOT ||
      'https://reports.staging.energybox.com',
    [Service.weather]:
      process.env.REACT_APP_SERVICE_WEATHER_ROOT ||
      'https://weather.staging.energybox.com',
    [Service.timeSeries]:
      process.env.REACT_APP_SERVICE_TIMESERIES_ROOT ||
      'https://ts.staging.energybox.com',
    [Service.comments]:
      process.env.REACT_APP_SERVICE_COMMENTS_ROOT ||
      'https://logging.staging.energybox.com',
  };

  if (
    process.env.ENVIRONMENT === 'production' &&
    Object.values(serviceUrls).includes('')
  ) {
    throw new Error('env vars incomplete');
  }

  return { serviceUrls };
}

const config = getConfig();

export function getServiceUrl(service): string {
  switch (service) {
    case Service.sentinel:
      return config.serviceUrls.SERVICE_SENTINELS;
    case Service.analytics:
      return config.serviceUrls.SERVICE_ANALYTICS;
    case Service.reports:
      return config.serviceUrls.SERVICE_REPORTS;
    case Service.weather:
      return config.serviceUrls.SERVICE_WEATHER;
    case Service.timeSeries:
      return config.serviceUrls.SERVICE_TIMESERIES;
    case Service.comments:
      return config.serviceUrls.SERVICE_COMMENTS;
    default:
      return config.serviceUrls.SERVICE_IAM;
  }
}
