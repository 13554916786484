import {
  ComparisonData,
  FilterTimePeriod,
} from '@energybox/react-ui-library/dist/types';
import {
  DefaultChartStyles,
  formatCurrencySymbol,
  genericYAxisTickFormatter,
} from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import {
  Bar,
  Cell,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomXTick from '../ReportChartCustomXTick/ReportChartCustomXTick';
import styles from './EnergyUseIntensityChart.module.css';

export type Props = {
  data: ComparisonData[];
  dataUnit: string;
  // If all bars should be the same just pass a string, else it should be an
  // array of same length of data with a color for each comparison slice
  dataFillColor: string | string[];
  tooltip: React.ReactNode;
  timePeriod?: FilterTimePeriod;
  xDataKey?: string;
  yDataKey?: string;
  showCurrency?: boolean;
};

const EnergyUseIntensityChart = (props: Props) => {
  const {
    data,
    dataUnit,
    dataFillColor,
    tooltip,
    timePeriod,
    xDataKey = 'entity',
    yDataKey = 'value',
    showCurrency,
  } = props;
  
  return (
    <div className={styles.root}>
      <div className={styles.yUnitLabel}>
        {showCurrency ? formatCurrencySymbol(dataUnit) : dataUnit}
      </div>
      <ResponsiveContainer width={700} height={330}>
        <ComposedChart data={data}>
          <CartesianGrid
            vertical={false}
            stroke={DefaultChartStyles.axisLineColor}
          />
          <YAxis
            type="number"
            tick={{
              fontSize: 16,
              fill: DefaultChartStyles.tick.fill,
            }}
            tickFormatter={genericYAxisTickFormatter}
            axisLine={false}
            tickLine={false}
            width={45}
          />
          <XAxis
            type="category"
            dataKey={xDataKey}
            tick={CustomXTick(data, timePeriod)}
            axisLine={{
              stroke: DefaultChartStyles.axisLineColor,
            }}
            tickLine={false}
            interval={0}
          />
          <Bar
            dataKey={yDataKey}
            unit={dataUnit}
            barSize={50}
            isAnimationActive={true}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  Array.isArray(dataFillColor)
                    ? dataFillColor[index]
                    : dataFillColor
                }
              />
            ))}
          </Bar>
          <Tooltip content={tooltip} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EnergyUseIntensityChart;
