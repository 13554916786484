import { Cell } from '@energybox/react-ui-library/dist/components/CardList';
import {
  Bar,
  ComposedChart,
  BarChart,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styles from './EnergyOffenderTile.module.css';
import * as Routes from '../../../routes';

type Props = {
  className?: string;
  data: {
    num_of_non_offender: number;
    num_of_offender: number;
    percent_non_offender: number;
    percent_offender: number;
  };
  isLoading: boolean;
};

const getPath = (x, y, width, height, slope, side) => {
  const lineThickness = slope == 0 ? 0 : 1;
  if (side === 'left') {
    return `M${x},${y || 5}
            H${x + width + slope - lineThickness}
            L${x + width - slope - lineThickness},${(y || 5) + 108}
            H${x}
            V${y || 5}`;
  } else {
    return `M${x + slope + lineThickness},${y || 5}
            L${x - slope + lineThickness},${(y || 5) + 108}
            H${x + width}
            V${y || 5}
            H${x}`;
  }
};

const LeftBar = (props) => {
  const {
    fill,
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    inTarget = 0,
    outTarget = 0,
  } = props;
  let slope = 24;
  if (inTarget < 25 || outTarget < 25) {
    slope = inTarget < outTarget ? inTarget / 2 : outTarget / 2;
  }
  return (
    <path
      d={getPath(x, y, width, height, slope, 'left')}
      stroke="none"
      fill={fill}
    />
  );
};

const RightBar = (props) => {
  const {
    fill,
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    inTarget = 0,
    outTarget = 0,
  } = props;
  let slope = 24;
  if (inTarget < 25 || outTarget < 25) {
    slope = inTarget < outTarget ? inTarget / 2 : outTarget / 2;
  }
  return (
    <path
      d={getPath(x, y, width, height, slope, 'right')}
      stroke="none"
      fill={fill}
    />
  );
};

const renderLabelLeft = (props) => {
  const { x = 70, y = 80, width, value = '', payload } = props;
  const calculatedX = payload[0].inTarget < 25 ? x : x + 20;
  return (
    <g className={styles.labelContainerLeft}>
      <text
        x={calculatedX}
        y={y || 80}
        fill="#fff"
        className={styles.barLabelLeft}
      >
        {`${payload[0].inTarget}%`}
      </text>
    </g>
  );
};

const renderLabelRight = (props) => {
  const { x = 70, y = 70, width, offset, value = '', payload } = props;
  const calculatedX = value < 25 ? x + width - 70 : x + width - 85;
  return (
    <g className={styles.labelContainerRight}>
      <text
        x={calculatedX}
        y={y || 70}
        fill="#fff"
        className={styles.barLabelRight}
      >
        {`${value}%`}
      </text>
    </g>
  );
};

const EnergyOffenderTile: React.FC<Props> = ({
  className,
  data,
  isLoading,
}) => {
  const {
    num_of_non_offender = 0,
    num_of_offender = 0,
    percent_non_offender  = 0,
    percent_offender  = 0,
  } = data || {};
  const formattedData = [
    {
      name: 'Sites within Target',
      siteInTarget: num_of_non_offender,
      siteOutTarget: num_of_offender,
      inTarget: percent_non_offender,
      outTarget: percent_offender,
    },
  ];

  return (
    <>
        <div className={styles.tileTitle}>
          {'Energy Offenders'}
        </div>
        <div className={styles.sitesInTarget}>
          <div className={styles.siteCount}>
            {formattedData && formattedData[0].siteInTarget}
          </div>
          <div className={styles.subText}>{'Sites within Target'}</div>
        </div>
        <div className={styles.sitesOutTarget}>
          <div className={styles.siteCount}>
            {formattedData && formattedData[0].siteOutTarget}
          </div>
          <div className={`${styles.subText} ${styles.right}`}>
            {'Sites outside of Target'}
          </div>
        </div>
        <ResponsiveContainer width={700} height={330}>
          <BarChart
            width={700}
            height={330}
            layout="vertical"
            data={formattedData}
          >
            <XAxis hide={true} width={340} type={'number'} domain={[0, 100]} />
            <YAxis hide={true} dataKey={'name'} />

            <Bar
              dataKey="inTarget"
              fill="#2ADDD0"
              stackId="a"
              shape={<LeftBar />}
              label={{ position: 'insideLeft' }}
            >
              {/* <LabelList dataKey="inTarget" content={renderLabelLeft} /> */}
            </Bar>
            <Bar
              dataKey="outTarget"
              fill="#FF407B"
              stackId="a"
              shape={<RightBar />}
              label={{ position: 'insideRight' }}
            >
              <LabelList dataKey="outTarget" content={renderLabelRight} />
            </Bar>

            <Legend
              align={'left'}
              verticalAlign={'middle'}
              content={renderLabelLeft}
              payload={formattedData}
            />
          </BarChart>
        </ResponsiveContainer>
    </>
  );
};

export default EnergyOffenderTile;
