import {
  ComparisonData,
  FilterTimePeriod,
  RechartsCustomTooltip,
} from '@energybox/react-ui-library/dist/types';
import { formatNumberString } from '@energybox/react-ui-library/dist/utils';
import ToolTip from '../../../components/Charts/ToolTip';
import styles from './NOHEfficiencyTile.module.css';
import { format, parseISO } from 'date-fns';
import { KWH_UNIT } from '@energybox/react-ui-library/dist/utils';
import * as Routes from '../../../routes';
import NOHEfficiencyLineChart from './NOHEfficiencyLineChart';

const CHART_BAR_FILL_COLOR_ARRAY = [
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
];

type Props = {
  className?: string;
  data: {
    by_org: {
      by_month: {
        [date: string]: {
          noh: {
            area: number;
            noh_efficiency: number;
            non_op_hour: object;
            op_hour: object;
          } | null;
        }
      }
    };
    by_site: {
      [siteId: number]: {
        by_month: {
          [date: string]: {
            area: number;
            noh_efficiency: number;
            non_op_hour: object;
            op_hour: object;
          };
        };
      };
    };
  };
  isLoading: boolean | undefined;
};

const CustomTooltip = (props: RechartsCustomTooltip) => {
  const { payload } = props;
  if (
    !!!payload ||
    payload.length < 1 ||
    payload[0].payload === undefined ||
    payload[0].payload === null
  ) {
    return null;
  } else {
    const {
      value,
      entity: title,
      fillColor,
    } = payload[0].payload as ComparisonData;
    return (
      <></>
      // <ToolTip
      //   {...props}
      //   title={title}
      //   values={`${formatNumberString(value, { numDecimals: 1 })} ${KWH_UNIT}`}
      //   valuesColor={fillColor}
      // />
    );
  }
};

const NOHEfficiencyTile: React.FC<Props> = ({
  className,
  data,
  isLoading,
}) => {
  let comparisonData: any[] = [];
  const { by_site, by_org } = data;
  const { by_month } = by_org;
  for (const [key, value] of Object.entries(by_month || {})) {
    const now = new Date();
    const year = now.getFullYear();
    const dataYear = Number(format(parseISO(key), 'yyyy'));
    const month = format(parseISO(key), 'MMM');
    const nohEfficiency = value?.noh?.noh_efficiency;
    if (dataYear === year) {
      comparisonData.push({
        entity: month,
        value: (nohEfficiency === null || nohEfficiency === undefined) ? null : nohEfficiency * 100,
        fillColor: 'var(--accent-base)',
      });
    }
  }
  return (
    <div className={styles.tileContainer}>
      <div className={styles.tileTitle}>
        {'NOH Efficiency'}
      </div>
      <NOHEfficiencyLineChart
        data={comparisonData}
        dataFillColor={CHART_BAR_FILL_COLOR_ARRAY}
        dataUnit={'%'}
        tooltip={CustomTooltip}
      />
    </div>
  );
};

export default NOHEfficiencyTile;
