import {OperationalSample} from '@energybox/react-ui-library/dist/types';
import {
  mapValues,
  normalizeTSOperationalDataBySensorId,
  OperationalSampleFromApiResponse,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import {Actions} from '../actions/operationalSamples';
import {DateTime} from "luxon";


export type DoorEntries = {
  //   currentPeriod:
  //   prevPeriod:
};

export type OperationalSamples = {
  doorEntries: DoorEntries;
  isLoadingBySensorId: IsLoadingBySensorId;
  operationalSamplesBySensorId: OperationalSamplesBySensorId;
};

export type OperationalSamplesBySensorId = {
  [id: string]: OperationalSample[];
};

export type IsLoadingBySensorId = {
  [sensorId: string]: boolean;
};

type OperationalSampleAttributeApiResponse = {
  sensorId: string;
  timestamp: number;
  temperature?: number;
  humidity?: number;
  state?: [boolean];
};

const initialState = {
  doorEntries: {
    currentPeriod: {},
    prevPeriod: {},
  },
  isLoadingBySensorId: {},
  operationalSamplesBySensorId: {},
};



const operationalSamples = (
  state: OperationalSamples = initialState,
  action: any
) => {
  switch (action.type) {
    case Actions.DOOR_READINGS_SUCCESS: {
      if (!action.payload.values){
        return R.assocPath(['doorEntries', action.period], {}, state);
      }
      const entries = action.payload.values
        .filter(reading => reading[1] === true)
        .map(reading => ({
          ...reading,
          time: DateTime.fromISO(reading[0]).get('hour'),
          day: DateTime.fromISO(reading[0]).toFormat('ccc')
        }));

      return R.assocPath(['doorEntries', action.period], entries, state);
    }

    case Actions.DOOR_READINGS_ERROR: {
      return R.assocPath(
        ['isLoadingBySensorId', action.sensorId],
        false,
        state
      );
    }

    case Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS: {
      if (!action.payload){
        return R.assocPath(['operationalSamplesBySensorId'], {}, state);
      }
      return R.pipe(
        R.assocPath(
          ['operationalSamplesBySensorId', action.id],
          mapValues(
            normalizeTSOperationalDataBySensorId(action.payload, action.id),
            OperationalSampleFromApiResponse
          )),
        R.assocPath(['isLoadingBySensorId', action.id], false)
      )(state);
    }

    case Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING: {
      return R.assocPath(['isLoadingBySensorId', action.sensorId], true, state);
    }

    case Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR: {
      return R.assocPath(
        ['isLoadingBySensorId', action.sensorId],
        false,
        state
      );
    }

    default:
      return state;
  }
};

export default operationalSamples;
