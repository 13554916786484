import {
  EquipmentTemperatureCheckReport,
  SopTypes,
} from '@energybox/react-ui-library/dist/types';
import React, { useMemo } from 'react';
import { useGetSiteTempCheck } from '../../../hooks/analytics/sites';
import { useGetEquipmentBySiteId } from '../../../hooks/equipment';
import { useGetTemperatureRecordsById } from '../../../hooks/reports/reports';
import { useGetSensorsByEquipmentId } from '../../../hooks/sensors';
import { useGetSite } from '../../../hooks/sites';
import { useSopByResourceId } from '../../../hooks/sop';
import { useGetCertificationsBySensorIdList } from '../../../hooks/useGetCertificationsBySensorId';
import EquipmentTempCheckReportPage from '../EquipmentTempCheckReportPage';

type Props = {
  siteId: string;
  reportId: string;
  fromDate: string; // 'yyyy-MM-dd'
  toDate: string;
  equipmentId: string;
};

const SiteTempCheckReportPageContainer = ({
  siteId,
  fromDate,
  toDate,
  reportId,
  equipmentId,
}: Props) => {
  const site = useGetSite(+siteId);

  const tempCheck = useGetSiteTempCheck(+siteId, fromDate, toDate);

  const reportConfig = useGetTemperatureRecordsById(reportId);

  const { equipmentById } = useGetEquipmentBySiteId(+siteId);

  const sensors = useGetSensorsByEquipmentId(+equipmentId);
  const sensorIds = useMemo(() => {
    return sensors.map(({ id }) => id);
  }, [sensors]);

  const certsBySensorId = useGetCertificationsBySensorIdList(siteId, sensorIds);

  const { sop: tempSop } = useSopByResourceId(
    equipmentId,
    SopTypes.TEMPERATURE_RANGE
  );

  const equipment = equipmentById[equipmentId];

  const equipmentTempCheck: EquipmentTemperatureCheckReport | undefined =
    tempCheck.by_report[reportId]?.by_equipment[equipmentId];
  const sensorsWithTempCheck = useMemo(() => {
    if (equipmentTempCheck === undefined) return [];
    const sensorsIdsWithTempCheck = Object.keys(equipmentTempCheck.by_sensor);
    const result = sensors.filter(sensor =>
      sensorsIdsWithTempCheck.includes(`${sensor.id}`)
    );
    return result;
  }, [equipmentTempCheck, sensors]);
  if (!site || !equipment || !reportConfig) return null;

  return (
    <EquipmentTempCheckReportPage
      reportConfig={reportConfig}
      fromDate={fromDate}
      toDate={toDate}
      equipmentTempChecks={equipmentTempCheck}
      sensors={sensorsWithTempCheck}
      site={site}
      equipment={equipment}
      tempSop={tempSop}
      certificationsBySensorId={certsBySensorId}
    />
  );
};

export default SiteTempCheckReportPageContainer;
