import { UsersById } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../actions/users';
import { ApplicationState } from '../reducers';

export const useGetUsers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const usersById = useSelector<ApplicationState, UsersById>(({ users }) => {
    return users.usersById;
  });

  return usersById;
};

export const useCurrentOrganizationId = () => {
  return useSelector<ApplicationState, number | undefined>(({ app }) => {
    return app.currentOrganizationId;
  });
};
