import { OperationalSample } from '@energybox/react-ui-library/dist/types';
import { format } from 'date-fns';
import { Periods } from './shared';

/// Functions in this file assume the series is fed in sequential order.

export const countDoorOpeningEvents = (data: OperationalSample[]): number => {
  let count = 0;

  data.forEach((d, index, arr) => {
    if (
      index !== 0 &&
      d.value === true &&
      (arr[index - 1] || {}).value === false
    ) {
      count = count + 1;
    }
  });

  return count;
};

//TODO factor in timezone, currently it just does it by the users' browsers timezone
//TODO I wrote a bunch of useless logic here to get every date within the series, do we need it later?
export const averageOpeningsPerPeriod = (
  data: OperationalSample[],
  period: Periods = Periods.DAY,
  timezone?: string
) => {
  const openingsPerPeriod = {};

  data.forEach((d, index, arr) => {
    const date = format(new Date(d.timestamp * 1000), period).toString();
    if (!openingsPerPeriod[date]) openingsPerPeriod[date] = 0;
    if (
      index !== 0 &&
      d.value === true &&
      (arr[index - 1] || {}).value === false
    ) {
      openingsPerPeriod[date] = openingsPerPeriod[date] + 1;
    }
  });

  let total = 0;
  let periodsCount = Object.keys(openingsPerPeriod).length;

  for (const key in openingsPerPeriod) {
    total = total + openingsPerPeriod[key];
  }

  if (total === 0) return 0;

  return total / periodsCount;
};
// Correct epoch time
export type Period = { start: number; end: number; duration: number };

export const calculateOpenDurations = (data: OperationalSample[]): Period[] => {
  let periods: { start: number; end: number; duration: number }[] = [];
  let start = 0;
  data.forEach((d, index, arr) => {
    if (index === 0 && d.value === true) start = d.timestamp * 1000;
    else if (d.value === true && (arr[index - 1] || {}).value === false) {
      start = d.timestamp * 1000;
    } else if (
      index !== 0 &&
      d.value === false &&
      (arr[index - 1] || {}).value === true
    ) {
      periods.push({
        start,
        end: d.timestamp * 1000,
        duration: d.timestamp * 1000 - start,
      });

      // TODO this logic seems like it could fail
    } else if (index === arr.length - 1 && d.value === true) {
      periods.push({
        start,
        end: d.timestamp * 1000,
        duration: d.timestamp * 1000 - start,
      });
    }
  });

  return periods;
};

export const calculateAverageOpenDuration = (data: Period[]) => {
  const avr = data.reduce((acc, curr) => acc + curr.duration, 0) / data.length;
  return avr;
};

export const longestPeriod = (data: Period[]): Period => {
  return data.reduce(
    (acc, cur) => {
      if (cur.duration > acc.duration) return cur;
      return acc;
    },
    { start: 0, end: 0, duration: 0 }
  );
};
