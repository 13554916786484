import { accent } from '@energybox/react-ui-library/dist/utils';
import { View, Image, Text } from '@react-pdf/renderer';
import EBImage from '../SiteInspectionReportPageContainer/EBImage';
import { sectionBorder } from './utils';

export const PdfViewFooter: React.FC = () => {
  return (
    <View
      fixed
      style={{
        borderTop: sectionBorder,
        padding: '3 0 3 10',
        flexDirection: 'row',
        fontSize: 8,
      }}
    >
      <Text>Powered By </Text>
      <Image
        src={EBImage}
        style={{
          height: 10,
          width: 'auto',
        }}
      />
      <View style={{ flexGrow: 1 }} />
      <Text render={({ pageNumber }) => pageNumber} />
      <Text style={{ color: accent.main, margin: '0 3' }}>/</Text>
      <Text render={({ totalPages }) => totalPages} />
    </View>
  );
};
