import {
  Comment,
  Equipment,
  EnergyPowerApiResponse,
  FlatNotification,
  HumiditySop,
  Sensor,
  SentinelType,
  Site,
  TemperatureSop,
  UsersById,
  ValueType,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  isDefined,
  sortAlphabetically,
} from '@energybox/react-ui-library/dist/utils';
import React, { createRef, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import EquipmentEnergyChartContainer from '../../../containers/EquipmentEnergyChartContainer';
import CommentUserLogs from '../../../containers/EquipmentReport/CommentUserLogs';
import OperationalSensorChartContainer from '../../../containers/EquipmentReport/OperationalSensorChartContainer';
import { ApplicationState } from '../../../reducers';
import { EquipmentReportFetchStatuses } from '../../../reducers/equipmentReport';
import ReportHeading from '../ReportHeading';
import ReportMeasurableElement from '../ReportMeasurableElement';
import ReportPageBreakControl from '../ReportPageBreakControl';

type Props = {
  fromDate: Date;
  toDate: Date;
  fromDateISO: string;
  toDateISO: string;
  site: Site;
  equipment: Equipment;
  usersById: UsersById;
  operationalSensors: Sensor[];
  equipmentNotifications: FlatNotification[] | undefined;
  equipmentComments: Comment[] | undefined;
  tempSop: TemperatureSop | undefined | null;
  humiditySop: HumiditySop | undefined | null;
  equipmentEnergy: EnergyPowerApiResponse | undefined;
  prevEquipmentEnergy: EnergyPowerApiResponse | undefined;
};

const EquipmentReportPage: React.FC<Props> = ({
  fromDate,
  toDate,
  fromDateISO,
  toDateISO,
  site,
  equipment,
  usersById,
  operationalSensors,
  equipmentNotifications,
  equipmentComments,
  tempSop,
  humiditySop,
  equipmentEnergy,
  prevEquipmentEnergy,
}) => {
  const headerRef = useRef(null);
  const commentUserLogRef = useRef(null);
  const emptyRef = useRef(null);
  const energyChartRef = useRef(null);

  //If ambient sensor, then split into two separate sensors (Temp, Humidity)
  const processedOperationalSensors = useMemo(() => {
    const separatedSensors: Sensor[] = [];

    operationalSensors
      .filter(s => s.types.length > 0)
      .forEach(s => {
        if (s.types.length === 2) {
          separatedSensors.push({ ...s, types: [s.types[0]] });
          separatedSensors.push({ ...s, types: [s.types[1]] });
        } else {
          separatedSensors.push(s);
        }
      });

    //We want chart order to be: Temp, Humidity, Energy
    return separatedSensors
      .sort((s1, s2) => sortAlphabetically(s1.types[0], s2.types[0]))
      .reverse();
  }, [operationalSensors]);

  //this is an array of refs, one for each operational sensor chart
  const operationalSensorChartRefs = useRef<React.RefObject<any>[]>();
  operationalSensorChartRefs.current = processedOperationalSensors.map(() =>
    createRef()
  );

  const reportFetchStatuses = useSelector<
    ApplicationState,
    EquipmentReportFetchStatuses
  >(({ equipmentReport }) => {
    return equipmentReport.fetched;
  });

  const energyNotifications = useMemo(() => {
    return filterNotificationsByEnergy(equipmentNotifications);
  }, [equipmentNotifications]);

  const energyComments = useMemo(() => {
    return filterCommentsByEnergy(equipmentComments, equipment);
  }, [equipmentComments, equipment]);

  const renderOperationalSensorCharts = () => {
    if (processedOperationalSensors.length === 0) {
      return (
        <ReportMeasurableElement setRef={emptyRef} key="empty">
          <div />
        </ReportMeasurableElement>
      );
    }

    return processedOperationalSensors.map((s, index) => {
      const sensorComments = filterCommentsBySensor(equipmentComments, s);

      return (
        <ReportMeasurableElement
          setRef={operationalSensorChartRefs?.current?.[index]}
          key={`${s.id}-${s.types[0]}-chart`}
        >
          <OperationalSensorChartContainer
            site={site}
            fromDateISO={fromDateISO}
            toDateISO={toDateISO}
            sensor={s}
            usersById={usersById}
            comments={sensorComments}
            tempSop={tempSop}
            humiditySop={humiditySop}
          />
        </ReportMeasurableElement>
      );
    });
  };

  const leftHeaderInfoColumn = useLeftHeaderInfoColumn(equipment);
  const rightHeaderInfoColumn = useRightHeaderInfoColumn(site);

  const headerContent = (
    <ReportMeasurableElement setRef={headerRef} key="heading">
      <ReportHeading
        title="Equipment Performance Summary"
        leftColumnContent={leftHeaderInfoColumn}
        rightColumnContent={rightHeaderInfoColumn}
        fromDate={fromDate}
        toDate={toDate}
      />
    </ReportMeasurableElement>
  );

  return (
    <ReportPageBreakControl
      fetchedStatuses={reportFetchStatuses}
      headerForNewPages={headerContent}
      headerRef={headerRef}
    >
      {headerContent}

      {renderOperationalSensorCharts()}

      <ReportMeasurableElement setRef={energyChartRef} key="energy-chart">
        <EquipmentEnergyChartContainer
          site={site}
          equipment={equipment}
          usersById={usersById}
          fromDateISO={fromDateISO}
          toDateISO={toDateISO}
          equipmentEnergy={equipmentEnergy}
          prevEquipmentEnergy={prevEquipmentEnergy}
          notifications={energyNotifications}
          comments={energyComments}
        />
      </ReportMeasurableElement>

      <ReportMeasurableElement
        setRef={commentUserLogRef}
        key="comment-userlogs"
      >
        <CommentUserLogs
          site={site}
          comments={equipmentComments}
          usersById={usersById}
          sensors={operationalSensors}
        />
      </ReportMeasurableElement>
    </ReportPageBreakControl>
  );
};

const useLeftHeaderInfoColumn = (equipment: Equipment | undefined) => {
  return useMemo(() => {
    return [
      {
        fieldName: 'Equipment Name',
        fieldValue: equipment?.title || global.NOT_AVAILABLE,
      },
      {
        fieldName: 'Space',
        fieldValue: equipment?.space?.title || global.NOT_AVAILABLE,
      },
      {
        fieldName: 'Equipment Type',
        fieldValue: equipment?.type?.title || global.NOT_AVAILABLE,
      },
      {
        fieldName: 'Vendor',
        fieldValue: equipment?.vendor || global.NOT_AVAILABLE,
      },
      {
        fieldName: 'Model',
        fieldValue: equipment?.model || global.NOT_AVAILABLE,
      },
    ];
  }, [equipment]);
};

const useRightHeaderInfoColumn = (site: Site | undefined) => {
  return useMemo(() => {
    return [
      {
        fieldName: 'Organization',
        fieldValue: site?.organization?.title || global.NOT_AVAILABLE,
      },
      { fieldName: 'Site', fieldValue: site?.title },
      { fieldName: 'Address', fieldValue: site?.address },
    ];
  }, [site]);
};

const filterNotificationsByEnergy = (
  notifications: FlatNotification[] | undefined
) => {
  if (!notifications) return undefined;
  return notifications.filter(n => {
    return n.sentinelType === SentinelType.ACTIVE_POWER_THRESHOLD;
  });
};

const filterCommentsByEnergy = (
  comments: Comment[] | undefined,
  equipment: Equipment
) => {
  if (!comments) return undefined;
  return comments.filter(c => {
    return (
      Number(c.resourceId) === Number(equipment.id) &&
      c.valueType === ValueType.ENERGY
    );
  });
};

const filterCommentsBySensor = (
  comments: Comment[] | undefined,
  sensor: Sensor
) => {
  if (!comments) return undefined;
  return comments.filter(c => {
    const commentSensorId = c.resourceId;

    return String(commentSensorId) === String(sensor.id);
  });
};

export default EquipmentReportPage;
