import { Report } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReportById,
  getReportsBySiteId,
} from '../../actions/temperatureRecords';
import { ApplicationState } from '../../reducers';

export const useGetTemperatureRecordsBySiteId = (siteId: number | string) => {
  const dispatch = useDispatch();

  const reportById = useSelector(({ temperatureRecord }: ApplicationState) => {
    return temperatureRecord.reportBySiteId[siteId] || {};
  });

  useEffect(() => {
    dispatch(getReportsBySiteId(siteId));
  }, [siteId, dispatch]);

  return reportById;
};

export const useGetTemperatureRecordsById = (
  id: number | string
): Report | undefined => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportById(id));
  }, [id, dispatch]);

  const report: Report | undefined = useSelector(
    ({ temperatureRecord }: ApplicationState) => {
      return temperatureRecord.reportById[id];
    }
  );

  return report;
};
