import styles from './NOHEfficiencySummaryTile.module.css';
import { format } from 'date-fns';
import { formatNumber } from '@energybox/react-ui-library/dist/utils/number';
import { monthlyData } from '../NOHEfficiencyLowestSites/NOHEfficiencyLowestSites';

type Props = {
  className?: string;
  data: monthlyData;
  nohEfficiency: number;
  isLoading: boolean | undefined;
};

const NOHEfficiencySummaryTile: React.FC<Props> = ({
  className,
  data,
  nohEfficiency,
  isLoading,
}) => {
  const { recom, org_title, month, by_org, by_site } = data;
  const { by_month } = by_org;
  const reportDate = new Date(month);
  const reportYear = reportDate.getFullYear();
  const reportMonth = format(reportDate, 'MMMM');
  const thisMonthNOHEfficiency = nohEfficiency * 100;
  const offenderPlural =
    recom?.num_of_offender > 1 ? ['are', 'sites'] : ['is', 'site'];
  const increaseOrDecrease = recom?.improve_noh < 0 ? 'decline' : 'improvement';
  const improve_noh =
    recom?.improve_noh < 0 ? recom?.improve_noh * -1 : recom?.improve_noh;
  return (
    <div className={styles.tileContainer}>
      {`The NOH Efficiency for ${org_title} in ${reportMonth} ${reportYear} is ${formatNumber(
        thisMonthNOHEfficiency || 0,
        1
      )}%. 
        This is a ${formatNumber(
          improve_noh * 100,
          1
        )}% ${increaseOrDecrease} from the previous month.`}
      <br />
      <br />
      {`There ${offenderPlural[0]} ${recom?.num_of_offender} ${offenderPlural[1]} below the ${org_title} NOH Efficiency.`}
      <br />
      <br />
      <div style={{ fontWeight: 600 }}>{`Recommendation`}</div>
      <br />
      {`Improving the NOH Efficiency of these ${recom?.num_of_offender} ${
        offenderPlural[1]
      } to ${org_title} average level will help save an estimated ${formatNumber(
        recom?.saving_energy || 0,
        1
      )} kWh a month. 
        If these improvements are made, ${org_title} will be able to reduce their annual EUI by ${formatNumber(
        recom?.saving_eui || 0,
        1
      )} kWh/m².`}
    </div>
  );
};

export default NOHEfficiencySummaryTile;
