import { Equipment } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getEquipmentBySiteId } from '../../../actions/equipment';
import { getReportsBySiteId } from '../../../actions/temperatureRecords';
import { ApplicationState } from '../../../reducers';
import { DateFilterTimePeriod } from '../../../reducers/dateFilter';
import SiteReportsList from '../SiteReportsList';

type OwnProps = {
  siteId: string;
};

interface Props extends OwnProps {
  getEquipmentBySiteId: () => void;
  getReportsBySiteId: () => void;
  timePeriod: DateFilterTimePeriod;
  equipment: Equipment[];
}

const SiteReportsListContainer = ({
  getEquipmentBySiteId,
  getReportsBySiteId,
  siteId,
  timePeriod,
  equipment,
}: Props) => {
  useEffect(() => {
    getEquipmentBySiteId();
    getReportsBySiteId();
  }, [getEquipmentBySiteId, getReportsBySiteId]);

  if (!siteId) return null;
  return (
    <SiteReportsList
      siteId={siteId}
      equipment={equipment}
      timePeriod={timePeriod}
    />
  );
};

const mapStateToProps = (
  { dateFilter, loading, equipment, energy }: ApplicationState,
  { siteId }: OwnProps
) => ({
  isEnergyDashboardLoading: R.pathOr(
    false,
    [siteId],
    energy.isEnergyDashboardLoadingBySiteId
  ),
  isPreviousConsumptionLoading: R.pathOr(
    false,
    [siteId],
    energy.isTotalConsumptionLoadingBySiteId
  ),
  isRecommendationLoading: loading.equipment,

  timePeriod: dateFilter.timePeriod,
  energyDashboardData: R.pathOr({}, [siteId], energy.energyDashboardBySiteId),
  previousConsumption: R.pathOr({}, [siteId], energy.totalConsumptionBySiteId),
  equipment: R.pathOr([], [siteId], equipment.equipmentBySiteId),
});

const mapDispatchToProps = (dispatch, { siteId }: OwnProps) => ({
  getEquipmentBySiteId: () => dispatch(getEquipmentBySiteId(siteId)),
  getReportsBySiteId: () => dispatch(getReportsBySiteId(siteId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteReportsListContainer);
