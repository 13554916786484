import {
  Table,
  TableColumns,
} from '@energybox/react-ui-library/dist/components';
import {
  Equipment,
  EquipmentAnalyticsConsumptionData,
  EquipmentType,
} from '@energybox/react-ui-library/dist/types';
import {
  formatNumberString,
  global,
  KWH_UNIT,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { useSiteCountryCode } from '../../../hooks/sites';
import DeltaNumbers from '../DeltaNumbers';
import reportStyles from '../ReportPage.module.css';

type Props = {
  siteId: number | string;
  top5ConsumptionSelectedTimePeriod: EquipmentAnalyticsConsumptionData[];
  equipmentConsumptionLastMonth: EquipmentAnalyticsConsumptionData[];
  equipments: Equipment[] | undefined;
  equipmentTypes: EquipmentType[];
  averagesByEquipmentTypeId: { [key: string]: number };
};

const EnergyReportEquipmentTable = ({
  siteId,
  top5ConsumptionSelectedTimePeriod,
  equipments,
  equipmentTypes,
  equipmentConsumptionLastMonth,
  averagesByEquipmentTypeId,
}: Props) => {
  const countryCode = useSiteCountryCode(siteId);

  if (
    !top5ConsumptionSelectedTimePeriod ||
    !equipmentConsumptionLastMonth ||
    !equipments ||
    !equipments.length ||
    equipmentConsumptionLastMonth.length === 0
  ) {
    return <div />;
  }

  const formattedData = top5ConsumptionSelectedTimePeriod.map((item, idx) => ({
    ...item,
    idx: idx,
  }));

  const getEquipmentTypeFromId = (id: string | number) => {
    const result = equipments.find(e => e.id === Number(id));
    return !result ? '' : result.typeId;
  };

  const calculatePercentage = (id: number) => {
    const { consumption: consumptionCurrentMonth } =
      top5ConsumptionSelectedTimePeriod.find(el => el.equipmentId === id) || {};

    const { consumption: consumptionPrevMonth } =
      equipmentConsumptionLastMonth.find(el => el.equipmentId === id) || {};

    if (
      (consumptionCurrentMonth || consumptionCurrentMonth === 0) &&
      consumptionPrevMonth
    ) {
      return (consumptionCurrentMonth / consumptionPrevMonth) * 100 - 100;
    } else return null;
  };

  const columns: TableColumns<{
    idx: number;
    consumption: number;
    equipmentType: number;
    equipmentId: number;
  }>[] = [
    {
      header: <div className={reportStyles.tableHeader}>#</div>,
      cellContent: row => (
        <div className={reportStyles.tableCell}>{row.idx + 1}</div>
      ),
    },
    {
      width: '30%',
      header: <div className={reportStyles.tableHeader}>Equipment</div>,
      cellContent: row => {
        const equipment = equipments.find(
          e => e.id === Number(row.equipmentId)
        );
        return (
          <div className={reportStyles.tableCell}>
            {equipment ? equipment.title : ''}
          </div>
        );
      },
    },
    {
      header: <div className={reportStyles.tableHeader}>Equipment Type</div>,
      cellContent: row => {
        const equipmentType = equipmentTypes.find(
          e => e.id === getEquipmentTypeFromId(row.equipmentId)
        );
        return (
          <div className={reportStyles.tableCell}>{`${
            equipmentType ? equipmentType.title : ''
          }`}</div>
        );
      },
    },
    {
      header: (
        <div className={reportStyles.tableHeader}>Total Energy Usage</div>
      ),
      align: 'right',
      cellContent: (row: any) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
        >
          {formatNumberString(row.consumption, {
            countryCode,
            numDecimals: 0,
            unit: KWH_UNIT,
          })}
        </div>
      ),
    },
    {
      header: <div className={reportStyles.tableHeader}>Monthly Change</div>,
      align: 'right',
      cellContent: (row: any) => {
        const percentage = calculatePercentage(row.equipmentId);
        if (percentage || percentage === 0) {
          return (
            <DeltaNumbers
              className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
              symbol="%"
              value={percentage}
            />
          );
        } else
          return (
            <div
              className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
            >
              {global.NOT_AVAILABLE}
            </div>
          );
      },
    },
    {
      header: (
        <div className={reportStyles.tableHeader}>
          Monthly Change in <br /> Equipment Type
        </div>
      ),
      align: 'right',
      cellContent: (row: any) => {
        const monthlyChangeValue =
          averagesByEquipmentTypeId[getEquipmentTypeFromId(row.equipmentId)];
        if (monthlyChangeValue || monthlyChangeValue === 0) {
          return (
            <DeltaNumbers
              className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
              symbol="%"
              value={
                averagesByEquipmentTypeId[
                  getEquipmentTypeFromId(row.equipmentId)
                ]
              }
            />
          );
        } else
          return (
            <div
              className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
            >
              {global.NOT_AVAILABLE}
            </div>
          );
      },
    },
  ];

  return (
    <div style={{ marginTop: '1rem' }}>
      <Table
        className={reportStyles.table}
        highlightAlternateRows
        columns={columns}
        data={formattedData}
      />
    </div>
  );
};

export default EnergyReportEquipmentTable;
