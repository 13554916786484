import * as R from 'ramda';

enum FetchingState {
  FETCHED = 'fetched',
  LOADING = 'loading',
  ERRORS = 'errors',
}

export const toggleLoading = (key: string | string[], state) => {
  return R.pipe(
    R.assocPath(determinePath(FetchingState.FETCHED, key), false),
    R.assocPath(determinePath(FetchingState.LOADING, key), true),
    R.assocPath(determinePath(FetchingState.ERRORS, key), false)
  )(state);
};

export const toggleSuccess = (key: string | string[], state) => {
  return R.pipe(
    R.assocPath(determinePath(FetchingState.FETCHED, key), true),
    R.assocPath(determinePath(FetchingState.LOADING, key), false),
    R.assocPath(determinePath(FetchingState.ERRORS, key), false)
  )(state);
};

export const toggleError = (key: string | string[], state) => {
  return R.pipe(
    R.assocPath(determinePath(FetchingState.FETCHED, key), true),
    R.assocPath(determinePath(FetchingState.LOADING, key), false),
    R.assocPath(determinePath(FetchingState.ERRORS, key), true)
  )(state);
};

const determinePath = (
  fetchingState: FetchingState,
  key: string | string[]
) => {
  if (Array.isArray(key)) {
    return [fetchingState, ...key];
  }
  return [fetchingState, key];
};
