import {
  Table,
  TableColumns,
} from '@energybox/react-ui-library/dist/components';
import { AmbientDotDataByID } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import SectionTitle from '../../../components/SectionTitle';
import reportStyles from '../ReportPage.module.css';

type Props = {
  ambientDotData: AmbientDotDataByID;
};

type FormattedData = {
  lockerNo: string;
  temperature: {
    min: number;
    max: number;
    mean: number;
  };
  humidity: {
    min: number;
    max: number;
    mean: number;
  };
};

const StorageReportAmbientConditions: React.FC<Props> = ({
  ambientDotData,
}) => {
  const columns: TableColumns<FormattedData>[] = [
    {
      header: <span className={reportStyles.tableHeader}>Locker No.</span>,
      cellContent: (row: FormattedData) => (
        <div className={reportStyles.tableCell}>{row.lockerNo}</div>
      ),
    },
    {
      header: <span className={reportStyles.tableHeader}>Avg Temp</span>,
      align: 'right',
      cellContent: (row: FormattedData) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
          style={row.temperature.mean > 25 ? { color: 'var(--pink-base)' } : {}}
        >
          {`${row.temperature.mean} \u00B0 C`}
        </div>
      ),
    },
    {
      header: <span className={reportStyles.tableHeader}>Max Temp</span>,
      align: 'right',
      cellContent: (row: FormattedData) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
          style={row.temperature.max > 25 ? { color: 'var(--pink-base)' } : {}}
        >
          {`${row.temperature.max} \u00B0 C`}
        </div>
      ),
    },
    {
      header: <span className={reportStyles.tableHeader}>Min Temp</span>,
      align: 'right',
      cellContent: (row: FormattedData) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
          style={row.temperature.min > 25 ? { color: 'var(--pink-base)' } : {}}
        >
          {`${row.temperature.min} \u00B0 C`}
        </div>
      ),
    },
    {
      header: <span className={reportStyles.tableHeader}>Avg Humidity</span>,
      align: 'right',
      cellContent: (row: FormattedData) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
          style={row.humidity.mean > 70 ? { color: 'var(--pink-base)' } : {}}
        >
          {`${row.humidity.mean} %`}
        </div>
      ),
    },
    {
      header: <span className={reportStyles.tableHeader}>Max Humidity</span>,
      align: 'right',
      cellContent: (row: FormattedData) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
          style={row.humidity.max > 70 ? { color: 'var(--pink-base)' } : {}}
        >
          {`${row.humidity.max} %`}
        </div>
      ),
    },
    {
      header: <span className={reportStyles.tableHeader}>Min Humidity</span>,
      align: 'right',
      cellContent: (row: FormattedData) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
          style={row.humidity.min > 70 ? { color: 'var(--pink-base)' } : {}}
        >
          {`${row.humidity.min} %`}
        </div>
      ),
    },
  ];

  if (!!ambientDotData && Object.keys(ambientDotData).length > 0) {
    const formattedData = Object.keys(ambientDotData).map(ambientDot => ({
      lockerNo: ambientDot,
      temperature: {
        min: Number(ambientDotData[ambientDot].temperature.min.toFixed(1)),
        max: Number(ambientDotData[ambientDot].temperature.max.toFixed(1)),
        mean: Number(ambientDotData[ambientDot].temperature.mean.toFixed(1)),
      },
      humidity: {
        min: Number(ambientDotData[ambientDot].humidity.min.toFixed(1)),
        max: Number(ambientDotData[ambientDot].humidity.max.toFixed(1)),
        mean: Number(ambientDotData[ambientDot].humidity.mean.toFixed(1)),
      },
    }));

    return (
      <div style={{ marginTop: '1em' }}>
        <div>
          <SectionTitle title="Ambient Conditions Overview" />
        </div>
        <div style={{ marginRight: '1.5em', marginLeft: '1.5em' }}>
          <Table
            className={reportStyles.table}
            highlightAlternateRows
            columns={columns}
            data={formattedData}
            renderLimit={25}
          />
        </div>
      </div>
    );
  }
  return <div />;
};

export default StorageReportAmbientConditions;
