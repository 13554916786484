import { Equipment, Site } from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { EnergyReportFetchStatusFlags } from '../../../reducers/energyReport';
import { ReportHeadingTitle } from '../../../types/shared';
import EnergyReportConsumptionInfoContainer from '../EnergyReportConsumptionInfoContainer';
import EnergyReportDonutChartContainer from '../EnergyReportDonutChartContainer';
import EnergyReportEquipmentTableContainer from '../EnergyReportEquipmentTableContainer';
import EnergyReportMonthlyConsumptionTableContainer from '../EnergyReportMonthlyConsumptionTableContainer';
import EnergyReportWeatherAndEnergyChartContainer from '../EnergyReportWeatherAndEnergyChartContainer';
import ReportHeading from '../ReportHeading';
import ReportMeasurableElement from '../ReportMeasurableElement';
import ReportPageBreakControl from '../ReportPageBreakControl';

type OwnProps = {
  site: Site;
  equipments: Equipment[] | undefined;
  fromDate: Date;
  toDate: Date;
  reportNumberOfDays: number | undefined;
};

interface Props extends OwnProps {
  energyReportFetched: EnergyReportFetchStatusFlags;
}

class EnergyReportPage extends React.Component<Props> {
  private headerRef: React.RefObject<HTMLDivElement>;
  private energyRef: React.RefObject<HTMLDivElement>;
  private weatherEnergyRef: React.RefObject<HTMLDivElement>;
  private consumptionTableRef: React.RefObject<HTMLDivElement>;
  private donutRef: React.RefObject<HTMLDivElement>;
  private equipmentTableRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
    this.energyRef = React.createRef();
    this.weatherEnergyRef = React.createRef();
    this.consumptionTableRef = React.createRef();
    this.donutRef = React.createRef();
    this.equipmentTableRef = React.createRef();
  }

  createHeaderInfoColumn = () => {
    const { site } = this.props;

    return [
      {
        fieldName: 'Organization',
        fieldValue: site?.organization?.title || global.NOT_AVAILABLE,
      },
      { fieldName: 'Site', fieldValue: site?.title },
      { fieldName: 'Address', fieldValue: site?.address },
    ];
  };

  render() {
    const {
      site,
      equipments,
      fromDate,
      toDate,
      energyReportFetched,
      reportNumberOfDays,
    } = this.props;
    if (!site) return <div />;

    const reportHeader = (
      <ReportMeasurableElement setRef={this.headerRef} key="heading">
        <ReportHeading
          title={ReportHeadingTitle.ENERGY}
          leftColumnContent={this.createHeaderInfoColumn()}
          fromDate={fromDate}
          toDate={toDate}
        />
      </ReportMeasurableElement>
    );

    return (
      <ReportPageBreakControl
        fetchedStatuses={energyReportFetched}
        headerForNewPages={reportHeader}
        headerRef={this.headerRef}
      >
        {reportHeader}

        <ReportMeasurableElement setRef={this.energyRef} key="consinfo">
          <EnergyReportConsumptionInfoContainer
            site={site}
            fromDate={fromDate}
            toDate={toDate}
            reportNumberOfDays={reportNumberOfDays}
          />
        </ReportMeasurableElement>

        <ReportMeasurableElement setRef={this.weatherEnergyRef} key="weather">
          <EnergyReportWeatherAndEnergyChartContainer
            site={site}
            toDate={toDate}
          />
        </ReportMeasurableElement>

        <ReportMeasurableElement
          setRef={this.consumptionTableRef}
          key="constable"
        >
          <EnergyReportMonthlyConsumptionTableContainer site={site} />
        </ReportMeasurableElement>

        <ReportMeasurableElement setRef={this.donutRef} key="donut">
          <EnergyReportDonutChartContainer siteId={site.id} />
        </ReportMeasurableElement>

        <ReportMeasurableElement setRef={this.equipmentTableRef} key="eqtable">
          <EnergyReportEquipmentTableContainer
            siteId={site.id}
            equipments={equipments}
            fromDate={fromDate}
            toDate={toDate}
          />
        </ReportMeasurableElement>
      </ReportPageBreakControl>
    );
  }
}

const mapStateToProps = ({ energyReport }: ApplicationState) => ({
  energyReportFetched: energyReport.fetched,
});

export default connect(mapStateToProps)(EnergyReportPage);
