import { Actions as AnalyticsActions } from '../actions/analytics';
import {
  toggleError,
  toggleSuccess,
  toggleLoading,
} from '../utils/reducerFetchingStates';

type FetchingStatesGenericReport = {
  coldStorageRecommendations: boolean;
  unnecessaryDoorAccess: boolean;
  temperatureComfortRecommendations: boolean;
  refrigerationCompliance: boolean;
};

export type GenericReport = {
  fetched: FetchingStatesGenericReport;
  loading: FetchingStatesGenericReport;
  errors: FetchingStatesGenericReport;
};

export const initialState: GenericReport = {
  fetched: {
    coldStorageRecommendations: false,
    unnecessaryDoorAccess: false,
    temperatureComfortRecommendations: false,
    refrigerationCompliance: false,
  },
  loading: {
    coldStorageRecommendations: false,
    unnecessaryDoorAccess: false,
    temperatureComfortRecommendations: false,
    refrigerationCompliance: false,
  },
  errors: {
    coldStorageRecommendations: false,
    unnecessaryDoorAccess: false,
    temperatureComfortRecommendations: false,
    refrigerationCompliance: false,
  },
};

export default (state: GenericReport = initialState, action) => {
  switch (action.type) {
    case AnalyticsActions.COOLER_ENERGY_RECOMMENDATIONS_LOADING:
      return toggleLoading('coldStorageRecommendations', state);

    case AnalyticsActions.COOLER_ENERGY_RECOMMENDATIONS_ERROR:
      return toggleError('coldStorageRecommendations', state);

    case AnalyticsActions.COOLER_ENERGY_RECOMMENDATIONS_SUCCESS:
      return toggleSuccess('coldStorageRecommendations', state);

    case AnalyticsActions.UNNECESSARY_DOOR_ACCESS_LOADING:
      return toggleLoading('unnecessaryDoorAccess', state);

    case AnalyticsActions.UNNECESSARY_DOOR_ACCESS_ERROR:
      return toggleError('unnecessaryDoorAccess', state);

    case AnalyticsActions.UNNECESSARY_DOOR_ACCESS_SUCCESS:
      return toggleSuccess('unnecessaryDoorAccess', state);

    case AnalyticsActions.TEMPERATURE_COMFORT_RECOMMENDATIONS_LOADING:
      return toggleLoading('temperatureComfortRecommendations', state);

    case AnalyticsActions.TEMPERATURE_COMFORT_RECOMMENDATIONS_ERROR:
      return toggleError('temperatureComfortRecommendations', state);

    case AnalyticsActions.TEMPERATURE_COMFORT_RECOMMENDATIONS_SUCCESS:
      return toggleSuccess('temperatureComfortRecommendations', state);

    default:
      return state;
  }
};
