import {
  EnergyDataForSankeyChart,
  Equipment,
  EquipmentGroup,
  EquipmentType,
  FormattedEnergyConsumptionData,
  Space,
} from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import StorageReportConsumptionCharts from '../StorageReportConsumptionCharts';

type OwnProps = {
  siteId: number;
};

interface Props extends OwnProps {
  equipments: Equipment[];
  spaces?: Space[];
  equipmentTypes: EquipmentType[];
  equipmentGroups: EquipmentGroup[];
  analyticsEnergyByEquipmentId: any;
  analyticsLoadingCurrentPeriod: boolean;
}

const findKeyFromResourceId = (
  searchArray: any[],
  target: number,
  key: string
) => {
  const result = searchArray.find(el => el.id === target);
  return !result ? 'undefined' : result[key];
};

const StorageReportConsumptionChartsContainer = ({
  siteId,
  equipments,
  spaces,
  equipmentTypes,
  equipmentGroups,
  analyticsEnergyByEquipmentId,
  analyticsLoadingCurrentPeriod,
}: Props) => {
  const initialSankeyData: EnergyDataForSankeyChart[] = [];
  const [sankeyData, setSankeyData] = useState(initialSankeyData);
  const [consumptionByGroups, setConsumptionByGroups] = useState<
    FormattedEnergyConsumptionData[]
  >();

  useEffect(() => {
    if (
      equipmentGroups.length > 0 &&
      !!equipments &&
      equipments.length > 0 &&
      !!spaces &&
      spaces.length > 0 &&
      Object.keys(analyticsEnergyByEquipmentId.length > 0) //FIXME: Change to fetched
    ) {
      const formattedData = Object.keys(analyticsEnergyByEquipmentId).map(
        key => {
          const typeId = findKeyFromResourceId(
            equipments,
            parseInt(key),
            'typeId'
          );
          const groupId = findKeyFromResourceId(
            equipments,
            parseInt(key),
            'groupId'
          );
          const spaceId = findKeyFromResourceId(
            equipments,
            parseInt(key),
            'spaceId'
          );
          const equipmentTitle = findKeyFromResourceId(
            equipments,
            parseInt(key),
            'title'
          );

          const equipmentTypeName = findKeyFromResourceId(
            equipmentTypes,
            typeId,
            'title'
          );

          const groupName = findKeyFromResourceId(
            equipmentGroups,
            groupId,
            'title'
          );

          const groupAlias = findKeyFromResourceId(
            equipmentGroups,
            groupId,
            'alias'
          );

          const spaceName = findKeyFromResourceId(spaces, spaceId, 'title');

          return {
            resourceId: parseInt(key),
            energyTotal: analyticsEnergyByEquipmentId[key].energyTotal || 0,
            equipmentTitle: equipmentTitle,
            typeId: typeId,
            typeName: equipmentTypeName,
            groupId: groupId,
            groupName: groupName,
            spaceId: spaceId,
            spaceName: spaceName,
            groupAlias: groupAlias,
          };
        }
      );
      setSankeyData(formattedData);
    }
  }, [
    analyticsEnergyByEquipmentId,
    equipments,
    spaces,
    equipmentGroups,
    equipmentTypes,
  ]);

  useEffect(() => {
    if (
      sankeyData.length > 0 &&
      Object.keys(analyticsEnergyByEquipmentId.length > 0)
    ) {
      const consumptionByGroups = sankeyData.map(consumptionData => ({
        type: consumptionData.groupAlias,
        label: consumptionData.groupName,
        count: sankeyData
          .filter(
            consumption => consumption.groupId === consumptionData.groupId
          )
          .reduce((acc, data) => acc + data.energyTotal, 0),
      }));

      const uniqueGroups: FormattedEnergyConsumptionData[] = Object.values(
        consumptionByGroups.reduce((a, b) => {
          // @ts-ignore
          a[b.type] = b;
          return a;
        }, {})
      );

      setConsumptionByGroups(uniqueGroups);
    }
  }, [sankeyData, analyticsEnergyByEquipmentId.length]);

  if (analyticsLoadingCurrentPeriod) return <div />;

  return (
    <div>
      <StorageReportConsumptionCharts
        siteId={siteId}
        consumptionData={consumptionByGroups || []}
        sankeyData={sankeyData}
      />
    </div>
  );
};

const mapStateToProps = (
  { equipmentTags, spaces, energy, storageReport }: ApplicationState,
  { siteId }: OwnProps
) => ({
  equipmentTypes: equipmentTags.equipmentTypes,
  equipmentGroups: equipmentTags.equipmentGroups,
  spaces: spaces.spacesBySiteId[siteId],
  analyticsEnergyByEquipmentId: energy.analyticsEnergyByEquipmentId,
  analyticsLoadingCurrentPeriod: storageReport.loading.analytics.currentPeriod,
});

export default connect(mapStateToProps)(
  StorageReportConsumptionChartsContainer
);
