import {
  InspectionDataField,
  InspectionDetailField,
} from '@energybox/react-ui-library/dist/types/Inspection';
import {
  accent,
  ambient,
  global,
} from '@energybox/react-ui-library/dist/utils';
import { View, Text, Svg, Path, Font } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React from 'react';

import OpenSansRegular from '@energybox/react-ui-library/dist/fonts/open-sans-v28-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff';
import OpenSansItalic from '@energybox/react-ui-library/dist/fonts/open-sans-v28-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-italic.woff';
import OpenSans600 from '@energybox/react-ui-library/dist/fonts/open-sans-v28-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-600.woff';
import OpenSans700 from '@energybox/react-ui-library/dist/fonts/open-sans-v28-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-700.woff';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: OpenSansRegular,
    },
    {
      src: OpenSansRegular,
      fontWeight: 400,
    },
    {
      src: OpenSans600,
      fontWeight: 600,
    },
    {
      src: OpenSans700,
      fontWeight: 700,
    },
    {
      src: OpenSansItalic,
      fontStyle: 'italic',
    },
  ],
});

export type RowProps = {
  children: React.ReactNode;
  gap?: Style['columnGap'];
  width?: Style['width'];
  wrap?: boolean;
  debug?: boolean;
};

export type WidthOnly = {
  width: string | number;
};

export type SummaryFieldProps = {
  field: InspectionDetailField;
  transform?(
    value?: InspectionDetailField['value']
  ): InspectionDetailField['value'];
  annotation?: (field: InspectionDetailField) => JSX.Element | undefined;
  width?: Style['width'];
  nameWidth?: Style['width'];
  nameWeight?: Style['fontWeight'];
  valueStyle?: Style;
  debug?: boolean;
};

export type DataFieldProps = {
  field: InspectionDataField;
  transform?(
    field?: InspectionDataField['field']
  ): JSX.Element | InspectionDataField['field'];
  annotation?: (field: InspectionDataField) => JSX.Element | undefined;
  style?: Style;
  Container?: (props: { children: React.ReactNode }) => JSX.Element;
  debug?: boolean;
};

export const Row = ({
  children,
  gap = 0,
  width = '100%',
  wrap,
  debug,
}: RowProps) => (
  <View
    style={{
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexGrow: 1,
      columnGap: gap,
      width,
      flexWrap: wrap ? 'wrap' : 'nowrap',
    }}
    debug={debug}
  >
    {children}
  </View>
);

export const RowFiller = ({ width }: WidthOnly) => <View style={{ width }} />;

export const Hr = ({ width }: WidthOnly) => (
  <View
    style={{ width, height: 0.5, backgroundColor: 'rgba(51, 62, 72, 0.25)' }}
  />
);

export const SummaryField = ({
  field,
  transform,
  annotation = getErrorOrWarningIconForPdfField,
  width = '100%',
  nameWidth = '50%',
  nameWeight = 600,
  valueStyle = {},
  debug,
}: SummaryFieldProps) => {
  const transformedValue = transform?.(field.value);
  return (
    <Row width={width} gap={3}>
      {field.name && (
        <Text
          style={{
            fontWeight: nameWeight,
            width: nameWidth,
          }}
          debug={debug}
        >
          {field.name}:
        </Text>
      )}
      {transformedValue && typeof transformedValue === 'object' ? (
        transformedValue
      ) : (
        <Text style={valueStyle} debug={debug}>
          {transformedValue || field?.value || global.NOT_AVAILABLE}
        </Text>
      )}
      {annotation(field)}
    </Row>
  );
};

export const DataField = ({
  field,
  transform,
  annotation = getErrorOrWarningIconForPdfField,
  style,
  Container = ({ children }) => <Row gap={3}>{children}</Row>,
  debug,
}: DataFieldProps) => {
  const transformedValue = transform?.(field?.field);
  return (
    <Container>
      {transformedValue &&
      typeof transformedValue === 'object' &&
      !Array.isArray(transformedValue) ? (
        transformedValue
      ) : (
        <Text style={style} debug={debug}>
          {transformedValue || field?.field || global.NOT_AVAILABLE}
        </Text>
      )}
      {annotation(field)}
    </Container>
  );
};

export const greenBase = '#01D33D';
export const orangeBase = '#FF9248';
export const pinkBase = '#FF407B';

export const statusColors = {
  good: accent.main,
  online: accent.main,
  active: greenBase,
  warning: orangeBase,
  error: pinkBase,
  offline: pinkBase,
  inactive: pinkBase,
};

export const getErrorOrWarningIconForPdfField = (
  field: InspectionDataField | InspectionDetailField
) => {
  if (field?.error || field?.warning) {
    return field?.error ? (
      <Svg width={10} height={10} viewBox="0 0 16.2 16.2">
        <Path
          fill={pinkBase}
          d="M8.1.48A7.62,7.62,0,1,0,15.72,8.1,7.62,7.62,0,0,0,8.1.48ZM12.3,5,9.18,8.1l3.12,3.12L11.22,12.3,8.1,9.18,5,12.3,3.9,11.22,7,8.1,3.9,5,5,3.9,8.1,7,11.22,3.9Z"
        />
      </Svg>
    ) : (
      <Svg width={10} height={10} viewBox="0 0 16.2 16.2">
        <Path
          fill={orangeBase}
          d="M15.69,13.67,8.79,1.73a.8.8,0,0,0-1.38,0L.51,13.67a.77.77,0,0,0,0,.8.79.79,0,0,0,.69.4H15a.78.78,0,0,0,.69-.4A.81.81,0,0,0,15.69,13.67ZM7.51,4.78H8.76a.27.27,0,0,1,.29.27l-.33,6H7.56L7.23,5.05A.27.27,0,0,1,7.51,4.78Zm1.35,8.75a.94.94,0,0,1-.72.27,1,1,0,0,1-.74-.26,1.06,1.06,0,0,1-.26-.76A1,1,0,0,1,7.4,12a1,1,0,0,1,.74-.25.94.94,0,0,1,.72.26,1,1,0,0,1,.25.74A1.08,1.08,0,0,1,8.86,13.53Z"
        />
      </Svg>
    );
  }
};

export const sectionBorder = `1.5px solid ${ambient.basePlus90}`;
