import {
  Table,
  TableColumns,
} from '@energybox/react-ui-library/dist/components';
import {
  EnergyTariff,
  UnnecessaryDoorAccessRecommendations,
} from '@energybox/react-ui-library/dist/types';
import { global, KWH_UNIT } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import reportStyles from '../../../views/report/ReportPage.module.css';

type Props = {
  doorAccessRecommendations: UnnecessaryDoorAccessRecommendations[];
  currency?: string;
  tariff?: EnergyTariff | null;
};

const DoorAccessTable = ({
  doorAccessRecommendations,
  currency,
  tariff,
}: Props) => {
  if (doorAccessRecommendations.length === 0) return null;

  const getSavingsValue = (savings: number) =>
    !tariff ? global.NOT_AVAILABLE : (tariff.tariff * savings).toFixed(2);

  const columns: TableColumns<UnnecessaryDoorAccessRecommendations>[] = [
    {
      header: <div className={reportStyles.tableHeader}>#</div>,
      cellContent: (row: UnnecessaryDoorAccessRecommendations) => (
        <div className={reportStyles.tableCell}>{row.idx}</div>
      ),
    },
    {
      header: <div className={reportStyles.tableHeader}>Equipment</div>,
      width: '25%',
      cellContent: (row: UnnecessaryDoorAccessRecommendations) => (
        <div className={reportStyles.tableCell}>
          {row.equipmentName || global.NOT_AVAILABLE}
        </div>
      ),
    },
    {
      header: <div className={reportStyles.tableHeader}>Add. Energy/Min</div>,
      align: 'right',
      cellContent: (row: UnnecessaryDoorAccessRecommendations) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
        >
          {Number(row.additionalEnergy.toFixed(2))}
        </div>
      ),
    },
    {
      header: <div className={reportStyles.tableHeader}>Duration</div>,
      align: 'right',
      cellContent: (row: UnnecessaryDoorAccessRecommendations) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
        >
          {row.duration.toFixed(0)} min(s)
        </div>
      ),
    },
    {
      header: <div className={reportStyles.tableHeader}>Energy Wasted</div>,
      align: 'right',
      cellContent: (row: UnnecessaryDoorAccessRecommendations) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
        >
          {Number(row.energyWasted.toFixed(2))} {KWH_UNIT}
        </div>
      ),
    },
    {
      header: (
        <div className={reportStyles.tableHeader}>
          Estimated Savings* {!!currency ? `(${currency})` : ''}
        </div>
      ),
      align: 'right',
      cellContent: (row: UnnecessaryDoorAccessRecommendations) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
        >
          {getSavingsValue(row.energyWasted)}
        </div>
      ),
    },
  ];

  return (
    <div style={{ marginTop: '1rem' }}>
      <Table
        highlightAlternateRows
        columns={columns}
        data={doorAccessRecommendations.slice(0, 5)}
      />
    </div>
  );
};

export default DoorAccessTable;
