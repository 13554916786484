import {
  Certification,
  DailyTemperatureCheck,
  Equipment,
  EquipmentTemperatureCheckReport,
  Locale,
  Report,
  Sensor,
  Site,
  TemperatureCheck,
  TemperatureSop,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  createTemperatureString,
  global,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import { compareAsc } from 'date-fns';

import React, { createRef, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppLocale, useCurrentUser } from '../../../hooks/useAppDetails';
import { useReportTimeFormats } from '../../../hooks/utils';
import { ApplicationState } from '../../../reducers';
import { CertificationsBySensorId } from '../../../reducers/certifications';
import { EquipmentTempCheckFetchStatuses } from '../../../reducers/equipmentTempCheckReport';
import ReportHeading from '../ReportHeading';
import ReportMeasurableElement from '../ReportMeasurableElement';
import ReportPageBreakControl from '../ReportPageBreakControl';
import styles from './EquipmentTempCheckReportPage.module.css';

type EquipmentTempCheckPageProps = {
  fromDate: string;
  toDate: string;
  site: Site;
  sensors: Sensor[];
  certificationsBySensorId: CertificationsBySensorId;
  equipmentTempChecks: EquipmentTemperatureCheckReport | undefined;
  equipment: Equipment;
  tempSop: TemperatureSop | undefined | null;
  reportConfig: Report;
};

type ProcessedDailyTempChecks = {
  daily_avg: number | undefined;
  date: number;
  temperatures: TemperatureCheck[] | undefined;
};

const EquipmentTempCheckReportPage = ({
  site,
  fromDate,
  toDate,
  equipment,
  sensors,
  equipmentTempChecks,
  tempSop,
  certificationsBySensorId,
  reportConfig,
}: EquipmentTempCheckPageProps) => {
  const locale = useAppLocale();
  const headerRef = useRef(null);
  const errorRef = useRef(null);
  //this is an array of refs, one for each sensor table
  const sensorTableRefs = useRef<React.RefObject<any>[]>();
  sensorTableRefs.current = sensors.map(() => createRef());

  const { fromDateInBrowserTimezone } = useReportTimeFormats(
    fromDate,
    toDate,
    site.timeZone
  );

  const reportFetchStatuses = useSelector<
    ApplicationState,
    EquipmentTempCheckFetchStatuses
  >(({ equipmentTempCheckReport }) => {
    return equipmentTempCheckReport.fetched;
  });

  const leftHeaderInfoColumn = useLeftHeaderInfoColumn(
    equipment,
    tempSop,
    locale
  );
  const rightHeaderInfoColumn = useRightHeaderInfoColumn(site);

  if (!fromDateInBrowserTimezone) return null;

  const headerContent = (
    <ReportMeasurableElement setRef={headerRef} key="heading">
      <ReportHeading
        title="Daily Temperature Report"
        toDate={fromDateInBrowserTimezone}
        showOnlyToDateMonthText
        leftColumnContent={leftHeaderInfoColumn}
        rightColumnContent={rightHeaderInfoColumn}
      />
    </ReportMeasurableElement>
  );

  return (
    <ReportPageBreakControl
      fetchedStatuses={reportFetchStatuses}
      headerForNewPages={headerContent}
      headerRef={headerRef}
    >
      {headerContent}

      {sensors.length ? (
        sensors.map((sensor, index) => {
          const dailyTempCheck = equipmentTempChecks?.by_sensor[sensor.id];
          if (!sensor || !dailyTempCheck || !dailyTempCheck.length) {
            return (
              <ReportMeasurableElement setRef={errorRef} key="error">
                <div className={styles.errorMessage}>No data available</div>
              </ReportMeasurableElement>
            );
          }

          return (
            <ReportMeasurableElement
              setRef={sensorTableRefs?.current?.[index]}
              key={`${sensor.id}-table`}
            >
              <SensorTempCheckReportPage
                key={`sensorTempCheck${sensor.id}`}
                dailyTemperatureChecks={dailyTempCheck}
                sensor={sensor}
                certifications={certificationsBySensorId[sensor.id]}
                locale={locale}
                fromDate={fromDate}
                toDate={toDate}
                reportConfig={reportConfig}
              />
            </ReportMeasurableElement>
          );
        })
      ) : (
        <ReportMeasurableElement setRef={errorRef} key="error">
          <div className={styles.errorMessage}>No data available</div>
        </ReportMeasurableElement>
      )}
    </ReportPageBreakControl>
  );
};

type SensorTempCheckPageProps = {
  dailyTemperatureChecks: DailyTemperatureCheck[];
  sensor: Sensor;
  certifications: Certification[] | undefined;
  locale: Locale;
  fromDate: string;
  toDate: string;
  reportConfig: Report;
};

const SensorTempCheckReportPage = ({
  sensor,
  dailyTemperatureChecks,
  certifications,
  locale,
  fromDate,
  toDate,
  reportConfig,
}: SensorTempCheckPageProps) => {
  const currentUser = useCurrentUser();
  const { leftColumn, rightColumn } = useMemo(() => {
    const from = +fromDate.split('-')[2];
    const to = +toDate.split('-')[2];

    let dates: number[] = [];
    for (let i = from; i <= to; i++) {
      dates.push(i);
    }
    const processedAvailableTempChecks = dailyTemperatureChecks.map(
      dailyData => {
        const sortedTemperatureReadings = dailyData.temperatures.sort(
          sortTempChecksByStartTime
        );
        const date = +dailyData.date.split('-')[2];
        return {
          ...dailyData,
          date,
          temperatures: sortedTemperatureReadings,
        };
      }
    );

    const availableTempChecksMapping = mapArrayToObject(
      processedAvailableTempChecks,
      'date'
    );

    // Fill in undefined report dates as array entry to make table look professional
    const processedDailyTempChecks = dates.map<ProcessedDailyTempChecks>(
      date => {
        const tempCheckData = availableTempChecksMapping[date];
        if (tempCheckData === undefined) {
          return {
            date,
            daily_avg: undefined,
            temperatures: undefined,
          };
        }
        return tempCheckData;
      }
    );

    const columnData = {
      leftColumn: processedDailyTempChecks.slice(0, 16),
      rightColumn: processedDailyTempChecks.slice(16),
    };
    return columnData;
  }, [dailyTemperatureChecks, fromDate, toDate]);

  const tempCheckPeriods = useTempCheckColumns(reportConfig);

  const cert = certifications?.[0];

  if (!currentUser) return null;

  return (
    <>
      <div>
        <div className={styles.tableHeader}>
          <div>
            <span className={styles.tableHeaderLabel}>Sensor Name:</span>{' '}
            {sensor.title}
          </div>
          <div>
            <span className={styles.tableHeaderLabel}>Sensor UUID:</span>{' '}
            {sensor.uuid}
          </div>
        </div>

        <div className={styles.certContainer}>
          {cert ? (
            <>
              <div>
                {cert.certificationBody} Certificate ID: {cert.id}
              </div>
              <div>Calibration Date: {cert.validFrom}</div>
              <div>Re-Calibration Date: {cert.validTo}</div>
            </>
          ) : (
            <br />
          )}
        </div>
        <div className={styles.tableContentContainer}>
          <div
            className={styles.tableContentSplit}
            style={{
              gridTemplateColumns: `[dateColumn] 3rem repeat(${tempCheckPeriods.length}, [dataColumn] auto) [averageColumn] 6.5rem`,
            }}
          >
            <div
              className={classNames(styles.tableHeaderCell, styles.tableCell)}
            >
              Date
            </div>
            {tempCheckPeriods.map((period, index) => (
              <div
                className={classNames(styles.tableHeaderCell, styles.tableCell)}
                key={`leftHeader${index}`}
              >
                {period}
              </div>
            ))}
            <div
              className={classNames(styles.tableHeaderCell, styles.tableCell)}
            >
              Daily Average
            </div>
            {leftColumn.map((dailyAggregate, rowIndex) => {
              const date = dailyAggregate.date;
              const average = dailyAggregate.daily_avg;
              const tempChecks = dailyAggregate.temperatures;
              return (
                <React.Fragment key={`leftSideRow${rowIndex}`}>
                  <div
                    className={classNames(
                      styles.tableCell,
                      rowIndex % 2 ? '' : styles.shaded
                    )}
                  >
                    {date}
                  </div>
                  {tempChecks
                    ? tempChecks.map(({ temperature }, columnIndex) => {
                        return (
                          <div
                            className={classNames(
                              styles.tableCell,
                              rowIndex % 2 ? '' : styles.shaded,
                              tempCheckPeriods.length > 4
                                ? styles.resizedFont
                                : ''
                            )}
                            key={`leftRow${rowIndex}Column${columnIndex}`}
                          >
                            {temperature
                              ? createTemperatureString(
                                  +temperature,
                                  currentUser,
                                  1
                                )
                              : global.NOT_AVAILABLE}
                          </div>
                        );
                      })
                    : tempCheckPeriods.map((_, columnIndex) => (
                        <div
                          className={classNames(
                            styles.tableCell,
                            rowIndex % 2 ? '' : styles.shaded
                          )}
                          key={`leftRow${rowIndex}Column${columnIndex}`}
                        >
                          {global.NOT_AVAILABLE}
                        </div>
                      ))}
                  <div
                    className={classNames(
                      styles.tableCell,
                      rowIndex % 2 ? '' : styles.shaded,
                      tempCheckPeriods.length > 4 ? styles.resizedFont : ''
                    )}
                  >
                    {average !== null && average !== undefined
                      ? createTemperatureString(average, currentUser, 1)
                      : global.NOT_AVAILABLE}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          {rightColumn.length > 0 && (
            <div
              className={styles.tableContentSplit}
              style={{
                gridTemplateColumns: `[dateColumn] 3rem repeat(${tempCheckPeriods.length}, [dataColumn] auto) [averageColumn] 6.5rem`,
              }}
            >
              <div
                className={classNames(styles.tableHeaderCell, styles.tableCell)}
              >
                Date
              </div>
              {tempCheckPeriods.map((period, index) => (
                <div
                  className={classNames(
                    styles.tableHeaderCell,
                    styles.tableCell
                  )}
                  key={`rightHeader${index}`}
                >
                  {period}
                </div>
              ))}
              <div
                className={classNames(styles.tableHeaderCell, styles.tableCell)}
              >
                Daily Average
              </div>
              {rightColumn.map((dailyAggregate, rowIndex) => {
                const date = dailyAggregate.date;
                const average = dailyAggregate.daily_avg;
                const tempChecks = dailyAggregate.temperatures;
                return (
                  <React.Fragment key={`rightSideRow${rowIndex}`}>
                    <div
                      className={classNames(
                        styles.tableCell,
                        rowIndex % 2 ? '' : styles.shaded
                      )}
                    >
                      {date}
                    </div>
                    {tempChecks
                      ? tempChecks.map(({ temperature }, columnIndex) => {
                          return (
                            <div
                              className={classNames(
                                styles.tableCell,
                                rowIndex % 2 ? '' : styles.shaded,
                                tempCheckPeriods.length > 4
                                  ? styles.resizedFont
                                  : ''
                              )}
                              key={`rightRow${rowIndex}Column${columnIndex}`}
                            >
                              {temperature
                                ? createTemperatureString(
                                    +temperature,
                                    currentUser,
                                    1
                                  )
                                : global.NOT_AVAILABLE}
                            </div>
                          );
                        })
                      : tempCheckPeriods.map((_, columnIndex) => (
                          <div
                            className={classNames(
                              styles.tableCell,
                              rowIndex % 2 ? '' : styles.shaded
                            )}
                            key={`rightRow${rowIndex}Column${columnIndex}`}
                          >
                            {global.NOT_AVAILABLE}
                          </div>
                        ))}
                    <div
                      className={classNames(
                        styles.tableCell,
                        rowIndex % 2 ? '' : styles.shaded,
                        tempCheckPeriods.length > 4 ? styles.resizedFont : ''
                      )}
                    >
                      {average !== null && average !== undefined
                        ? createTemperatureString(average, currentUser, 1)
                        : global.NOT_AVAILABLE}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className={styles.reportReview}>
          <div className={styles.reportSignature}>
            <div>Reviewed By (Signature)</div>
            <div>Comment</div>
          </div>
          <div>Date</div>
        </div>
      </div>
    </>
  );
};

const useTempCheckColumns = (reportConfig: Report) => {
  return useMemo(() => {
    const columns = reportConfig.periods.map(({ from, to }) => {
      return `${from} - ${to}`;
    });

    return columns;
  }, [reportConfig]);
};

const sortTempChecksByStartTime = (
  checkA: TemperatureCheck,
  checkB: TemperatureCheck
) => {
  const a = checkA.from.split(':');
  const b = checkB.from.split(':');
  const fromDateA = new Date();
  const fromDateB = new Date();
  fromDateA.setHours(+a[0]);
  fromDateA.setMinutes(+a[1]);
  fromDateB.setHours(+b[0]);
  fromDateB.setMinutes(+b[1]);
  return compareAsc(fromDateA, fromDateB);
};

const useLeftHeaderInfoColumn = (
  equipment: Equipment,
  tempSop: TemperatureSop | undefined | null,
  locale: Locale
) => {
  const currentUser = useCurrentUser();
  if (!currentUser) return [];
  return useMemo(() => {
    return [
      { fieldName: 'Equipment Name', fieldValue: equipment.title },
      {
        fieldName: 'Space',
        fieldValue: equipment.space?.title || global.NOT_AVAILABLE,
      },
      {
        fieldName: 'Equipment Type',
        fieldValue: equipment.type?.title || global.NOT_AVAILABLE,
      },
      {
        fieldName: 'Vendor',
        fieldValue: equipment.vendor || global.NOT_AVAILABLE,
      },
      {
        fieldName: 'Model',
        fieldValue: equipment.model || global.NOT_AVAILABLE,
      },
      {
        fieldName: 'SOP Range',
        fieldValue: tempSop
          ? `${createTemperatureString(
              tempSop.min,
              currentUser,
              1
            )} to ${createTemperatureString(tempSop.max, currentUser, 1)}`
          : global.NOT_AVAILABLE,
      },
    ];
  }, [equipment, tempSop, locale]);
};

const useRightHeaderInfoColumn = (site: Site) => {
  return useMemo(() => {
    return [
      {
        fieldName: 'Organization',
        fieldValue: site.organization?.title || global.NOT_AVAILABLE,
      },
      { fieldName: 'Site', fieldValue: site.title },
      { fieldName: 'Address', fieldValue: site.address },
    ];
  }, [site]);
};

export default EquipmentTempCheckReportPage;
