import {
  OperationalSample,
  TemperatureSop,
  HumiditySop,
  SensorType,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { average } from '../util';
import min from 'ramda/src/min';
import max from 'ramda/src/max';

export enum Periods {
  'DAY' = 'MM/dd/yyyy',
  'HOUR' = 'H',
}

export enum PeriodToLabel {
  'MM/dd/yyyy' = 'Day',
  'H' = 'Hour',
}

export const resolveDateRangeToPeriodEnumValue = (
  fromDateEpochMs,
  toDateEpochMs
) => {
  return toDateEpochMs - fromDateEpochMs > 86400000
    ? Periods.DAY
    : Periods.HOUR;
};

export const sanitizeSampleValues = (data: OperationalSample[]) => {
  return data
    .map(({ value }) => value)
    .filter((v): v is number => typeof v === 'number');
};

export const temperatureOrHumidityAverage = (
  data: OperationalSample[],
  type: SensorType
) => {
  const sanitizedSampleValues = sanitizeSampleValues(
    data.filter(entry => entry.type === type)
  );
  return average(sanitizedSampleValues);
};

export const temperatureOrHumidityMin = (
  data: OperationalSample[],
  type: SensorType
) => {
  const sanitizedSampleValues = sanitizeSampleValues(
    data.filter(entry => entry.type === type)
  );
  return Math.min(...sanitizedSampleValues);
};

export const temperatureOrHumidityMax = (
  data: OperationalSample[],
  type: SensorType
) => {
  const sanitizedSampleValues = sanitizeSampleValues(
    data.filter(entry => entry.type === type)
  );
  return Math.max(...sanitizedSampleValues);
};

export type ComplianceObject = {
  inComplianceNumber: number;
  inCompliancePercent: number;
  outOfComplianceNumber: number;
  outOfCompliancePercent: number;
  totalDataPoints: number;
};

export const complianceAnalysis = (
  data: OperationalSample[],
  sop: TemperatureSop | HumiditySop
): ComplianceObject => {
  const sanitizedSampleValues = sanitizeSampleValues(data);
  const res = sanitizedSampleValues.reduce(
    (acc, cur) => {
      if (cur < sop.min || cur > sop.max) {
        acc.outOfComplianceNumber = acc.outOfComplianceNumber + 1;
      } else {
        acc.inComplianceNumber = acc.inComplianceNumber + 1;
      }

      return acc;
    },
    {
      inComplianceNumber: 0,
      outOfComplianceNumber: 0,
      inCompliancePercent: 0,
      outOfCompliancePercent: 0,
      totalDataPoints: sanitizedSampleValues.length,
    }
  );
  res.inCompliancePercent =
    (res.inComplianceNumber / res.totalDataPoints) * 100;
  res.outOfCompliancePercent =
    (res.outOfComplianceNumber / res.totalDataPoints) * 100;
  return res;
};
