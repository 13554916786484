
import {
  Comment,
  User,
  UsersById,
  ValueType,
  SensorType,
  CurrentUser,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  determineCommentValue,
} from '@energybox/react-ui-library/dist/utils';
import parseISO from 'date-fns/parseISO';

export const filterAndProcessComments = (
  comments: Comment[] = [],
  resourceIds: number[] = [],
  currentUser: CurrentUser,
  usersById: UsersById,
  valueType: SensorType | ValueType
) => {
  const getUserName = (user?: User) => {
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }
    return global.NOT_AVAILABLE;
  };

  if (comments && resourceIds) {
    const filteredComments = comments.filter(c => {
      return resourceIds.includes(c.resourceId) && c.valueType === valueType;
    });

    return filteredComments.map(c => {
      return {
        ...c,
        timestamp: parseISO(c.from).valueOf(),
        commentValue: determineCommentValue(
          c.value,
          c.valueType,
          currentUser,
          'forDisplayingInChart'
        ) as number,
        userName: getUserName(usersById[c.userId]),
      };
    });
  }
  return undefined;
};
