import { EnergyData, Site } from '@energybox/react-ui-library/dist/types';
import subDays from 'date-fns/subDays';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { fetchSiteEnergyConsumptionOverWeeks } from '../../../actions/energy';
import { useWeatherOverWeeks } from '../../../hooks/useWeathers';
import { CombinedWeatherAndEnergyData } from '../../../types/weather';
import EnergyReportWeatherAndEnergyChart from '../EnergyReportWeatherAndEnergyChart';

type OwnProps = {
  site: Site;
  toDate: Date;
  hideSectionTitle?: boolean;
};

interface Props extends OwnProps {
  fetchSiteEnergyConsumptionOverWeeks: (dates: Date[], siteId: number) => void;
  energyOverWeeksLoading: boolean;
  energyOverWeeksFetched: boolean;
  siteEnergyConsumptionOverWeeks: EnergyData[];
}

const EnergyReportWeatherAndEnergyChartContainer: React.FC<Props> = ({
  hideSectionTitle,
  site,
  fetchSiteEnergyConsumptionOverWeeks,
  energyOverWeeksLoading,
  energyOverWeeksFetched,
  siteEnergyConsumptionOverWeeks,
  toDate,
}) => {
  const [datesForQuery, setDatesForQuery] = useState<Date[]>();

  const {
    weatherOverWeeksLoading,
    weatherOverWeeksFetched,
    weatherOverWeeksError,
    siteWeatherOverWeeks,
    tempUnit,
    fetchSiteWeatherOverWeeks,
  } = useWeatherOverWeeks();

  useEffect(() => {
    let aWeekBefore = subDays(toDate, 7);
    const weeks: Date[] = [];

    for (let noOfWeeks = 0; noOfWeeks <= 12; ) {
      weeks.unshift(aWeekBefore);
      aWeekBefore = subDays(aWeekBefore, 7);
      noOfWeeks += 1;
    }

    weeks.push(toDate);
    setDatesForQuery(weeks);
  }, [setDatesForQuery, toDate]);

  useEffect(() => {
    if (
      !energyOverWeeksLoading &&
      !energyOverWeeksFetched &&
      !!datesForQuery &&
      datesForQuery.length > 0
    ) {
      fetchSiteEnergyConsumptionOverWeeks(datesForQuery, site.id);
    }
  }, [
    datesForQuery,
    site.id,
    energyOverWeeksLoading,
    energyOverWeeksFetched,
    fetchSiteEnergyConsumptionOverWeeks,
  ]);

  useEffect(() => {
    if (
      !weatherOverWeeksLoading &&
      !weatherOverWeeksFetched &&
      !weatherOverWeeksError &&
      !!datesForQuery &&
      datesForQuery.length > 0
    ) {
      fetchSiteWeatherOverWeeks(datesForQuery, site.id);
    }
  }, [
    datesForQuery,
    fetchSiteWeatherOverWeeks,
    weatherOverWeeksFetched,
    weatherOverWeeksLoading,
    weatherOverWeeksError,
    site.id,
  ]);

  const combinedData: CombinedWeatherAndEnergyData[] = useMemo(() => {
    if (energyOverWeeksFetched && weatherOverWeeksFetched) {
      return siteEnergyConsumptionOverWeeks
        .filter(energy => energy !== undefined)
        .map(energy => {
          return {
            ...energy,
            ...siteWeatherOverWeeks.find(
              weather => weather.fromDate === energy.fromDate
            ),
          };
        });
    } else {
      return [];
    }
  }, [
    siteWeatherOverWeeks,
    siteEnergyConsumptionOverWeeks,
    energyOverWeeksFetched,
    weatherOverWeeksFetched,
  ]);

  if (!weatherOverWeeksFetched || !energyOverWeeksFetched) {
    return <div />;
  }
  return (
    <EnergyReportWeatherAndEnergyChart
      hideSectionTitle={hideSectionTitle}
      combinedData={combinedData}
      tempUnit={tempUnit}
    />
  );
};

const mapStateToProps = state => ({
  energyReport: state.energy,
  siteEnergyConsumptionOverWeeks: state.energy.energyOverWeeks,
  energyOverWeeksLoading: state.energyReport.loading.siteEnergyByWeeks,
  energyOverWeeksFetched: state.energyReport.fetched.siteEnergyByWeeks,
});

const mapDispatchToProps = {
  fetchSiteEnergyConsumptionOverWeeks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyReportWeatherAndEnergyChartContainer);
