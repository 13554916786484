import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateReportById } from '../../../actions/temperatureRecords';
import { useGetSite } from '../../../hooks/sites';
import { ApplicationState } from '../../../reducers';
import { TemperatureRecordReportFetchStatuses } from '../../../reducers/temperatureRecordReport';
import TemperatureRecordReportPage from '../TemperatureRecordReportPage';
import { parseISO, isBefore, differenceInCalendarDays, addDays, formatISO } from 'date-fns'
import { GeneratedReport } from '../../../types/temperatureRecord';
import { all, equals } from 'ramda';

type Props = {
  siteId: string;
  reportId: string;
  fromDate: string;
  toDate: string;
};

const TemperatureRecordReportPageContainer: React.FC<Props> = ({
  siteId,
  reportId,
  fromDate,
  toDate,
}) => {
  const dispatch = useDispatch();
  const site = useGetSite(Number(siteId));
  const fromDateInBrowserTimezone: Date = parseISO(fromDate);
  const toDateInBrowserTimezone: Date = parseISO(toDate);
  const generateDatesArray = (from: Date, to: Date): { dateString: string, dateObject: Date }[] => {
    if (fromDate !== toDate && !isBefore(from, to)) {
      throw new Error('toDate must be later than or same as fromDate');
    }
    const numOfDays: number = differenceInCalendarDays(to, from);
    const dateArray: { dateString: string, dateObject: Date }[] = [];
    for (let i = 0; i <= numOfDays; i++) {
      const nextDay = addDays(from, i);
      dateArray.push({
        dateString: formatISO(nextDay, { representation: 'date' }),
        dateObject: nextDay
      });
    }
    return dateArray;
  };
  const datesArray = generateDatesArray(fromDateInBrowserTimezone, toDateInBrowserTimezone);

  useEffect(() => {
    datesArray.forEach((date) => {
      dispatch(generateReportById(reportId, date.dateString));
    });
  }, [dispatch]);

  const generatedReports = useSelector(
    ({ temperatureRecord }: ApplicationState) => {
      const generatedReportById = temperatureRecord.generatedReportById[reportId];
      return datesArray.reduce<GeneratedReport[]>((reports, date) => {
        const report = generatedReportById?.[date.dateString];
        if (report) {
          reports.push(report);
        }
        return reports;
      }, []);
    }
  );
  const fetchedGeneratedReport = useSelector(
    ({ temperatureRecordReport }: ApplicationState) => {
      const fetchedStatus = temperatureRecordReport.fetched?.generatedReport[reportId];
      const isTrue = equals(true);
      return all(isTrue)(Object.values(fetchedStatus || { 0: false }));
    }
  );

  const temperatureRecordReportFetchStatus: TemperatureRecordReportFetchStatuses = {
    generatedReport: fetchedGeneratedReport,
  };

  if (!site || !temperatureRecordReportFetchStatus) return null;

  return (
    <>
      {generatedReports.map((generatedReport, i) => {
        return (
          <TemperatureRecordReportPage
            site={site}
            generatedReport={generatedReport}
            date={datesArray[i].dateObject}
            temperatureRecordReportFetchStatus={temperatureRecordReportFetchStatus}
          />
        )
      })}
    </>
  );
};

export default TemperatureRecordReportPageContainer;
