import { EnergyTariff } from '@energybox/react-ui-library/dist/types';

export function calculateEnergyCost(
  value: number,
  energyTariffSop: EnergyTariff
) {
  return value * energyTariffSop.tariff;
}

export function createEnergyCostText(
  value: number,
  energyTariffSop: EnergyTariff
) {
  return `${(value * energyTariffSop.tariff).toFixed(2)} ${
    energyTariffSop.currencyCode
  }`;
}

export function mapTariffCurrencyCodeToCountryCode(currencyCode?: string) {
  switch (currencyCode) {
    case 'USD':
      return 'US';
    case 'IDR':
      return 'ID';
    case 'HKD':
      return 'HK';
    case 'CNY':
      return 'CN';
    case 'MXN':
      return 'MX';
    case 'EUR':
      return 'DE';
    case 'PHP':
      return 'PH';
    default:
      return 'US';
  }
}
