import { NotificationApiFilter } from '../types/notification';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import { Service } from '../config';

const DEFAULT_LIMIT = 2500;
const DEFAULT_OFFSET = 0;

export enum Actions {
  GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS = '@@equipment/GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS',
  GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING = '@@equipment/GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING',
  GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR = '@@equipment/GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR',

  GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS = '@@equipment/GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS',
  GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_LOADING = '@@equipment/GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_LOADING',
  GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_ERROR = '@@equipment/GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_ERROR',

  EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_SUCCESS = '@@equipment/EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_SUCCESS',
  EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_LOADING = '@@equipment/EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_LOADING',
  EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_ERROR = '@@equipment/EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_ERROR',
}

const setNotificationFilter = (filter?: NotificationApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds) {
    if (typeof filter.siteIds === 'string')
      queryParams.set('siteIds', filter.siteIds);
    else {
      queryParams.set('siteIds', filter.siteIds.join(','));
    }
  }

  if (filter && filter.equipmentIds && filter.equipmentIds.length > 0) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.sensorIds && filter.sensorIds.length > 0) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  if (filter && filter.fromDate) {
    //currently notification list endpoint CANNOT take time...
    // so we add a day on either end so that our request includes notifications that
    // fall on another date from the notification API's POV because it is TZ insensitive
    const dateToQuery = subDays(Date.parse(filter.fromDate), 1);
    queryParams.set('fromDate', format(dateToQuery, 'yyyy-MM-dd'));
  }

  if (filter && filter.toDate) {
    //currently notification list endpoint CANNOT take time...
    // so we add a day on either end so that our request includes notifications that
    // fall on another date from the notification API's POV because it is TZ insensitive
    const dateToQuery = addDays(Date.parse(filter.toDate), 1);
    queryParams.set('toDate', format(dateToQuery, 'yyyy-MM-dd'));
  }

  if (filter && filter.from) {
    //flatNotifications endpoint only takes ISO in UTC
    queryParams.set('from', filter.from);
  }

  if (filter && filter.to) {
    //flatNotifications endpoint only takes ISO in UTC
    queryParams.set('to', filter.to);
  }

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  } else {
    queryParams.set('limit', DEFAULT_LIMIT.toString());
  }

  if (filter && filter.offset) {
    queryParams.set('offset', filter.offset.toString());
  } else {
    queryParams.set('offset', DEFAULT_OFFSET.toString());
  }

  queryParams.set('withPath', 'true');

  return queryParams.toString();
};

export const getFlatNotificationsByEquipmentId = (
  equipmentId: number,
  queryParams: NotificationApiFilter
) => {
  const updatedQueryParams = queryParamsWithDefaultLimitAndOffset(queryParams);

  return {
    type: 'API_GET',
    path: `/api/v1/flatnotifications?${setNotificationFilter(
      updatedQueryParams
    )}`,
    service: Service.sentinel,
    limit: updatedQueryParams.limit,
    offset: updatedQueryParams.offset,
    loading: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING,
      equipmentId,
    },
    success: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS,
      equipmentId,
      timePeriod: { from: queryParams.from, to: queryParams.to },
    },
    error: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR,
      equipmentId,
    },
  };
};

export const getFlatNotificationsBySensorId = (
  sensorId: number,
  queryParams: NotificationApiFilter
) => {
  const updatedQueryParams = queryParamsWithDefaultLimitAndOffset(queryParams);

  return {
    type: 'API_GET',
    path: `/api/v1/flatnotifications?${setNotificationFilter(
      updatedQueryParams
    )}`,
    service: Service.sentinel,
    limit: updatedQueryParams.limit,
    offset: updatedQueryParams.offset,
    loading: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_LOADING,
      sensorId,
    },
    success: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS,
      sensorId,
      timePeriod: { from: queryParams.from, to: queryParams.to },
    },
    error: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_ERROR,
      sensorId,
    },
  };
};

///*** Miscellaneous ***///
const queryParamsWithDefaultLimitAndOffset = (
  queryParams: NotificationApiFilter
) => ({
  ...queryParams,
  limit: queryParams.limit || DEFAULT_LIMIT,
  offset: queryParams.offset || DEFAULT_OFFSET,
});
