import React from 'react';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import styles from './BaseContentContainer.module.css';
import LoadingBar from '../LoadingBar';

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  fillFrame?: boolean;
  capHeight?: boolean;
};

const BaseContentContainer = ({
  children,
  isLoading = false,
  fillFrame = false,
  capHeight = true,
}: Props) => (
  <div
    className={classNames(
      styles.root,
      fillFrame ? styles.fillFrame : styles.margin,
      capHeight ? styles.capHeight : null
    )}
  >
    {children}

    <LoadingBar isLoading={isLoading} />
  </div>
);

export default BaseContentContainer;
