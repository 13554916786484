import { useDispatch, useSelector } from 'react-redux';
import { convertTemp } from '@energybox/react-ui-library/dist/utils';
import format from 'date-fns/format';
import * as R from 'ramda';

import { useTemperatureUnit } from './useAppDetails';
import {
  fetchSiteWeatherOverWeeks as fetchWeekWeather,
  getWeatherBySiteId,
} from '../actions/weather';
import { ApplicationState } from '../reducers';
import { WeatherDataForChart } from '../types/weather';
import { TemperatureUnit } from '@energybox/react-ui-library/dist/types';

export const useInit = () => {
  const tempUnit = useTemperatureUnit();

  const dispatch = useDispatch();

  const convertWeatherData = (weatherData: WeatherDataForChart) => {
    let convertedWeather = { ...weatherData };
    // weather.energybox.com always return celcius temperature
    if (tempUnit === TemperatureUnit.F) {
      for (const key in weatherData) {
        if (key.endsWith('_temperature')) {
          convertedWeather[key] = convertTemp(weatherData[key], 'c');
        }
      }
    }
    return convertedWeather;
  };

  return {
    tempUnit,
    dispatch,
    convertWeatherData,
  };
};

export const useWeather = (siteId: string) => {
  const { tempUnit, dispatch, convertWeatherData } = useInit();

  const weather = useSelector(({ weather }: ApplicationState) =>
    R.pathOr(null, ['weatherBySiteId', siteId], weather)
  );

  const getWeather = dispatch(
    getWeatherBySiteId(Number(siteId), format(new Date(), 'yyyy-MM-dd'))
  );

  return {
    weather: convertWeatherData(weather),
    getWeather,
    tempUnit,
  };
};

export const useWeatherOverWeeks = () => {
  const { tempUnit, dispatch, convertWeatherData } = useInit();

  const {
    weatherOverWeeksLoading,
    weatherOverWeeksFetched,
    weatherOverWeeksError,
    siteWeatherOverWeeks,
  } = useSelector(({ energyReport, weather }: ApplicationState) => ({
    weatherOverWeeksLoading: energyReport.loading.siteWeatherByWeeks,
    weatherOverWeeksFetched: energyReport.fetched.siteWeatherByWeeks,
    weatherOverWeeksError: energyReport.errors.siteWeatherByWeeks,
    siteWeatherOverWeeks: weather.siteWeatherOverWeeks,
  }));

  const fetchSiteWeatherOverWeeks = (datesForQuery: Date[], siteId: number) =>
    dispatch(fetchWeekWeather(datesForQuery, siteId));

  return {
    weatherOverWeeksFetched,
    weatherOverWeeksLoading,
    weatherOverWeeksError,
    siteWeatherOverWeeks: siteWeatherOverWeeks.map(weatherData =>
      convertWeatherData(weatherData)
    ),
    tempUnit,
    fetchSiteWeatherOverWeeks,
  };
};
