import {DoorEntryData} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
} from 'recharts';

type Props = {
  entriesOverTime: DoorEntryData[];
  numberOfDays: number;
};

function CustomXAxisTick({ x, y, textAnchor, payload, fontSize }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy="0.71em"
        textAnchor={textAnchor}
        fill="rgba(51, 62, 72, 0.5)"
        fontSize={fontSize}
        stroke="none"
      >
        {payload.tickValue}
      </text>
    </g>
  );
}

const ReportDoorEntriesGraphs = ({ entriesOverTime, numberOfDays }: Props) => {
  const sorter = {
    Mon: 0,
    Tue: 1,
    Wed: 2,
    Thu: 3,
    Fri: 4,
    Sat: 5,
    Sun: 6,
  };
  if (!!entriesOverTime && entriesOverTime.length > 0) {
    const times = {};
    const days = {};
    entriesOverTime.forEach(entry => {
      const time = entry.time;
      const day = entry.day;
      if (days[day]) {
        days[day] = days[day] + 1;
      } else {
        days[day] = 1;
      }

      if (times[time]) {
        times[time] = times[time] + 1;
      } else {
        times[time] = 1;
      }
    });

    Object.keys(times).forEach(
      key => (times[key] = (times[key] / numberOfDays).toFixed(1))
    );
    Object.keys(days).forEach(
      key => (days[key] = (days[key] / numberOfDays).toFixed(1))
    );

    const formattedHoursData = Object.keys(times).map(key => ({
      x: key,
      y: times[key],
    }));

    const formattedDaysData = Object.keys(days)
      .map(key => ({
        x: key,
        y: days[key],
      }))
      .sort((a, b) => {
        let day1 = a.x;
        let day2 = b.x;

        return sorter[day1] - sorter[day2];
      });

    return (
      <div style={{ marginTop: '1em' }}>
        <div
          style={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginBottom: '2rem',
          }}
        >
          Daily Traffic
        </div>
        <div>
          <ComposedChart
            data={formattedHoursData}
            margin={{ right: 10 }}
            height={300}
            width={1440}
          >
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#2addd0" stopOpacity={0.4} />
                <stop offset="70%" stopColor="#2addd0" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <Area
              dataKey="y"
              stroke={false}
              fill="url(#gradient)"
              isAnimationAction={false}
            />
            <XAxis
              axisLine={false}
              tickLine={false}
              datakey="x"
              type="category"
              minTickGap={0}
              tick={({ x, y, textAnchor, payload }) => (
                <CustomXAxisTick
                  x={x}
                  y={y}
                  textAnchor={textAnchor}
                  payload={{
                    ...payload,
                    tickValue: formattedHoursData[payload.value].x,
                  }}
                  fontSize="18px"
                />
              )}
            />
            <YAxis
              datakey="y"
              type="number"
              axisLine={false}
              tickLine={false}
              domain={[0, dataMax => dataMax + Math.abs((dataMax / 100) * 10)]}
              tick={{ fontSize: '16px', fill: 'black' }}
              tickFormatter={tickItem => {
                return Number(tickItem.toFixed(2));
              }}
            />
            <Line
              // type="natural"
              key="insights-line"
              dot={false}
              dataKey="y"
              stroke="#2addd0"
              isAnimationAction={false}
            />
          </ComposedChart>
        </div>
        <div
          style={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginBottom: '2rem',
            marginTop: '1rem',
          }}
        >
          Weekly Traffic
        </div>
        <div>
          <ComposedChart data={formattedDaysData} height={300} width={1440}>
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#2addd0" stopOpacity={0.4} />
                <stop offset="70%" stopColor="#2addd0" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <Area
              // type="natural"
              dataKey="y"
              stroke={false}
              fill="url(#gradient)"
              isAnimationAction={false}
            />
            <XAxis
              axisLine={false}
              tickLine={false}
              datakey="x"
              type="category"
              minTickGap={0}
              tick={({ x, y, textAnchor, payload }) => (
                <CustomXAxisTick
                  x={x}
                  y={y}
                  textAnchor={textAnchor}
                  payload={{
                    ...payload,
                    tickValue: formattedDaysData[payload.value].x,
                  }}
                  fontSize="18px"
                />
              )}
            />
            <YAxis
              datakey="y"
              type="number"
              axisLine={false}
              tickLine={false}
              domain={[0, dataMax => dataMax + Math.abs((dataMax / 100) * 10)]}
              tick={{ fontSize: '16px', fill: 'black' }}
              tickFormatter={tickItem => {
                return Number(tickItem.toFixed(2));
              }}
            />
            <Line
              // type="natural"
              key="insights-line"
              dot={false}
              dataKey="y"
              stroke="#2addd0"
              isAnimationAction={false}
            />
          </ComposedChart>
        </div>
      </div>
    );
  }
  return <div />;
};

export default ReportDoorEntriesGraphs;
