import { Certification, Sensor } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import styles from './SensorInfoContainer.module.css';

type Props = {
  sensor?: Sensor | undefined;
  certifications?: Certification[] | undefined;
};

const SensorInfoContainer: React.FC<Props> = ({ sensor, certifications }) => {
  const sensorTitle = sensor?.title;
  const sensorUUID = sensor?.uuid;
  const cert = certifications?.[0];
  const certBody = cert?.certificationBody;
  const certId = cert?.certificationId;

  return (
    <div className={styles.root}>
      <div>{sensorTitle ? `Sensor Name: ${sensorTitle}` : ''}</div>
      <div>{sensorUUID ? `Sensor UUID: ${sensorUUID}` : ''}</div>
      <div>
        {certBody && certId ? (
          `${certBody} Certification Id: ${certId}`
        ) : (
          <div className={styles.empty} />
        )}
      </div>
    </div>
  );
};

export default SensorInfoContainer;
