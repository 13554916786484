import { Service } from '../config';
import { CommentApiFilter } from '@energybox/react-ui-library/dist/types';

const apiBase = '/api/v1/comments';
export enum Actions {
  GET_DOWNSTREAM_COMMENTS_LOADING = '@@resources/GET_DOWNSTREAM_COMMENTS_LOADING',
  GET_DOWNSTREAM_COMMENTS_SUCCESS = '@@resources/GET_DOWNSTREAM_COMMENTS_SUCCESS',
  GET_DOWNSTREAM_COMMENTS_ERROR = '@@resources/GET_DOWNSTREAM_COMMENTS_ERROR',
}

const setCommentFilter = (filter?: CommentApiFilter): string => {
  const queryParams = new URLSearchParams();
  if (!filter) return queryParams.toString();

  // To and from must be iso string
  const {
    from,
    to,
    resourceType,
    commentType,
    valueType,
    orgId,
    siteId,
    userId,
    query,
  } = filter;

  if (from) {
    queryParams.set('from', from);
  }

  if (to) {
    queryParams.set('to', to);
  }

  if (resourceType && resourceType.length > 0) {
    queryParams.set('resourceType', resourceType.join(','));
  }

  if (commentType && commentType.length > 0) {
    queryParams.set('commentType', commentType.join(','));
  }

  if (valueType && valueType.length > 0) {
    queryParams.set('valueType', valueType.join(','));
  }

  if (orgId) {
    queryParams.set('orgId', orgId.toString());
  }

  if (siteId && siteId.length > 0) {
    queryParams.set('siteId', siteId.join(','));
  }

  if (userId && userId.length > 0) {
    queryParams.set('userId', userId.join(','));
  }

  if (query) {
    queryParams.set('query', query);
  }

  return queryParams.toString();
};

//gets comments directly attached to Resource (i.e. equipment),
//as well as for other resources (i.e. sensors) attached to that Resource
export const getDownstreamComments = (
  resourceId: number | string,
  filter?: CommentApiFilter
) => ({
  type: 'API_GET',
  service: Service.comments,
  path: `${apiBase}/resource/${resourceId}/downstream?${setCommentFilter(
    filter
  )}`,
  loading: { type: Actions.GET_DOWNSTREAM_COMMENTS_LOADING, resourceId },
  success: { type: Actions.GET_DOWNSTREAM_COMMENTS_SUCCESS, resourceId },
  error: { type: Actions.GET_DOWNSTREAM_COMMENTS_ERROR, resourceId },
});
