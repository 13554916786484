import { Actions as SiteActions } from '../actions/sites';
import { Actions as SensorActions } from '../actions/sensors';
import { Actions as EquipmentActions } from '../actions/equipment';
import { Actions as CommentActions } from '../actions/comments';
import { Actions as UserActions } from '../actions/users';
import { Actions as NotificationActions } from '../actions/notifications';
import { Actions as SopActions } from '../actions/sops';
import { Actions as EnergyActions } from '../actions/energy';
import { Actions as SampleActions } from '../actions/operationalSamples';
import { Actions as CertActions } from '../actions/certifications';
import {
  toggleLoading,
  toggleSuccess,
  toggleError,
} from '../utils/reducerFetchingStates';

export type EquipmentReport = {
  errors: EquipmentReportFetchStatuses;
  loading: EquipmentReportFetchStatuses;
  fetched: EquipmentReportFetchStatuses;
};

export type EquipmentReportFetchStatuses = {
  site: boolean;
  equipment: boolean;
  sensors: boolean;
  comments: boolean;
  users: boolean;
  notifications: boolean;
  energy: boolean;
  tempSop: boolean;
  operationalSamples: boolean;
  certifications: boolean;
  energySensors: boolean;
};

const initialState: EquipmentReport = {
  errors: {
    site: false,
    equipment: false,
    sensors: false,
    comments: false,
    users: false,
    notifications: false,
    energy: false,
    tempSop: false,
    operationalSamples: false,
    certifications: false,
    energySensors: false,
  },
  loading: {
    site: false,
    equipment: false,
    sensors: false,
    comments: false,
    users: false,
    notifications: false,
    energy: false,
    tempSop: false,
    operationalSamples: false,
    certifications: false,
    energySensors: false,
  },
  fetched: {
    site: false,
    equipment: false,
    sensors: false,
    comments: false,
    users: false,
    notifications: false,
    energy: false,
    tempSop: false,
    operationalSamples: false,
    certifications: false,
    energySensors: false,
  },
};

export default (state: EquipmentReport = initialState, action) => {
  switch (action.type) {
    case UserActions.GET_USERS_LOADING:
      return toggleLoading('users', state);

    case UserActions.GET_USERS_ERROR:
      return toggleError('users', state);

    case UserActions.GET_USERS_SUCCESS:
      return toggleSuccess('users', state);

    case SiteActions.SITE_LOADING:
      return toggleLoading('site', state);

    case SiteActions.SITE_ERROR:
      return toggleError('site', state);

    case SiteActions.SITE_SUCCESS:
      return toggleSuccess('site', state);

    case EquipmentActions.GET_EQUIPMENT_LOADING:
      return toggleLoading('equipment', state);

    case EquipmentActions.GET_EQUIPMENT_ERROR:
      return toggleError('equipment', state);

    case EquipmentActions.GET_EQUIPMENT_SUCCESS:
      return toggleSuccess('equipment', state);

    case SensorActions.SENSORS_BY_EQUIPMENT_ID_LOADING:
      return toggleLoading('sensors', state);

    case SensorActions.SENSORS_BY_EQUIPMENT_ID_ERROR:
      return toggleError('sensors', state);

    case SensorActions.SENSORS_BY_EQUIPMENT_ID_SUCCESS:
      return toggleSuccess('sensors', state);

    case NotificationActions.EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_LOADING:
      return toggleLoading('notifications', state);

    case NotificationActions.EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_ERROR:
      return toggleError('notifications', state);

    case NotificationActions.EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_SUCCESS:
      return toggleSuccess('notifications', state);

    case CommentActions.GET_DOWNSTREAM_COMMENTS_LOADING:
      return toggleLoading('comments', state);

    case CommentActions.GET_DOWNSTREAM_COMMENTS_ERROR:
      return toggleError('comments', state);

    case CommentActions.GET_DOWNSTREAM_COMMENTS_SUCCESS:
      return toggleSuccess('comments', state);

    case SopActions.RESOLVE_SOP_BY_RESOURCE_ID_LOADING:
      return toggleLoading('tempSop', state);

    case SopActions.RESOLVE_SOP_BY_RESOURCE_ID_ERROR:
      return toggleError('tempSop', state);

    case SopActions.RESOLVE_SOP_BY_RESOURCE_ID_SUCCESS:
      return toggleSuccess('tempSop', state);

    case EnergyActions.EQUIPMENT_REPORT_ENERGY_LOADING:
      return toggleLoading('energy', state);

    case EnergyActions.EQUIPMENT_REPORT_ENERGY_ERROR:
      return toggleError('energy', state);

    case EnergyActions.SKIP_EQUIPMENT_REPORT_ENERGY:
    case EnergyActions.EQUIPMENT_REPORT_ENERGY_SUCCESS:
      return toggleSuccess('energy', state);

    case SampleActions.EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_LOADING:
      return toggleLoading('operationalSamples', state);

    case SampleActions.EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_ERROR:
      return toggleError('operationalSamples', state);

    case SampleActions.SKIP_EQUIPMENT_REPORT_OPERATIONAL_SAMPLES:
    case SampleActions.EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_SUCCESS:
      return toggleSuccess('operationalSamples', state);

    case CertActions.EQUIPMENT_REPORT_CERTIFICATIONS_LOADING:
      return toggleLoading('certifications', state);

    case CertActions.EQUIPMENT_REPORT_CERTIFICATIONS_ERROR:
      return toggleError('certifications', state);

    case CertActions.SKIP_EQUIPMENT_REPORT_CERTIFICATIONS:
    case CertActions.EQUIPMENT_REPORT_CERTIFICATIONS_SUCCESS:
      return toggleSuccess('certifications', state);

    case EnergyActions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_LOADING:
      return toggleLoading('energySensors', state);

    case EnergyActions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR:
      return toggleError('energySensors', state);

    case EnergyActions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_SUCCESS:
      return toggleSuccess('energySensors', state);

    default:
      return state;
  }
};
