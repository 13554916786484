import { Space } from '@energybox/react-ui-library/dist/types';
import assocPath from 'ramda/src/assocPath';
import { Actions } from '../actions/spaces';

export interface SpacesBySiteId {
  [siteId: number]: Space[];
}

export type Spaces = {
  spacesBySiteId: SpacesBySiteId;
};

const initialState = {
  spacesBySiteId: {},
};

export default (state: Spaces = initialState, action: any) => {
  switch (action.type) {
    case Actions.SPACES_SUCCESS: {
      return assocPath(
        ['spacesBySiteId', action.siteId],
        action.payload,
        state
      );
    }

    default: {
      return state;
    }
  }
};
