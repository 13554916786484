import {
  AggregationLevel,
  SopTypes,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import useGetEquipmentEnergy from '../../../hooks/analytics/equipment';
import { useGetDownstreamComments } from '../../../hooks/comments';
import useGetEquipment from '../../../hooks/equipment';
import { useGetFlatNotificationsByEquipmentId } from '../../../hooks/notifications';
import { useGetSensorsByEquipmentId } from '../../../hooks/sensors';
import { useGetSite } from '../../../hooks/sites';
import { useSopByResourceId } from '../../../hooks/sop';
import { useGetUsers } from '../../../hooks/users';
import { useReportTimeFormats } from '../../../hooks/utils';
import EquipmentReportPage from '../EquipmentReportPage';

type Props = {
  siteId: string;
  equipmentId: string;
  fromDate: string;
  toDate: string;
  aggregationLevel: AggregationLevel;
};

const previousPeriodAffixConst = 'prev';
// const sentinelAPIdateformat = 'yyyy-MM-dd';

const EquipmentReportPageContainer: React.FC<Props> = ({
  siteId,
  equipmentId,
  fromDate,
  toDate,
  aggregationLevel,
}) => {
  const site = useGetSite(+siteId);
  const ianaTimeZone = site?.timeZone;
  const equipment = useGetEquipment(+equipmentId);
  const usersById = useGetUsers();

  const {
    fromDateInBrowserTimezone,
    toDateInBrowserTimezone,
    fromDateISO,
    fromDateUnixTimestamp,
    toDateISO,
    toDateUnixTimestamp,
    prevFromDateUnixTimestamp,
    prevToDateUnixTimestamp,
  } = useReportTimeFormats(fromDate, toDate, ianaTimeZone);

  //these sensors are already sorted by types:
  //ambient => temp => humidity => binary
  const operationalSensors = useGetSensorsByEquipmentId(+equipmentId);

  const equipmentNotifications = useGetFlatNotificationsByEquipmentId(
    +equipmentId,
    fromDateISO,
    toDateISO
  );

  const equipmentComments = useGetDownstreamComments(
    +equipmentId,
    fromDateISO,
    toDateISO
  );

  const { sop: tempSop } = useSopByResourceId(
    equipmentId,
    SopTypes.TEMPERATURE_RANGE
  );

  const { sop: humiditySop } = useSopByResourceId(
    equipmentId,
    SopTypes.HUMIDITY_RANGE
  );

  const equipmentEnergy = useGetEquipmentEnergy(
    +equipmentId,
    fromDateUnixTimestamp,
    toDateUnixTimestamp,
    aggregationLevel,
    site?.newTsdb
  );

  const prevEquipmentEnergy = useGetEquipmentEnergy(
    +equipmentId,
    prevFromDateUnixTimestamp,
    prevToDateUnixTimestamp,
    aggregationLevel,
    site?.newTsdb,
    previousPeriodAffixConst
  );

  if (!site || !equipment || !fromDateISO || !toDateISO) return null;
  return (
    <EquipmentReportPage
      fromDate={fromDateInBrowserTimezone}
      toDate={toDateInBrowserTimezone}
      fromDateISO={fromDateISO}
      toDateISO={toDateISO}
      site={site}
      equipment={equipment}
      usersById={usersById}
      operationalSensors={operationalSensors}
      equipmentNotifications={equipmentNotifications}
      equipmentComments={equipmentComments}
      tempSop={tempSop}
      humiditySop={humiditySop}
      equipmentEnergy={equipmentEnergy}
      prevEquipmentEnergy={prevEquipmentEnergy}
    />
  );
};

export default EquipmentReportPageContainer;
