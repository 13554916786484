import {
  FlatNotification,
  Locale,
  OperationalSample,
  Site,
  TemperatureSop,
  HumiditySop,
  SentinelTypeToSensorType,
  SensorType,
  EnergyPowerApiResponse,
} from '@energybox/react-ui-library/dist/types';
import {
  createTemperatureString,
  formatDistanceMixedUnits,
  formatIanaTimeZone,
  formatKwhValues,
  formatKwValues,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import React, { useMemo } from 'react';
import {
  calculateAverageOpenDuration,
  calculateOpenDurations,
  countDoorOpeningEvents,
  longestPeriod,
} from '../../analysis/door';
import { findPeakDemand } from '../../analysis/energy';
import {
  complianceAnalysis,
  temperatureOrHumidityAverage,
  temperatureOrHumidityMax,
  temperatureOrHumidityMin,
} from '../../analysis/shared';
import { useCurrentUser } from '../useAppDetails';

export const useEquipmentChartTemperatureAnalytics = (
  fromDateInMilliseconds: number,
  toDateInMilliseconds: number,
  data: OperationalSample[] | undefined,
  flatNotifications: FlatNotification[] | undefined,
  tempSop: TemperatureSop | undefined | null
) => {
  const currentUser = useCurrentUser();

  const tempData = useMemo(() => {
    if (!data) return undefined;
    return data.filter(d => d.type === SensorType.TEMPERATURE)
  }, [data]);

  const avgTemp = useMemo(() => {
    if (!tempData) return undefined;
    return temperatureOrHumidityAverage(tempData, SensorType.TEMPERATURE);
  }, [tempData]);

  const analyzedCompliance = useMemo(() => {
    if (!tempSop || !tempData || tempData.length === 0) return undefined;
    return complianceAnalysis(tempData, tempSop);
  }, [tempSop, tempData]);

  const inComplianceString = analyzedCompliance
    ? `${Number(analyzedCompliance?.inCompliancePercent.toFixed(1))}%`
    : undefined;

  const sopRangeString = useMemo(() => {
    if (!currentUser || !tempSop) return undefined;
    const sopMin = createTemperatureString(tempSop.min, currentUser, 1);

    const sopMax = createTemperatureString(tempSop.max, currentUser, 1);

    if (!sopMin || !sopMax) return undefined;
    return `${sopMin} -  ${sopMax}`;
  }, [tempSop, currentUser]);

  const notificationCount = useMemo(() => {
    if (!flatNotifications) return 0;

    const tempNotifications = flatNotifications.filter(({ sentinelType }) => {
      return SensorType.TEMPERATURE === SentinelTypeToSensorType[sentinelType];
    });

    return tempNotifications.length;
  }, [flatNotifications, fromDateInMilliseconds, toDateInMilliseconds]);

  return {
    'Average Temperature':
      currentUser && avgTemp
        ? createTemperatureString(
            parseFloat(avgTemp.toFixed(1)),
            currentUser,
            1
          )
        : undefined,
    'SOP Range': sopRangeString,
    'In Compliance': inComplianceString,
    Notifications: notificationCount,
  };
};

export const useEquipmentChartHumidityAnalytics = (
  fromDateInMilliseconds: number,
  toDateInMilliseconds: number,
  data: OperationalSample[] | undefined,
  flatNotifications: FlatNotification[] | undefined,
  humiditySop: HumiditySop | undefined | null
) => {
  const avgHumidity = useMemo(() => {
    if (!data) return undefined;
    return temperatureOrHumidityAverage(data, SensorType.HUMIDITY);
  }, [data]);

  const maxHumidity = useMemo(() => {
    if (!data) return undefined;
    return temperatureOrHumidityMax(data, SensorType.HUMIDITY);
  }, [data]);

  const minHumidity = useMemo(() => {
    if (!data) return undefined;
    return temperatureOrHumidityMin(data, SensorType.HUMIDITY);
  }, [data]);

  const humidData = useMemo(() => {
    if (!data) return undefined;
    return data.filter(d => d.type === SensorType.HUMIDITY)
  }, [data]);

  const analyzedCompliance = useMemo(() => {
    if (!humiditySop || !humidData || humidData.length === 0) return undefined;
    return complianceAnalysis(humidData, humiditySop);
  }, [humiditySop, humidData]);

  const inComplianceString = analyzedCompliance
    ? `${Number(analyzedCompliance.inCompliancePercent.toFixed(1))}%`
    : undefined;

  const notificationCount = useMemo(() => {
    if (!flatNotifications) return 0;

    const humidityNotifications = flatNotifications.filter(
      ({ sentinelType }) => {
        return SensorType.HUMIDITY === SentinelTypeToSensorType[sentinelType];
      }
    );

    return humidityNotifications.length;
  }, [flatNotifications, fromDateInMilliseconds, toDateInMilliseconds]);

  return {
    'Average Humidity': avgHumidity
      ? `${Number(avgHumidity.toFixed(1))} %`
      : undefined,
    Range: displayHumidityRange(minHumidity, maxHumidity),
    'In Compliance': inComplianceString,
    Notifications: notificationCount,
  };
};

export const useEquipmentChartDoorAccessAnalytics = (
  data: OperationalSample[] | undefined,
  locale: Locale,
  site: Site
) => {
  // data comes in descending order, therefore need to be reversed
  if (isDefined(data))
    data = data.filter(({ type }) => type === SensorType.BINARY).reverse();
  const ianaTimeZoneCode = site.timeZone;

  // Setting total open events
  const totalOpenEvents = useMemo(() => {
    if (!data) return undefined;
    return countDoorOpeningEvents(data);
  }, [data]);

  // Setting average duration stats
  const openEvents = useMemo(() => {
    if (!data) return undefined;
    return calculateOpenDurations(data);
  }, [data]);

  const averageDuration = useMemo(() => {
    if (!openEvents) return undefined;
    return calculateAverageOpenDuration(openEvents);
  }, [openEvents]);

  const formatedAverageDuration = useMemo(() => {
    if (!averageDuration) return undefined;
    return formatDistanceMixedUnits(0, averageDuration, true);
  }, [averageDuration]);

  // Setting Longest duration stats
  const longestDuration = useMemo(() => {
    if (!openEvents) return undefined;
    return longestPeriod(openEvents);
  }, [openEvents]);

  const longestDurationTimeFormated = useMemo(() => {
    if (!longestDuration) return undefined;
    if (longestDuration.start !== 0 && longestDuration.end !== 0) {
      return formatDistanceMixedUnits(
        longestDuration.start,
        longestDuration.end,
        true
      );
    }
    return undefined;
  }, [longestDuration]);

  const longestDurationStartFormated = useMemo(() => {
    if (!longestDuration?.start) return undefined;
    return formatIanaTimeZone(
      longestDuration.end,
      locale.dateFormat,
      ianaTimeZoneCode,
      true
    );
  }, [longestDuration?.start]);

  const longestDurationEndFormated = useMemo(() => {
    if (!longestDuration?.end) return undefined;
    return formatIanaTimeZone(
      longestDuration.end,
      locale.dateFormat,
      ianaTimeZoneCode,
      true
    );
  }, [longestDuration?.end]);

  const longestDurationFormatedDate = useMemo(() => {
    if (longestDurationStartFormated === longestDurationEndFormated) {
      return longestDurationStartFormated;
    } else if (longestDurationStartFormated && longestDurationStartFormated) {
      return `${longestDurationStartFormated} - ${longestDurationEndFormated}`;
    }
  }, [longestDurationStartFormated, longestDurationEndFormated]);

  return {
    'Door Openings': totalOpenEvents,
    'Average Open Duration': formatedAverageDuration,
    'Longest Open Duration': (
      <>
        <div>{longestDurationTimeFormated || global.NOT_AVAILABLE}</div>
        <div>{longestDurationFormatedDate || ''}</div>
      </>
    ),
  };
};

export const useEquipmentChartEnergyAnalytics = (
  data: EnergyPowerApiResponse | undefined,
  prevData: EnergyPowerApiResponse | undefined,
  locale: Locale,
  site: Site
) => {
  const countryCode = site.country;
  const ianaTimeZoneCode = site.timeZone;

  const energyConsumption = useMemo(() => {
    if (!data) return undefined;
    return formatKwhValues(data.energyTotal, countryCode);
  }, [data, countryCode]);

  const prevEnergyConsumption = useMemo(() => {
    if (!prevData) return undefined;
    return formatKwhValues(prevData.energyTotal, countryCode);
  }, [prevData, countryCode]);

  const peakData = useMemo(() => {
    if (!data || !data.readings?.length) return undefined;
    return findPeakDemand(data.readings);
  }, [data]);

  const peakEnergyConsumption = peakData
    ? formatKwValues(peakData.power, countryCode)
    : undefined;
  const peakDate = peakData
    ? formatIanaTimeZone(
        peakData.timestamp * 1000,
        locale.dateFormat,
        ianaTimeZoneCode,
        true
      )
    : undefined;

  return {
    'Energy Consumption': energyConsumption,
    'Consumption in the Last Period': prevEnergyConsumption,
    'Peak Load': (
      <>
        <div>{peakEnergyConsumption || global.NOT_AVAILABLE}</div>
        <div>{peakDate}</div>
      </>
    ),
  };
};

////UTILITY FUNCTIONS////
const displayHumidityValue = (humidityValue: number | undefined) => {
  if (!isDefined(humidityValue)) {
    return global.NOT_AVAILABLE;
  }

  return `${Number(humidityValue.toFixed(1))} %`;
};

const displayHumidityRange = (
  minHumidity: number | undefined,
  maxHumidity: number | undefined
) => {
  if (!isDefined(minHumidity) || !isDefined(maxHumidity)) {
    return global.NOT_AVAILABLE;
  }

  return `${displayHumidityValue(minHumidity)} - ${displayHumidityValue(
    maxHumidity
  )}`;
};
