import { Site } from '@energybox/react-ui-library/dist/types';
import {
  formatNumberString,
  KWH_UNIT,
} from '@energybox/react-ui-library/dist/utils';
import format from 'date-fns/format';

import React from 'react';
import reportStyles from '../ReportPage.module.css';

type ConsumptionPrMonth = {
  consumption: number;
  fromDate: Date;
};

type Props = {
  site: Site;
  consumptionLastMonth: ConsumptionPrMonth;
  consumptionTwoMonthsAgo: ConsumptionPrMonth;
  consumptionThreeMonthsAgo: ConsumptionPrMonth;
};

const EnergyReportMonthlyConsumptionTable = ({
  site,
  consumptionLastMonth,
  consumptionTwoMonthsAgo,
  consumptionThreeMonthsAgo,
}: Props) => {
  if (
    !consumptionLastMonth ||
    !consumptionTwoMonthsAgo ||
    !consumptionThreeMonthsAgo ||
    !consumptionLastMonth.fromDate ||
    !consumptionTwoMonthsAgo.fromDate ||
    !consumptionThreeMonthsAgo.fromDate
  ) {
    return <div />;
  }

  return (
    <div
      style={{
        width: '90%',
        marginRight: '5%',
        marginLeft: '5%',
        marginTop: '2em',
      }}
    >
      <div className={reportStyles.column}>
        <div className={reportStyles.consumptionInfoRow}>
          <div className={reportStyles.monthlyConsumptionHeaderCell}>Month</div>
          <div className={reportStyles.monthlyConsumptionHeaderCell}>
            {format(consumptionThreeMonthsAgo.fromDate, 'MMMM')}
          </div>
          <div className={reportStyles.monthlyConsumptionHeaderCell}>
            {format(consumptionTwoMonthsAgo.fromDate, 'MMMM')}
          </div>
          <div className={reportStyles.monthlyConsumptionHeaderCell}>
            {format(consumptionLastMonth.fromDate, 'MMMM')}
          </div>
        </div>
        <div className={reportStyles.consumptionInfoRow}>
          <div className={reportStyles.monthlyConsumptionContentCell}>
            Energy Consumption
          </div>
          <div className={reportStyles.monthlyConsumptionContentCell}>
            {formatNumberString(consumptionThreeMonthsAgo.consumption, {
              countryCode: site.country,
              numDecimals: 0,
              unit: KWH_UNIT,
            })}
          </div>
          <div className={reportStyles.monthlyConsumptionContentCell}>
            {formatNumberString(consumptionTwoMonthsAgo.consumption, {
              countryCode: site.country,
              numDecimals: 0,
              unit: KWH_UNIT,
            })}
          </div>
          <div className={reportStyles.monthlyConsumptionContentCell}>
            {formatNumberString(consumptionLastMonth.consumption, {
              countryCode: site.country,
              numDecimals: 0,
              unit: KWH_UNIT,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyReportMonthlyConsumptionTable;
