// import * as R from 'ramda';

import { InspectionComponentName } from '@energybox/react-ui-library/dist/types';

// Takes an array and converts it into object where keys are extracted from
// each item by given selector
export const mapArrayToObject = (data: any[], selector = 'id') =>
  data.reduce((result, record) => {
    const key = record[selector];
    return Object.assign(result, { [key]: record }, {});
  }, {});

// Map all values of the object with given function
export const mapValues = (data: Object, f: (value: any) => any) =>
  Object.keys(data).map(i => f(data[i]));

// Returns values of given object
export const values = (obj: Object) => Object.keys(obj).map(i => obj[i]);

export interface UserName {
  firstName: string;
  lastName: string;
}

export const fullName = ({ firstName, lastName }: UserName): string =>
  [firstName, lastName].filter(n => !!n).join(' ');

export const initials = ({ firstName, lastName }: UserName): string => {
  let result = '';
  result += firstName && firstName[0];
  result += lastName && lastName[0];

  return result.length > 0 ? result : '?';
};

export const hasSubstr = (s: string, q: string) =>
  (s || '').toLowerCase().indexOf((q || '').toLowerCase()) !== -1;

export const hasKeys = (o: { [k: string]: any }) =>
  Object.keys(o || {}).length > 0;

// Immutable version of "delete object.key"
export const dropKey = (o: { [_: string]: any }, k: string) =>
  Object.keys(o || {}).reduce((result, key) => {
    if (key !== k) result[key] = o[key];
    return result;
  }, {});

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const reverseSortDirection = (d: SortDirection) =>
  d === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;

// FIXME: Super straight forward implementation of "pluralize" helper.
// Replace business logic if more sophisticated approach when required.
export const pluralize = (noun: string, count: number) =>
  count === 1 ? noun : `${noun}s`;

export interface GenericErrors {
  [k: string]: string[];
}

// Splits given list using given boolean predicate
// Returns array where first element contains all posivite outputs and seoncs element all negative
// export const split = (pred: (any) => boolean, data: any[]) => {
//   const { true: positive = [], false: negative = [] } = R.groupBy(
//     v => pred(v).toString(),
//     data
//   );

//   return [positive, negative];
// };

// Shallow replaces null values in any Object with undefined
export function replaceNullValues<T>(obj): T {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = value || undefined;
    return acc;
  }, {} as T);
}

export function average(values: number[]) {
  const sum = values.reduce(function(sum, value) {
    return sum + value;
  }, 0);

  const avg = sum / values.length;
  return avg;
}

export function standardDeviation(values: number[]) {
  const avg = average(values);

  const squareDiffs = values.map(function(value) {
    const diff = value - avg;
    const sqrDiff = diff * diff;
    return sqrDiff;
  });

  const avgSquareDiff = average(squareDiffs);

  const stdDev = Math.sqrt(avgSquareDiff);

  return stdDev;
}

type NoInfer<T> = [T][T extends any ? 0 : never];
export const formatAPIFilters = <T = never>(filters?: NoInfer<T>): string => {
  if (filters === undefined) return '';
  const queryParams = new URLSearchParams();
  Object.keys(filters!).forEach(property => {
    const filterValue = filters?.[property];
    if (filterValue === undefined || filterValue === null) return;
    if (Array.isArray(filterValue)) {
      if (filterValue.length > 0) {
        queryParams.set(property, filterValue.join(','));
      }
    } else queryParams.set(property, filterValue);
  });

  return queryParams.toString();
};

export const convertMeterSquaredToFeetSquared = (
  areaInMeterSquared: number
) => {
  return areaInMeterSquared * 10.7639;
};

export const getSiteControllerModel = (version: string) => {
  // Regex to check if the version starts with 2.0 followed by a dot and any number
  const regex = /^1\.\d+\.\d+/;

  if (regex.test(version)) {
    return InspectionComponentName.SITE_CONTROLLER;
  } else {
    return InspectionComponentName.SITE_CONTROLLER_ILO;
  }
};

export const convertMinutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return remainingMinutes === 0
    ? `${hours} hours`
    : `${hours} hours ${remainingMinutes} minutes`;
};
