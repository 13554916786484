import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import {
  Bar,
  ComposedChart,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  DefaultChartStyles,
  global,
  KWH_UNIT,
  getDifferenceInDays,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import {
  getComputedStyleProperty,
  getHexColorWithAlpha,
} from '@energybox/react-ui-library/dist/utils/util';
import {
  ChartDateTickFormat,
  Loader,
} from '@energybox/react-ui-library/dist/components';
import styles from './SitesPerformanceChart.module.css';
import { DateTime } from 'luxon';
import { LineLegendIcon } from '@energybox/react-ui-library/dist/icons';
import ChartTooltip from '@energybox/react-ui-library/dist/components/ChartTooltip';
import * as R from 'ramda';
import {
  ComparisonData,
  RechartsCustomTooltip,
} from '@energybox/react-ui-library/dist/types';
import { useAppLocale } from '../../../hooks/useAppDetails';
import { roundToRelativeScale } from '@energybox/react-ui-library/dist/utils/number';

const KWH_PER_M2_TARGET = 500;

type SitesPerformanceData = {
  [siteId: number]: {
    site_title: string;
    site_eui_existing: number;
    site_eui_est: {
      eui_est: number;
      eui_status: string;
    };
    monthly_eui: {
      [date: string]: number;
    };
  };
};

type Props = {
  isLoading: boolean;
  data: SitesPerformanceData | undefined;
  selectedYear: number | string;
  height?: string | number;
  selectedDate?: string;
  setSelectedDate?: (date: string) => void;
};

const SitesPerformanceChart = ({
  isLoading,
  data,
  selectedYear,
  height,
  selectedDate,
  setSelectedDate,
}: Props) => {
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const locale = useAppLocale();
  // const ianaTimeZoneCode = useSiteTimezone();
  const isFeatureFlagged = true;
  const sortData = R.sortBy(R.path(['site_eui_est', 'eui_est']));
  const sortedData = R.reverse(
    sortData(R.filter((d) => d.site_eui_est?.eui_est > 0 && d.site_eui_est?.eui_est !== null, data))
  );
  const bestNWorstData =
    sortedData.length > 10
      ? [...R.take(5, sortedData), ...R.takeLast(5, sortedData)]
      : sortedData;

  if (isLoading)
    return (
      <ResponsiveContainer
        height={height ? height : DefaultChartStyles.chartHeight}
        width={1430}
      >
        {isLoading ? (
          <div className={styles.loading}>
            <Loader />
          </div>
        ) : (
          <div />
        )}
      </ResponsiveContainer>
    );

  return (
    <div>
      <div className={styles.yUnitLabel}>{'kWh/m²'}</div>
      <ResponsiveContainer
        height={height ? height : DefaultChartStyles.chartHeight}
        width={1430}
      >
        <ComposedChart data={bestNWorstData}>
          <CartesianGrid
            vertical={false}
            stroke={DefaultChartStyles.axisLineColor}
          />
          <XAxis
            dataKey="site_title"
            xAxisId="siteTitle"
            axisLine={false}
            tickLine={false}
            dateKey={'site_title'}
            // tick={{ fontSize: '0.6rem', wordWrap: 'break-word' }}
            tick={CustomTick}
            tickFormatter={(title: string) => title.split(' - ')[0]}
          />
          <YAxis
            domain={[
              0,
              (dataMax) => {
                return bestNWorstData < KWH_PER_M2_TARGET ? KWH_PER_M2_TARGET :
                  roundToRelativeScale(getBiggestNumber(bestNWorstData), true)
              },
            ]}
            yAxisId="consumption"
            type="number"
            axisLine={false}
            tickLine={false}
            tick={{
              fontSize: 16,
              fill: DefaultChartStyles.tick.fill,
              reportFontSize: DefaultChartStyles.tick.reportFontSize,
            }}
          />
          <Bar
            className={setSelectedDate ? styles.selectableBar : undefined}
            xAxisId="siteTitle"
            yAxisId="consumption"
            stackId="sitePerformance"
            dataKey={({ site_eui_existing }) => {
              return site_eui_existing;
            }}
            shape={CustomBarWithTarget}
            fill="var(--turquoise-base)"
            maxBarSize={80}
            isAnimationActive={false}
          />

          {isFeatureFlagged && (
            <ReferenceLine
              y={KWH_PER_M2_TARGET}
              xAxisId="siteTitle"
              yAxisId="consumption"
              stroke="var(--ambient-base)"
              strokeDasharray={'3 3'}
              strokeWidth={1}
              label={CustomTargetLabel}
              isFront
              isAnimationActive={false}
            />
          )}

          <Legend
            iconType="line"
            content={() => renderLegend(selectedYear)}
            wrapperStyle={{
              ...DefaultChartStyles.legendContainer,
              position: 'relative',
              marginTop: '-1rem',
            }}
          />
          <Tooltip content={CustomChartTooltip} isAnimationActive={false} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const getBiggestNumber = (data) => {
  const estList = R.sort(
    (a, b) => a - b,
    data.map((value) => value.site_eui_est.eui_est)
  );
  return R.last(estList) || 0;
};

const CustomTick = (props) => {
  const { x, y, stroke, payload } = props;
  const splitedByDash = payload.value.split(' - ');
  const splitBySpace = (name) => {
    return typeof name === 'string' ? name.split(' ') : [];
  };

  let tempText = '',
    counter = 1;
  return (
    <>
      {splitedByDash[0] && (<text
        x={x}
        y={y + 5}
        dy={6}
        textAnchor="middle"
        fill="#666"
        fontSize="0.8rem"
      >
        {splitedByDash[0]}
      </text>)}
      {splitedByDash[1] && splitBySpace(splitedByDash[1]).map((text, i, arr) => {
        let newText = ''
        if (tempText !== '') {
          newText = `${tempText} ${text}`;
        } else {
          newText = text;
        }
        if (newText.length * 6 >= 60 || i + 1 === arr.length) {
          const vSpacing = 2 * (counter + 1);
          const lineHeight = 12 * (counter + 1);
          counter++;
          tempText = '';
          return (
            <text
              x={x}
              y={y + vSpacing}
              dy={lineHeight}
              textAnchor="middle"
              fill="#666"
              fontSize="0.8rem"
            >
              {newText}
            </text>
          );
        } else {
          tempText = newText;
        }
      })}
    </>
  );
};

const CustomChartTooltip = (props) => {
  const { locale, payload, timezone } = props;

  const payloadExists = payload && payload[0] && payload[0].payload;
  if (!payloadExists) return null;

  const truePayload = payload[0].payload;

  if (!truePayload) return null;

  const {
    site_title,
    site_eui_existing,
    site_eui_est: { eui_est, eui_status },
  } = truePayload;

  const displayedValues = [
    {
      title: 'Estimate',
      color:
        eui_status === 'above_target'
          ? 'var(--pink-base)'
          : 'var(--turquoise-base)',
      value: eui_est || global.NOT_AVAILABLE,
      unit: 'kWh/m²',
    },
    {
      title: 'EUI (YTD)',
      color:
        eui_status === 'above_target'
          ? 'var(--pink-base)'
          : 'var(--turquoise-base)',
      value: site_eui_existing || global.NOT_AVAILABLE,
      unit: 'kWh/m²',
    },
  ];
  return (
    <div style={{ backgroundColor: 'rgba(51, 62, 72, 1)' }}>
      <ChartTooltip dateString={site_title} valuesDisplay={displayedValues} />
    </div>
  );
};

const CustomBarWithTarget = (props) => {
  const {
    fill,
    x,
    y,
    width,
    height,
    site_eui_existing: amt,
    site_eui_est: t,
  } = props;

  let totalHeight = y + height;
  let targetY = totalHeight - (height / amt) * t.eui_est;
  let barColor = t.eui_status === 'above_target' ? 'var(--pink-base)' : fill;
  let lineColor = '#365b73';
    // t.eui_status === 'above_target' ? 'var(--pink-base)' : '#365b73';

  return (
    <svg>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke="none"
        fill={barColor}
      />
      {t.eui_est !== null && t.eui_est !== undefined && (
        <line
          x1={x}
          x2={x + width}
          y1={targetY}
          y2={targetY}
          stroke={lineColor}
          strokeWidth={3}
          strokeDasharray={'1 0'}
        />
      )}
    </svg>
  );
};

const CustomTargetLabel = (props) => {
  const { viewBox: { x, y, width, height } } = props;
  return (
    <text x={width} y={y} dy={-4} dx={x} fill={'#666'} fontSize={10} textAnchor="end">
      {'Target'}
    </text>
  );
};

const renderLegend = (selectedYear) => {
  return (
    <div className={styles.legendContainer}>
      <span className={styles.lineIcon}>
        <LineLegendIcon color="var(--pink-base)" />
      </span>

      <span className={styles.lineIcon}>
        <LineLegendIcon color="var(--turquoise-base)" />
      </span>
      <span className={styles.marginRight}>YTD</span>

      <span className={styles.lineIcon}>
        <LineLegendIcon color="var(--blue-baseMinus75)" />
      </span>
      <span className={styles.marginRight}>{`Estimated for ${selectedYear}`}</span>
    </div>
  );
};

export default SitesPerformanceChart;
