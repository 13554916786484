import Table, { Columns, TableGroupHeader } from "@energybox/react-ui-library/dist/components/Table";
import { formatNumber, roundToRelativeScale } from "@energybox/react-ui-library/dist/utils/number";
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import * as R from 'ramda';
import styles from './NOHEfficiencyLowestSites.module.css';

export type recomData = {
  improve_energy: number;
  improve_noh: number;
  num_of_offender: number;
  org_noh_efficiency: number;
  saving_energy: number;
  saving_eui: number;
};

export type bySiteData = {
  [siteId: number]: {
    by_month: {
      [date: string]: {
        area: number;
        noh_efficiency: number;
        non_op_hour: object;
        op_hour: object;
      };
    };
  };
};

export type byOrgData = {
  by_month: {
    [date: string]: {
      area: number;
      noh_efficiency: number;
      non_op_hour: object;
      op_hour: object;
    }
  }
};

export type orgData = {
  month: string;
  org_title: string;
  by_org: byOrgData;
  by_site: bySiteData;
};

export type monthlyData = {
  month: string;
  org_title: string;
  recom: recomData;
  by_org: byOrgData;
  by_site: bySiteData;
};

type Props = {
  className?: string;
  orgData: orgData;
  monthlyData: monthlyData;
  isLoading: boolean | undefined;
};

const columns: Columns<any>[] = [
  {
    header: 'Site Name',
    width: '18%',
    cellContent: ({ site_title }) => (
      <div style={{ padding: '5px' }}>
        {site_title}
      </div>
    ),
  },
  {
    header: 'NOH Efficiency',
    width: '10%',
    rightAlignContent: true,
    cellContent: ({ site_noh }) => {
      const percentage = site_noh?.noh_efficiency * 100 || '-';
        return (
          <div className={(typeof percentage === 'number' && percentage < 20) ? styles.red : undefined}>
            {typeof percentage === 'string'
              ? percentage
              : `${formatNumber(percentage, 1)}%`}
          </div>
        );
    },
  },
  {
    header: 'Trends (Last 6 months)',
    width: '8%',
    rightAlignContent: true,
    align: 'center',
    cellStyle: { height: '30px' },
    cellContent: ({ monthly }) => {
      const keys = R.keys(monthly);
      const values = R.values(monthly);
      const trendData = keys
        .sort()
        .slice(-7, -1)
        .reduce((acc, key) => {
          acc.push({
            name: key,
            value: monthly[key]?.noh_efficiency * 100 || 0,
          });
          return acc;
        }, []);

      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={150} height={50} data={trendData}>
            <Line
              type="monotone"
              dataKey="value"
              stroke="#00a1af"
              strokeWidth={1}
              dot={{ fill: '#00a1af', strokeWidth: 0, r: 2 }}
              isAnimationActive={false}
            />
            <Tooltip
              formatter={(value, name, props) => {
                return [`${roundToRelativeScale(props.payload.value)}%`];
              }}
              labelFormatter={(value, name, props) => {
                return props?.payload?.name || '';
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      );
    },
  },
  {
    header: 'Operating Hour',
    width: '10%',
    align: 'right',
    rightAlignContent: true,
    cellContent: ({ site_noh }) => {
      const { op_hour } = site_noh;
      const { energy_total } = op_hour;
      return <div>{energy_total ? formatNumber(roundToRelativeScale(energy_total), 1) : '-'}</div>;
    },
  },
  {
    header: 'Non-Operating Hour',
    width: '10%',
    align: 'right',
    rightAlignContent: true,
    cellContent: ({ site_noh }) => {
      const { non_op_hour } = site_noh;
      const { energy_total } = non_op_hour;
      return <div>{energy_total ? formatNumber(roundToRelativeScale(energy_total), 1) : '-'}</div>;
    },
  },
  {
    header: 'Operating Hour',
    width: '10%',
    align: 'right',
    rightAlignContent: true,
    cellContent: ({ site_noh }) => {
      const { op_hour } = site_noh;
      const { hourly_eui } = op_hour;
      return <div>{hourly_eui ? roundToRelativeScale(hourly_eui * 1000) : '-'}</div>;
    },
  },
  {
    header: 'Non-Operating Hour',
    width: '10%',
    align: 'right',
    rightAlignContent: true,
    cellContent: ({ site_noh }) => {
      const { non_op_hour } = site_noh;
      const { hourly_eui } = non_op_hour;
      return <div>{hourly_eui ? roundToRelativeScale(hourly_eui * 1000) : '-'}</div>;
    },
  },
  {
    header: 'Equipment lower than 20% NOH Efficiency',
    width: '10%',
    align: 'right',
    rightAlignContent: true,
    cellContent: ({ num_of_offender }) => {
      return <div>{num_of_offender || '-'}</div>;
    },
  }
];

const groupHeaders: TableGroupHeader[] = [
  {
    header: '',
    colSpan: 3,
    className: styles.tableGroupHeader,
  },
  {
    header: 'Energy Consumption (kWh)',
    colSpan: 2,
    className: styles.tableGroupHeader,
  },
  {
    header: 'Hourly EUI (Wh/m²)',
    colSpan: 2,
    className: styles.tableGroupHeader,
  },
];

const NOHEfficiencyLowestSites: React.FC<Props> = ({
  className,
  orgData,
  monthlyData,
  isLoading,
}) => {
  const { by_site: bySiteData, by_org: byOrgData } = orgData;

  const monthlyBySiteData = monthlyData?.by_site;

  const data = mapSiteData(bySiteData, monthlyBySiteData);

  const displayData = data
    .filter((row) => row.site_noh?.noh_efficiency !== null)
    .sort((a, b) => (a.site_noh?.noh_efficiency || 0) - (b.site_noh?.noh_efficiency || 0))
    .slice(0, 5);

  return (
    <div className={styles.tileContainer}>
      <div className={styles.tileTitle}>
        {'Sites with Lowest NOH Efficiency'}
      </div>
      <Table
        className={styles.topTable}
        headerClassName={styles.tableHeader}
        groupHeaders={groupHeaders}
        columns={columns}
        data={displayData}
        listView={false}
        highlightAlternateRows
      />
    </div>
  );
};

const mapSiteData = (siteData = {}, siteMonthlyData = {}) => {
  const siteIds = Object.keys(siteData);
  return siteIds.map((siteId) => ({
    siteId,
    ...siteData[siteId],
    monthly: siteMonthlyData[siteId]?.by_month,
  }));
};

export default NOHEfficiencyLowestSites;