import {
  AggregationLevel,
  EnergyPowerApiResponse,
} from '@energybox/react-ui-library/dist/types';
import {
  isDefined,
  determineWhichEnergyApiFunctionToUse,
} from '@energybox/react-ui-library/dist/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEnergyByEquipmentId,
  getEnergySensorsByEquipmentId,
} from '../../actions/energy';
import { getTSEnergyByEquipmentId } from '../../actions/energyTS';
import { ApplicationState } from '../../reducers';

const useGetEquipmentEnergy = (
  equipmentId: number,
  fromDateTimestampInSeconds: number | undefined,
  toDateTimestampInSeconds: number | undefined,
  aggregationLevel: AggregationLevel,
  isNewTsdb: boolean | undefined,
  idAffix?: string
) => {
  const dispatch = useDispatch();
  const energySensors = useGetEquipmentEnergySensors(equipmentId);
  const areThereEnergySensors =
    isDefined(energySensors) && energySensors.length > 0;

  useEffect(() => {
    if (
      areThereEnergySensors &&
      typeof isNewTsdb === 'boolean' &&
      fromDateTimestampInSeconds &&
      toDateTimestampInSeconds
    ) {
      const getEnergyFunction = determineWhichEnergyApiFunctionToUse(
        isNewTsdb,
        getEnergyByEquipmentId,
        getTSEnergyByEquipmentId
      );

      dispatch(
        getEnergyFunction(
          equipmentId,
          {
            from: fromDateTimestampInSeconds,
            to: toDateTimestampInSeconds,
            aggregationLevel,
            seeSpilloverPoint: true,
          },
          idAffix
        )
      );
    }
  }, [
    isNewTsdb,
    equipmentId,
    aggregationLevel,
    fromDateTimestampInSeconds,
    toDateTimestampInSeconds,
    idAffix,
    dispatch,
    areThereEnergySensors,
  ]);

  const equipmentEnergy = useSelector<
    ApplicationState,
    EnergyPowerApiResponse | undefined
  >(({ energy }) => {
    const updatedEquipmentId = idAffix ? equipmentId + idAffix : equipmentId;
    return energy.energyByEquipmentId[updatedEquipmentId] as
      | EnergyPowerApiResponse
      | undefined;
  });

  return equipmentEnergy;
};

const useGetEquipmentEnergySensors = (equipmentId: number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!equipmentId) {
      dispatch(getEnergySensorsByEquipmentId(equipmentId));
    }
  }, [equipmentId]);

  return useSelector<ApplicationState, string[] | undefined>(({ energy }) => {
    return energy.energySensorsByEquipmentId[equipmentId]?.sensors;
  });
};

export default useGetEquipmentEnergy;
