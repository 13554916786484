import {
  AggregationLevel,
  Equipment,
  EquipmentGroup,
  EquipmentTemperatureCheckReport,
  EquipmentType,
  Locale,
  Report,
  ReportType,
  Site,
  SiteTemperatureCheckAnalyticsByEquipmentId,
} from '@energybox/react-ui-library/dist/types';
import { Report as ReportIcon } from '@energybox/react-ui-library/dist/icons';
import format from 'date-fns/format';
import * as R from 'ramda';

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setDateFilter } from '../../../actions/dateFilter';
import DateFilter from '../../../components/Filters/DateFilter';
import { useGetSiteTempCheck } from '../../../hooks/analytics/sites';
import { useGetEquipmentBySiteId } from '../../../hooks/equipment';
import { useGetTemperatureRecordsBySiteId } from '../../../hooks/reports/reports';
import { useReportTimeFormats } from '../../../hooks/utils';
import { ApplicationState } from '../../../reducers';
import { DateFilterTimePeriod } from '../../../reducers/dateFilter';
import { createDashboardDateFilter } from '../../../utils/notificationDateFilter';
import styles from './SiteReportsList.module.css';

type OwnProps = {
  siteId: string;
  equipment: Equipment[];
  timePeriod: DateFilterTimePeriod;
};

interface Props extends OwnProps {
  locale: Locale;
  site: Site;
  equipmentTypes: EquipmentType[];
  equipmentGroups: EquipmentGroup[];
  setDateFilter: (dateFilter: DateFilterTimePeriod) => void;
}

const SiteReportsList = ({
  siteId,
  site,
  equipment,
  setDateFilter,
  timePeriod,
}: Props) => {
  const { equipmentById } = useGetEquipmentBySiteId(+siteId);
  const temperatureRecordsById = useGetTemperatureRecordsBySiteId(siteId);

  const temperatureCheckReportIds = useMemo(() => {
    return Object.keys(temperatureRecordsById).filter(reportId => {
      return (
        temperatureRecordsById[reportId].reportType ===
        ReportType.DAILY_TEMPERATURE_REPORT
      );
    });
  }, [temperatureRecordsById]);

  const fromDate = format(timePeriod.fromDate, 'yyyy-MM-dd');
  const toDate = format(timePeriod.toDate, 'yyyy-MM-dd');
  const tempCheck = useGetSiteTempCheck(+siteId, fromDate, toDate);
  const equipmentIds = useMemo(() => {
    let result: string[] = [];
    temperatureCheckReportIds.forEach(reportId => {
      const report: SiteTemperatureCheckAnalyticsByEquipmentId =
        tempCheck.by_report[reportId];
      if (!report) return;
      const equipmentIdsForThisReport = Object.keys(report.by_equipment);

      result = result.concat(equipmentIdsForThisReport);
    });
    return [...new Set(result)];
  }, [tempCheck, temperatureCheckReportIds]);

  const { fromDateISO, toDateISO } = useReportTimeFormats(
    fromDate,
    toDate,
    site?.timeZone
  );

  if (!site) return null;

  return (
    <>
      <div className={styles.root}>
        <div className={styles.headerContainer}>
          <div className={styles.leftHeader}>
            <DateFilter
              value={timePeriod}
              setFilter={setDateFilter}
              options={createDashboardDateFilter()}
              customPickerVariant="date"
            />
          </div>
        </div>

        <div className={styles.contentContainer}>
          <Link
            className={styles.linkContainer}
            to={`./${siteId}/report/energy/${fromDate}/${toDate}`}
          >
            <ReportIcon size={20} />
            <span className={styles.report}>
              {`[Energy Report] - ${fromDate} to ${toDate}`}
            </span>
          </Link>

          <Link
            className={styles.linkContainer}
            to={`./${siteId}/report/storage/${fromDate}/${toDate}`}
          >
            <ReportIcon size={20} />
            <span className={styles.report}>
              {`[Storage/SF Report] - ${fromDate} to ${toDate}`}
            </span>
          </Link>

          <Link
            className={styles.linkContainer}
            to={`./${siteId}/report/generic/${fromDate}/${toDate}/[["coldStorageRecommendations","unnecessaryDoorAccess"],["temperatureComfortRecommendations","energyTrends"],["refrigerationCompliance",null]]`}
          >
            <ReportIcon size={20} />
            <span className={styles.report}>
              {`[Generic Report] - ${fromDate} to ${toDate}`}
            </span>
          </Link>

          {Object.values(temperatureRecordsById).map((report: Report) => (
            <Link
              key={report.id}
              className={styles.linkContainer}
              to={`./${siteId}/report/temperatureRecord/${report.id}/${toDate}`}
            >
              <ReportIcon size={20} />
              <span className={styles.report}>
                {`[Daily Temperature Record]: ${report.title} - ${toDate}`}
              </span>
            </Link>
          ))}

          {temperatureCheckReportIds.map(reportId => {
            return (
              <React.Fragment key={`reportConfig${reportId}`}>
                {equipmentIds.map(equipmentId => {
                  const equipment = equipmentById[equipmentId];
                  const equipmentTempCheck:
                    | EquipmentTemperatureCheckReport
                    | undefined =
                    tempCheck.by_report[reportId]?.by_equipment[equipmentId];
                  if (
                    !site ||
                    !equipment ||
                    !fromDateISO ||
                    !toDateISO ||
                    !equipmentTempCheck
                  )
                    return null;
                  return (
                    <Link
                      key={`equipment${equipmentId}config${reportId}tempcheck`}
                      className={styles.linkContainer}
                      to={`./${siteId}/report/temperatureCheck/${reportId}/equipment/${equipmentId}/${fromDate}/${toDate}`}
                    >
                      <ReportIcon size={20} />
                      <span className={styles.report}>
                        {`[Equipment Temp Check]: ${equipment.title} - ${fromDate} to ${toDate}`}
                      </span>
                    </Link>
                  );
                })}
              </React.Fragment>
            );
          })}

          {equipment.map(e => (
            <Link
              key={e.id}
              className={styles.linkContainer}
              to={`./${siteId}/report/equipment/${e.id}/${fromDate}/${toDate}/aggregationLevel/${AggregationLevel.ONE_HOUR}`}
            >
              <ReportIcon size={20} />
              <span className={styles.report}>
                {`[Equipment Performance Summary]: ${e.title} - ${fromDate} to ${toDate}`}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (
  { app, organizations, sites, equipmentTags }: ApplicationState,
  { siteId }: OwnProps
) => ({
  locale: app.locale,
  site: R.pathOr(null, ['sitesById', siteId], sites),
  equipmentTypes: equipmentTags.equipmentTypes,
  equipmentGroups: equipmentTags.equipmentGroups,
});

const mapDispatchToProps = dispatch => ({
  setDateFilter: (timeFilter: DateFilterTimePeriod) =>
    dispatch(setDateFilter(timeFilter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteReportsList);
