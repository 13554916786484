import React from 'react';
import styles from './GrayInfoBox.module.css';

type Props = {
  className?: string;
  data: GrayInfoBoxData[];
};

export type GrayInfoBoxData = {
  name: string;
  value: React.ReactNode;
};

const GrayInfoBox: React.FC<Props> = ({ className, data }) => {
  return (
    <div className={`${styles.root} ${className}`}>
      {data.map(d => (
        <div key={d.name} className={styles.textContainer}>
          <div className={styles.label}>{d.name}: </div>
          <div>{d.value}</div>
        </div>
      ))}
    </div>
  );
};

export default GrayInfoBox;
