import { Space, SpaceApiFilter } from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../middleware/apiMiddleware';

export enum Actions {
  SPACES_SUCCESS = '@@spaces/SPACES_SUCCESS',
  SPACES_ERROR = '@@spaces/SPACES_ERROR',
  SPACES_LOADING = '@@spaces/SPACES_LOADING',
}

export const fetchSpaces = (filter?: SpaceApiFilter): Promise<Space[]> => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.withPath) {
    queryParams.set('withPath', 'true');
  }

  return fetchApi({
    endpoint: `/api/v1/spaces?${queryParams.toString()}`,
  });
};

export const getSpacesBySiteId = (siteId: number) => ({
  type: 'API_GET',
  path: `/api/v1/spaces/parent/${siteId}`,
  loading: { type: Actions.SPACES_LOADING, siteId },
  success: { type: Actions.SPACES_SUCCESS, siteId },
  error: { type: Actions.SPACES_ERROR, siteId },
});
