import { Report } from '@energybox/react-ui-library/dist/types';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import lensProp from 'ramda/src/lensProp';
import mergeRight from 'ramda/src/mergeRight';
import pipe from 'ramda/src/pipe';
import view from 'ramda/src/view';
import { Actions } from '../actions/temperatureRecords';
import { GeneratedReport } from '../types/temperatureRecord';
import { mapArrayToObject } from '../util';

const initialState = {
  isReportBySiteIdLoading: false,
  reportById: {},
  reportBySiteId: {},
  generatedReportById: {},
};

export type Reports = {
  isReportBySiteIdLoading: boolean;
  reportById: ReportById;
  reportBySiteId: ReportBySiteId;
  generatedReportById: GeneratedReportById;
};

export type ReportBySiteId = {
  [siteId: string]: ReportById;
};

export type GeneratedReportById = {
  [id: string]: {
    [date: string]: GeneratedReport;
  };
};

export type ReportById = {
  [reportId: string]: Report;
};

const reports = (state: Reports = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_REPORTS_BY_SITE_ID_LOADING:
      return assoc('isReportBySiteIdLoading', true, state);

    case Actions.GET_REPORTS_BY_SITE_ID_ERROR:
      return assoc('isReportBySiteIdLoading', false, state);

    case Actions.GET_REPORTS_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['reportBySiteId', action.siteId],
          mapArrayToObject(action.payload)
        ),
        assoc(
          'reportById',
          mergeRight(
            view(lensProp('reportById'), state),
            mapArrayToObject(action.payload)
          )
        ),
        assoc('isReportBySiteIdLoading', false)
      )(state);

    case Actions.GET_REPORT_SUCCESS:
      return pipe(assocPath(['reportById', action.reportId], action.payload))(
        state
      );

    case Actions.GENERATE_REPORT_SUCCESS:
      return pipe(
        assocPath(['generatedReportById', action.reportId, action.date], action.payload)
      )(state);

    default:
      return state;
  }
};

export default reports;
