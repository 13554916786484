import { StyleSheet } from '@react-pdf/renderer';
import { useEffect, useMemo } from 'react';
import { useGetSite } from '../../../hooks/sites';
import { ReportHeadingTitle } from '../../../types/shared';
import PdfViewContainer from '../Pdf';
import {
  convertIsoToJSDate,
  determineTimeStringByTimezone,
  global,
} from '@energybox/react-ui-library/dist/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getSiteInspectionReport } from '../../../actions/inspection';
import { ApplicationState } from '../../../reducers';
import { RawInspectionReport } from '../../../reducers/inspection';
import { PdfHeaderProps } from '../Pdf/PdfViewHeader';
import { useAppLocale, useCurrentUser } from '../../../hooks/useAppDetails';
import { SiteInspectionReportPdf } from '../SiteInspectionReportPage';
import EBImage from './EBImage';

type SiteInspectionReportProps = {
  siteId: string;
  timestamp: string;
  debug?: boolean;
};

const styles = StyleSheet.create({
  base: {
    fontFamily: 'Open Sans',
  },
});

const SiteInspectionReportPdfContainer: React.FC<SiteInspectionReportProps> = ({
  siteId,
  timestamp,
  debug,
}) => {
  const site = useGetSite(+siteId);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSiteInspectionReport(siteId, timestamp));
  }, []);

  const report = useSelector<ApplicationState, RawInspectionReport | undefined>(
    ({ inspection }) => {
      if (inspection.inspectionReportIsLoadingBySiteId[siteId]) {
        return undefined;
      }
      return inspection.inspectionReportBySiteId[siteId];
    }
  );

  const leftColumn = useMemo(() => {
    return [
      {
        fieldName: 'Organization',
        fieldValue: site?.organization?.title || global.NOT_AVAILABLE,
      },
    ];
  }, [site]);

  const rightColumn = useMemo(() => {
    return [
      {
        fieldName: 'Site',
        fieldValue: site?.title || global.NOT_AVAILABLE,
      },
    ];
  }, [site]);

  const locale = useAppLocale();

  const currentUser = useCurrentUser();

  if (!report || !site) return null;

  const timeString = determineTimeStringByTimezone(
    convertIsoToJSDate(timestamp + 'Z'),
    locale.fullDateTimeFormat,
    site.timeZone,
    true
  );

  const headerProps: PdfHeaderProps = {
    imagePath: EBImage,
    title: ReportHeadingTitle.INSPECTION,
    leftColumnContent: leftColumn,
    rightColumnContent: rightColumn,
    timeString,
  };

  return (
    <PdfViewContainer
      headerProps={headerProps}
      content={
        <SiteInspectionReportPdf
          site={site}
          report={report}
          currentUser={currentUser}
        />
      }
      debug={debug}
    />
  );
};

export default SiteInspectionReportPdfContainer;
