import { SensorApiFilter } from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../middleware/apiMiddleware';

const apiBase = '/api/v1/sensors';

export enum Actions {
  SENSORS_SUCCESS = '@@sensors/SENSORS_SUCCESS',
  SENSORS_LOADING = '@@sensors/SENSORS_LOADING',
  SENSORS_ERROR = '@@sensors/SENSORS_ERROR',

  SENSORS_BY_EQUIPMENT_ID_SUCCESS = '@@sensors/SENSORS_BY_EQUIPMENT_ID_SUCCESS',
  SENSORS_BY_EQUIPMENT_ID_LOADING = '@@sensors/SENSORS_BY_EQUIPMENT_ID_LOADING',
  SENSORS_BY_EQUIPMENT_ID_ERROR = '@@sensors/SENSORS_BY_EQUIPMENT_ID_ERROR',

  SENSORS_BY_EQUIPMENT_IDS_LIST_SUCCESS = '@@sensors/SENSORS_BY_EQUIPMENT_IDS_LIST_SUCCESS',
  SENSORS_BY_EQUIPMENT_IDS_LIST_LOADING = '@@sensors/SENSORS_BY_EQUIPMENT_IDS_LIST_LOADING',
  SENSORS_BY_EQUIPMENT_IDS_LIST_ERROR = '@@sensors/SENSORS_BY_EQUIPMENT_IDS_LIST_ERROR',
}

const setSensorFilter = (filter?: SensorApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.equipmentIds && filter.equipmentIds.length > 0) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.ids) {
    queryParams.set('ids', filter.ids.join(','));
  }

  if (filter && filter.sensorTypes) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.spaceIds && filter.spaceIds.length > 0) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  queryParams.set('withPath', 'true');
  return queryParams.toString();
};

// STORAGE REPORT
export const fetchSensorsByResource = (
  resourceId: number,
  recursive: boolean = false
) =>
  fetchApi({
    endpoint: `${apiBase}/resource/${resourceId.toString()}?recursive=${recursive.toString()}`,
  });

// STORAGE REPORT
export const getSensorsBySiteId = (siteId: number) => dispatch => {
  dispatch({ type: Actions.SENSORS_LOADING });
  return fetchSensorsByResource(siteId, true)
    .then(sensors => {
      dispatch({ type: Actions.SENSORS_SUCCESS, payload: { sensors, siteId } });
    })
    .catch(error => {
      dispatch({ type: Actions.SENSORS_ERROR, payload: { error } });
    });
};

export const getSensorsByEquipmentId = (equipmentId: number) => ({
  type: 'API_GET',
  path: `/api/v1/sensors?${setSensorFilter({ equipmentIds: [equipmentId] })}`,
  loading: Actions.SENSORS_BY_EQUIPMENT_ID_LOADING,
  success: { type: Actions.SENSORS_BY_EQUIPMENT_ID_SUCCESS, equipmentId },
  error: Actions.SENSORS_BY_EQUIPMENT_ID_ERROR,
});

export const getSensorsByEquipmentIdsList = (
  equipmentIds: number[] | string[]
) => ({
  type: 'API_GET',
  path: `/api/v1/sensors?${setSensorFilter({ equipmentIds })}`,
  loading: Actions.SENSORS_BY_EQUIPMENT_IDS_LIST_LOADING,
  success: {
    type: Actions.SENSORS_BY_EQUIPMENT_IDS_LIST_SUCCESS,
    equipmentIds,
  },
  error: Actions.SENSORS_BY_EQUIPMENT_IDS_LIST_ERROR,
});
