import { SitesApiFilter } from '@energybox/react-ui-library/dist/types';

export enum Actions {
  SITES_SUCCESS = '@@app/SITES_SUCCESS',
  SITES_LOADING = '@@app/SITES_LOADING',
  SITES_ERROR = '@@app/SITES_ERROR',

  SITE_SUCCESS = '@@app/SITE_SUCCESS',
  SITE_LOADING = '@@app/SITE_LOADING',
  SITE_ERROR = '@@app/SITE_ERROR',
}

export const setSitesFilter = (filter?: SitesApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.sensorIds) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.sensorTarget) {
    queryParams.set('sensorTarget', filter.sensorTarget);
  }

  return `/api/v1/sites?${queryParams.toString()}`;
};

export const getSites = (filter?: SitesApiFilter) => ({
  type: 'API_GET',
  path: setSitesFilter(filter),
  loading: Actions.SITES_LOADING,
  success: Actions.SITES_SUCCESS,
  error: Actions.SITES_ERROR,
});

export const getSite = (id: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/sites/${id}`,
  loading: Actions.SITE_LOADING,
  success: Actions.SITE_SUCCESS,
  error: Actions.SITE_ERROR,
});
