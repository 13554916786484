import { Service } from '../config';
import {
  createOperationalSamplesQueryString,
  convertQueryDatesToTsApiFormat,
} from '@energybox/react-ui-library/dist/utils';
import {
  Periods,
  GetOperationalSamplesParams } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';


export enum Actions {
  DOOR_READINGS_LOADING = '@@operationalSamples/DOOR_READINGS_LOADING',
  DOOR_READINGS_SUCCESS = '@@operationalSamples/DOOR_READINGS_SUCCESS',
  DOOR_READINGS_ERROR = '@@operationalSamples/DOOR_READINGS_ERROR',

  GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS = '@operational_samples/GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS',
  GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR = '@operational_samples/GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR',
  GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING = '@operational_samples/GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING',

  EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_LOADING = '@@energy/EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_LOADING',
  EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_ERROR = '@@energy/EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_ERROR',
  EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_SUCCESS = '@@energy/EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_SUCCESS',
  SKIP_EQUIPMENT_REPORT_OPERATIONAL_SAMPLES = '@@energy/SKIP_EQUIPMENT_REPORT_OPERATIONAL_SAMPLES',
}


export const getOperationalSamplesBySensorId = (
  params: GetOperationalSamplesParams
) => ({
  service: Service.timeSeries,
  type: 'API_GET',
  path: `/operational/sensor/${params.sensorId}?${createOperationalSamplesQueryString(
    convertQueryDatesToTsApiFormat(R.omit(['sensorId'], params))
  )}`,  success: {
    type: Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS,
    id: params.sensorId,
  },
  error: {
    type: Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR,
    id: params.sensorId,
  },
  loading: {
    type: Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING,
    id: params.sensorId,
  },
});

export const skipEquipmentReportOperationalSamples = () => ({
  type: Actions.SKIP_EQUIPMENT_REPORT_OPERATIONAL_SAMPLES,
});

export const getDoorEntriesOverTimeBySensorId = (
  params: GetOperationalSamplesParams,
  period: Periods
) => ({
  service: Service.timeSeries,
  type: 'API_GET',
  path: `/binary/sensor/${params.sensorId}?${createOperationalSamplesQueryString(
      convertQueryDatesToTsApiFormat(R.omit(['sensorId'], params)))}`,
  success: {
    type: Actions.DOOR_READINGS_SUCCESS,
    period,
  },
  error: {
    type: Actions.DOOR_READINGS_ERROR,
    period,
  },
  loading: {
    type: Actions.DOOR_READINGS_LOADING,
    period,
  },
});


export const noDoorEntriesToFetch = (period: Periods) => ({
  type: Actions.DOOR_READINGS_ERROR,
  period,
});
