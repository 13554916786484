import { formatNumberString } from '@energybox/react-ui-library/dist/utils';
import React, { ReactElement } from 'react';
import styles from './ReportPage.module.css';

type BigNumberProps = {
  countryCode?: string;
  number: number;
  unit?: ReactElement | string;
  symbol?: string;
  decimals?: number;
};

type BigNumberDeltaProps = {
  number: number;
  black?: boolean;
  fontSize?: string;
};

export const BigNumber = ({
  countryCode,
  number,
  unit,
  symbol,
  decimals = 0,
}: BigNumberProps) => {
  return (
    <div>
      {!!symbol ? (
        <span className={styles.bigNumberSymbol}>{symbol}</span>
      ) : null}
      <span className={styles.bigNumber}>
        {formatNumberString(number, {
          countryCode,
          numDecimals: decimals,
        })}
      </span>
      {!!unit ? <span className={styles.bigNumberUnit}>{unit}</span> : null}
    </div>
  );
};

export const BigNumberDelta = ({
  number,
  black,
  fontSize,
}: BigNumberDeltaProps) => {
  const colouredArrowStyles =
    number > 0 ? styles.deltaArrowPositive : styles.deltaArrowNegative;

  const blackArrowStyles =
    number > 0
      ? styles.blackDeltaArrowPositive
      : styles.blackDeltaArrowNegative;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span className={styles.bigNumberDeltaBlack} style={{ fontSize }}>
        {`${(number || 0).toFixed()}`}
      </span>
      <span className={styles.deltaUnitBlack} style={{ fontSize }}>{`%`}</span>
      <div
        className={black ? blackArrowStyles : colouredArrowStyles}
        style={{ fontSize }}
      />
    </div>
  );
};
