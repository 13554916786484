import { createTimeFilterMapping } from '../utils/notificationDateFilter';
import { Actions as DateFilterActions } from '../actions/dateFilter';

export type DateFilter = {
  timePeriod: DateFilterTimePeriod;
};

export type DateFilterTimePeriod = {
  title?: string;
  fromDate: Date;
  toDate: Date;
  isCustomRange?: boolean;
};

const notificationDateFilterMap = createTimeFilterMapping();

const initialState: DateFilter = {
  timePeriod: {
    title: notificationDateFilterMap['last7Days'].title,
    fromDate: notificationDateFilterMap['last7Days'].fromDate,
    toDate: notificationDateFilterMap['last7Days'].toDate,
  },
};

export default (state: DateFilter = initialState, action: any) => {
  switch (action.type) {
    case DateFilterActions.DATE: {
      return {
        ...state,
        timePeriod: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
