import {
  EnergyTariff,
  PieSection,
} from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import EnergyTariffTooltip from '../EnergyTariffTooltip';
import styles from './RechartsDonutChart.module.css';

type Props = {
  data: PieSection[];
  report?: boolean;
  energyTariffSop?: EnergyTariff;
};

const CustomTooltip = ({ active, payload, label, energyTariffSop }) => {
  if (active && payload.length && payload[0].payload) {
    return (
      <div className={styles.tooltipContainer}>
        <div className={styles.tooltipInner}>
          <p className={styles.tooltipHeader}>{payload[0].payload.label}</p>
          <p className={styles.tooltipValues}>
            {payload[0].value.toFixed(0)} kWh
          </p>
          {energyTariffSop ? (
            <EnergyTariffTooltip
              value={payload[0].value}
              energyTariffSop={energyTariffSop}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const RechartsDonutChart = ({ data, report, energyTariffSop }: Props) => {
  const [chart, setChart] = useState(<div />);
  useEffect(() => {
    if (report) {
      setChart(
        <ResponsiveContainer height={325}>
          <PieChart>
            <Tooltip
              //@ts-ignore because this is how the documentation suggest we handle custom tooltip
              content={<CustomTooltip />}
            />
            <Pie
              data={data}
              dataKey="count"
              innerRadius="45%"
              outerRadius="99%"
              blendStroke={true}
              isAnimationActive={false}
              startAngle={90}
              endAngle={-270}
            >
              {data.map(_data => {
                return <Cell key={_data.type} fill={_data.chartColor} />;
              })}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      );
    } else {
      setChart(
        <div
          style={{
            maxHeight: '300px',
            height: '100%',
            width: '100%',
            minWidth: '300px',
            minHeight: '300px',
          }}
        >
          <ResponsiveContainer>
            <PieChart>
              <Tooltip
                //@ts-ignore because this is how the documentation suggest we handle custom tooltip
                content={<CustomTooltip energyTariffSop={energyTariffSop} />}
              />
              <Pie
                data={data}
                dataKey="count"
                label={false}
                innerRadius="45%"
                outerRadius="99%"
                blendStroke={true}
                startAngle={90}
                endAngle={-270}
              >
                {data.map(_data => (
                  <Cell key={_data.type} fill={_data.chartColor} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      );
    }
  }, [data, report, energyTariffSop]);

  return chart;
};

export default RechartsDonutChart;
