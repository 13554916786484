import { Certification } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCertificationBySensorId,
  setSiteTempCheckReportCertFetchLoading,
  setSiteTempCheckReportCertFetchSuccess,
} from '../actions/certifications';
import { ApplicationState } from '../reducers';
import {
  CertificationsBySensorId,
  IsLoadingBySiteId,
} from '../reducers/certifications';
import { IsLoadingBySensorId } from '../reducers/operationalSamples';

const useGetCertificationsBySensorId = (sensorId: number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCertificationBySensorId(sensorId));
  }, [sensorId, dispatch]);

  const certifications = useSelector<
    ApplicationState,
    Certification[] | undefined
  >(({ certifications }) => {
    return certifications.bySensorId[sensorId];
  });

  return certifications;
};

export const useGetCertificationsBySensorIdList = (
  siteId: number | string,
  sensorIds: number[] | string[]
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    sensorIds.forEach(sensorId =>
      dispatch(getCertificationBySensorId(sensorId))
    );
  }, [sensorIds, dispatch]);

  const bySensorId = useSelector<ApplicationState, CertificationsBySensorId>(
    ({ certifications }) => {
      return certifications.bySensorId;
    }
  );

  const isLoadingBySensorId = useSelector<
    ApplicationState,
    IsLoadingBySensorId
  >(({ certifications }) => {
    return certifications.isLoadingBySensorId;
  });

  const isLoadingBySiteId = useSelector<ApplicationState, IsLoadingBySiteId>(
    ({ certifications }) => {
      return certifications.isLoadingBySiteId;
    }
  );

  useEffect(() => {
    const isLoading = sensorIds.some(
      sensorId =>
        isLoadingBySensorId[sensorId] === undefined ||
        isLoadingBySensorId[sensorId] === true
    );

    if (isLoading) {
      if (
        isLoadingBySiteId[siteId] === undefined ||
        isLoadingBySiteId[siteId] === false
      ) {
        dispatch(setSiteTempCheckReportCertFetchLoading(siteId));
      }
    } else {
      if (isLoadingBySiteId[siteId] === true) {
        dispatch(setSiteTempCheckReportCertFetchSuccess(siteId));
      }
    }
  }, [isLoadingBySensorId, isLoadingBySiteId, siteId]);

  return bySensorId;
};

export default useGetCertificationsBySensorId;
