import { Equipment } from '@energybox/react-ui-library/dist/types';
import { isDefined, KWH_UNIT } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import BigNumberWithUnitAndHeading from '../../../components/BigNumberWithUnitAndHeading';
import NumberHeaderBox from '../../../components/NumberHeaderBox';
import VerticalDivider from '../../../components/VerticalDivider';
import { useAreaUnit } from '../../../hooks/useAppDetails';
import { convertMeterSquaredToFeetSquared } from '../../../util';
import { Colors } from '../../../utils/colors';
import { AreaUnitLabels } from '@energybox/react-ui-library/dist/types/Global';

type Props = {
  equipments: Equipment[];
  totalConsumption: number;
  siteSize: number | undefined;
  prevTotalConsumption: number;
  reportNumberOfDays: number;
  costPerKwh: number | undefined;
  sopCurrencyCode?: string;
};

const StorageReportConsumptionInfo = ({
  equipments,
  totalConsumption,
  siteSize,
  prevTotalConsumption,
  reportNumberOfDays,
  costPerKwh,
  sopCurrencyCode,
}: Props) => {
  if (!equipments || !totalConsumption || !prevTotalConsumption) return <div />;
  const areaUnit = useAreaUnit()

  const determineEnergyCostPerSiteSize = () => {
    if (!isDefined(siteSize) || !isDefined(costPerKwh)) {
      return null;
    }

    const convertedSiteSize =
      areaUnit === AreaUnitLabels.FT2
        ? convertMeterSquaredToFeetSquared(siteSize)
        : siteSize;

    return (totalConsumption * costPerKwh) / convertedSiteSize;
  };

  return (
    <NumberHeaderBox>
      <BigNumberWithUnitAndHeading
        value={equipments.length}
        headerText="Equipment"
        color={Colors.AMBIENT}
      />

      <VerticalDivider />

      <BigNumberWithUnitAndHeading
        value={totalConsumption}
        headerText="Energy Consumption"
        color={Colors.AMBIENT}
        unit={KWH_UNIT}
        decimals={0}
      />

      <VerticalDivider />

      <BigNumberWithUnitAndHeading
        value={determineEnergyCostPerSiteSize()}
        headerText={<span>Energy Cost / {areaUnit}</span>}
        color={Colors.AMBIENT}
        unit={sopCurrencyCode}
        decimals={2}
      />

      <VerticalDivider />

      <BigNumberWithUnitAndHeading
        isDelta
        unit={'%'}
        value={(totalConsumption / prevTotalConsumption) * 100 - 100}
        headerText={`Compared to Previous ${reportNumberOfDays} Days`}
        color={Colors.AMBIENT}
        decimals={1}
      />
    </NumberHeaderBox>
  );
};

export default StorageReportConsumptionInfo;
