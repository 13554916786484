import {
  Table,
  TableColumns,
} from '@energybox/react-ui-library/dist/components';
import {
  CellAlign,
  Locale,
  MapDateFormat,
  Notification,
} from '@energybox/react-ui-library/dist/types';
import {
  convert24hrTo12HrFormat,
  createTemperatureString,
  formatIanaTimeZone,
} from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import TemperatureCell from '../../../components/TemperatureCell';
import {
  useAppLocale,
  useCurrentUser,
  useIs12HrTimeFormat,
} from '../../../hooks/useAppDetails';
import {
  GeneratedReport,
  GeneratedReportResource,
} from '../../../types/temperatureRecord';
import reportStyles from '../ReportPage.module.css';
import styles from './TemperatureRecordReportTable.module.css';

type Props = {
  generatedReport: GeneratedReport;
};

type ResourceWithIdx = GeneratedReportResource & { idx: number };

const TemperatureRecordReportTable: React.FC<Props> = ({ generatedReport }) => {
  const currentUser = useCurrentUser();
  const locale = useAppLocale();
  const is12HrFormat = useIs12HrTimeFormat();

  if (!currentUser) return null;

  const data: ResourceWithIdx[] = generatedReport.resources.map((r, idx) => ({
    ...r,
    idx: idx + 1,
  }));

  const renderPeriodTimeRange = (from: string, to: string) => {
    if (is12HrFormat) {
      return `${convert24hrTo12HrFormat(from)} - ${convert24hrTo12HrFormat(
        to
      )}`;
    }
    return `${from} - ${to}`;
  };

  const renderTemperatureColumns = () => {
    return generatedReport.periods.map((p, idx) => ({
      header: (
        <div className={styles.headerCell}>
          {renderPeriodTimeRange(p.from, p.to)}
        </div>
      ),
      align: 'right' as CellAlign,
      cellStyle: { verticalAlign: 'top' },
      cellContent: (row: ResourceWithIdx) =>
        row.sensors.map(s => (
          <div key={s.id} className={styles.cell}>
            <span
              style={{
                fontWeight: s['notifications'][idx] ? 'bold' : 'normal',
              }}
            >
              <TemperatureCell
                temperatureInCelsius={
                  s.data[idx] ? s.data[idx].temperature : null
                }
              />
            </span>

            <div className={reportStyles.tableCellRightAlign}>
              {renderNotificationInfo(s['notifications'][idx], locale)}
            </div>
          </div>
        )),
    }));
  };

  const calculateLocalTimeBySite = (
    date: Date,
    locale: Locale,
    includeDate?: 'includeDate'
  ) => {
    const siteTimeZone = generatedReport.organizationUnit.timeZone;
    let format: string;

    if (includeDate) {
      const dateFormat =
        locale.dateFormat === MapDateFormat.MM_DD_YYYY
          ? 'MMMM do '
          : 'do MMMM ';

      format = dateFormat + locale.timeFormat;
    } else {
      format = locale.timeFormat;
    }

    return formatIanaTimeZone(date, format, siteTimeZone);
  };

  const renderNotificationInfo = (
    notification: Notification | null,
    locale: Locale
  ) => {
    if (!notification) return null;
    const featureNotification = notification.handlingLogs[0];
    const notificationTime = calculateLocalTimeBySite(
      new Date(featureNotification.at),
      locale
    );
    const notificationTemperature = createTemperatureString(
      Number(featureNotification.sensorParams[0].value),
      currentUser
    );

    const dismissedTime = calculateLocalTimeBySite(
      new Date(notification.dismissedAt),
      locale,
      'includeDate'
    );
    const dismissedBy = notification.dismissedBy;
    const dismissedComment = notification.dismissedComment;

    const renderDismissedInfo = () => {
      if (!dismissedTime || !dismissedBy) return null;
      return (
        <div>
          Dismissed at {dismissedTime} by {dismissedBy}
          {dismissedComment ? `: ${dismissedComment}` : ''}
        </div>
      );
    };

    return (
      <>
        <div className={styles.notificationCellContent}>
          Alert at {notificationTime} ({notificationTemperature})
        </div>

        <div className={styles.notificationCellContent}>
          {renderDismissedInfo()}
        </div>
      </>
    );
  };

  const columns: TableColumns<ResourceWithIdx>[] = [
    {
      header: (
        <div className={`${styles.headerCell} ${styles.leftPadding}`}>#</div>
      ),
      width: '5%',
      cellContent: (row: ResourceWithIdx) => (
        <>
          {row.sensors.map((s, idx) => (
            <div
              key={`${s.id} index`}
              className={`${styles.cell} ${styles.leftPadding}`}
            >
              {idx === 0 ? row.idx : ''}
            </div>
          ))}
        </>
      ),
    },
    {
      header: <div className={styles.headerCell}>Equipment</div>,
      width: '25%',
      cellContent: (row: ResourceWithIdx) => (
        <>
          {row.sensors.map((s, idx) => (
            <div key={`${s.id} equipment`} className={styles.cell}>
              {idx === 0 ? row.title : ''}
            </div>
          ))}
        </>
      ),
    },
    {
      header: <div className={styles.headerCell} />,
      width: '15%',
      cellStyle: { verticalAlign: 'top' },
      cellContent: (row: ResourceWithIdx) => {
        return row.sensors.map(s => (
          <div
            key={`${s.id} ${s.title}`}
            className={`${styles.cell} ${styles.sensorTitleCell}`}
          >
            {row.sensors.length > 1 ? s.title : ''}
          </div>
        ));
      },
    },
    ...renderTemperatureColumns(),
  ];

  return (
    <div className={styles.tableContainer}>
      <Table columns={columns} data={data} highlightAlternateRows />
    </div>
  );
};

export default TemperatureRecordReportTable;
