import React from 'react';

const NumberHeaderBox = ({ children }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}
    >
      {children}
    </div>
  );
};

export default NumberHeaderBox;
