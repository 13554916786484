import { FlatNotification } from '@energybox/react-ui-library/dist/types';
import { deriveDateValue } from '@energybox/react-ui-library/dist/utils';
import isWithinInterval from 'date-fns/isWithinInterval';
import * as R from 'ramda';
import { Actions } from '../actions/notifications';
import {
  FlatNotificationsByEquipmentId,
  FlatNotificationsBySensorId,
} from '../types/notification';

export type Notifications = {
  isLoadingByResourceId: IsLoadingByResourceId;
  flatNotificationsByEquipmentId: FlatNotificationsByEquipmentId;
  flatNotificationsBySensorId: FlatNotificationsBySensorId;
};

type IsLoadingByResourceId = {
  [resourceId: string]: boolean;
};

const initialState = {
  isLoadingByResourceId: {},
  flatNotificationsByEquipmentId: {},
  flatNotificationsBySensorId: {},
};

export function sortFlatNotificationsByDateAscending(
  a: FlatNotification,
  b: FlatNotification
): number {
  return deriveDateValue(a.at) - deriveDateValue(b.at);
}

export function filterFlatNotificationsOutsideDesiredRange(
  flatNotifications: FlatNotification[],
  timePeriod: { from: string; to: string }
) {
  return flatNotifications.filter((n: FlatNotification) => {
    return isWithinInterval(Date.parse(n.at), {
      start: Date.parse(timePeriod.from),
      end: Date.parse(timePeriod.to),
    });
  });
}

const notifications = (state: Notifications = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['flatNotificationsByEquipmentId', action.equipmentId],
          filterFlatNotificationsOutsideDesiredRange(
            action.payload,
            action.timePeriod
          ).sort(sortFlatNotificationsByDateAscending)
        ),
        R.assocPath(['isLoadingByResourceId', action.equipmentId], false)
      )(state);

    case Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['flatNotificationsBySensorId', action.sensorId],
          filterFlatNotificationsOutsideDesiredRange(
            action.payload,
            action.timePeriod
          ).sort(sortFlatNotificationsByDateAscending)
        ),
        R.assocPath(['isLoadingByResourceId', action.sensorId], false)
      )(state);

    case Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING:
      return R.assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        true,
        state
      );

    case Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR:
      return R.assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        false,
        state
      );

    case Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_LOADING:
      return R.assocPath(
        ['isLoadingByResourceId', action.sensorId],
        true,
        state
      );

    case Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_ERROR:
      return R.assocPath(
        ['isLoadingByResourceId', action.sensorId],
        false,
        state
      );

    default:
      return state;
  }
};

export default notifications;
