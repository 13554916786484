import { Service } from '../config';

export enum Actions {
  GET_SITE_INSPECTION_REPORT_SUCCESS = '@inspection/GET_SITE_INSPECTION_REPORT_SUCCESS',
  GET_SITE_INSPECTION_REPORT_LOADING = '@inspection/GET_SITE_INSPECTION_REPORT_LOADING',
  GET_SITE_INSPECTION_REPORT_ERROR = '@inspection/GET_SITE_INSPECTION_REPORT_ERROR',
}

export const getSiteInspectionReport = (siteId: string, timestamp: string) => ({
  type: 'API_GET',
  path: `/report/inspection_report/${siteId}/${timestamp}`,
  service: Service.analytics,
  loading: { type: Actions.GET_SITE_INSPECTION_REPORT_LOADING, siteId },
  success: { type: Actions.GET_SITE_INSPECTION_REPORT_SUCCESS, siteId },
  error: { type: Actions.GET_SITE_INSPECTION_REPORT_ERROR, siteId },
});
