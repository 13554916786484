import {
  EnergyPowerApiResponse,
  FlatNotification,
  OperationalSample,
  SensorType,
  Site,
  TemperatureSop,
  HumiditySop,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import {
  useEquipmentChartDoorAccessAnalytics,
  useEquipmentChartHumidityAnalytics,
  useEquipmentChartEnergyAnalytics,
  useEquipmentChartTemperatureAnalytics,
} from '../../../hooks/charts/equipment';
import { useAppLocale } from '../../../hooks/useAppDetails';
import styles from './EquipmentChartAnalyticsContainer.module.css';

export enum EquipmentChartType {
  TEMPERATURE = 'Temperature',
  HUMIDITY = 'Humidity',
  BINARY = 'Binary',
  ENERGY = 'Energy',
}

type Props = {
  site: Site;
  fromDateInMilliseconds: number;
  toDateInMilliseconds: number;
  chartType: EquipmentChartType;
  data: OperationalSample[] | EnergyPowerApiResponse | undefined;
  prevData?: EnergyPowerApiResponse | undefined;
  tempSop?: TemperatureSop | undefined | null;
  humiditySop?: HumiditySop | undefined | null;
  flatNotifications?: FlatNotification[];
};

const EquipmentChartAnalyticsContainer: React.FC<Props> = ({
  site,
  fromDateInMilliseconds,
  toDateInMilliseconds,
  chartType,
  data,
  prevData,
  flatNotifications,
  tempSop,
  humiditySop,
}) => {
  const locale = useAppLocale();
  const processedChartAnalytics = (() => {
    switch (chartType) {
      case EquipmentChartType.TEMPERATURE: {
        return useEquipmentChartTemperatureAnalytics(
          fromDateInMilliseconds,
          toDateInMilliseconds,
          data as OperationalSample[],
          flatNotifications,
          tempSop
        );
      }

      case EquipmentChartType.HUMIDITY: {
        return useEquipmentChartHumidityAnalytics(
          fromDateInMilliseconds,
          toDateInMilliseconds,
          data as OperationalSample[],
          flatNotifications,
          humiditySop
        );
      }

      case EquipmentChartType.BINARY: {
        return useEquipmentChartDoorAccessAnalytics(
          data as OperationalSample[] | undefined,
          locale,
          site
        );
      }

      case EquipmentChartType.ENERGY: {
        return useEquipmentChartEnergyAnalytics(
          data as EnergyPowerApiResponse | undefined,
          prevData as EnergyPowerApiResponse | undefined,
          locale,
          site
        );
      }

      default:
        return undefined;
    }
  })();

  if (processedChartAnalytics === undefined) {
    return null;
  }
  return (
    <div className={styles.root}>
      {Object.keys(processedChartAnalytics).map(k => (
        <div className={styles.textContainer} key={k}>
          <span className={styles.label}>{k}: </span>
          <span>
            {isDefined(processedChartAnalytics[k])
              ? processedChartAnalytics[k]
              : global.NOT_AVAILABLE}
          </span>
        </div>
      ))}
    </div>
  );
};

export default EquipmentChartAnalyticsContainer;
