import { fetchApi } from '../middleware/apiMiddleware';

const apiBase = `/api/v1/users`;

export enum Actions {
  USER_TYPE_SUCCESS = '@@user/USER_TYPE_SUCCESS',
  USER_TYPE_ERROR = '@@user/USER_TYPE_ERROR',

  GET_USERS_SUCCESS = '@users/GET_USERS_SUCCESS',
  GET_USERS_ERROR = '@users/GET_USERS_ERROR',
  GET_USERS_LOADING = '@users/GET_USERS_LOADING',
}

export const getUsers = (userIds?: (string | number)[]) => ({
  type: 'API_GET',
  path: `${apiBase}?${userIds ? `ids=${userIds.join()}` : ''}`,
  success: Actions.GET_USERS_SUCCESS,
  error: Actions.GET_USERS_ERROR,
  loading: Actions.GET_USERS_LOADING,
});

export const getUserType = () => dispatch =>
  fetchApi({
    endpoint: `/api/v1/sites`,
  })
    .then(sites => {
      dispatch({
        type: Actions.USER_TYPE_SUCCESS,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: Actions.USER_TYPE_ERROR,
        payload: {
          error,
        },
      });
    });
