import React from 'react';
import {
  genericTableSort,
  global,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import {
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  PowerMeteringType,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  getTitle,
  getDetailFields,
  getSummaryFields,
  transformDhcpStatusValue,
  transformBusDeviceInfoValue,
  getSensorsOrActuatorsSummaryFields,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { formatDecimalValue } from '@energybox/react-ui-library/dist/utils/number';
import { ComponentHeader, InspectionComponent } from './InspectionComponent';
import { DataField } from '../Pdf/utils';
import { DeviceIcon } from '../Pdf/Icons';

type Props = {
  data: InspectionDataFieldsByKey;
};

const formatTableNumber = field => {
  if (
    (!field && field !== 0) ||
    field === undefined ||
    field === null ||
    isNaN(field)
  ) {
    return global.NOT_AVAILABLE;
  }
  return formatDecimalValue(field);
};

export const EnergyPro: React.FC<Props> = ({ data }) => {
  const columns = [
    {
      header: 'Index',
      width: '15%',
      defaultSortDirection: SortDirection.ASC,
      isDefaultSort: true,
      cellContent: ({ index }) => <DataField field={index} />,
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'index',
          'field',
        ]);
      },
    },
    {
      header: 'Breaker',
      width: '10%',
      cellContent: ({ breaker }) => <DataField field={breaker} />,
    },
    {
      header: 'Equipment',
      width: '16%',
      cellContent: ({ equipment_title }) => (
        <DataField field={equipment_title} transform={field => field || ' '} />
      ),
    },
    {
      header: 'Active Power (kW)',
      width: '6%',
      cellContent: ({ active_power }) => (
        <DataField field={active_power} transform={formatTableNumber} />
      ),
    },
    {
      header: 'Current (A)',
      width: '6%',
      cellContent: ({ current }) => (
        <DataField field={current} transform={formatTableNumber} />
      ),
    },
    {
      header: 'Power Factor',
      width: '6%',
      cellContent: ({ power_factor }) => (
        <DataField field={power_factor} transform={formatTableNumber} />
      ),
    },
  ];
  const fields = [
    {
      name: 'IP Address',
      key: 'ip',
    },
    {
      name: 'MAC Address (UUID)',
      key: 'UUID',
    },
    {
      name: 'DHCP Status',
      key: 'ip_setting',
      transformValue: transformDhcpStatusValue,
    },
    {
      name: 'Firmware Version',
      key: 'firmware_version',
    },
    {
      name: 'Sensor Bus 1',
      key: 'bus_device_info_1',
      transformValue: transformBusDeviceInfoValue,
    },
    {
      name: 'Power Metering Type',
      key: 'power_metering_type',
      transformValue: value =>
        PowerMeteringType[value] || value || global.NOT_AVAILABLE,
    },
    {
      name: 'Sensor Bus 2',
      key: 'bus_device_info_2',
      transformValue: transformBusDeviceInfoValue,
    },
    {
      name: 'MQTT Broker',
      key: 'mqtt_broker',
    },
    {
      name: 'Interval',
      key: 'interval',
      transformValue: value => `${value} sec`,
    },
  ];
  const subtitle = getTitle(data);
  const allTabsTableData = data.ct_sensors as InspectionDataFieldsByKey[];
  const tableDataBySensorBus = {};
  allTabsTableData.forEach(ctSensor => {
    const sensorBusField = ctSensor.sensor_bus as InspectionDataField;
    const sensorBus = (sensorBusField?.field === 1 ||
    sensorBusField?.field === 2
      ? sensorBusField.field
      : 0) as number;
    if (!tableDataBySensorBus[sensorBus]) {
      tableDataBySensorBus[sensorBus] = [];
    }
    tableDataBySensorBus[sensorBus].push(ctSensor);
  });
  const tableTitles = ['EnergyPro', 'Bus 1', 'Bus 2'];
  const summaryFields = getSummaryFields(data);
  const sensorsSummaryFields = getSensorsOrActuatorsSummaryFields(
    data.ct_sensors as InspectionDataFieldsByKey[]
  );

  return (
    <InspectionComponent
      title={InspectionComponentName.ENERGY_PRO}
      titleIcon={<DeviceIcon type={InspectionComponentName.ENERGY_PRO} />}
      subtitle={subtitle}
      summaryFields={summaryFields}
      detailFields={getDetailFields(fields, data, InspectionDetailLevel.ALL)}
      detailTableHeader={
        <ComponentHeader
          title="EnergyPro CT Sensors"
          summaryFields={sensorsSummaryFields}
        />
      }
      detailTable={[0, 1, 2].map(idx => ({
        title: tableTitles[idx],
        props: {
          dataIsLoading: false,
          highlightAlternateRows: true,
          columns,
          data: tableDataBySensorBus[idx],
        },
      }))}
      noTableDataMessage="No Sensors Configured"
    />
  );
};
