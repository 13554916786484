import { Site } from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

import React, { useMemo, useRef } from 'react';
import { TemperatureRecordReportFetchStatuses } from '../../../reducers/temperatureRecordReport';
import { ReportHeadingTitle } from '../../../types/shared';
import { GeneratedReport } from '../../../types/temperatureRecord';
import ReportHeading from '../ReportHeading';
import ReportMeasurableElement from '../ReportMeasurableElement';
import ReportPageBreakControl from '../ReportPageBreakControl';
import TemperatureRecordReportTable from '../TemperatureRecordReportTable';

type Props = {
  site: Site;
  generatedReport: GeneratedReport;
  date: Date;
  temperatureRecordReportFetchStatus: TemperatureRecordReportFetchStatuses;
};

const TemperatureRecordReportPage: React.FC<Props> = ({
  site,
  temperatureRecordReportFetchStatus,
  generatedReport,
  date,
}) => {
  const headerRef = useRef(null);
  const tableRef = useRef(null);

  const headerInfo = useCreateHeaderInfoColumn(site);

  return (
    <ReportPageBreakControl
      fetchedStatuses={temperatureRecordReportFetchStatus}
    >
      <ReportMeasurableElement setRef={headerRef} key="heading">
        <ReportHeading
          title={ReportHeadingTitle.TEMPERATURE_RECORD}
          leftColumnContent={headerInfo}
          toDate={date}
        />
      </ReportMeasurableElement>

      <ReportMeasurableElement setRef={tableRef} key="table">
        <TemperatureRecordReportTable generatedReport={generatedReport} />
      </ReportMeasurableElement>
    </ReportPageBreakControl>
  );
};

const useCreateHeaderInfoColumn = (site: Site) => {
  return useMemo(() => {
    return [
      {
        fieldName: 'Organization',
        fieldValue: site?.organization?.title || global.NOT_AVAILABLE,
      },
      { fieldName: 'Site', fieldValue: site?.title },
      { fieldName: 'Address', fieldValue: site?.address },
    ];
  }, [site]);
};

export default TemperatureRecordReportPage;
