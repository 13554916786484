import {
  EnergyByEquipmentId,
  Equipment,
  HVACLoad,
  Site,
} from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import StorageReportHVACLoadCharts from '../StorageReportHVACLoadCharts';

type Props = {
  site: Site;
  fromDateISO: string;
  toDateISO: string;
  equipments: Equipment[];
  equipmentId: number;
  fetchedEnergyByEquipment: boolean;
  energyByEquipmentId: EnergyByEquipmentId;
  errorEnergyBySiteEquipment: boolean;
};

const findKeyFromResourceId = (
  searchArray: any[],
  target: any,
  key: string
) => {
  return searchArray.find(el => el.id === target)[key];
};

const StorageReportHVACLoadChartsContainer = ({
  site,
  fromDateISO,
  toDateISO,
  equipments,
  equipmentId,
  fetchedEnergyByEquipment,
  energyByEquipmentId,
  errorEnergyBySiteEquipment,
}: Props) => {
  const [HVACLoadData, setHVACLoadData] = useState<HVACLoad>();
  useEffect(() => {
    if (fetchedEnergyByEquipment && !!equipments && equipments.length > 0) {
      const consumption = {
        ...energyByEquipmentId[equipmentId],
        equipmentTitle: findKeyFromResourceId(equipments, equipmentId, 'title'),
      };
      setHVACLoadData(consumption);
    }
  }, [equipmentId, equipments, energyByEquipmentId, fetchedEnergyByEquipment]);

  if (
    !fetchedEnergyByEquipment ||
    errorEnergyBySiteEquipment ||
    !HVACLoadData
  ) {
    return <div />;
  }
  return (
    <div>
      <StorageReportHVACLoadCharts
        site={site}
        fromDateISO={fromDateISO}
        toDateISO={toDateISO}
        HVACLoadData={HVACLoadData}
        equipmentId={equipmentId}
      />
    </div>
  );
};

const mapStateToProps = ({ energy, storageReport }: ApplicationState) => ({
  energyBySiteEquipmentGroup: energy.energyBySiteEquipmentGroup,
  energyByEquipmentId: energy.energyByEquipmentId as EnergyByEquipmentId,
  fetchedEnergyByEquipment: storageReport.fetched.energyByEquipmentId,
  errorEnergyBySiteEquipment: storageReport.errors.energyByEquipmentId,
});

export default connect(mapStateToProps)(StorageReportHVACLoadChartsContainer);
