import {
  ComparisonData,
  FilterTimePeriod,
  RechartsCustomTooltip,
} from '@energybox/react-ui-library/dist/types';
import { formatNumberString } from '@energybox/react-ui-library/dist/utils';
import ToolTip from '../../../components/Charts/ToolTip';
import styles from './EnergyUseIntensityTile.module.css';
import { format, parseISO } from 'date-fns';
import { KWH_UNIT } from '@energybox/react-ui-library/dist/utils';
import * as Routes from '../../../routes';
import EnergyUseIntensityChart from './EnergyUseIntensityChart';

const CHART_BAR_FILL_COLOR_ARRAY = [
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
];

type Props = {
  className?: string;
  data: {
    [date: string]: {
      value: number;
      data_comp: number;
      status: boolean;
    };
  } | undefined;
  isLoading: boolean | undefined;
};

const CustomTooltip = (props: RechartsCustomTooltip) => {
  const { payload } = props;
  if (
    !!!payload ||
    payload.length < 1 ||
    payload[0].payload === undefined ||
    payload[0].payload === null
  ) {
    return null;
  } else {
    const {
      value,
      entity: title,
      fillColor,
    } = payload[0].payload as ComparisonData;
    return (
      <></>
      // <ToolTip
      //   {...props}
      //   title={title}
      //   values={`${formatNumberString(value, { numDecimals: 1 })} ${KWH_UNIT}`}
      //   valuesColor={fillColor}
      // />
    );
  }
};

const EnergyUseIntensityTile: React.FC<Props> = ({
  className,
  data,
  isLoading,
}) => {
  let comparisonData: ComparisonData[] = [];
  for (const [key, value] of Object.entries(data || {})) {
    const month = format(parseISO(key), 'MMM');
    comparisonData.push({
      entity: month,
      value: value?.value,
      fillColor: 'var(--turquoise-base)',
    });
  }

  return (
    <div className={styles.tileContainer}>
      <div className={styles.tileTitle}>
        {'Energy Use Intensity (EUI)*'}
      </div>
      <EnergyUseIntensityChart
        data={comparisonData}
        dataFillColor={CHART_BAR_FILL_COLOR_ARRAY}
        dataUnit={'kWh/m²'}
        tooltip={CustomTooltip}
      />
    </div>
  );
};

export default EnergyUseIntensityTile;
