import {
  AnalyticsEnergyResponse,
  EnergyApiFilter,
  EnergyPowerApiResponse,
  Equipment,
  EquipmentEnergy,
  OrgEnergyReportingPeriod,
} from '@energybox/react-ui-library/dist/types';
import * as Sentry from '@sentry/browser';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import { Service } from '../config';
import { fetchApi } from '../middleware/apiMiddleware';

export enum Actions {
  SITE_ENERGY_SELECTED_TIME_PERIOD_LOADING = '@@energy/SITE_ENERGY_SELECTED_TIME_PERIOD_LOADING',
  SITE_ENERGY_SELECTED_TIME_PERIOD_SUCCESS = '@@energy/SITE_ENERGY_SELECTED_TIME_PERIOD_SUCCESS',
  SITE_ENERGY_SELECTED_TIME_PERIOD_ERROR = '@@energy/SITE_ENERGY_SELECTED_TIME_PERIOD_ERROR',

  SITE_ENERGY_LAST_MONTH_LOADING = '@@energy/SITE_ENERGY_LAST_MONTH_LOADING',
  SITE_ENERGY_LAST_MONTH_SUCCESS = '@@energy/SITE_ENERGY_LAST_MONTH_SUCCESS',
  SITE_ENERGY_LAST_MONTH_ERROR = '@@energy/SITE_ENERGY_LAST_MONTH_ERROR',

  SITE_ENERGY_2_MONTHS_AGO_LOADING = '@@energy/SITE_ENERGY_2_MONTHS_AGO_LOADING',
  SITE_ENERGY_2_MONTHS_AGO_SUCCESS = '@@energy/SITE_ENERGY_2_MONTHS_AGO_SUCCESS',
  SITE_ENERGY_2_MONTHS_AGO_ERROR = '@@energy/SITE_ENERGY_2_MONTHS_AGO_ERROR',

  SITE_ENERGY_3_MONTHS_AGO_LOADING = '@@energy/SITE_ENERGY_3_MONTHS_AGO_LOADING',
  SITE_ENERGY_3_MONTHS_AGO_SUCCESS = '@@energy/SITE_ENERGY_3_MONTHS_AGO_SUCCESS',
  SITE_ENERGY_3_MONTHS_AGO_ERROR = '@@energy/SITE_ENERGY_3_MONTHS_AGO_ERROR',

  ENERGY_BY_EQUIPMENTS_LOADING = '@@energy/ENERGY_BY_EQUIPMENTS_LOADING',
  ENERGY_BY_EQUIPMENTS_SUCCESS = '@@energy/ENERGY_BY_EQUIPMENTS_SUCCESS',
  ENERGY_BY_EQUIPMENTS_ERROR = '@@energy/ENERGY_BY_EQUIPMENTS_ERROR',

  SITE_ENERGY_BY_WEEKS_LOADING = '@@energy/SITE_ENERGY_BY_WEEKS_LOADING',
  SITE_ENERGY_BY_WEEKS_SUCCESS = '@@energy/SITE_ENERGY_BY_WEEKS_SUCCESS',
  SITE_ENERGY_BY_WEEKS_ERROR = '@@energy/SITE_ENERGY_BY_WEEKS_ERROR',

  ORG_ENERGY_BY_REPORTING_PERIOD_LOADING = '@@energy/ORG_ENERGY_BY_REPORTING_PERIOD_LOADING',
  ORG_ENERGY_BY_REPORTING_PERIOD_SUCCESS = '@@energy/ORG_ENERGY_BY_REPORTING_PERIOD_SUCCESS',
  ORG_ENERGY_BY_REPORTING_PERIOD_ERROR = '@@energy/ORG_ENERGY_BY_REPORTING_PERIOD_ERROR',

  ANALYTICS_ENERGY_BY_SITE_EQUIPMENT_GROUP_LOADING = '@@energy/ANALYTICS_ENERGY_BY_SITE_EQUIPMENT_GROUP_LOADING',
  ANALYTICS_ENERGY_BY_SITE_EQUIPMENT_GROUP_ERROR = '@@energy/ANALYTICS_ENERGY_BY_SITE_EQUIPMENT_GROUP_ERROR',
  ANALYTICS_ENERGY_BY_SITE_EQUIPMENT_GROUP_SUCCESS = '@@energy/ANALYTICS_ENERGY_BY_SITE_EQUIPMENT_GROUP_SUCCESS',

  ANALYTICS_ENERGY_REPORT_PREV_PERIOD_LOADING = '@@energy/ANALYTICS_ENERGY_REPORT_PREV_PERIOD_LOADING',
  ANALYTICS_ENERGY_REPORT_PREV_PERIOD_ERROR = '@@energy/ANALYTICS_ENERGY_REPORT_PREV_PERIOD_ERROR',
  ANALYTICS_ENERGY_REPORT_PREV_PERIOD_SUCCESS = '@@energy/ANALYTICS_ENERGY_REPORT_PREV_PERIOD_SUCCESS',

  ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_LOADING = '@@energy/ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_LOADING',
  ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_ERROR = '@@energy/ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_ERROR',
  ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_SUCCESS = '@@energy/ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_SUCCESS',

  ANALYTICS_ENERGY_BY_EQUIPMENT_SUCCESS = '@@energy/ANALYTICS_ENERGY_BY_EQUIPMENT_SUCCESS',

  ENERGY_BY_EQUIPMENT_ID_LOADING = '@@energy/ENERGY_BY_EQUIPMENT_ID_LOADING',
  ENERGY_BY_EQUIPMENT_ID_ERROR = '@@energy/ENERGY_BY_EQUIPMENT_ID_ERROR',
  ENERGY_BY_EQUIPMENT_ID_SUCCESS = '@@energy/ENERGY_BY_EQUIPMENT_ID_SUCCESS',
  EQUIPMENT_REPORT_ENERGY_LOADING = '@@energy/EQUIPMENT_REPORT_ENERGY_LOADING',
  EQUIPMENT_REPORT_ENERGY_ERROR = '@@energy/EQUIPMENT_REPORT_ENERGY_ERROR',
  EQUIPMENT_REPORT_ENERGY_SUCCESS = '@@energy/EQUIPMENT_REPORT_ENERGY_SUCCESS',
  SKIP_EQUIPMENT_REPORT_ENERGY = '@@energy/SKIP_EQUIPMENT_REPORT_ENERGY',

  GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_LOADING = '@@energy/GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_LOADING',
  GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR = '@@energy/GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR',
  GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_SUCCESS = '@@energy/GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_SUCCESS',
}

const baseUrl = '/api/v1/ioe-energy';

export const setEnergyFilter = (filter?: EnergyApiFilter): string => {
  const queryParams = new URLSearchParams();

  //filter by equipmentId
  if (filter && filter.id) {
    queryParams.set('id', filter.id.toString());
  }

  if (filter && filter.from) {
    queryParams.set('from', filter.from.toString());
  }

  if (filter && filter.to) {
    queryParams.set('to', filter.to.toString());
  }

  if (filter && filter.aggregationLevel) {
    queryParams.set('aggregationLevel', filter.aggregationLevel.toString());
  }

  return queryParams.toString();
};

const fetchEquipmentEnergyById = (
  equipmentId: number | string,
  queryParams: EnergyApiFilter
): Promise<EnergyPowerApiResponse> => {
  return fetchApi({
    endpoint: `${baseUrl}/consumption/equipment/${equipmentId}?${setEnergyFilter(
      queryParams
    )}`,
  });
};

export const dontGetEnergyByEquipmentIds = () => ({
  type: Actions.ENERGY_BY_EQUIPMENTS_ERROR,
});

export const getEnergyByEquipmentIds = (
  equipments: Equipment[],
  fromDateTimestamp: number,
  toDateTimestamp: number
) => dispatch => {
  dispatch({
    type: Actions.ENERGY_BY_EQUIPMENTS_LOADING,
  });

  const fetches = equipments.map((equipment: Equipment) => {
    return fetchEquipmentEnergyById(equipment.id, {
      from: fromDateTimestamp.toFixed(0),
      to: toDateTimestamp.toFixed(0),
    })
      .then((res: EnergyPowerApiResponse) => {
        return {
          ...res,
          equipmentId: equipment.id,
        };
      })
      .catch(err => {
        console.log(err);
      });
  });

  Promise.all<EquipmentEnergy | void>(fetches).then(
    (res: (EquipmentEnergy | void)[]) => {
      const validResults: EquipmentEnergy[] = res.filter(
        result => result !== undefined
      ) as EquipmentEnergy[];
      if (validResults.length > 0) {
        dispatch({
          type: Actions.ENERGY_BY_EQUIPMENTS_SUCCESS,
          payload: validResults,
        });
      } else {
        dispatch({
          type: Actions.ENERGY_BY_EQUIPMENTS_ERROR,
        });
      }
    }
  );
};

export const getSiteConsumptionSelectedTimePeriod = (
  reportFromDate: Date,
  reportToDate: Date,
  siteId: number
) => {
  const formattedFromDate = format(reportFromDate, 'yyyy-MM-dd');
  const formattedToDate = format(reportToDate, 'yyyy-MM-dd');

  return {
    type: 'API_GET',
    service: Service.analytics,
    path: `/report/energy_report/${siteId}/${formattedFromDate}/${formattedToDate}`,
    loading: {
      type: Actions.SITE_ENERGY_SELECTED_TIME_PERIOD_LOADING,
      fromDate: reportFromDate,
    },
    success: {
      type: Actions.SITE_ENERGY_SELECTED_TIME_PERIOD_SUCCESS,
      fromDate: reportFromDate,
    },
    error: {
      type: Actions.SITE_ENERGY_SELECTED_TIME_PERIOD_ERROR,
      fromDate: reportFromDate,
    },
  };
};

export const getSiteConsumptionLastMonth = (
  reportToDate: Date,
  siteId: number
) => {
  const beginningOfLastMonth = startOfMonth(subMonths(reportToDate, 1));
  const endOfLastMonth = endOfMonth(subMonths(reportToDate, 1));
  const formattedFromDate = format(beginningOfLastMonth, 'yyyy-MM-dd');
  const formattedToDate = format(endOfLastMonth, 'yyyy-MM-dd');

  return {
    type: 'API_GET',
    service: Service.analytics,
    path: `/report/energy_report/${siteId}/${formattedFromDate}/${formattedToDate}`,
    loading: {
      type: Actions.SITE_ENERGY_LAST_MONTH_LOADING,
      fromDate: beginningOfLastMonth,
    },
    success: {
      type: Actions.SITE_ENERGY_LAST_MONTH_SUCCESS,
      fromDate: beginningOfLastMonth,
    },
    error: {
      type: Actions.SITE_ENERGY_LAST_MONTH_ERROR,
      fromDate: beginningOfLastMonth,
    },
  };
};

export const getSiteConsumptionTwoMonthsAgo = (
  reportToDate: Date,
  siteId: number
) => {
  const twoMonthsAgo = subMonths(reportToDate, 2);
  const beginningOfTwoMonthsAgo = startOfMonth(twoMonthsAgo);
  const endOfTwoMonthsAgo = endOfMonth(twoMonthsAgo);
  const formattedFromDate = format(beginningOfTwoMonthsAgo, 'yyyy-MM-dd');
  const formattedToDate = format(endOfTwoMonthsAgo, 'yyyy-MM-dd');

  return {
    type: 'API_GET',
    service: Service.analytics,
    path: `/report/energy_report/${siteId}/${formattedFromDate}/${formattedToDate}`,
    loading: {
      type: Actions.SITE_ENERGY_2_MONTHS_AGO_LOADING,
      fromDate: beginningOfTwoMonthsAgo,
    },
    success: {
      type: Actions.SITE_ENERGY_2_MONTHS_AGO_SUCCESS,
      fromDate: beginningOfTwoMonthsAgo,
    },
    error: {
      type: Actions.SITE_ENERGY_2_MONTHS_AGO_ERROR,
      fromDate: beginningOfTwoMonthsAgo,
    },
  };
};

export const getSiteConsumptionThreeMonthsAgo = (
  reportToDate: Date,
  siteId: number
) => {
  const threeMonthsAgo = subMonths(reportToDate, 3);
  const beginningOfThreeMonthsAgo = startOfMonth(threeMonthsAgo);
  const endOfThreeMonthsAgo = endOfMonth(threeMonthsAgo);
  const formattedFromDate = format(beginningOfThreeMonthsAgo, 'yyyy-MM-dd');
  const formattedToDate = format(endOfThreeMonthsAgo, 'yyyy-MM-dd');

  return {
    type: 'API_GET',
    service: Service.analytics,
    path: `/report/energy_report/${siteId}/${formattedFromDate}/${formattedToDate}`,
    loading: {
      type: Actions.SITE_ENERGY_3_MONTHS_AGO_LOADING,
      fromDate: beginningOfThreeMonthsAgo,
    },
    success: {
      type: Actions.SITE_ENERGY_3_MONTHS_AGO_SUCCESS,
      fromDate: beginningOfThreeMonthsAgo,
    },
    error: {
      type: Actions.SITE_ENERGY_3_MONTHS_AGO_ERROR,
      fromDate: beginningOfThreeMonthsAgo,
    },
  };
};

export const getOrgEnergyReport = (
  organizationId: number,
  fromDate: Date,
  toDate: Date,
  reportingPeriod: OrgEnergyReportingPeriod
) => {
  const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
  const formattedToDate = format(toDate, 'yyyy-MM-dd');

  return {
    type: 'API_GET',
    service: Service.analytics,
    path: `/report/org_energy_report/${organizationId}/${formattedFromDate}/${formattedToDate}`,
    loading: {
      type: Actions.ORG_ENERGY_BY_REPORTING_PERIOD_LOADING,
      reportingPeriod,
    },
    success: {
      type: Actions.ORG_ENERGY_BY_REPORTING_PERIOD_SUCCESS,
      reportingPeriod,
    },
    error: {
      type: Actions.ORG_ENERGY_BY_REPORTING_PERIOD_ERROR,
      reportingPeriod,
    },
  };
};

export const fetchSiteEnergyConsumptionOverWeeks = (
  datesForQuery: Date[],
  siteId: number
) => (dispatch: any) => {
  dispatch({ type: Actions.SITE_ENERGY_BY_WEEKS_LOADING });
  const fetches = datesForQuery.map((date, idx) => {
    //dont fetch last dateForQuery
    if (idx < datesForQuery.length - 1) {
      const fromDate = date;
      const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
      const toDate =
        //usually toDate should be one day before the next dateForQuery,
        //EXCEPT for the last set
        idx < datesForQuery.length - 2
          ? subDays(datesForQuery[idx + 1], 1)
          : datesForQuery[idx + 1];
      const formattedToDate = format(toDate, 'yyyy-MM-dd');
      const legend = `${format(fromDate, 'dd')} - ${format(toDate, 'dd')}`;

      return fetchAnalyticsConsumptionOnlyBySiteId(siteId, fromDate, toDate)
        .then(res => {
          return {
            ...res,
            fromDate: formattedFromDate,
            toDate: formattedToDate,
            totalConsumption: res.data?.total_kwh,
            legend: legend,
          };
        })
        .catch(err => {
          Sentry.captureMessage(
            `Something went wrong when getting site consumption for site id ${siteId} from ${formattedFromDate} to ${formattedToDate}. Error message: ${err}`
          );
        });
    }
    return undefined;
  });

  return Promise.all(fetches).then(res => {
    dispatch({
      type: Actions.SITE_ENERGY_BY_WEEKS_SUCCESS,
      payload: res.filter(result => result !== undefined),
    });
  });
};

export const fetchAnalyticsConsumptionOnlyBySiteId = (
  siteId: number,
  fromDate: Date,
  toDate: Date
): Promise<AnalyticsEnergyResponse> => {
  const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
  const formattedToDate = format(toDate, 'yyyy-MM-dd');

  return fetchApi({
    service: Service.analytics,
    endpoint: `/report/energy_report/${siteId}/${formattedFromDate}/${formattedToDate}`,
  });
};

export const getAnalyticsEnergyConsumptionPreviousPeriod = (
  reportFromDate: string,
  reportToDate: string,
  siteId: number
) => {
  return {
    type: 'API_GET',
    service: Service.analytics,
    path: `/report/energy_report/${siteId}/${reportFromDate}/${reportToDate}`,
    loading: Actions.ANALYTICS_ENERGY_REPORT_PREV_PERIOD_LOADING,
    success: Actions.ANALYTICS_ENERGY_REPORT_PREV_PERIOD_SUCCESS,
    error: Actions.ANALYTICS_ENERGY_REPORT_PREV_PERIOD_ERROR,
  };
};

export const getAnalyticsEnergyConsumptionCurrentPeriod = (
  reportFromDate: string,
  reportToDate: string,
  siteId: number
) => {
  return {
    type: 'API_GET',
    service: Service.analytics,
    path: `/report/energy_report/${siteId}/${reportFromDate}/${reportToDate}`,
    loading: Actions.ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_LOADING,
    success: Actions.ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_SUCCESS,
    error: Actions.ANALYTICS_ENERGY_REPORT_CURRENT_PERIOD_ERROR,
  };
};

export const getEnergyByEquipmentId = (
  equipmentId: number | string,
  queryParams: EnergyApiFilter,
  idAffix?: string
) => ({
  type: 'API_GET',
  path: `${baseUrl}/consumption/equipment/${equipmentId}?${setEnergyFilter(
    queryParams
  )}`,
  loading: {
    type: Actions.ENERGY_BY_EQUIPMENT_ID_LOADING,
    equipmentId: idAffix ? equipmentId + idAffix : equipmentId,
  },
  success: {
    type: Actions.ENERGY_BY_EQUIPMENT_ID_SUCCESS,
    equipmentId: idAffix ? equipmentId + idAffix : equipmentId,
  },
  error: {
    type: Actions.ENERGY_BY_EQUIPMENT_ID_ERROR,
    equipmentId: idAffix ? equipmentId + idAffix : equipmentId,
  },
});

export const getEnergySensorsByEquipmentId = (equipmentId: number) => ({
  type: 'API_GET',
  path: `/api/v1/ioe-energy-sensors/equipment/${equipmentId}`,
  loading: {
    type: Actions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_LOADING,
    equipmentId,
  },
  success: {
    type: Actions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_SUCCESS,
    equipmentId,
  },
  error: {
    type: Actions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR,
    equipmentId,
  },
});

export const skipEquipmentReportEnergy = () => ({
  type: Actions.SKIP_EQUIPMENT_REPORT_ENERGY,
});
