import { Site } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAnalyticsEnergyConsumptionPreviousPeriod as getSiteConsumptionPrevTimePeriod,
  getSiteConsumptionSelectedTimePeriod,
} from '../../../actions/energy';
import { ApplicationState } from '../../../reducers';
import EnergyReportConsumptionInfo from '../EnergyReportConsumptionInfo';

type Props = {
  site: Site;
  fromDate: Date;
  toDate: Date;
  reportNumberOfDays: number | undefined;
  prevFromDateString: string | undefined;
  prevToDateString: string | undefined;
};

const GenericReportConsumptionInfoContainer: React.FC<Props> = ({
  site,
  fromDate,
  toDate,
  prevFromDateString,
  prevToDateString,
  reportNumberOfDays,
}) => {
  const dispatch = useDispatch();

  const loadingAnalyticsEnergyConsumption = useSelector<
    ApplicationState,
    boolean
  >(({ energyReport }) => {
    return energyReport.loading.analyticsEnergyConsumptionSelectedTimePeriod;
  });

  const fetchedAnalyticsEnergyConsumption = useSelector<
    ApplicationState,
    boolean
  >(({ energyReport }) => {
    return energyReport.fetched.analyticsEnergyConsumptionSelectedTimePeriod;
  });

  const analyticsEnergy = useSelector<ApplicationState, number | undefined>(
    ({ energy }) => {
      return energy.analyticsEnergySelectedTimePeriod?.data?.total_kwh;
    }
  );

  const prevTimePeriodAnalyticsEnergy = useSelector<
    ApplicationState,
    number | undefined
  >(({ energy }) => {
    return energy.totalConsumption?.prevPeriod;
  });

  useEffect(() => {
    if (
      !fetchedAnalyticsEnergyConsumption &&
      !loadingAnalyticsEnergyConsumption
    ) {
      dispatch(getSiteConsumptionSelectedTimePeriod(fromDate, toDate, site.id));
    }
  }, [
    fromDate,
    toDate,
    site,
    fetchedAnalyticsEnergyConsumption,
    loadingAnalyticsEnergyConsumption,
  ]);

  useEffect(() => {
    if (isDefined(prevFromDateString) && isDefined(prevToDateString)) {
      dispatch(
        getSiteConsumptionPrevTimePeriod(
          prevFromDateString,
          prevToDateString,
          site.id
        )
      );
    }
  }, [site, prevFromDateString, prevToDateString]);

  return (
    <EnergyReportConsumptionInfo
      site={site}
      totalConsumption={analyticsEnergy}
      prevTotalConsumption={prevTimePeriodAnalyticsEnergy}
      fromDate={fromDate}
      toDate={toDate}
      reportNumberOfDays={reportNumberOfDays}
    />
  );
};

export default GenericReportConsumptionInfoContainer;
