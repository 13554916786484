import { ColdStorageRecommendations } from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';

import React, { useMemo } from 'react';
import GrayInfoBox from '../../GrayInfoBox';
import ItalicText from '../../ItalicText';
import RecommendationText from '../../RecommendationText';
import SectionTitle from '../../SectionTitle';
import styles from './ColdStorageReduction.module.css';
import ColdStorageReductionTable from './ColdStorageReductionTable';

type Props = {
  coolingEquipmentRecommendations: ColdStorageRecommendations[];
  currency?: string;
  tariff?: number;
  totalSavings: number;
};

const ColdStorageReduction = ({
  coolingEquipmentRecommendations,
  currency,
  tariff,
  totalSavings,
}: Props) => {
  const numberOfEquipments = coolingEquipmentRecommendations.length;
  const estimatedSavingsValue = useMemo(() => {
    if (!isDefined(totalSavings)) {
      return global.NOT_AVAILABLE;
    }

    return `${totalSavings.toFixed(2)} ${currency}`;
  }, [totalSavings, currency]);

  const grayInfoBoxData = useMemo(() => {
    return [
      { name: 'Piece(s) of Equipment', value: numberOfEquipments },
      { name: 'Total Estimated Savings*', value: estimatedSavingsValue },
    ];
  }, [numberOfEquipments, estimatedSavingsValue]);

  const savingsText = `* If our recommendations are fully implemented`;

  return (
    <div className={styles.content}>
      <div className={styles.headerContainer}>
        <div className={styles.headerText}>
          <SectionTitle
            title="Reduce Your Cold Storage Consumption"
            description="The system has noticed the following refrigeration equipment operating 
            at an unnecessarily low temperature, causing the equipment to waste energy."
          />
        </div>
      </div>

      <GrayInfoBox data={grayInfoBoxData} />

      <ColdStorageReductionTable
        coolingEquipmentRecommendations={coolingEquipmentRecommendations}
        currency={currency}
        tariff={tariff}
      />

      <RecommendationText text="Please adjust the settings by the recommended amount in order to optimize use. Use Energybox’s platform to double check if the temperature are within the right range after the adjustments." />

      <ItalicText text={savingsText} />
    </div>
  );
};

export default ColdStorageReduction;
