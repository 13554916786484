import {
  DefaultChartStyles,
  formatCurrencySymbol,
  genericYAxisTickFormatter,
} from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import {
  CartesianGrid,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
} from 'recharts';
import { Props } from '../EnergyUseIntensityTile/EnergyUseIntensityChart';
import CustomXTick from '../ReportChartCustomXTick/ReportChartCustomXTick';
import styles from './NOHEfficiencyLineChart.module.css';


const NOHEfficiencyLineChart = ({
  data,
  dataUnit,
  dataFillColor,
  tooltip,
  timePeriod,
  xDataKey = 'entity',
  yDataKey = 'value',
  showCurrency,
}: Props) => {
  
  return (
    <div className={styles.root}>
      <ResponsiveContainer width={700} height={330}>
        <ComposedChart data={data}>
          <CartesianGrid
            vertical={false}
            stroke={DefaultChartStyles.axisLineColor}
          />
          <Tooltip content={tooltip} />
          <XAxis
            type="category"
            dataKey={xDataKey}
            tick={CustomXTick(data, timePeriod)}
            axisLine={{
              stroke: DefaultChartStyles.axisLineColor,
            }}
            tickLine={false}
            interval={0}
          />
          <YAxis
            type="number"
            domain={[(dataMin) => 0, (dataMax) => 100]}
            tickCount={5}
            tick={{
              fontSize: 16,
              fill: DefaultChartStyles.tick.fill,
            }}
            tickFormatter={genericYAxisTickFormatter}
            unit={'%'}
            axisLine={false}
            tickLine={false}
            width={45}
          />
          <Line
            dataKey={yDataKey}
            unit={dataUnit}
            isAnimationActive={true}
            strokeWidth={2}
            dot={{ fill: 'var(--accent-base)', strokeWidth: 4 }}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  Array.isArray(dataFillColor)
                    ? dataFillColor[index]
                    : dataFillColor
                }
              />
            ))}
          </Line>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default NOHEfficiencyLineChart;
