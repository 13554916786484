import { Site } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import styles from './SiteInfo.module.css';

interface Props {
  site: Site;
}

const SiteInfo = (props: Props) => {
  const { site } = props;

  return (
    <div className={styles.container}>
      <div className={styles.siteTitle}>{site.title}</div>
      <div className={styles.siteAddress}>{site.address || ' '}</div>
    </div>
  );
};

export default SiteInfo;
