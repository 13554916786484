import { Locale } from '@energybox/react-ui-library/dist/types';
import { determineTimeStringByTimezone } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { useAppLocale } from '../../../hooks/useAppDetails';
import styles from './ReportHeading.module.css';

export type HeaderProps = {
  title: string;
  leftColumnContent: LeftColumnContent[];
  rightColumnContent?: RightColumnContent[];
  fromDate?: Date;
  toDate: Date;
  showOnlyToDateMonthText?: boolean;
  showTime?: boolean;
  timezone?: string;
  withSeparator?: boolean;
};

export type LeftColumnContent = {
  fieldName: string;
  fieldValue: string;
};

export type RightColumnContent = {
  fieldName: string;
  fieldValue?: string;
};

const ReportHeading: React.FC<HeaderProps> = ({
  title,
  leftColumnContent,
  rightColumnContent,
  fromDate,
  toDate,
  showOnlyToDateMonthText,
  showTime,
  timezone,
  withSeparator,
}) => {
  const isDoubleColumn = !!leftColumnContent && !!rightColumnContent;
  const locale = useAppLocale();
  const dateText = renderDateText(fromDate, toDate, locale, {
    showOnlyToDateMonthText,
    showTime,
    timezone,
  });

  return (
    <div className={withSeparator ? styles.withSeparator : styles.root}>
      <div className={styles.headerTitleContainer}>
        <div className={styles.headerTitle}>{title}</div>
        <div className={styles.headerSubtitle}>{dateText}</div>
      </div>

      <div
        className={
          isDoubleColumn
            ? styles.doubleColumnContainer
            : styles.singleColumnContainer
        }
      >
        <div
          className={
            isDoubleColumn
              ? styles.doubleColumnEntityContent
              : styles.singleColumnEntityContent
          }
        >
          {leftColumnContent &&
            leftColumnContent.map((field, index) => (
              <React.Fragment key={`leftColumnRow${index}`}>
                <div className={styles.fieldLabel}>{field.fieldName}:</div>
                <div className={styles.fieldValue}>{field.fieldValue}</div>
              </React.Fragment>
            ))}
        </div>
        <div className={styles.doubleColumnEntityContent}>
          {rightColumnContent &&
            rightColumnContent.map((field, index) => (
              <React.Fragment key={`rightColumnRow${index}`}>
                <div className={styles.fieldLabel}>{field.fieldName}:</div>
                <div className={styles.fieldValue}>{field.fieldValue}</div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export const renderDateText = (
  fromDate: Date | undefined,
  toDate: Date,
  locale: Locale,
  options?: {
    showOnlyToDateMonthText?: boolean;
    showTime?: boolean;
    timezone?: string;
  }
) => {
  const { showOnlyToDateMonthText, showTime, timezone } = options || {};

  const format = showOnlyToDateMonthText
    ? date => determineTimeStringByTimezone(date, 'MMMM yyyy', timezone, true)
    : showTime
    ? date =>
        determineTimeStringByTimezone(
          date,
          locale.fullDateTimeFormat,
          timezone,
          true
        )
    : date =>
        determineTimeStringByTimezone(date, locale.dateFormat, timezone, true);

  const formattedFromDate = fromDate ? format(fromDate) : undefined;

  const formattedToDate = format(toDate);

  return `${
    formattedFromDate ? formattedFromDate + ' - ' : ''
  }${formattedToDate}`;
};

export default ReportHeading;
