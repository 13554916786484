import { Equipment } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEquipment, getEquipmentBySiteId } from '../actions/equipment';
import { ApplicationState } from '../reducers';
import { Equipments } from '../reducers/equipment';

const useGetEquipment = (equipmentId: number | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (equipmentId) {
      dispatch(getEquipment(equipmentId));
    }
  }, [equipmentId, dispatch]);

  const equipment = useSelector<ApplicationState, Equipment | undefined>(
    ({ equipment }) => {
      if (!equipmentId) return undefined;
      return equipment.equipmentById[equipmentId];
    }
  );

  return equipment;
};

export const useGetEquipmentBySiteId = (siteId: number | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId) {
      dispatch(getEquipmentBySiteId(siteId));
    }
  }, [siteId, dispatch]);

  const equipmentStore = useSelector<ApplicationState, Equipments>(
    ({ equipment }) => {
      return equipment;
    }
  );

  return equipmentStore;
};

export default useGetEquipment;
