import { Equipment } from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject } from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/equipment';

export type Equipments = {
  isLoading: boolean;
  isLoadingBySiteId: IsLoadingBySiteId;
  equipment: Equipment[];
  equipmentById: EquipmentById;
  equipmentBySiteId: EquipmentBySiteId;
};

type IsLoadingBySiteId = {
  [siteId: string]: boolean;
};

export type EquipmentById = {
  [equipmentId: string]: Equipment;
};

type EquipmentBySiteId = {
  [siteId: string]: Equipment[];
};

const initialState = {
  isLoading: false,
  isLoadingBySiteId: {},
  equipment: [],
  equipmentBySiteId: {},
  equipmentById: {},
};

export default (state: Equipments = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_EQUIPMENT_SUCCESS:
      return R.pipe(
        R.assoc('equipment', [action.payload]),
        R.assocPath(['equipmentById', action.equipmentId], action.payload),
        R.assoc('isLoading', false)
      )(state);

    case Actions.GET_EQUIPMENT_LOADING:
      return R.assoc('isLoading', true, state);

    case Actions.GET_EQUIPMENT_ERROR:
      return R.assoc('isLoading', false, state);

    case Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING:
      return R.assocPath(['isLoadingBySiteId', action.siteId], true, state);

    case Actions.GET_EQUIPMENT_BY_SITE_ID_SUCCESS:
      return R.pipe(
        R.assocPath(['equipmentBySiteId', action.siteId], action.payload),
        R.assoc(
          'equipmentById',
          R.mergeRight(
            mapArrayToObject(action.payload),
            R.view(R.lensProp('equipmentById'), state)
          )
        ),
        R.assocPath(['isLoadingBySiteId', action.siteId], false)
      )(state);

    case Actions.GET_EQUIPMENT_BY_SITE_ID_ERROR:
      return R.assocPath(['isLoadingBySiteId', action.siteId], false, state);

    default:
      return state;
  }
};
