import { FlatNotification } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFlatNotificationsByEquipmentId,
  getFlatNotificationsBySensorId,
} from '../actions/notifications';
import { ApplicationState } from '../reducers';

export const useGetFlatNotificationsByEquipmentId = (
  equipmentId: number,
  from: string | undefined,
  to: string | undefined
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (from && to) {
      dispatch(
        getFlatNotificationsByEquipmentId(equipmentId, {
          equipmentIds: [equipmentId],
          from,
          to,
        })
      );
    }
  }, [equipmentId, from, to, dispatch]);

  const flatNotifications = useSelector<
    ApplicationState,
    FlatNotification[] | undefined
  >(({ notifications }) => {
    return notifications.flatNotificationsByEquipmentId[equipmentId];
  });

  return flatNotifications;
};

export const useGetFlatNotificationsBySensorId = (
  sensorId: number,
  from: string | undefined,
  to: string | undefined
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (from && to) {
      dispatch(
        getFlatNotificationsBySensorId(sensorId, {
          sensorIds: [sensorId],
          from,
          to,
        })
      );
    }
  }, [sensorId, from, to, dispatch]);

  const flatNotifications = useSelector<
    ApplicationState,
    FlatNotification[] | undefined
  >(({ notifications }) => {
    return notifications.flatNotificationsBySensorId[sensorId];
  });

  return flatNotifications;
};
