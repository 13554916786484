import { Sensor, SensorType } from '@energybox/react-ui-library/dist/types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSensorsByEquipmentId,
  getSensorsByEquipmentIdsList,
} from '../actions/sensors';
import { ApplicationState } from '../reducers';
import { SensorsById } from '../reducers/sensors';

enum OperationalSensorTypeOrder {
  'AMBIENT' = 1,
  'TEMPERATURE' = 2,
  'HUMIDITY' = 3,
  'BINARY' = 4,
}

export const useGetSensorsByEquipmentIdsList = (
  equipmentIds: number[] | string[] | undefined
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (equipmentIds && equipmentIds.length > 0) {
      dispatch(getSensorsByEquipmentIdsList(equipmentIds));
    }
  }, [equipmentIds, dispatch]);

  const sensorsById = useSelector<ApplicationState, SensorsById>(
    ({ sensors }) => {
      return sensors.byId;
    }
  );

  return sensorsById;
};

export const useGetSensorsByEquipmentId = (equipmentId: number | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (equipmentId) {
      dispatch(getSensorsByEquipmentId(equipmentId));
    }
  }, [equipmentId, dispatch]);

  const equipmentSensors = useSelector<ApplicationState, Sensor[] | undefined>(
    ({ sensors }) => {
      if (!equipmentId) return undefined;
      return sensors.byEquipmentId[equipmentId];
    }
  );

  //sort sensors by types:
  //ambient => temp => humidity => binary
  const sortedEquipmentSensors = useMemo(() => {
    if (!equipmentSensors) return [];

    return equipmentSensors.sort((s1, s2) => {
      return determineTypeOrder(s1) - determineTypeOrder(s2);
    });
  }, [equipmentSensors]);

  return sortedEquipmentSensors;
};

const determineTypeOrder = (s: Sensor) => {
  if (
    s.types.includes(SensorType.TEMPERATURE) &&
    s.types.includes(SensorType.HUMIDITY)
  ) {
    return OperationalSensorTypeOrder.AMBIENT;
  }
  if (s.types.includes(SensorType.TEMPERATURE)) {
    return OperationalSensorTypeOrder.TEMPERATURE;
  }
  if (s.types.includes(SensorType.HUMIDITY)) {
    return OperationalSensorTypeOrder.HUMIDITY;
  }
  if (s.types.includes(SensorType.BINARY)) {
    return OperationalSensorTypeOrder.BINARY;
  }
  return 0;
};
