import { EnergyLineChart } from '@energybox/react-ui-library/dist/components';
import { HVACLoad, Site } from '@energybox/react-ui-library/dist/types';
import { generateXTicks } from '@energybox/react-ui-library/dist/utils';
import React, { useMemo } from 'react';
import SectionTitle from '../../../components/SectionTitle';
import { useAppLocale } from '../../../hooks/useAppDetails';
import { CHART_WIDTH_FOR_PDF } from '../../../utils/charts';

type Props = {
  site: Site;
  fromDateISO: string;
  toDateISO: string;
  HVACLoadData: HVACLoad;
  equipmentId: number;
};

const StorageReportHVACLoadCharts = ({
  site,
  fromDateISO,
  toDateISO,
  HVACLoadData,
  equipmentId,
}: Props) => {
  const locale = useAppLocale();
  const fromDateTimestampInMs = useMemo(() => {
    return Date.parse(fromDateISO);
  }, [fromDateISO]);

  const toDateTimestampInMs = useMemo(() => {
    return Date.parse(toDateISO);
  }, [toDateISO]);

  const formattedData = useMemo(() => {
    if (!HVACLoadData) return [];

    return HVACLoadData.readings.map(d => ({
      timestamp: d.timestamp * 1000,
      value: d.power,
    }));
  }, [HVACLoadData]);

  const xTicks = useMemo(() => {
    return generateXTicks(fromDateTimestampInMs, toDateTimestampInMs, {
      ianaTimeZone: site.timeZone,
    });
  }, [fromDateTimestampInMs, toDateTimestampInMs]);

  if (!HVACLoadData) {
    return <div />;
  }

  return (
    <div style={{ marginTop: '2rem' }} key={equipmentId}>
      <SectionTitle
        title={`Load for HVAC Unit #${HVACLoadData.equipmentTitle}`}
      />

      {/* Chart width will look too long on localhost, but need this width for pdf to render chart correctly */}
      <div style={{ width: CHART_WIDTH_FOR_PDF }}>
        <EnergyLineChart
          isLoading={false}
          locale={locale}
          ianaTimeZoneCode={site.timeZone}
          fromDate={fromDateTimestampInMs}
          toDate={toDateTimestampInMs}
          ticks={xTicks}
          data={formattedData}
          hideThresholdLinesCheckbox
          hideNotificationsCheckbox
          hideCommentsCheckbox
          //don't need these for this report
          //but need base values
          thresholdLines={[]}
          zoomIn={() => {}}
          refAreaStart={''}
          refAreaEnd={''}
          updateRefAreaStart={() => {}}
          updateRefAreaEnd={() => {}}
        />
      </div>
    </div>
  );
};

export default StorageReportHVACLoadCharts;
