import { useRef } from "react";
import styles from './EnergyTrackerReportPage.module.css';
import EnergyTrackerReportHeading from "../EnergyTrackerReportHeading/EnergyTrackerReportHeading";
// import ReportHeading from "../ReportHeading";
import ReportMeasurableElement from "../ReportMeasurableElement";
import ReportPageBreakControl from "../ReportPageBreakControl";
import {
  formatNumberString,
  global,
  isDefined,
  sortAlphabetically,
} from '@energybox/react-ui-library/dist/utils';
import { MiniTile } from '@energybox/react-ui-library/dist/components';
import EnergyUseIntensityTile from "../EnergyUseIntensityTile/EnergyUseIntensityTile";
import EnergyOffenderTile from "../EnergyOffenderTile/EnergyOffenderTile";
import SitesPerformanceTile from "../SitesPerformanceTile/SitesPerformanceTile";
import HorizontalDivider from "../../../components/HorizontalDivider";
import { formatNumber } from "@energybox/react-ui-library/dist/utils/number";
import NonOperatingHourEfficiencyTile from "../NonOperatingHourEfficiencyTile/NonOperatingHourEfficiencyTile";
import NOHEfficiencyTile from "../NOHEfficiencyLineChart/NOHEfficiencyTile";
import NOHEfficiencySummaryTile from "../NOHEfficiencySummaryTile/NOHEfficiencySummaryTile";
import NOHEfficiencyLowestSites from "../NOHEfficiencyLowestSites/NOHEfficiencyLowestSites";

type Props = {
  title: string | undefined;
  date: Date;
  eui: {
    data: {
      eui_diff_month: number;
      eui_diff_year:number;
      month: string;
      offender: {
        num_of_non_offender: number;
        num_of_offender: number;
        percent_non_offender: number;
        percent_offender: number;
      };
      org_monthly_eui: {
        [date: string]: {
          value: number;
          data_comp: number;
          status: boolean;
        };
      };
      org_name: string;
      repo_year: string;
      site_diff: number;
      site_eui: {
        [siteId: number]: {
          site_title: string;
          site_eui_existing: number;
          monthly_eui: {
            [date: string]: number;
          };
          site_eui_est: {
            eui_est: number;
            eui_status: string;
          };
        };
      };
      site_monitored: number;
      target: number;
      this_month_eui: number;
      year_eui_est_diff: number | undefined;
      yearly_eui_est: number;
      yearly_eui_existing: number;
      yearly_eui_existing_diff: number;
    };
    reportKey: string;
    isLoading: boolean;
    isReportReady: boolean;
    targetLevel: number;
  } | undefined;
  co2: {
    data: {
      org_co2_total: number;
      site_dict: {
        [siteId: number]: {
          site_title: string;
          site_total: number;
          co2_factor: number;
          monthly_dict: {
            [date: string]: number | undefined;
          };
        }
      };
    };
    isLoading: boolean;
    isReportReady: boolean;
  } | undefined;
  energy: {
    data: {
      org_energy_total: number;
      repo_year: string;
    },
    isLoading: boolean,
    isReportReady: boolean,
  } | undefined;
  orgNOH: {
    data: any,
    isLoading: boolean,
    isReportReady: boolean,
  };
  monthlyNOH: {
    data: any,
    isLoading: boolean,
    isReportReady: boolean,
  } | undefined;
};

const EnergyTrackerReportPage: React.FC<Props> = ({ eui, co2, energy, orgNOH, monthlyNOH, date, title }) => {
  const reportYear = date.getFullYear();
  const headerRef = useRef(null);
  const newPageHeaderRef = useRef(null);
  const miniTileRef = useRef(null);
  const euiAndOffenderRef = useRef(null);
  const bestAndWorstRef = useRef(null);
  const nohEfficiencyRef = useRef(null);
  const nohEfficiencyTileRef = useRef(null);
  const nohEfficiencyLowestRef = useRef(null);
  const { data: euiReportData, isLoading: isEUILoading, isReportReady: isEUIReady, targetLevel } = eui || {};
  const {
    org_name = '',
    repo_year = 0,
    month = '',
    site_monitored = 0,
    site_diff = 0,
    target = 0,
    yearly_eui_existing = 0,
    yearly_eui_existing_diff = 0,
    this_month_eui = 0,
    eui_diff_month = 0,
    eui_diff_year = 0,
    yearly_eui_est = 0,
    year_eui_est_diff = 0,
    offender = {
      num_of_non_offender: 0,
      num_of_offender: 0,
      percent_non_offender: 0,
      percent_offender: 0,
    },
    site_eui = {},
  } = euiReportData || {};
  const {
    data: co2ReportData,
    isLoading: isCO2Loading = false,
    isReportReady: isCO2Ready = false,
  } = co2 || {};
  const { org_co2_total = 0, site_dict = {} } = co2ReportData || {};
  const {
    data: energyReportData,
    isLoading: isEnergyLoading = false,
    isReportReady: isEnergyReady = false,
  } = energy || {};
  const { org_energy_total = 0 } = energyReportData || {};

  const miniTilesData = [
    {
      title: 'Sites Monitored',
      value: site_monitored || '-',
      // diffValue: [{ diffFrom: 'month', value: site_diff }],
    },
    {
      title: 'Energy Consumption (YTD)',
      value: org_energy_total ? formatNumber(org_energy_total, 1) : '-',
      unit: 'kWh',
    },
    {
      title: 'CO2 Emission (YTD)',
      value: org_co2_total ? formatNumber(org_co2_total, 1) : '-',
      unit: 'kg',
    },
    {},
    {},
    {},
    {
      title: 'EUI (This month)',
      value: this_month_eui ? formatNumber(this_month_eui, 1) : '-',
      unit: 'kWh/m2',
      diffValue: [{ diffFrom: 'month', value: eui_diff_month, suffix: '%' }, { diffFrom: 'year', value: eui_diff_year, suffix: '%' }],
    },
    {
      title: 'EUI (YTD)',
      value: yearly_eui_existing ? formatNumber(yearly_eui_existing, 1) : '-',
      unit: 'kWh/m2',
      diffValue: [{ diffFrom: 'year', value: yearly_eui_existing_diff, suffix: '%' }],
    },
    {
      title: 'Estimated EUI',
      value: yearly_eui_est ? formatNumber(yearly_eui_est, 1) : '-',
      unit: 'kWh/m2',
      diffValue: [{ diffFrom: 'year', value: year_eui_est_diff, suffix: '%' }],
    },
  ];

  const leftHeaderInfoColumn = [
    {
      fieldName: 'Organization Name:',
      fieldValue: org_name || title || global.NOT_AVAILABLE,
    },
    // { fieldName: 'Address', fieldValue: 'Address' }
  ];
  
  const rightHeaderInfoColumn = [{
    fieldName: 'Annual EUI Target',
    fieldValue: `${targetLevel || global.NOT_AVAILABLE} kWh/m2`,
  }];

  const headerContent = (
    <ReportMeasurableElement setRef={headerRef} key="heading">
      <EnergyTrackerReportHeading
        title="Energy Performance Report"
        leftColumnContent={leftHeaderInfoColumn}
        rightColumnContent={rightHeaderInfoColumn}
        reportDate={date}
      />
    </ReportMeasurableElement>
  );
  
  const newPageHeaderContent = (
    <ReportMeasurableElement setRef={newPageHeaderRef} key="heading">
      <EnergyTrackerReportHeading
        title="Energy Performance Report"
        leftColumnContent={[]}
        reportDate={date}
      />
    </ReportMeasurableElement>
  );

  return (
    <ReportPageBreakControl
      fetchedStatuses={isEUIReady && isCO2Ready && isEnergyReady}
      headerForNewPages={newPageHeaderContent}
      headerRef={headerRef}
    >
      {headerContent}

      <ReportMeasurableElement setRef={miniTileRef} key="mini-tiles">
        <div style={{ display: 'grid', grid: 'auto-flow / 1fr 1fr 1fr' }}>
          {miniTilesData.map((tileData, index) => {
            const { title, value, unit, diffValue } = tileData;
            if (title !== undefined && value !== undefined) {
              return (<MiniTile
                className={styles.miniTile}
                key={`miniTile${index + 1}`}
                title={title}
                value={value}
                unit={unit}
                diff={diffValue}
                pdf={true}
              />);
            } else {
              return (<div key={`miniTile${index + 1}`} className={styles.miniTile}></div>);
            }
          })}
        </div>
      </ReportMeasurableElement>

      <ReportMeasurableElement setRef={euiAndOffenderRef} key="eui-offender">
        <div style={{ display: 'grid', grid: 'auto-flow / 1fr 1fr', marginTop: '6rem'}}>
          <div>
            <EnergyUseIntensityTile
              data={euiReportData?.org_monthly_eui}
              isLoading={isEUILoading || false}
            />
          </div>
          <div>
            <EnergyOffenderTile data={offender} isLoading={isEUILoading || false} />
          </div>
        </div>
      </ReportMeasurableElement>

      <HorizontalDivider />

      <ReportMeasurableElement setRef={bestAndWorstRef} key="best-worst">
        <div style={{ marginTop: '2rem' }}>
          <SitesPerformanceTile
            className={styles.doubleTile}
            data={site_eui}
            isLoading={isEUILoading || false}
            selectedYear={reportYear}
          />
        </div>
      </ReportMeasurableElement>

      <ReportMeasurableElement setRef={nohEfficiencyRef} key="noh-efficiency">
        <NonOperatingHourEfficiencyTile
          data={orgNOH}
        />
      </ReportMeasurableElement>

      <HorizontalDivider />

      <ReportMeasurableElement setRef={nohEfficiencyTileRef} key="noh-efficiency-tile">
        <div className={styles.twoColumns}>
          <NOHEfficiencyTile data={monthlyNOH?.data} isLoading={monthlyNOH?.isLoading} />
          <NOHEfficiencySummaryTile nohEfficiency={orgNOH?.data?.by_org?.noh_efficiency} data={monthlyNOH?.data} isLoading={monthlyNOH?.isLoading} />
        </div>
      </ReportMeasurableElement>

      <HorizontalDivider />

      <ReportMeasurableElement setRef={nohEfficiencyLowestRef} key="noh-efficiency-lowest">
        <NOHEfficiencyLowestSites
          orgData={orgNOH?.data}
          monthlyData={monthlyNOH?.data}
          isLoading={orgNOH?.isLoading && monthlyNOH?.isLoading}
        />
      </ReportMeasurableElement>

    </ReportPageBreakControl>
  );
};

export default EnergyTrackerReportPage;
