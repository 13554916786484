import { Certification } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions } from '../actions/certifications';

export type Certifications = {
  isLoadingBySensorId: IsLoadingBySensorId;
  isLoadingBySiteId: IsLoadingBySiteId;
  bySensorId: CertificationsBySensorId;
};

export type IsLoadingBySensorId = {
  [sensorId: string]: boolean;
};

export type CertificationsBySensorId = {
  [sensorId: string]: Certification[];
};

export type IsLoadingBySiteId = {
  [sensorId: string]: boolean;
};

const initialState = {
  isLoadingBySensorId: {},
  isLoadingBySiteId: {},
  bySensorId: {},
};

export default (state: Certifications = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_CERTIFICATION_BY_SENSOR_ID_SUCCESS:
      return R.pipe(
        R.assocPath(['bySensorId', action.sensorId], action.payload),
        R.assocPath(['isLoadingBySensorId', action.sensorId], false)
      )(state);

    case Actions.GET_CERTIFICATION_BY_SENSOR_ID_LOADING:
      return R.assocPath(['isLoadingBySensorId', action.sensorId], true, state);

    case Actions.GET_CERTIFICATION_BY_SENSOR_ID_ERROR:
      return R.assocPath(
        ['isLoadingBySensorId', action.sensorId],
        false,
        state
      );

    case Actions.SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_SUCCESS:
      return R.assocPath(['isLoadingBySiteId', action.siteId], false, state);

    case Actions.SET_SITE_TEMP_CHECK_REPORT_CERT_FETCH_LOADING:
      return R.assocPath(['isLoadingBySiteId', action.siteId], true, state);

    default:
      return state;
  }
};
