import { Site } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import SectionTitle from '../../../components/SectionTitle';
import { ReportTimeFormats } from '../../../hooks/utils';
import EnergyReportWeatherAndEnergyChartContainer from '../../../views/report/EnergyReportWeatherAndEnergyChartContainer';
import GenericReportConsumptionInfoContainer from '../../../views/report/GenericReportConsumptionInfoContainer';
import GenericReportMonthlyConsumptionInfoContainer from '../../../views/report/GenericReportMonthlyConsumptionInfoContainer';
import styles from './WeatherAndEnergyConsumptionContainer.module.css';

type Props = {
  site: Site;
  reportTimeFormats: ReportTimeFormats;
};

const WeatherAndEnergyConsumptionContainer: React.FC<Props> = ({
  site,
  reportTimeFormats,
}) => {
  const {
    fromDateInBrowserTimezone: fromDate,
    toDateInBrowserTimezone: toDate,
    reportDifferenceInDays: reportNumberOfDays,
    prevFromDateString,
    prevToDateString,
  } = reportTimeFormats;

  return (
    <div className={styles.content}>
      <div className={styles.headerContainer}>
        <div className={styles.headerText}>
          <SectionTitle title="Energy Consumption" />
        </div>
      </div>

      <GenericReportConsumptionInfoContainer
        site={site}
        fromDate={fromDate}
        toDate={toDate}
        reportNumberOfDays={reportNumberOfDays}
        prevFromDateString={prevFromDateString}
        prevToDateString={prevToDateString}
      />
      <EnergyReportWeatherAndEnergyChartContainer
        hideSectionTitle
        site={site}
        toDate={toDate}
      />
      <GenericReportMonthlyConsumptionInfoContainer
        site={site}
        toDate={toDate}
      />
    </div>
  );
};

export default WeatherAndEnergyConsumptionContainer;
