import * as R from 'ramda';
import { Actions } from '../actions/weather';
import { WeatherDataForChart } from '../types/weather';
import { WeatherData } from '@energybox/react-ui-library/dist/types';

const initialState = {
  weatherBySiteId: {},
  siteWeatherOverWeeks: [],
};

export interface Weather {
  weatherBySiteId: WeatherBySiteId;
  siteWeatherOverWeeks: WeatherDataForChart[];
}

export type WeatherBySiteId = {
  [siteId: string]: WeatherData;
};

const weather = (state: Weather = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SITE_WEATHER_OVER_WEEKS_SUCCESS:
      return R.pipe(R.assoc('siteWeatherOverWeeks', action.payload))(state);

    case Actions.GET_WEATHER_BY_SITE_ID_SUCCESS:
      return R.pipe(
        R.assocPath(['weatherBySiteId', action.siteId], action.payload),
        R.assoc('isLoading', false)
      )(state);

    case Actions.GET_WEATHER_BY_SITE_ID_ERROR:
      return R.assoc('isLoading', false, state);

    case Actions.GET_WEATHER_BY_SITE_ID_LOADING:
      return R.assoc('isLoading', true, state);

    default:
      return state;
  }
};

export default weather;
