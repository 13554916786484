import {
  Table,
  TableColumns,
} from '@energybox/react-ui-library/dist/components';
import { ColdStorageRecommendations } from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import reportStyles from '../../../views/report/ReportPage.module.css';
import TemperatureCell from '../../TemperatureCell';

type Props = {
  coolingEquipmentRecommendations: ColdStorageRecommendations[];
  currency?: string;
  tariff?: number;
};

const displayTimeOverCooled = (percent?: number) => {
  if (!percent) return global.NOT_AVAILABLE;
  return `${percent.toFixed(2)}%`;
};

const ColdStorageReductionTable = ({
  coolingEquipmentRecommendations,
  currency,
  tariff,
}: Props) => {
  if (coolingEquipmentRecommendations.length === 0) return null;

  const savingsHeader =
    !currency || !tariff
      ? 'Estimated Savings*'
      : `Estimated Savings* (${currency})`;

  const getSavingsValue = (savings: number) =>
    !tariff ? global.NOT_AVAILABLE : (tariff * savings).toFixed(2);

  const columns: TableColumns<ColdStorageRecommendations>[] = [
    {
      header: <div className={reportStyles.tableHeader}>#</div>,
      cellContent: (row: ColdStorageRecommendations) => (
        <div className={reportStyles.tableCell}>{row.idx}</div>
      ),
    },
    {
      header: <div className={reportStyles.tableHeader}>Equipment</div>,
      width: '33%',
      cellContent: (row: ColdStorageRecommendations) => (
        <div className={reportStyles.tableCell}>{row.equipmentName}</div>
      ),
    },
    {
      header: <div className={reportStyles.tableHeader}>Time Over-Cooled</div>,
      align: 'right',
      cellContent: (row: ColdStorageRecommendations) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
        >
          {displayTimeOverCooled(row.timeOverCooled)}
        </div>
      ),
    },
    {
      header: (
        <div className={reportStyles.tableHeader}>Settings Adjustment</div>
      ),
      align: 'right',
      cellContent: (row: ColdStorageRecommendations) => (
        <TemperatureCell
          displayTemperatureDifference
          temperatureInCelsius={row.settingsAdjustment}
        />
      ),
    },
    {
      header: <div className={reportStyles.tableHeader}>{savingsHeader}</div>,
      align: 'right',
      cellContent: (row: ColdStorageRecommendations) => (
        <div
          className={`${reportStyles.tableCell} ${reportStyles.tableCellRightAlign}`}
        >
          {getSavingsValue(row.savings)}
        </div>
      ),
    },
  ];

  return (
    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <Table
        highlightAlternateRows
        columns={columns}
        data={coolingEquipmentRecommendations.slice(0, 5)}
      />
    </div>
  );
};

export default ColdStorageReductionTable;
