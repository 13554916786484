import {
  DoorEntries,
  Periods,
  SensorsBySiteId,
  Site,
  GetOperationalSamplesParams,
} from '@energybox/react-ui-library/dist/types';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {
  getDoorEntriesOverTimeBySensorId,
  noDoorEntriesToFetch,
} from '../../../actions/operationalSamples';
import {getSensorsBySiteId} from '../../../actions/sensors';
import ItalicText from '../../../components/ItalicText';
import {SF_SITE_TO_LOCKERS} from '../../../constants/storeFriendlySiteIdToLockers';
import {ReportTimeFormats} from '../../../hooks/utils';
import {DoorEntriesFetchStatus} from '../../../reducers/storageReport';
import StorageReportDoorEntriesGraphs from './StorageReportDoorEntriesGraphs';
import StorageReportDoorStats from './StorageReportDoorStats';
import {DateTime} from "luxon";

type Props = {
  site: Site;
  reportTimeFormats: ReportTimeFormats;
  sensorsBySiteId: SensorsBySiteId;
  getSensorsBySiteId: (siteId: number) => void;
  currentOrganizationId?: number;
  getDoorEntriesOverTimeBySensorId: (
    params: GetOperationalSamplesParams,
    period: Periods
  ) => void;
  noDoorEntriesToFetch: (period: Periods) => void;
  doorEntries: DoorEntries;
  fetchedDoorEntries: DoorEntriesFetchStatus;
  loadingDoorEntries: DoorEntriesFetchStatus;
  errorCurrentDoorEntries: boolean;
  errorPrevDoorEntries: boolean;
};

const StorageReportDoorsContainer = ({
  site,
  reportTimeFormats,
  sensorsBySiteId,
  getSensorsBySiteId,
  currentOrganizationId,
  getDoorEntriesOverTimeBySensorId,
  noDoorEntriesToFetch,
  doorEntries,
  fetchedDoorEntries,
  loadingDoorEntries,
  errorCurrentDoorEntries,
  errorPrevDoorEntries,
}: Props) => {
  const {
    fromDateUnixTimestamp,
    toDateUnixTimestamp,
    prevFromDateUnixTimestamp,
    prevToDateUnixTimestamp,
    reportDifferenceInDays,
  } = reportTimeFormats;

  useEffect(() => {
    if (!!site) {
      getSensorsBySiteId(site.id);
    }
  }, [site, getSensorsBySiteId]);

  useEffect(() => {
    if (
      !!site &&
      !!sensorsBySiteId[site.id] &&
      !loadingDoorEntries.currentPeriod &&
      !fetchedDoorEntries.currentPeriod
    ) {
      const doorSensors = sensorsBySiteId[site.id].filter(
        sensor => sensor.types[0] === 'BINARY'
      );
      if (
        doorSensors.length > 0 &&
        !!fromDateUnixTimestamp &&
        !!toDateUnixTimestamp
      ) {
        const params: GetOperationalSamplesParams = {
          sensorId: doorSensors[0].id.toString(),
          from: DateTime.fromSeconds(Math.trunc(fromDateUnixTimestamp)).toUTC().toISO(),
          to: DateTime.fromSeconds(Math.trunc(toDateUnixTimestamp)).toUTC().toISO(),
        }
        getDoorEntriesOverTimeBySensorId(
          params,
          Periods.CURRENT_PERIOD
        );
      } else {
        noDoorEntriesToFetch(Periods.CURRENT_PERIOD);
      }
    }
  }, [
    sensorsBySiteId,
    fromDateUnixTimestamp,
    toDateUnixTimestamp,
    site,
    getDoorEntriesOverTimeBySensorId,
    fetchedDoorEntries.currentPeriod,
    loadingDoorEntries.currentPeriod,
    noDoorEntriesToFetch,
  ]);

  useEffect(() => {
    if (
      !!site &&
      !!sensorsBySiteId[site.id] &&
      !loadingDoorEntries.prevPeriod &&
      !fetchedDoorEntries.prevPeriod
    ) {
      const doorSensors = sensorsBySiteId[site.id].filter(
        sensor => sensor.types[0] === 'BINARY'
      );
      if (
        doorSensors.length > 0 &&
        !!prevFromDateUnixTimestamp &&
        !!prevToDateUnixTimestamp
      ) {
        const params: GetOperationalSamplesParams = {
          sensorId: doorSensors[0].id.toString(),
          from: DateTime.fromSeconds(Math.trunc(prevFromDateUnixTimestamp)).toUTC().toISO(),
          to: DateTime.fromSeconds(Math.trunc(prevToDateUnixTimestamp)).toUTC().toISO()
        }
        getDoorEntriesOverTimeBySensorId(
          params,
          Periods.PREV_PERIOD
        );
      } else {
        noDoorEntriesToFetch(Periods.PREV_PERIOD);
      }
    }
  }, [
    sensorsBySiteId,
    fromDateUnixTimestamp,
    toDateUnixTimestamp,
    site,
    fetchedDoorEntries.prevPeriod,
    getDoorEntriesOverTimeBySensorId,
    loadingDoorEntries.prevPeriod,
    noDoorEntriesToFetch,
  ]);

  const siteHasStorageLockers = !!SF_SITE_TO_LOCKERS[site.id];
  const STORE_FRIENDLY_ORGANIZATION = 15778;
  const isStoreFriendlyOrg =
    currentOrganizationId === STORE_FRIENDLY_ORGANIZATION;

  const renderStorageLockerText = () => {
    if (isStoreFriendlyOrg && !siteHasStorageLockers) {
      return (
        <div style={{marginTop: '1rem'}}>
          <ItalicText text="* Please contact your Energybox representative to register the number of storage lockers to enable calculation" />
        </div>
      );
    }
    return <div/>;
  };

  if (
    (!fetchedDoorEntries.currentPeriod && !fetchedDoorEntries.prevPeriod) ||
    errorCurrentDoorEntries ||
    errorPrevDoorEntries
  ) {
    return <div />;
  }

  return (
    <div style={{paddingTop: '2rem'}}>
      <StorageReportDoorStats
        isStoreFriendlyOrg={isStoreFriendlyOrg}
        doorEntries={doorEntries.currentPeriod.length}
        prevDoorEntries={doorEntries.prevPeriod.length}
        numberOfDays={reportDifferenceInDays || 0}
        site={site}
      />

      <StorageReportDoorEntriesGraphs
        entriesOverTime={doorEntries.currentPeriod}
        numberOfDays={reportDifferenceInDays || 0}
      />

      {renderStorageLockerText()}
    </div>
  );
};

const mapStateToProps = state => ({
  sensorsBySiteId: state.sensorsBySiteId,
  currentOrganizationId: state.app.currentOrganizationId,
  doorEntries: state.operationalSamples.doorEntries,
  fetchedDoorEntries: state.storageReport.fetched.doorEntries,
  loadingDoorEntries: state.storageReport.loading.doorEntries,
  errorCurrentDoorEntries: state.storageReport.errors.doorEntries.currentPeriod,
  errorPrevDoorEntries: state.storageReport.errors.doorEntries.prevPeriod,
});

export default connect(mapStateToProps, {
  getSensorsBySiteId,
  getDoorEntriesOverTimeBySensorId,
  noDoorEntriesToFetch,
})(StorageReportDoorsContainer);
