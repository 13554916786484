import { Calendar } from '@energybox/react-ui-library/dist/components';
import { usePrevious } from '@energybox/react-ui-library/dist/hooks';

import { endOfDay, format, parse, startOfDay, subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { DateFilterTimePeriod } from '../../../reducers/dateFilter';

type Props = {
  setDateRange: (fromDate: Date | null, toDate: Date | null) => void;
  limitRangeSelection: number;
  presetDates?: DateFilterTimePeriod;
};

const CalendarFilter = ({
  setDateRange,
  limitRangeSelection,
  presetDates,
}: Props) => {
  const today = new Date();
  const [fromDate, setFromDate] = useState<Date | null>(
    presetDates ? presetDates.fromDate : startOfDay(subDays(today, 1))
  );
  const [toDate, setToDate] = useState<Date | null>(
    presetDates ? presetDates.toDate : endOfDay(today)
  );

  const prevPresetDates = usePrevious<DateFilterTimePeriod | undefined>(
    presetDates
  );
  useEffect(() => {
    if (presetDates && prevPresetDates !== presetDates) {
      setFromDate(presetDates.fromDate);
      setToDate(presetDates.toDate);
    }
  }, [presetDates, prevPresetDates]);

  return (
    <Calendar
      from={fromDate ? format(fromDate, 'yyyy-MM-dd') : undefined}
      to={toDate ? format(toDate, 'yyyy-MM-dd') : undefined}
      limitRangeSelection={limitRangeSelection}
      onChange={({ from, to }: { from?: string; to?: string }) => {
        // Calendar returns dates as 'yyyy-MM-dd' strings
        const fromDate = from
          ? startOfDay(parse(from, 'yyyy-MM-dd', new Date()))
          : null;
        const toDate = to
          ? endOfDay(parse(to, 'yyyy-MM-dd', new Date()))
          : null;
        setFromDate(fromDate);
        setToDate(toDate);
        setDateRange(fromDate, toDate);
      }}
    />
  );
};

export default CalendarFilter;
