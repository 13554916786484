const apiBase = '/api/v1/report';

export enum Actions {
  GET_REPORTS_LOADING = '@report/GET_REPORTS_LOADING',
  GET_REPORTS_SUCCESS = '@report/GET_REPORTS_SUCCESS',
  GET_REPORTS_ERROR = '@report/GET_REPORTS_ERROR',

  GET_REPORT_LOADING = '@report/GET_REPORT_LOADING',
  GET_REPORT_SUCCESS = '@report/GET_REPORT_SUCCESS',
  GET_REPORT_ERROR = '@report/GET_REPORT_ERROR',

  GET_REPORTS_BY_SITE_ID_LOADING = '@report/GET_REPORTS_BY_SITE_ID_LOADING',
  GET_REPORTS_BY_SITE_ID_SUCCESS = '@report/GET_REPORTS_BY_SITE_ID_SUCCESS',
  GET_REPORTS_BY_SITE_ID_ERROR = '@report/GET_REPORTS_BY_SITE_ID_ERROR',

  GENERATE_REPORT_LOADING = '@report/GENERATE_REPORT_LOADING',
  GENERATE_REPORT_SUCCESS = '@report/GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_ERROR = '@report/GENERATE_REPORT_ERROR',
}

export const getReportsBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}/organization-unit/${siteId}`,
  success: { type: Actions.GET_REPORTS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_REPORTS_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_REPORTS_BY_SITE_ID_LOADING, siteId },
});

export const getReportById = (reportId: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}/${reportId}`,
  success: { type: Actions.GET_REPORT_SUCCESS, reportId },
  error: { type: Actions.GET_REPORT_ERROR, reportId },
  loading: { type: Actions.GET_REPORT_LOADING, reportId },
});

export const generateReportById = (
  reportId: number | string,
  date: string
) => ({
  type: 'API_GET',
  path: `${apiBase}/generate/${reportId}/${date}`,
  success: { type: Actions.GENERATE_REPORT_SUCCESS, reportId, date },
  error: { type: Actions.GENERATE_REPORT_ERROR, reportId, date },
  loading: { type: Actions.GENERATE_REPORT_LOADING, reportId, date },
});
