import React from 'react';
import { Chart } from 'react-google-charts';

const colors = [
  '#397d9e',
  '#66bf75',
  '#ffda66',
  '#ff9248',
  '#ff6f9c',
  '#626dfa',
  '#9665bd',
];

type Props = {
  className?: string;
  data?: any;
  report?: boolean;
};

const inlineStyles = {
  labelStyle: {
    color: 'var(--medium-base)',
    fontSize: '1rem',
  },
};

const SankeyChart = ({ className, data, report }: Props) => {
  const sankeyFormattedData = [['From', 'To', 'Amount']];
  if (!!data) {
    data.forEach(dataPoint => {
      sankeyFormattedData.push([
        dataPoint.spaceName,
        dataPoint.groupName,
        dataPoint.energyTotal,
      ]);
      sankeyFormattedData.push([
        dataPoint.groupName,
        dataPoint.typeName,
        dataPoint.energyTotal,
      ]);
    });
  }
  return (
    <div
      className={className}
      style={{ marginTop: '4rem', marginBottom: '4rem' }}
    >
      <div className="sankey-charts">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '0.5rem',
          }}
        >
          <div style={inlineStyles.labelStyle}>Room</div>
          <div
            style={{
              ...inlineStyles.labelStyle,
              transform: 'translate(45%, 0)',
            }}
          >
            Equipment Group
          </div>
          <div style={inlineStyles.labelStyle}>Equipment Type</div>
        </div>

        <div style={{ pointerEvents: 'none' }}>
          <Chart
            width={'1440px'}
            height={'600px'}
            chartType="Sankey"
            loader={<div>Loading Chart</div>}
            options={{
              sankey: {
                iterations: 64,
                showTooltip: false,
                link: {
                  colors: colors,
                  colorMode: 'gradient',
                },
                node: {
                  colors: colors,
                  label: { color: 'black', fontSize: 16 },
                },
              },
              tooltip: { isHTML: true, legend: 'none' },
            }}
            data={sankeyFormattedData}
            rootProps={{ 'data-testid': '2' }}
          />
        </div>
      </div>
    </div>
  );
};

export default SankeyChart;
