import {
  Actions as EnergyActions,
  skipEquipmentReportEnergy,
} from '../actions/energy';
import { Actions as TSEnergyActions } from '../actions/energyTS';
import { Actions as SensorActions } from '../actions/sensors';
import { Actions as NotificationActions } from '../actions/notifications';
import {
  Actions as CertActions,
  skipEquipmentReportCertifications,
} from '../actions/certifications';
import {
  Actions as SampleActions,
  skipEquipmentReportOperationalSamples,
} from '../actions/operationalSamples';

const equipmentReportMiddleware = store => next => action => {
  switch (action.type) {
    case NotificationActions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_ERROR:
    case NotificationActions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS:
    case NotificationActions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR:
    case NotificationActions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS: {
      const isLoadingByResourceId = {
        ...store.getState().notifications.isLoadingByResourceId,
      };

      const updatedIsLoadingBySensorId = {
        ...isLoadingByResourceId,
        [action.sensorId || action.equipmentId || -1]: false,
      };

      const isDoneLoading = Object.values(updatedIsLoadingBySensorId).every(
        v => v === false
      );

      if (isDoneLoading) {
        const nextActionType =
          action.type ===
            NotificationActions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS ||
          action.type ===
            NotificationActions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS
            ? NotificationActions.EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_SUCCESS
            : NotificationActions.EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_ERROR;

        store.dispatch({
          type: nextActionType,
        });
      } else {
        store.dispatch({
          type:
            NotificationActions.EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_LOADING,
        });
      }
      return next(action);
    }

    case NotificationActions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_LOADING:
    case NotificationActions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING: {
      store.dispatch({
        type:
          NotificationActions.EQUIPMENT_SUMMARY_REPORT_NOTIFICATIONS_LOADING,
      });
      return next(action);
    }

    case CertActions.GET_CERTIFICATION_BY_SENSOR_ID_LOADING: {
      store.dispatch({
        type: CertActions.EQUIPMENT_REPORT_CERTIFICATIONS_LOADING,
      });
      return next(action);
    }

    case CertActions.GET_CERTIFICATION_BY_SENSOR_ID_SUCCESS:
    case CertActions.GET_CERTIFICATION_BY_SENSOR_ID_ERROR: {
      const isLoadingBySensorId = {
        ...store.getState().certifications.isLoadingBySensorId,
      };
      const updatedIsLoadingBySensorId = {
        ...isLoadingBySensorId,
        [action.sensorId]: false,
      };

      const isDoneLoading = Object.values(updatedIsLoadingBySensorId).every(
        v => v === false
      );

      if (isDoneLoading) {
        const nextActionType =
          action.type === CertActions.GET_CERTIFICATION_BY_SENSOR_ID_SUCCESS
            ? CertActions.EQUIPMENT_REPORT_CERTIFICATIONS_SUCCESS
            : CertActions.EQUIPMENT_REPORT_CERTIFICATIONS_ERROR;

        store.dispatch({
          type: nextActionType,
        });
      } else {
        store.dispatch({
          type: CertActions.EQUIPMENT_REPORT_CERTIFICATIONS_LOADING,
        });
      }
      return next(action);
    }

    case SensorActions.SENSORS_BY_EQUIPMENT_ID_SUCCESS: {
      if (!action.payload || action.payload.length === 0) {
        store.dispatch(skipEquipmentReportOperationalSamples());
        store.dispatch(skipEquipmentReportCertifications());
      }
      return next(action);
    }

    case SensorActions.SENSORS_BY_EQUIPMENT_ID_ERROR: {
      store.dispatch(skipEquipmentReportOperationalSamples());
      store.dispatch(skipEquipmentReportCertifications());
      return next(action);
    }

    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS:
    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_SUCCESS:
    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_ERROR:
    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_ERROR: {
      const isLoadingByResourceId = {
        ...store.getState().energy.isLoadingByResourceId,
      };
      const updatedIsLoadingByResourceId = {
        ...isLoadingByResourceId,
        [action.equipmentId]: false,
      };

      const isDoneLoading = Object.values(updatedIsLoadingByResourceId).every(
        v => v === false
      );

      if (isDoneLoading) {
        const nextActionType =
          action.type === TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS ||
          EnergyActions.ENERGY_BY_EQUIPMENT_ID_SUCCESS
            ? EnergyActions.EQUIPMENT_REPORT_ENERGY_SUCCESS
            : EnergyActions.EQUIPMENT_REPORT_ENERGY_ERROR;
        store.dispatch({
          type: nextActionType,
        });
      } else {
        store.dispatch({
          type: EnergyActions.EQUIPMENT_REPORT_ENERGY_LOADING,
        });
      }
      return next(action);
    }

    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_LOADING:
    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_LOADING: {
      store.dispatch({
        type: EnergyActions.EQUIPMENT_REPORT_ENERGY_LOADING,
      });
      return next(action);
    }

    case SampleActions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS:
    case SampleActions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR: {
      const isLoadingBySensorId = {
        ...store.getState().operationalSamples.isLoadingBySensorId,
      };
      const updatedIsLoadingBySensorId = {
        ...isLoadingBySensorId,
        [action.sensorId]: false,
      };

      const isDoneLoading = Object.values(updatedIsLoadingBySensorId).every(
        v => v === false
      );

      if (isDoneLoading) {
        const nextActionType =
          action.type ===
          SampleActions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS
            ? SampleActions.EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_SUCCESS
            : SampleActions.EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_ERROR;

        store.dispatch({
          type: nextActionType,
        });
      } else {
        store.dispatch({
          type: SampleActions.EQUIPMENT_REPORT_OPERATIONAL_SAMPLES_LOADING,
        });
      }
      return next(action);
    }

    case EnergyActions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_SUCCESS: {
      if (!action.payload || action.payload.length === 0) {
        store.dispatch(skipEquipmentReportEnergy());
      }
      return next(action);
    }

    case EnergyActions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR: {
      store.dispatch(skipEquipmentReportEnergy());
      return next(action);
    }

    default:
      return next(action);
  }
};

export default equipmentReportMiddleware;
