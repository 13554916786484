import { ResourceType } from '@energybox/react-ui-library/dist/types';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/sops';
import {
  DoorOpenDurationSop,
  EnergyTariff,
  HumiditySop,
  Sop,
  SopTypes,
  TemperatureSop,
} from '@energybox/react-ui-library/dist/types';

export type SopsById = {
  [id: string]: Sop;
};

export type EnergyTariffByResourceId = {
  [id: string]: {
    sop?: EnergyTariff | null;
    isLoading?: boolean;
  };
};

export type TemperatureRangeByResourceId = {
  [id: string]: {
    sop?: TemperatureSop | null;
    isLoading?: boolean;
  };
};

export type HumidityRangeByResourceId = {
  [id: string]: {
    sop?: HumiditySop | null;
    isLoading?: boolean;
  };
};

export type DoorDurationByResourceId = {
  [id: string]: {
    sop?: DoorOpenDurationSop | null;
    isLoading?: boolean;
  };
};

export type SopComponentTypesByResourceId = {
  [SopTypes.ENERGY_TARIFF]: EnergyTariffByResourceId;
  [SopTypes.TEMPERATURE_RANGE]: TemperatureRangeByResourceId;
  [SopTypes.HUMIDITY_RANGE]: HumidityRangeByResourceId;
  [SopTypes.DOOR_OPENED_MAX_DURATION]: DoorDurationByResourceId;
};

const initialSopComponentTypesByResourceId = {
  [SopTypes.ENERGY_TARIFF]: {},
  [SopTypes.TEMPERATURE_RANGE]: {},
  [SopTypes.HUMIDITY_RANGE]: {},
  [SopTypes.DOOR_OPENED_MAX_DURATION]: {},
};

export type Sops = {
  sopComponentTypesByResourceId: SopComponentTypesByResourceId;
};

const sopsFromApiResponse = (data: any) => ({
  id: data.id,
  title: data.title,
  components: data.components || [],
  description: data.description,
  equipmentTypeIds: data.equipmentTypeIds || [],
  organizationUnitId: data.organizationUnitId,
  resourceIds: data.resourceIds || [],
  timetableId: data.timetableId || null,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
});

export const initialState = {
  sopComponentTypesByResourceId: initialSopComponentTypesByResourceId,
};

const sops = (state: Sops = initialState, action: any) => {
  switch (action.type) {
    case Actions.RESOLVE_SOP_BY_RESOURCE_ID_SUCCESS: {
      if (!action.payload) {
        return pipe(
          assocPath(
            ['sopComponentTypesByResourceId', action.sopType, action.id, 'sop'],
            null
          ),
          assocPath(
            [
              'sopComponentTypesByResourceId',
              action.sopType,
              action.id,
              'isLoading',
            ],
            false
          )
        )(state);
      }

      const sop = sopsFromApiResponse(action.payload);
      const sopComponent =
        sop.components.find(c => c.type === action.sopType) || null;

      return pipe(
        assocPath(
          ['sopComponentTypesByResourceId', action.sopType, action.id, 'sop'],
          sopComponent
        ),
        assocPath(
          [
            'sopComponentTypesByResourceId',
            action.sopType,
            action.id,
            'isLoading',
          ],
          false
        )
      )(state);
    }
    case Actions.RESOLVE_SOP_BY_RESOURCE_ID_LOADING: {
      return assocPath(
        [
          'sopComponentTypesByResourceId',
          action.sopType,
          action.id,
          'isLoading',
        ],
        true,
        state
      );
    }

    case Actions.RESOLVE_SOP_BY_RESOURCE_ID_ERROR: {
      return pipe(
        assocPath(
          ['sopComponentTypesByResourceId', action.sopType, action.id, 'sop'],
          null
        ),
        assocPath(
          [
            'sopComponentTypesByResourceId',
            action.sopType,
            action.id,
            'isLoading',
          ],
          false
        )
      )(state);
    }

    default:
      return state;
  }
};

export default sops;
