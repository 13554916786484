import {
  AmbientDotDataByID,
  Site,
} from '@energybox/react-ui-library/dist/types';
import format from 'date-fns/format';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAmbientDotReadings } from '../../../actions/analytics';
import { ApplicationState } from '../../../reducers';
import StorageReportAmbientConditions from '../StorageReportAmbientConditions';

type Props = {
  site: Site;
  fromDateISO: string;
  toDateISO: string;

  fetchedAmbientDots: boolean;
  getAmbientDotReadings: (
    siteId: number,
    fromDateString: string,
    toDateString: string
  ) => void;
  ambientDotsReadings: AmbientDotDataByID;
  errorAmbientDots: boolean;
};

const StorageReportAmbientConditionsContainer = ({
  site,
  fromDateISO,
  toDateISO,
  getAmbientDotReadings,
  fetchedAmbientDots,
  ambientDotsReadings,
  errorAmbientDots,
}: Props) => {
  const fromDateString = format(Date.parse(fromDateISO), 'yyyy-MM-dd');
  const toDateString = format(Date.parse(toDateISO), 'yyyy-MM-dd');

  useEffect(() => {
    if (!!site) {
      getAmbientDotReadings(site.id, fromDateString, toDateString);
    }
  }, [site, fromDateString, toDateString, getAmbientDotReadings]);

  if (!fetchedAmbientDots || errorAmbientDots) {
    return <div />;
  }
  return (
    <StorageReportAmbientConditions ambientDotData={ambientDotsReadings} />
  );
};

const mapStateToProps = ({ storageReport, analytics }: ApplicationState) => ({
  fetchedAmbientDots: storageReport.fetched.ambientDots,
  errorAmbientDots: storageReport.errors.ambientDots,
  ambientDotsReadings: analytics.ambientDots,
});

export default connect(mapStateToProps, { getAmbientDotReadings })(
  StorageReportAmbientConditionsContainer
);
