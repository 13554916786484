import { WeatherData } from '@energybox/react-ui-library/dist/types';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import { Service } from '../config';
import { fetchApi } from '../middleware/apiMiddleware';
import { WeatherDataForChart } from '../types/weather';

export enum Actions {
  GET_WEATHER_BY_SITE_ID_SUCCESS = '@@weather/GET_WEATHER_BY_SITE_ID_SUCCESS',
  GET_WEATHER_BY_SITE_ID_LOADING = '@@weather/GET_WEATHER_BY_SITE_ID_LOADING',
  GET_WEATHER_BY_SITE_ID_ERROR = '@@weather/GET_WEATHER_BY_SITE_ID_ERROR',

  GET_SITE_WEATHER_OVER_WEEKS_LOADING = '@@weather/GET_SITE_WEATHER_OVER_WEEKS_LOADING',
  GET_SITE_WEATHER_OVER_WEEKS_SUCCESS = '@@weather/GET_SITE_WEATHER_OVER_WEEKS_SUCCESS',
  GET_SITE_WEATHER_OVER_WEEKS_ERROR = '@@weather/GET_SITE_WEATHER_OVER_WEEKS_ERROR',
}

// in?
export const getWeatherBySiteId = (siteId: number, date: string) => ({
  type: 'API_GET',
  service: Service.weather,
  path: `/weather/${siteId}/${date}`,
  success: { type: Actions.GET_WEATHER_BY_SITE_ID_SUCCESS, siteId },
  loading: { type: Actions.GET_WEATHER_BY_SITE_ID_LOADING, siteId },
  error: { type: Actions.GET_WEATHER_BY_SITE_ID_ERROR, siteId },
});

export const fetchSiteWeatherOverWeeks = (
  datesForQuery: Date[],
  siteId: number
) => (dispatch: any) => {
  dispatch({ type: Actions.GET_SITE_WEATHER_OVER_WEEKS_LOADING });

  const fetches = datesForQuery.map((date, idx) => {
    //dont fetch last dateForQuery
    if (idx < datesForQuery.length - 1) {
      const fromDate = format(date, 'yyyy-MM-dd');
      const legend = `${format(date, 'dd')} - ${
        //usually toDate should be one day before the next dateForQuery,
        //EXCEPT for the last set
        idx < datesForQuery.length - 2
          ? format(subDays(datesForQuery[idx + 1], 1), 'dd')
          : format(datesForQuery[idx + 1], 'dd')
      }`;

      return fetchApi({
        service: Service.weather,
        endpoint: `/weather/${siteId}/${fromDate}`,
        method: 'GET',
      })
        .then((res: WeatherData) => {
          return {
            ...res,
            fromDate: fromDate,
            legend: legend,
          };
        })
        .catch(err => {
          console.log(err);
        });
    }
    return {};
  });

  Promise.all(fetches)
    .then(res => {
      const validResults: WeatherDataForChart[] = res.filter(
        result => result !== undefined
      ) as WeatherDataForChart[];

      if (!!validResults[0].average_humidity) {
        dispatch({
          type: Actions.GET_SITE_WEATHER_OVER_WEEKS_SUCCESS,
          payload: validResults,
        });
      } else {
        dispatch({
          type: Actions.GET_SITE_WEATHER_OVER_WEEKS_ERROR,
        });
      }
    })
    .catch(err => {
      console.log(err);
    });
};
