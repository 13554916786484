import React from 'react';
import styles from './ReportErrorPageContainer.module.css';

const ReportErrorPageContainer = () => {
  return (
    <div className={styles.root}>
      There was an error. Please try again later.
    </div>
  );
};

export default ReportErrorPageContainer;
