import React from 'react';

const VerticalDivider: React.FC = () => {
  return (
    <div
      style={{
        width: '2px',
        height: '150px',
        backgroundColor: 'var(--ambient-basePlus75)',
      }}
    />
  );
};

export default VerticalDivider;
