import { Site } from '@energybox/react-ui-library/dist/types';
import format from 'date-fns/format';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { getSite } from '../../../actions/sites';
import { getWeatherBySiteId } from '../../../actions/weather';
import SiteInfo from '../../../components/SiteInfo/SiteInfo';
import { ApplicationState } from '../../../reducers';
import SiteReportsListContainer from '../SiteReportsListContainer';
import styles from './ShowSitePageContainer.module.css';

interface OwnProps {
  siteId: string;
}

interface Props extends OwnProps {
  getSite: () => void;
  getWeather: () => void;
  onDelete: () => void;
  site?: Site;
}

class ShowSitePageContainer extends React.Component<Props> {
  componentDidMount() {
    const { getSite, getWeather } = this.props;
    getSite();
    getWeather();
  }

  render() {
    const { siteId, site } = this.props;

    return (
      <div className={styles.root}>
        {site && <SiteInfo site={site} />}

        <div className={styles.contentContainer}>
          <SiteReportsListContainer siteId={siteId} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  { sites }: ApplicationState,
  { siteId }: OwnProps
) => ({
  site: R.pathOr(null, ['sitesById', siteId], sites),
});

const mapDispatchToProps = (dispatch: any, { siteId }: OwnProps) => ({
  getSite: () => dispatch(getSite(siteId)),
  getWeather: () =>
    dispatch(
      getWeatherBySiteId(Number(siteId), format(new Date(), 'yyyy-MM-dd'))
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowSitePageContainer);
