import { Sensor } from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject } from '@energybox/react-ui-library/dist/utils';
import assocPath from 'ramda/src/assocPath';
import { Actions } from '../actions/sensors';

export interface Sensors {
  byId: SensorsById;
  byEquipmentId: SensorsByEquipmentId;
}

export type SensorsById = {
  [sensorId: string]: Sensor;
};

export type SensorsByEquipmentId = {
  [equipmentId: string]: Sensor[];
};

const initialState: Sensors = {
  byId: {},
  byEquipmentId: {},
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.SENSORS_BY_EQUIPMENT_ID_SUCCESS: {
      return assocPath(
        ['byEquipmentId', action.equipmentId],
        action.payload,
        state
      );
    }

    case Actions.SENSORS_BY_EQUIPMENT_IDS_LIST_SUCCESS: {
      const sensorsById = mapArrayToObject(action.payload);
      return assocPath(['byId'], sensorsById, state);
    }

    default:
      return state;
  }
};
