import { Site } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions } from '../actions/sites';

const initialState = {
  sites: [],
  sitesById: {},
};

export interface Sites {
  sites: Site[];
  sitesById: SitesById;
}

type SitesById = {
  [siteId: string]: Site;
};

const processSitesResponse = (data: Site[]) => {
  return data.map((site: Site) => ({
    ...site,
    latitude: site.latitude || 0,
    longitude: site.longitude || 0,
  }));
};

export default (state: Sites = initialState, action: any) => {
  switch (action.type) {
    case Actions.SITES_SUCCESS:
      return R.assoc('sites', processSitesResponse(action.payload), state);

    case Actions.SITE_SUCCESS:
      return R.assocPath(
        ['sitesById', action.payload.id],
        action.payload,
        state
      );

    default:
      return state;
  }
};
