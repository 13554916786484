import React from 'react';

type Props = {
  text: string;
};

const ItalicText = ({ text }: Props) => {
  return (
    <div style={{ fontStyle: 'italic', marginBottom: '1em', color: 'grey' }}>
      {text}
    </div>
  );
};

export default ItalicText;
