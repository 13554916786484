import {
  Equipment,
  Site,
  SopTypes,
} from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSopByResourceId } from '../../../hooks/sop';
import { ApplicationState } from '../../../reducers';
import StorageReportConsumptionInfo from '../StorageReportConsumptionInfo';

type Props = {
  reportDifferenceInDays: number;
  site: Site;
  equipments: Equipment[];
  fetchedAnalyticsCurrentPeriod: boolean;
  fetchedAnalyticsPrevPeriod: boolean;
  totalConsumptionCurrentPeriod: number;
  totalConsumptionPrevPeriod: number;
  loadingAnalyticsCurrentPeriod: boolean;
  loadingAnalyticsPrevPeriod: boolean;
};

const StorageReportConsumptionInfoContainer = ({
  reportDifferenceInDays,
  site,
  equipments,
  fetchedAnalyticsCurrentPeriod,
  fetchedAnalyticsPrevPeriod,
  totalConsumptionCurrentPeriod,
  totalConsumptionPrevPeriod,
  loadingAnalyticsCurrentPeriod,
  loadingAnalyticsPrevPeriod,
}: Props) => {
  const [totalConsumption, setTotalConsumption] = useState<number>(0);
  const [prevTotalConsumption, setPrevTotalConsumption] = useState<number>(0);

  const { sop: energySop } = useSopByResourceId(
    site.id,
    SopTypes.ENERGY_TARIFF
  );
  const sopCurrencyCode = energySop ? energySop.currencyCode : undefined;
  const costPrKwh = energySop?.tariff;

  useEffect(() => {
    if (fetchedAnalyticsCurrentPeriod) {
      setTotalConsumption(totalConsumptionCurrentPeriod);
    }
  }, [fetchedAnalyticsCurrentPeriod, totalConsumptionCurrentPeriod]);

  useEffect(() => {
    if (fetchedAnalyticsPrevPeriod) {
      setPrevTotalConsumption(totalConsumptionPrevPeriod);
    }
  }, [fetchedAnalyticsPrevPeriod, totalConsumptionPrevPeriod]);

  if (loadingAnalyticsPrevPeriod || loadingAnalyticsCurrentPeriod) {
    return <div />;
  }
  return (
    <StorageReportConsumptionInfo
      equipments={equipments}
      siteSize={site.areaTotal}
      totalConsumption={totalConsumption}
      prevTotalConsumption={prevTotalConsumption}
      reportNumberOfDays={reportDifferenceInDays}
      costPerKwh={costPrKwh}
      sopCurrencyCode={sopCurrencyCode}
    />
  );
};

const mapStateToProps = ({ storageReport, energy }: ApplicationState) => ({
  fetchedAnalyticsCurrentPeriod: storageReport.fetched.analytics.currentPeriod,
  loadingAnalyticsCurrentPeriod: storageReport.loading.analytics.currentPeriod,
  loadingAnalyticsPrevPeriod: storageReport.loading.analytics.prevPeriod,

  fetchedAnalyticsPrevPeriod: storageReport.fetched.analytics.prevPeriod,
  totalConsumptionPrevPeriod: energy.totalConsumption.prevPeriod,
  totalConsumptionCurrentPeriod: energy.totalConsumption.currentPeriod,
});

export default connect(mapStateToProps)(StorageReportConsumptionInfoContainer);
