import {
  Button,
  FilterDropdown,
  FilterDropdownBasicItem,
  FilterDropdownTextItem,
} from '@energybox/react-ui-library/dist/components';
import { getDateLast15MinuteStep } from '@energybox/react-ui-library/dist/utils';
import { DateTimePicker } from '@material-ui/pickers';
import {
  differenceInDays,
  format,
  isToday,
  startOfDay,
  subDays,
  subHours,
} from 'date-fns';

import React, { useEffect, useState } from 'react';
import {
  useAppLocale,
  useIs12HrTimeFormat,
} from '../../../hooks/useAppDetails';
import { DateFilterTimePeriod } from '../../../reducers/dateFilter';
import { createNotificationDateFilter } from '../../../utils/notificationDateFilter';
import CalendarFilter from '../CalendarFilter';
import styles from './DateFilter.module.css';

type FilterOption = {
  title: string;
  fromDate: Date;
  toDate: Date;
};

type Props = {
  setFilter: (dateFilter: DateFilterTimePeriod) => void;
  value?: DateFilterTimePeriod;
  alignItemsRight?: boolean;
  limitRangeSelection?: number;
  disabled?: boolean;
  disableCustomRange?: boolean;
  options?: FilterOption[];
  secondary?: boolean;
  customPickerVariant?: 'date' | 'datetime';
};

export function formatTitle(
  fromDate: Date,
  toDate: Date,
  localeFormatString: string
) {
  if (fromDate === toDate) return format(fromDate, localeFormatString);
  return `${format(fromDate, localeFormatString)} - ${format(
    toDate,
    localeFormatString
  )}`;
}

const DateFilter = ({
  value,
  setFilter,
  alignItemsRight = false,
  limitRangeSelection = 31,
  disabled = false,
  disableCustomRange = false,
  options = createNotificationDateFilter(),
  secondary,
  customPickerVariant = 'datetime',
}: Props) => {
  const is12HrTimeFormat = useIs12HrTimeFormat();
  const ensureRoundDown = getDateLast15MinuteStep();

  const locale = useAppLocale();
  const [errorMessage, setErrorMessage] = useState('');
  const [customPickerOpen, setCustomPickerOpen] = useState(false);
  const [customDateRange, setCustomDateRange] = useState<{
    fromDate: Date | null;
    toDate: Date | null;
  }>({
    fromDate: startOfDay(subDays(ensureRoundDown, 1)),
    toDate: ensureRoundDown,
  });
  const [customFromDate, setCustomFromDate] = useState<Date>(
    subHours(ensureRoundDown, 1)
  );
  const [customToDate, setCustomToDate] = useState<Date>(ensureRoundDown);
  const [fromOpen, setFromOpen] = useState(false);
  const [toOpen, setToOpen] = useState(false);
  const [isCustomRangeValid, setIsCustomRangeValid] = useState(true);

  //Validation for datetime variant
  useEffect(() => {
    const isStartBeforeEnd = customFromDate < customToDate;
    if (setErrorMessage !== undefined && !isStartBeforeEnd) {
      setErrorMessage('The start of the date range must precede the end.');
    }
    const isRangeLengthOk =
      Math.abs(differenceInDays(customFromDate, customToDate)) <=
      limitRangeSelection;
    if (setErrorMessage !== undefined && !isRangeLengthOk) {
      setErrorMessage(
        `The range should not exceed ${limitRangeSelection} days.`
      );
    }
    const isValid = isStartBeforeEnd && isRangeLengthOk;
    if (isValid) setErrorMessage('');
    setIsCustomRangeValid(isValid);
  }, [customFromDate, customToDate, limitRangeSelection]);

  useEffect(() => {
    const { fromDate, toDate } = customDateRange;
    if (fromDate && toDate) {
      setIsCustomRangeValid(true);
    } else {
      setIsCustomRangeValid(false);
    }
  }, [customDateRange]);

  const applyFilter = () => {
    if (customPickerVariant === 'datetime') {
      setFilter({
        title: `${format(customFromDate, locale.dateTimeFormat)} - ${format(
          customToDate,
          locale.dateTimeFormat
        )}`,
        fromDate: customFromDate,
        toDate: customToDate,
        isCustomRange: true,
      });
    } else {
      const { fromDate, toDate } = customDateRange;
      if (fromDate && toDate) {
        setFilter({
          title: `${format(fromDate, locale.dateFormat)} - ${format(
            toDate,
            locale.dateFormat
          )}`,
          fromDate,
          toDate: isToday(toDate) ? getDateLast15MinuteStep() : toDate,
          isCustomRange: true,
        });
      } else {
        setErrorMessage('Both a start and end date must be selected.');
      }
    }
  };

  const customDatetimePicker = (
    <>
      <DateTimePicker
        label="From"
        value={customFromDate}
        onChange={newDate => newDate && setCustomFromDate(newDate)}
        minutesStep={15}
        format={locale.dateTimeFormat}
        open={fromOpen}
        onOpen={() => setFromOpen(true)}
        onClose={() => setFromOpen(false)}
        ampm={is12HrTimeFormat}
        disableFuture
      />
      <DateTimePicker
        label="To"
        value={customToDate}
        onChange={newDate => newDate && setCustomToDate(newDate)}
        minutesStep={15}
        format={locale.dateTimeFormat}
        open={toOpen}
        onOpen={() => setToOpen(true)}
        onClose={() => setToOpen(false)}
        ampm={is12HrTimeFormat}
        disableFuture
      />
    </>
  );

  const renderFilterDropdownTitle = () => {
    if (value && value.title) return value.title;
    if (value && value.fromDate && value.toDate)
      return formatTitle(value.fromDate, value.toDate, locale.dateTimeFormat);
    return 'Choose date range';
  };

  return (
    <div className={styles.root}>
      <FilterDropdown
        title={renderFilterDropdownTitle()}
        secondary={secondary}
        isDatePickerModalOpen={fromOpen || toOpen}
        alignItemsRight={alignItemsRight}
        disabled={disabled}
      >
        {options.map(({ title, fromDate, toDate }) => (
          <FilterDropdownTextItem
            key={title}
            title={title}
            onClick={() => {
              setCustomPickerOpen(false);
              setFilter({
                title,
                fromDate,
                toDate,
                isCustomRange: false,
              });
            }}
            closeOnClick
          />
        ))}

        {!disableCustomRange && (
          <FilterDropdownTextItem
            title="Custom date range..."
            onClick={() => setCustomPickerOpen(!customPickerOpen)}
          />
        )}
        {customPickerOpen && (
          <div className={styles.customDateOpenContainerMargin}>
            {customPickerVariant === 'datetime' && customDatetimePicker}
            {customPickerVariant === 'date' && (
              <CalendarFilter
                limitRangeSelection={limitRangeSelection}
                setDateRange={(fromDate, toDate) => {
                  setCustomDateRange({ fromDate, toDate });
                }}
              />
            )}
            {errorMessage !== '' && (
              <span className={styles.errorMessage}>{errorMessage}</span>
            )}
          </div>
        )}
        {customPickerOpen && (
          <FilterDropdownBasicItem className={styles.applyButton} closeOnClick>
            <Button
              size="medium"
              variant="solid"
              onClick={applyFilter}
              disabled={!isCustomRangeValid}
            >
              Apply
            </Button>
          </FilterDropdownBasicItem>
        )}
      </FilterDropdown>
    </div>
  );
};

export default DateFilter;
