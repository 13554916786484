import {
  OrgEnergyReportingPeriod,
  TimeZone,
} from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions as AppActions } from '../actions/app';
import { Actions as EnergyActions } from '../actions/energy';
import { Actions as WeatherActions } from '../actions/weather';
import {
  toggleError,
  toggleLoading,
  toggleSuccess,
} from '../utils/reducerFetchingStates';

export type EnergyReportFetchStatusFlags = {
  siteEnergyByWeeks: boolean;
  siteWeatherByWeeks: boolean;
  orgEnergyByPeriod: OrgEnergyReportFetchStatus;
  analyticsEnergyConsumptionSelectedTimePeriod: boolean;
  analyticsEnergyConsumptionLastMonth: boolean;
  analyticsEnergyConsumptionTwoMonthsAgo: boolean;
  analyticsEnergyConsumptionThreeMonthsAgo: boolean;
};

export type OrgEnergyReportFetchStatus = {
  [OrgEnergyReportingPeriod.CURRENT]: boolean;
  [OrgEnergyReportingPeriod.PREV_MONTH]: boolean;
};

export type EnergyReport = {
  siteTimezone: TimeZone;
  fetched: EnergyReportFetchStatusFlags;
  loading: EnergyReportFetchStatusFlags;
  errors: EnergyReportFetchStatusFlags;
};

export const initialState: EnergyReport = {
  siteTimezone: {
    title: '',
    region: '',
    commonName: '',
    offset: '',
    offsetMinutes: 0,
  },
  fetched: {
    siteEnergyByWeeks: false,
    siteWeatherByWeeks: false,
    orgEnergyByPeriod: {
      [OrgEnergyReportingPeriod.CURRENT]: false,
      [OrgEnergyReportingPeriod.PREV_MONTH]: false,
    },
    analyticsEnergyConsumptionSelectedTimePeriod: false,
    analyticsEnergyConsumptionLastMonth: false,
    analyticsEnergyConsumptionTwoMonthsAgo: false,
    analyticsEnergyConsumptionThreeMonthsAgo: false,
  },
  loading: {
    siteEnergyByWeeks: false,
    siteWeatherByWeeks: false,
    orgEnergyByPeriod: {
      [OrgEnergyReportingPeriod.CURRENT]: false,
      [OrgEnergyReportingPeriod.PREV_MONTH]: false,
    },
    analyticsEnergyConsumptionSelectedTimePeriod: false,
    analyticsEnergyConsumptionLastMonth: false,
    analyticsEnergyConsumptionTwoMonthsAgo: false,
    analyticsEnergyConsumptionThreeMonthsAgo: false,
  },
  errors: {
    siteEnergyByWeeks: false,
    siteWeatherByWeeks: false,
    orgEnergyByPeriod: {
      [OrgEnergyReportingPeriod.CURRENT]: false,
      [OrgEnergyReportingPeriod.PREV_MONTH]: false,
    },
    analyticsEnergyConsumptionSelectedTimePeriod: false,
    analyticsEnergyConsumptionLastMonth: false,
    analyticsEnergyConsumptionTwoMonthsAgo: false,
    analyticsEnergyConsumptionThreeMonthsAgo: false,
  },
};

export default (state: EnergyReport = initialState, action) => {
  switch (action.type) {
    case EnergyActions.SITE_ENERGY_SELECTED_TIME_PERIOD_LOADING:
      return toggleLoading(
        'analyticsEnergyConsumptionSelectedTimePeriod',
        state
      );

    case EnergyActions.SITE_ENERGY_SELECTED_TIME_PERIOD_SUCCESS:
      return toggleSuccess(
        'analyticsEnergyConsumptionSelectedTimePeriod',
        state
      );

    case EnergyActions.SITE_ENERGY_SELECTED_TIME_PERIOD_ERROR:
      return toggleError('analyticsEnergyConsumptionSelectedTimePeriod', state);

    case EnergyActions.SITE_ENERGY_LAST_MONTH_SUCCESS:
      return toggleSuccess('analyticsEnergyConsumptionLastMonth', state);

    case EnergyActions.SITE_ENERGY_LAST_MONTH_ERROR:
      return toggleError('analyticsEnergyConsumptionLastMonth', state);

    case EnergyActions.SITE_ENERGY_LAST_MONTH_LOADING:
      return toggleLoading('analyticsEnergyConsumptionLastMonth', state);

    case EnergyActions.SITE_ENERGY_2_MONTHS_AGO_SUCCESS:
      return toggleSuccess('analyticsEnergyConsumptionTwoMonthsAgo', state);

    case EnergyActions.SITE_ENERGY_2_MONTHS_AGO_ERROR:
      return toggleError('analyticsEnergyConsumptionTwoMonthsAgo', state);

    case EnergyActions.SITE_ENERGY_2_MONTHS_AGO_LOADING:
      return toggleLoading('analyticsEnergyConsumptionTwoMonthsAgo', state);

    case EnergyActions.SITE_ENERGY_3_MONTHS_AGO_SUCCESS:
      return toggleSuccess('analyticsEnergyConsumptionThreeMonthsAgo', state);

    case EnergyActions.SITE_ENERGY_3_MONTHS_AGO_ERROR:
      return toggleError('analyticsEnergyConsumptionThreeMonthsAgo', state);

    case EnergyActions.SITE_ENERGY_3_MONTHS_AGO_LOADING:
      return toggleLoading('analyticsEnergyConsumptionThreeMonthsAgo', state);

    case EnergyActions.SITE_ENERGY_BY_WEEKS_SUCCESS:
      return toggleSuccess('siteEnergyByWeeks', state);

    case EnergyActions.SITE_ENERGY_BY_WEEKS_LOADING:
      return toggleLoading('siteEnergyByWeeks', state);

    case EnergyActions.SITE_ENERGY_BY_WEEKS_ERROR:
      return toggleError('siteEnergyByWeeks', state);

    case WeatherActions.GET_SITE_WEATHER_OVER_WEEKS_LOADING:
      return toggleLoading('siteWeatherByWeeks', state);

    case WeatherActions.GET_SITE_WEATHER_OVER_WEEKS_SUCCESS:
      return toggleSuccess('siteWeatherByWeeks', state);

    case WeatherActions.GET_SITE_WEATHER_OVER_WEEKS_ERROR:
      return toggleError('siteWeatherByWeeks', state);

    case EnergyActions.ORG_ENERGY_BY_REPORTING_PERIOD_LOADING:
      return toggleLoading(
        ['orgEnergyByPeriod', action.reportingPeriod],
        state
      );

    case EnergyActions.ORG_ENERGY_BY_REPORTING_PERIOD_SUCCESS:
      return toggleSuccess(
        ['orgEnergyByPeriod', action.reportingPeriod],
        state
      );

    case EnergyActions.ORG_ENERGY_BY_REPORTING_PERIOD_ERROR:
      return toggleError(['orgEnergyByPeriod', action.reportingPeriod], state);

    case AppActions.SET_SITE_TIMEZONE_MINUTES_OFFSET:
      return R.pipe(
        R.assoc('siteTimezone', action.payload),
        R.assocPath(['fetched', 'siteTimezone'], true)
      )(state);
    default:
      return state;
  }
};
