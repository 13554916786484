import React from 'react';
import BoldText from './BoldText';
import { Colors } from '../utils/colors';

type Props = {
  text: React.ReactNode;
};

const RecommendationText = ({ text }: Props) => {
  return (
    <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <div style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
        Recommendation
      </div>

      <div style={{ lineHeight: '1.5rem' }}>{text}</div>
    </div>
  );
};

export default RecommendationText;
