import {
  AnalyticsEnergyResponse,
  Site,
} from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getSiteConsumptionLastMonth,
  getSiteConsumptionThreeMonthsAgo,
  getSiteConsumptionTwoMonthsAgo,
} from '../../../actions/energy';
import { ApplicationState } from '../../../reducers';
import EnergyReportMonthlyConsumptionTable from '../EnergyReportMonthlyConsumptionTable';

type OwnProps = {
  site: Site;
  toDate: Date;
};

interface Props extends OwnProps {
  fetchedAnalyticsEnergyConsumptionLastMonth: boolean;
  fetchedAnalyticsEnergyConsumptionTwoMonthsAgo: boolean;
  fetchedAnalyticsEnergyConsumptionThreeMonthsAgo: boolean;
  analyticsEnergyLastMonth?: AnalyticsEnergyResponse;
  analyticsEnergyLastMonthDate?: Date;
  analyticsEnergyTwoMonthsAgo?: AnalyticsEnergyResponse;
  analyticsEnergyTwoMonthsAgoDate?: Date;
  analyticsEnergyThreeMonthsAgo?: AnalyticsEnergyResponse;
  analyticsEnergyThreeMonthsAgoDate?: Date;
  errorAnalyticsEnergyLastMonth: boolean;
  errorAnalyticsEnergyTwoMonthsAgo: boolean;
  errorAnalyticsEnergyThreeMonthsAgo: boolean;
  getSiteConsumptionLastMonth: (toDate: Date, siteId: number) => void;
  getSiteConsumptionTwoMonthsAgo: (toDate: Date, siteId: number) => void;
  getSiteConsumptionThreeMonthsAgo: (toDate: Date, siteId: number) => void;
}

const EnergyReportMonthlyConsumptionTableContainer = ({
  getSiteConsumptionLastMonth,
  getSiteConsumptionTwoMonthsAgo,
  getSiteConsumptionThreeMonthsAgo,
  site,
  toDate,
  fetchedAnalyticsEnergyConsumptionLastMonth,
  fetchedAnalyticsEnergyConsumptionTwoMonthsAgo,
  fetchedAnalyticsEnergyConsumptionThreeMonthsAgo,
  analyticsEnergyLastMonth,
  analyticsEnergyLastMonthDate,
  analyticsEnergyTwoMonthsAgo,
  analyticsEnergyTwoMonthsAgoDate,
  analyticsEnergyThreeMonthsAgo,
  analyticsEnergyThreeMonthsAgoDate,
  errorAnalyticsEnergyLastMonth,
  errorAnalyticsEnergyTwoMonthsAgo,
  errorAnalyticsEnergyThreeMonthsAgo,
}: Props) => {
  const [consumptionLastMonth, setConsumptionLastMonth] = useState({
    consumption: 0,
    fromDate: new Date(),
  });
  const [consumptionTwoMonthsAgo, setConsumptionTwoMonthsAgo] = useState({
    consumption: 0,
    fromDate: new Date(),
  });
  const [consumptionThreeMonthsAgo, setConsumptionThreeMonthsAgo] = useState({
    consumption: 0,
    fromDate: new Date(),
  });

  useEffect(() => {
    if (
      !fetchedAnalyticsEnergyConsumptionLastMonth &&
      !errorAnalyticsEnergyLastMonth &&
      !!site
    ) {
      getSiteConsumptionLastMonth(toDate, site.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedAnalyticsEnergyConsumptionLastMonth,
    errorAnalyticsEnergyLastMonth,
    getSiteConsumptionLastMonth,
    site,
  ]);

  useEffect(() => {
    if (
      !fetchedAnalyticsEnergyConsumptionTwoMonthsAgo &&
      !errorAnalyticsEnergyTwoMonthsAgo &&
      !!site
    ) {
      getSiteConsumptionTwoMonthsAgo(toDate, site.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedAnalyticsEnergyConsumptionTwoMonthsAgo,
    errorAnalyticsEnergyTwoMonthsAgo,
    getSiteConsumptionTwoMonthsAgo,
    site,
  ]);

  useEffect(() => {
    if (
      !fetchedAnalyticsEnergyConsumptionThreeMonthsAgo &&
      !errorAnalyticsEnergyThreeMonthsAgo &&
      !!site
    ) {
      getSiteConsumptionThreeMonthsAgo(toDate, site.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedAnalyticsEnergyConsumptionThreeMonthsAgo,
    errorAnalyticsEnergyThreeMonthsAgo,
    getSiteConsumptionThreeMonthsAgo,
    site,
  ]);

  useEffect(() => {
    if (
      fetchedAnalyticsEnergyConsumptionLastMonth &&
      !errorAnalyticsEnergyLastMonth &&
      analyticsEnergyLastMonthDate &&
      analyticsEnergyLastMonth &&
      Object.keys(analyticsEnergyLastMonth).length > 0
    ) {
      const totalConsumption = analyticsEnergyLastMonth.data?.total_kwh;

      setConsumptionLastMonth({
        consumption: totalConsumption ?? 0,
        fromDate: analyticsEnergyLastMonthDate,
      });
    }
    if (errorAnalyticsEnergyLastMonth && analyticsEnergyLastMonthDate) {
      setConsumptionLastMonth({
        consumption: 0,
        fromDate: analyticsEnergyLastMonthDate,
      });
    }
  }, [
    errorAnalyticsEnergyLastMonth,
    analyticsEnergyLastMonth,
    analyticsEnergyLastMonthDate,
    fetchedAnalyticsEnergyConsumptionLastMonth,
  ]);

  useEffect(() => {
    if (
      fetchedAnalyticsEnergyConsumptionTwoMonthsAgo &&
      !errorAnalyticsEnergyTwoMonthsAgo &&
      analyticsEnergyTwoMonthsAgoDate &&
      analyticsEnergyTwoMonthsAgo &&
      Object.keys(analyticsEnergyTwoMonthsAgo).length > 0
    ) {
      const totalConsumption = analyticsEnergyTwoMonthsAgo.data?.total_kwh;

      setConsumptionTwoMonthsAgo({
        consumption: totalConsumption ?? 0,
        fromDate: analyticsEnergyTwoMonthsAgoDate,
      });
    }
    if (errorAnalyticsEnergyTwoMonthsAgo && analyticsEnergyTwoMonthsAgoDate) {
      setConsumptionTwoMonthsAgo({
        consumption: 0,
        fromDate: analyticsEnergyTwoMonthsAgoDate,
      });
    }
  }, [
    analyticsEnergyTwoMonthsAgo,
    analyticsEnergyTwoMonthsAgoDate,
    errorAnalyticsEnergyTwoMonthsAgo,
    fetchedAnalyticsEnergyConsumptionTwoMonthsAgo,
  ]);

  useEffect(() => {
    if (
      fetchedAnalyticsEnergyConsumptionThreeMonthsAgo &&
      !errorAnalyticsEnergyThreeMonthsAgo &&
      analyticsEnergyThreeMonthsAgoDate &&
      analyticsEnergyThreeMonthsAgo &&
      Object.keys(analyticsEnergyThreeMonthsAgo).length > 0
    ) {
      const totalConsumption = analyticsEnergyThreeMonthsAgo.data?.total_kwh;

      setConsumptionThreeMonthsAgo({
        consumption: totalConsumption ?? 0,
        fromDate: analyticsEnergyThreeMonthsAgoDate,
      });
    }
    if (
      errorAnalyticsEnergyThreeMonthsAgo &&
      analyticsEnergyThreeMonthsAgoDate
    ) {
      setConsumptionThreeMonthsAgo({
        consumption: 0,
        fromDate: analyticsEnergyThreeMonthsAgoDate,
      });
    }
  }, [
    analyticsEnergyThreeMonthsAgo,
    analyticsEnergyThreeMonthsAgoDate,
    errorAnalyticsEnergyThreeMonthsAgo,
    fetchedAnalyticsEnergyConsumptionThreeMonthsAgo,
  ]);

  if (
    !fetchedAnalyticsEnergyConsumptionLastMonth ||
    !fetchedAnalyticsEnergyConsumptionTwoMonthsAgo ||
    !fetchedAnalyticsEnergyConsumptionThreeMonthsAgo ||
    !site
  ) {
    return <div />;
  }
  return (
    <EnergyReportMonthlyConsumptionTable
      site={site}
      consumptionLastMonth={consumptionLastMonth}
      consumptionTwoMonthsAgo={consumptionTwoMonthsAgo}
      consumptionThreeMonthsAgo={consumptionThreeMonthsAgo}
    />
  );
};

const mapStateToProps = ({ energy, energyReport }: ApplicationState) => ({
  analyticsEnergyLastMonth: energy.analyticsEnergyLastMonth,
  analyticsEnergyTwoMonthsAgo: energy.analyticsEnergyTwoMonthsAgo,
  analyticsEnergyThreeMonthsAgo: energy.analyticsEnergyThreeMonthsAgo,

  analyticsEnergyLastMonthDate:
    energy.analyticsEnergyLastMonth && energy.analyticsEnergyLastMonth.fromDate,
  analyticsEnergyTwoMonthsAgoDate:
    energy.analyticsEnergyTwoMonthsAgo &&
    energy.analyticsEnergyTwoMonthsAgo.fromDate,
  analyticsEnergyThreeMonthsAgoDate:
    energy.analyticsEnergyThreeMonthsAgo &&
    energy.analyticsEnergyThreeMonthsAgo.fromDate,

  fetchedAnalyticsEnergyConsumptionLastMonth:
    energyReport.fetched.analyticsEnergyConsumptionLastMonth,
  fetchedAnalyticsEnergyConsumptionTwoMonthsAgo:
    energyReport.fetched.analyticsEnergyConsumptionTwoMonthsAgo,
  fetchedAnalyticsEnergyConsumptionThreeMonthsAgo:
    energyReport.fetched.analyticsEnergyConsumptionThreeMonthsAgo,

  errorAnalyticsEnergyLastMonth:
    energyReport.errors.analyticsEnergyConsumptionLastMonth,
  errorAnalyticsEnergyTwoMonthsAgo:
    energyReport.errors.analyticsEnergyConsumptionTwoMonthsAgo,
  errorAnalyticsEnergyThreeMonthsAgo:
    energyReport.errors.analyticsEnergyConsumptionThreeMonthsAgo,
});
export default connect(mapStateToProps, {
  getSiteConsumptionLastMonth,
  getSiteConsumptionTwoMonthsAgo,
  getSiteConsumptionThreeMonthsAgo,
})(EnergyReportMonthlyConsumptionTableContainer);
