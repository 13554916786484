import { ComparisonData, FilterTimePeriod } from "@energybox/react-ui-library/dist/types";
import { DefaultChartStyles } from "@energybox/react-ui-library/dist/utils";

const CustomXTick =
  (data: ComparisonData[], timePeriod?: FilterTimePeriod) => (props) => {
    const { x, y, payload } = props;
    const textSplits = payload.value.split(' - ');
    const textForPreviousPeriods =
      textSplits.length === 1 || textSplits[0] === textSplits[1] ? (
        <tspan textAnchor="middle">{textSplits[0]}</tspan>
      ) : (
        <>
          <tspan x={15} y={9}>
            {textSplits[1]}
          </tspan>
          <tspan x={20} y={8}>
            {textSplits[0]} -
          </tspan>
        </>
      );
    let textForSavings = <tspan x={15} y={9}></tspan>;
    let shouldHideTick = false;
    if (timePeriod !== undefined) {
      const { shouldHideTick: temp } = data[payload.index];
      if (temp !== undefined) {
        shouldHideTick = temp;
      }
      textForSavings = (
        <tspan x={15} y={9}>
          {payload.value}
        </tspan>
      );
    }

    const tickElem = (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={10}
          textAnchor="end"
          style={{
            fontSize: 16,
            fill: DefaultChartStyles.tick.fill,
          }}
        >
          {timePeriod === undefined ? textForPreviousPeriods : textForSavings}
        </text>
      </g>
    );

    if (timePeriod === undefined) {
      return tickElem;
    }
    return shouldHideTick ? undefined : tickElem;
  };

  export default CustomXTick;