import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import history from '../history';
import { Actions as AppActions } from '../actions/app';

import app, { App } from './app';
import analytics, { Analytics } from './analytics';
import equipmentTags, { EquipmentTags } from './equipmentTags';
import equipment, { Equipments } from './equipment';
import loading, { Loading } from './loading';
import sites, { Sites } from './sites';
import spaces, { Spaces } from './spaces';
import dateFilter, { DateFilter } from './dateFilter';
import sops, { Sops } from './sops';
import sensors, { Sensors } from './sensors';
import sensorsBySiteId, { SensorsBySiteId } from './sensorsBySiteId';
import energy, { Energy } from './energy';
import organizations, { Organizations } from './organizations';
import weather, { Weather } from './weather';
import operationalSamples, { OperationalSamples } from './operationalSamples';
import temperatureRecord, {
  Reports as TemperatureRecords,
} from './temperatureRecords';
import energyReport, { EnergyReport } from './energyReport';
import storageReport, { StorageReport } from './storageReport';
import genericReport, { GenericReport } from './genericReport';
import temperatureRecordReport, {
  TemperatureRecordReport,
} from './temperatureRecordReport';
import equipmentReport, { EquipmentReport } from './equipmentReport';
import notifications, { Notifications } from './notifications';
import comments, { Comments } from './comments';
import users, { Users } from './users';
import equipmentTempCheckReport, {
  EquipmentTempCheckReport,
} from './equipmentTempCheckReport';
import certifications, { Certifications } from './certifications';
import inspection, { Inspection } from './inspection';
import inspectionReport, { InspectionReportInfo } from './inspectionReport';

export interface ApplicationState {
  router: RouterState;
  app: App;
  analytics: Analytics;
  equipmentTags: EquipmentTags;
  equipment: Equipments;
  loading: Loading;
  sites: Sites;
  spaces: Spaces;
  dateFilter: DateFilter;
  sops: Sops;
  sensors: Sensors;
  sensorsBySiteId: SensorsBySiteId;
  energy: Energy;
  organizations: Organizations;
  weather: Weather;
  operationalSamples: OperationalSamples;
  temperatureRecord: TemperatureRecords;
  energyReport: EnergyReport;
  storageReport: StorageReport;
  genericReport: GenericReport;
  temperatureRecordReport: TemperatureRecordReport;
  equipmentReport: EquipmentReport;
  equipmentTempCheckReport: EquipmentTempCheckReport;
  notifications: Notifications;
  comments: Comments;
  users: Users;
  certifications: Certifications;
  inspection: Inspection;
  inspectionReport: InspectionReportInfo;
}

const appReducer = combineReducers<ApplicationState>({
  router: connectRouter(history),
  app,
  analytics,
  equipment,
  equipmentTags,
  loading,
  dateFilter,
  sites,
  spaces,
  sops,
  energy,
  sensors,
  sensorsBySiteId,
  organizations,
  weather,
  operationalSamples,
  temperatureRecord,
  energyReport,
  storageReport,
  genericReport,
  temperatureRecordReport,
  equipmentReport,
  equipmentTempCheckReport,
  notifications,
  comments,
  users,
  certifications,
  inspection,
  inspectionReport,
});

const rootReducer = (state, action) => {
  if (action.type === AppActions.LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
