import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import * as Routes from '../routes';
import history from '../history';
import { isSignedIn } from '../reducers/app';

import DefaultLayout from './layout/DefaultLayout';
import LoginPage from './LoginPage';
import { ApplicationState } from '../reducers';
import OrgSwitch from './OrgSwitch';

type Props = {
  isSignedIn: boolean;
};

function App({ isSignedIn }: Props) {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={Routes.ORG_SWITCH} component={OrgSwitch} />
        <Route path={Routes.LOGIN} component={LoginPage} />
        {isSignedIn ? (
          <Route path="/*" component={DefaultLayout} />
        ) : (
          <Redirect
            from="/*"
            to={`${Routes.LOGIN}?next=${encodeURIComponent(
              window.location.pathname
            )}`}
            noThrow
          />
        )}
      </Switch>
    </ConnectedRouter>
  );
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  isSignedIn: isSignedIn(app),
});

export default connect(mapStateToProps, null)(App);
