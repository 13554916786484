import {
  FormattedEnergyConsumptionData,
  PieSection,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import {
  DonutChartLegend,
  RechartsDonutChart,
} from '../../../components/Charts/Donut';
import SectionTitle from '../../../components/SectionTitle';
import { decorateDataWithColors } from '../../../utils/colorForDonutType';

type Props = {
  siteId: number;
  energyDataByEquipmentGroups: FormattedEnergyConsumptionData[];
};

const EnergyReportDonutChart = ({
  siteId,
  energyDataByEquipmentGroups,
}: Props) => {
  if (!energyDataByEquipmentGroups) return <div />;
  let formattedConsumptionData: PieSection[] = [];

  if (energyDataByEquipmentGroups.length > 7) {
    const otherData = energyDataByEquipmentGroups
      .sort((a, b) => b.count - a.count)
      .slice(7);

    let othersTotal = 0;

    otherData.forEach(_data => {
      othersTotal = othersTotal + _data.count;
    });

    const others = {
      count: othersTotal,
      label: 'Others',
      type: 'Others',
      unit: '',
    };

    formattedConsumptionData = energyDataByEquipmentGroups
      .slice(0, 7)
      .map(d => ({ ...d, unit: '' }));

    formattedConsumptionData.push(others);
  } else {
    formattedConsumptionData = energyDataByEquipmentGroups.map(d => ({
      ...d,
      unit: '',
    }));
  }

  const sortedData = formattedConsumptionData.sort((a, b) => b.count - a.count);
  const sortedDataWithChartColors = decorateDataWithColors(sortedData);

  return (
    <div>
      <SectionTitle title="Energy Consumption Overview" />

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'center',
          marginTop: '2rem',
          marginBottom: '2rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RechartsDonutChart data={sortedDataWithChartColors} report />
        </div>
        <div>
          <DonutChartLegend
            siteId={siteId}
            data={sortedDataWithChartColors}
            showAbsoluteConsumption
          />
        </div>
      </div>
    </div>
  );
};

export default EnergyReportDonutChart;
