import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import thunk from 'redux-thunk';
import rootReducer from './reducers';

import apiMiddleware from './middleware/apiMiddleware';
import authMiddleware from './middleware/authMiddleware';
import gaMiddleware from './middleware/gaMiddleware';
import equipmentReportMiddleware from './middleware/equipmentReportMiddleware';
import { loadState } from './middleware/localStorage';

import history from './history';

interface Window {
  [key: string]: any;
}

export interface Action {
  type: string;
  payload: any;
}

const middleware = [thunk];
let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');
  const w: Window = window;
  composeEnhancers =
    typeof w !== 'undefined' && w['__REDUX_DEVTOOLS_EXTENSION__']
      ? w['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({})
      : compose;

  middleware.push(logger);
}

export const configureStore = (preloadedState: any = {}) =>
  createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        ...middleware,
        routerMiddleware(history),
        gaMiddleware,
        apiMiddleware,
        authMiddleware,
        equipmentReportMiddleware
      )
    )
  );

const store = configureStore(loadState());

export default store;
