export const HOME = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const SITES = '/sites';
export const ORGS = '/orgs';
export const SITE_PAGE = `${SITES}/:siteId`;
export const ORG_SWITCH = '/org-switch';

export const ERROR_PAGE = '/report/error';
export const STORAGE_REPORT_CONTAINER_PAGE = `${SITES}/:siteId/report/storage/:fromDate/:toDate`;
export const ENERGY_REPORT_CONTAINER_PAGE = `${SITES}/:siteId/report/energy/:fromDate/:toDate`;
export const GENERIC_REPORT_CONTAINER_PAGE = `${SITES}/:siteId/report/generic/:fromDate/:toDate/:components?/`;
export const TEMPERATURE_RECORD_REPORT_CONTAINER_PAGE = `${SITES}/:siteId/report/temperatureRecord/:reportId/:fromDate/:toDate`;
export const EQUIPMENT_REPORT_CONTAINER_PAGE = `${SITES}/:siteId/report/equipment/:equipmentId/:fromDate/:toDate/aggregationLevel/:aggregationLevel`;
export const SITE_TEMP_CHECK_CONTAINER_PAGE = `${SITES}/:siteId/report/temperatureCheck/:reportId/equipment/:equipmentId/:fromDate/:toDate`;
export const SITE_INSPECTION_REPORT_CONTAINER_PAGE = `${SITES}/:siteId/report/inspection/:timestamp`;
export const ENERGY_TRACKER_REPORT_CONTAINER_PAGE = `${ORGS}/:organizationId/report/energyTracker/date/:date/yearlyTarget/:target`;

// should only work with report-pdf-service v1
export const SITE_INSPECTION_REPORT_CONTAINER_PDF = `${SITES}/:siteId/report-pdf/inspection/:timestamp`;
