export const SF_SITE_TO_LOCKERS = {
  1290: 213,
  1929: 162,
  15575: 117,
  15824: 155,
  15576: 144,
  15577: 117,
  15578: 225,
  2015: 172,
  15817: 138,
  15819: 180,
  15820: 145,
  15821: 150,
  2016: 175,
  2017: 175,
  2018: 312,
  2020: 129,
  15822: 257,
  15823: 211,
  1912: 274,
  2043: 511,
  43240: 143,
  43379: 123,
  51099: 235,
  50253: 139,
  51749: 72,
  50147: 106,
  49119: 141,
  50619: 80,
  49595: 231,
  51618: 136,
  49911: 195,
};
