import {
  AnalyticsEnergyResponse,
  EquipmentType,
  FormattedEnergyConsumptionData,
} from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import EnergyReportDonutChart from '../EnergyReportDonutChart';

type OwnProps = {
  siteId: number;
};

interface Props extends OwnProps {
  analyticsEnergySelectedTimePeriod?: AnalyticsEnergyResponse;
  equipmentTypes: EquipmentType[];
  analyticsEnergySelectedTimePeriodFetched: boolean;
  errorAnalyticsEnergySelectedTimePeriod: boolean;
}

const EnergyReportDonutChartContainer: React.FC<Props> = ({
  siteId,
  analyticsEnergySelectedTimePeriod,
  equipmentTypes,
  analyticsEnergySelectedTimePeriodFetched,
  errorAnalyticsEnergySelectedTimePeriod,
}) => {
  const initialEnergyDataByEquipmentGroups: FormattedEnergyConsumptionData[] = [];

  const [
    energyDataByEquipmentGroups,
    setEnergyDataByEquipmentGroups,
  ] = useState(initialEnergyDataByEquipmentGroups);

  useEffect(() => {
    if (
      analyticsEnergySelectedTimePeriodFetched &&
      equipmentTypes.length > 0 &&
      !errorAnalyticsEnergySelectedTimePeriod &&
      analyticsEnergySelectedTimePeriod &&
      !!analyticsEnergySelectedTimePeriod.data
    ) {
      const consumptionProperty =
        analyticsEnergySelectedTimePeriod.data.by_space;

      const formattedConsumptionByGroup = {};
      ([] as any)
        .concat(...Object.values(consumptionProperty))
        .map(equipmentConsumption => {
          return {
            ...equipmentConsumption,
            equipmentGroupId: equipmentTypes.find(
              el => el.id === equipmentConsumption.equipmentType
            )!.groupId,
            groupName: equipmentTypes.find(
              el => el.id === equipmentConsumption.equipmentType
            )!.group!.title,
            typeName: equipmentTypes.find(
              el => el.id === equipmentConsumption.equipmentType
            )!.group!.alias,
          };
        })
        .forEach(equipment => {
          const keyExists = !!formattedConsumptionByGroup[
            equipment.equipmentGroupId
          ];
          if (!keyExists) {
            formattedConsumptionByGroup[equipment.equipmentGroupId] = {
              count: equipment.consumption,
              label: equipment.groupName,
              groupId: equipment.equipmentGroupId,
              type: equipment.typeName,
              typeId: equipment.equipmentType,
            };
          } else {
            formattedConsumptionByGroup[equipment.equipmentGroupId].count =
              formattedConsumptionByGroup[equipment.equipmentGroupId].count +
              equipment.consumption;
          }
        });
      setEnergyDataByEquipmentGroups(
        Object.values(formattedConsumptionByGroup)
      );
    }
  }, [
    analyticsEnergySelectedTimePeriod,
    analyticsEnergySelectedTimePeriodFetched,
    equipmentTypes,
    errorAnalyticsEnergySelectedTimePeriod,
  ]);

  return (
    <div>
      <EnergyReportDonutChart
        energyDataByEquipmentGroups={energyDataByEquipmentGroups}
        siteId={siteId}
      />
    </div>
  );
};

const mapStateToProps = ({
  equipmentTags,
  energyReport,
  energy,
}: ApplicationState) => ({
  equipmentGroups: equipmentTags.equipmentGroups,
  errorAnalyticsEnergySelectedTimePeriod:
    energyReport.errors.analyticsEnergyConsumptionSelectedTimePeriod,
  analyticsEnergySelectedTimePeriod: energy.analyticsEnergySelectedTimePeriod,
  analyticsEnergySelectedTimePeriodFetched:
    energyReport.fetched.analyticsEnergyConsumptionSelectedTimePeriod,
  equipmentTypes: equipmentTags.equipmentTypes,
});

export default connect(mapStateToProps)(EnergyReportDonutChartContainer);
