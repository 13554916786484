import { Site } from '@energybox/react-ui-library/dist/types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSite } from '../../../actions/sites';
import { useReportTimeFormats } from '../../../hooks/utils';
import { ApplicationState } from '../../../reducers';
import {
  SelectedComponent,
  SelectedComponents,
  SelectedComponentsByPage,
} from '../../../types/genericReport';
import GenericReportPage from '../GenericReportPage';

type OwnProps = {
  siteId: string;
  components: string;
  fromDate: string;
  toDate: string;
};

interface Props extends OwnProps {
  site?: Site;
  fetchedColdStorageRecommendations: boolean;
  fetchedUnnecessaryDoorAccess: boolean;
  fetchedTemperatureComfortRecommendations: boolean;
  fetchedRefrigerationCompliance: boolean;
  fetchedSiteEnergyByWeeks: boolean;
  fetchedSiteWeatherByWeeks: boolean;
  fetchedAnalyticsEnergyConsumptionSelectedTimePeriod: boolean;
  fetchedAnalyticsEnergyConsumptionLastMonth: boolean;
  fetchedAnalyticsEnergyConsumptionTwoMonthsAgo: boolean;
  fetchedAnalyticsEnergyConsumptionThreeMonthsAgo: boolean;
  getSite: (siteId: number | string) => void;
}

const GenericReportPageContainer: React.FC<Props> = ({
  siteId,
  components,
  fromDate,
  toDate,
  site,
  getSite,
  fetchedColdStorageRecommendations,
  fetchedUnnecessaryDoorAccess,
  fetchedTemperatureComfortRecommendations,
  fetchedRefrigerationCompliance,
  fetchedSiteEnergyByWeeks,
  fetchedSiteWeatherByWeeks,
  fetchedAnalyticsEnergyConsumptionSelectedTimePeriod,
  fetchedAnalyticsEnergyConsumptionLastMonth,
  fetchedAnalyticsEnergyConsumptionTwoMonthsAgo,
  fetchedAnalyticsEnergyConsumptionThreeMonthsAgo,
}: Props) => {
  const reportTimeFormats = useReportTimeFormats(
    fromDate,
    toDate,
    site?.timeZone
  );
  const siteIdNumber = parseInt(siteId);

  useEffect(() => {
    if (!site) {
      getSite(siteIdNumber);
    }
  }, [site, getSite, siteIdNumber]);

  const processedSelectedComponents: SelectedComponentsByPage = (() => {
    const selectedComponentsByPage = {};
    const parsedComponents: (string | null)[][] = JSON.parse(components);

    parsedComponents.forEach(
      (selectedComponentsInPage: (string | null)[], index: number) => {
        selectedComponentsByPage[
          `page ${index + 1}`
        ] = selectedComponentsInPage;
      }
    );

    return selectedComponentsByPage;
  })();

  const determineFilteredFetchStates = () => {
    const filteredFetchedStates = {};
    const fetchedStates = {
      coldStorageRecommendations: fetchedColdStorageRecommendations,
      unnecessaryDoorAccess: fetchedUnnecessaryDoorAccess,
      temperatureComfortRecommendations: fetchedTemperatureComfortRecommendations,
      refrigerationCompliance: fetchedRefrigerationCompliance,
      siteEnergyByWeeks: fetchedSiteEnergyByWeeks,
      siteWeatherByWeeks: fetchedSiteWeatherByWeeks,
      analyticsEnergyConsumptionSelectedTimePeriod: fetchedAnalyticsEnergyConsumptionSelectedTimePeriod,
      analyticsEnergyConsumptionLastMonth: fetchedAnalyticsEnergyConsumptionLastMonth,
      analyticsEnergyConsumptionTwoMonthsAgo: fetchedAnalyticsEnergyConsumptionTwoMonthsAgo,
      analyticsEnergyConsumptionThreeMonthsAgo: fetchedAnalyticsEnergyConsumptionThreeMonthsAgo,
    };

    Object.values(processedSelectedComponents).forEach(
      (componentsInPage: SelectedComponents) => {
        componentsInPage.forEach((componentName: SelectedComponent) => {
          if (componentName && componentName !== 'energyTrends') {
            filteredFetchedStates[componentName] = fetchedStates[componentName];
          }

          if (componentName && componentName === 'energyTrends') {
            filteredFetchedStates['siteEnergyByWeeks'] =
              fetchedStates['siteEnergyByWeeks'];

            filteredFetchedStates['siteWeatherByWeeks'] =
              fetchedStates['siteWeatherByWeeks'];

            filteredFetchedStates[
              'analyticsEnergyConsumptionSelectedTimePeriod'
            ] = fetchedStates['analyticsEnergyConsumptionSelectedTimePeriod'];

            filteredFetchedStates['analyticsEnergyConsumptionLastMonth'] =
              fetchedStates['analyticsEnergyConsumptionLastMonth'];

            filteredFetchedStates['analyticsEnergyConsumptionTwoMonthsAgo'] =
              fetchedStates['analyticsEnergyConsumptionTwoMonthsAgo'];

            filteredFetchedStates['analyticsEnergyConsumptionThreeMonthsAgo'] =
              fetchedStates['analyticsEnergyConsumptionThreeMonthsAgo'];
          }
        });
      }
    );

    return filteredFetchedStates;
  };

  if (!site) {
    return <div />;
  }

  return (
    <GenericReportPage
      site={site}
      selectedComponentsByPage={processedSelectedComponents}
      selectedComponentsFetchedStates={determineFilteredFetchStates()}
      reportTimeFormats={reportTimeFormats}
    />
  );
};

const mapStateToProps = (
  { sites, genericReport, energyReport }: ApplicationState,
  { siteId }: OwnProps
) => ({
  site: sites.sitesById[siteId],
  //Due to current implementation, need to individually spell these props out in order to prevent infinite rerendering
  fetchedColdStorageRecommendations:
    genericReport.fetched.coldStorageRecommendations,
  fetchedUnnecessaryDoorAccess: genericReport.fetched.unnecessaryDoorAccess,
  fetchedTemperatureComfortRecommendations:
    genericReport.fetched.temperatureComfortRecommendations,
  fetchedRefrigerationCompliance: genericReport.fetched.refrigerationCompliance,

  fetchedSiteEnergyByWeeks: energyReport.fetched.siteEnergyByWeeks,
  fetchedSiteWeatherByWeeks: energyReport.fetched.siteWeatherByWeeks,
  fetchedAnalyticsEnergyConsumptionSelectedTimePeriod:
    energyReport.fetched.analyticsEnergyConsumptionSelectedTimePeriod,
  fetchedAnalyticsEnergyConsumptionLastMonth:
    energyReport.fetched.analyticsEnergyConsumptionLastMonth,
  fetchedAnalyticsEnergyConsumptionTwoMonthsAgo:
    energyReport.fetched.analyticsEnergyConsumptionTwoMonthsAgo,
  fetchedAnalyticsEnergyConsumptionThreeMonthsAgo:
    energyReport.fetched.analyticsEnergyConsumptionThreeMonthsAgo,
});

const mapDispatchToProps = {
  getSite,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericReportPageContainer);
