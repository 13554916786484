import {
  ACConsumptionRecommendations,
  Site,
  SopTypes,
} from '@energybox/react-ui-library/dist/types';
import format from 'date-fns/format';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getACEnergyRecommendations } from '../../../actions/analytics';
import ACConsumption from '../../../components/GenericReport/ACConsumption';
import { useSopByResourceId } from '../../../hooks/sop';
import { ApplicationState } from '../../../reducers';

type OwnProps = {
  site: Site;
  fromDate: Date;
  toDate: Date;
};

interface Props extends OwnProps {
  organizationId?: number;
  fetchedTemperatureComfortRecommendations: boolean;
  temperatureComfortRecommendations: ACConsumptionRecommendations[];
  getACEnergyRecommendations: (
    orgId: number | string,
    fromDate: string,
    toDate: string,
    siteId: number | string
  ) => void;
}

const ACConsumptionContainer: React.FC<Props> = ({
  site,
  organizationId,
  fetchedTemperatureComfortRecommendations,
  temperatureComfortRecommendations,
  getACEnergyRecommendations,
  fromDate,
  toDate,
}) => {
  const { sop: energySop } = useSopByResourceId(
    site.id,
    SopTypes.ENERGY_TARIFF
  );

  useEffect(() => {
    if (
      !!organizationId &&
      !!site &&
      !fetchedTemperatureComfortRecommendations
    ) {
      const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
      const formattedToDate = format(toDate, 'yyyy-MM-dd');

      getACEnergyRecommendations(
        organizationId,
        formattedFromDate,
        formattedToDate,
        site.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedTemperatureComfortRecommendations,
    organizationId,
    getACEnergyRecommendations,
    site,
  ]);

  if (
    !fetchedTemperatureComfortRecommendations ||
    !temperatureComfortRecommendations
  )
    return <div />;
  return (
    <div>
      <ACConsumption
        ACConsumptionRecommendations={temperatureComfortRecommendations}
        sop={energySop}
      />
    </div>
  );
};

const mapStateToProps = ({
  app,
  analytics,
  genericReport,
}: ApplicationState) => ({
  organizationId: app.currentOrganizationId,
  temperatureComfortRecommendations:
    analytics.temperatureComfortRecommendations,
  fetchedTemperatureComfortRecommendations:
    genericReport.fetched.temperatureComfortRecommendations,
});

export default connect(mapStateToProps, { getACEnergyRecommendations })(
  ACConsumptionContainer
);
