import {
  EnergyReadingApiResponse,
  EnergyTariff,
} from '@energybox/react-ui-library/dist/types';
import { formatCurrency } from '@energybox/react-ui-library/dist/utils';
import { format } from 'date-fns';
import { calculateEnergyCost } from '../utils/sop';
import { Periods } from './shared';

export const formatEnergyCost = (
  energyValue: number,
  sop: EnergyTariff,
  countryCode: string
) => {
  const { currencyCode } = sop;
  const cost = calculateEnergyCost(energyValue, sop);

  return formatCurrency(cost, currencyCode, countryCode);
};

export const findPeakDemand = (
  data: EnergyReadingApiResponse[]
): EnergyReadingApiResponse | undefined => {
  if (data.length === 0) return undefined;

  return data.reduce((acc, cur) => {
    if (!acc) acc = cur;
    if (acc.power < cur.power) acc = cur;

    return acc;
  });
};

export const avrKwhPerDay = (data: EnergyReadingApiResponse[]) => {
  const openingsByDay = {};

  data.forEach((d, index, arr) => {
    const date = format(new Date(d.timestamp * 1000), 'MM/dd/yyyy').toString();
    if (!openingsByDay[date]) openingsByDay[date] = 0;
    openingsByDay[date] = openingsByDay[date] + d.energy;
  });

  let total = 0;
  let daysCount = Object.keys(openingsByDay).length;

  for (const key in openingsByDay) {
    total = total + openingsByDay[key];
  }

  if (total === 0) return 0;

  return total / daysCount;
};
export const avrKwhPerPeriod = (
  data: EnergyReadingApiResponse[],
  period: Periods = Periods.DAY
) => {
  const openingsByPeriod = {};

  data.forEach((d, index, arr) => {
    const date = format(new Date(d.timestamp * 1000), period).toString();
    if (!openingsByPeriod[date]) openingsByPeriod[date] = 0;
    openingsByPeriod[date] = openingsByPeriod[date] + d.energy;
  });

  let total = 0;
  let periodsCount = Object.keys(openingsByPeriod).length;

  for (const key in openingsByPeriod) {
    total = total + openingsByPeriod[key];
  }

  if (total === 0) return 0;

  return total / periodsCount;
};
