import { Actions as TemperatureRecordActions } from '../actions/temperatureRecords';
import {
  toggleLoading,
  toggleSuccess,
  toggleError,
} from '../utils/reducerFetchingStates';

export type TemperatureRecordReport = {
  [id: string]: {
    [date: string]: {
      errors: TemperatureRecordReportFetchStatuses;
      loading: TemperatureRecordReportFetchStatuses;
      fetched: TemperatureRecordReportFetchStatuses;
    };
  };
};

export type TemperatureRecordReportFetchStatuses = {
  generatedReport: boolean;
};

const initialState: TemperatureRecordReport = {};

export default (state: TemperatureRecordReport = initialState, action) => {
  switch (action.type) {
    case TemperatureRecordActions.GENERATE_REPORT_LOADING:
      return toggleLoading(['generatedReport', action.reportId, action.date], state);

    case TemperatureRecordActions.GENERATE_REPORT_ERROR:
      return toggleError(['generatedReport', action.reportId, action.date], state);

    case TemperatureRecordActions.GENERATE_REPORT_SUCCESS:
      return toggleSuccess(['generatedReport', action.reportId, action.date], state);

    default:
      return state;
  }
};
