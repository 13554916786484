import { global } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './ReportPage.module.css';

type Props = {
  value: number;
  decimals?: number;
  symbol?: string;
  className?: string;
};

const DeltaNumbers = ({ className, value, decimals, symbol }: Props) => {
  if (!value) return <>{global.NOT_AVAILABLE}</>;

  const positiveValue = value > 0;
  return (
    <div
      className={className}
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      {value.toFixed(decimals || 0)}
      {symbol}

      <div
        className={
          positiveValue ? styles.deltaArrowPositive : styles.deltaArrowNegative
        }
      />
    </div>
  );
};

export default DeltaNumbers;
