import {
  ApplicationMenu,
  ApplicationMenuItem,
} from '@energybox/react-ui-library/dist/components';
import { Sites as SitesIcon } from '@energybox/react-ui-library/dist/icons';
import React from 'react';
import { Link, Route, withRouter } from 'react-router-dom';
import * as Routes from '../routes';

type Props = {
  noNavigation?: boolean;
};

const menuItems = [
  {
    title: 'Sites',
    icon: <SitesIcon size={30} />,
    to: `${Routes.SITES}`,
    activeOn: new RegExp(`^(${Routes.SITES})`, 'g'),
  },
];

const Navigation: React.FC<Props> = ({ noNavigation }) => {
  if (noNavigation) {
    return null;
  }
  return (
    <ApplicationMenu>
      <div>
        {menuItems.map(({ to, icon, title, activeOn }) => (
          <Route
            key={title}
            children={({ location }) => (
              <Link to={to}>
                <ApplicationMenuItem
                  isActive={!!(location && location.pathname.match(activeOn))}
                  icon={icon}
                  title={title || ''}
                />
              </Link>
            )}
          />
        ))}
      </div>
    </ApplicationMenu>
  );
};

export default withRouter(Navigation);
