import {
  AnalyticsEnergyResponse,
  Equipment,
  EquipmentAnalyticsConsumptionData,
  EquipmentType,
  OrgEnergyByPeriod,
  OrgEnergyReportingPeriod,
} from '@energybox/react-ui-library/dist/types';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgEnergyReport } from '../../../actions/energy';
import { ApplicationState } from '../../../reducers';
import EnergyReportEquipmentTable from '../EnergyReportEquipmentTable';

type Props = {
  siteId: number;
  equipments: Equipment[] | undefined;
  fromDate: Date;
  toDate: Date;
};

const EnergyReportEquipmentTableContainer: React.FC<Props> = ({
  siteId,
  equipments,
  fromDate,
  toDate,
}) => {
  /// **useSelector** ///
  const organizationId = useSelector<ApplicationState, number | undefined>(
    ({ app }) => app.currentOrganizationId
  );

  const analyticsEnergySelectedTimePeriod = useSelector<
    ApplicationState,
    AnalyticsEnergyResponse | undefined
  >(({ energy }) => energy.analyticsEnergySelectedTimePeriod);

  const analyticsEnergyLastMonth = useSelector<
    ApplicationState,
    AnalyticsEnergyResponse | undefined
  >(({ energy }) => energy.analyticsEnergyLastMonth);

  const equipmentTypes = useSelector<ApplicationState, EquipmentType[]>(
    ({ equipmentTags }) => equipmentTags.equipmentTypes
  );

  const orgEnergyByPeriod = useSelector<
    ApplicationState,
    OrgEnergyByPeriod | undefined
  >(({ energy }) => energy.orgEnergyByPeriod);
  /// ** end useSelector** ///

  /// ** useEffect** ///
  const dispatch = useDispatch();
  useEffect(() => {
    if (fromDate && toDate && organizationId) {
      const prevMonthToDate = subDays(fromDate, 1);
      const prevMonthFromDate = subMonths(prevMonthToDate, 1);

      dispatch(
        getOrgEnergyReport(
          organizationId,
          fromDate,
          toDate,
          OrgEnergyReportingPeriod.CURRENT
        )
      );

      dispatch(
        getOrgEnergyReport(
          organizationId,
          prevMonthFromDate,
          prevMonthToDate,
          OrgEnergyReportingPeriod.PREV_MONTH
        )
      );
    }
  }, [dispatch, fromDate, toDate, organizationId]);
  ///** end useEffect **///

  /// ** useMemo** ///
  const top5ConsumptionSelectedTimePeriod = useMemo(() => {
    const energyByEquipmentConsumptionProperty = Object.values(
      analyticsEnergySelectedTimePeriod?.data?.by_space || {}
    );

    if (energyByEquipmentConsumptionProperty.length === 0) {
      return [];
    }

    const formattedConsumptionByEquipment = {};

    energyByEquipmentConsumptionProperty.forEach(
      (consumptionInfoBySpace: EquipmentAnalyticsConsumptionData[]) => {
        consumptionInfoBySpace.forEach(
          (consumptionInfo: EquipmentAnalyticsConsumptionData) => {
            formattedConsumptionByEquipment[
              consumptionInfo.equipmentId
            ] = consumptionInfo;
          }
        );
      }
    );

    const top5 = Object.keys(formattedConsumptionByEquipment)
      .map((key: string) => ({
        equipmentId: key,
        ...formattedConsumptionByEquipment[key],
      }))
      .filter((equipmentEnergy: EquipmentAnalyticsConsumptionData) => {
        return equipmentEnergy.consumption ?? false;
      })
      .sort((a, b) => b.consumption - a.consumption)
      .slice(0, 5);

    return top5;
  }, [analyticsEnergySelectedTimePeriod]);

  const equipmentConsumptionLastMonth = useMemo(() => {
    const energyByEquipmentConsumptionPrevPeriodProperty = Object.values(
      analyticsEnergyLastMonth?.data?.by_space || {}
    );

    if (energyByEquipmentConsumptionPrevPeriodProperty.length === 0) {
      return [];
    }

    const formattedEnergyData: EquipmentAnalyticsConsumptionData[] = [];

    energyByEquipmentConsumptionPrevPeriodProperty.forEach(
      (energyInfoBySpace: EquipmentAnalyticsConsumptionData[]) => {
        energyInfoBySpace.forEach(energyInfoByEquipment => {
          formattedEnergyData.push(energyInfoByEquipment);
        });
      }
    );

    if (formattedEnergyData.length > 0) {
      return Object.keys(formattedEnergyData)
        .map(key => ({
          equipmentId: key,
          ...formattedEnergyData[key],
        }))
        .filter(equipment => equipment.consumption ?? false)
        .sort((a, b) => b.consumption - a.consumption);
    }
    return [];
  }, [analyticsEnergyLastMonth]);

  const averagesByEquipmentTypeId = useMemo(() => {
    if (
      !orgEnergyByPeriod?.accumulatedConsumptionPrEquipmentTypePrevMonth ||
      !orgEnergyByPeriod?.accumulatedConsumptionPrEquipmentTypeReportingMonth
    ) {
      return {};
    } else {
      let changeInConsumptionByEquipmentType = {};

      Object.keys(
        orgEnergyByPeriod.accumulatedConsumptionPrEquipmentTypeReportingMonth
      ).forEach(key => {
        changeInConsumptionByEquipmentType[key] =
          (orgEnergyByPeriod
            .accumulatedConsumptionPrEquipmentTypeReportingMonth[key] /
            orgEnergyByPeriod.accumulatedConsumptionPrEquipmentTypePrevMonth[
              key
            ]) *
            100 -
          100;
      });

      return changeInConsumptionByEquipmentType;
    }
  }, [orgEnergyByPeriod]);
  /// ** end useMemo ** ///

  if (
    !averagesByEquipmentTypeId ||
    !top5ConsumptionSelectedTimePeriod ||
    !equipmentConsumptionLastMonth
  ) {
    return <div />;
  }
  return (
    <EnergyReportEquipmentTable
      siteId={siteId}
      top5ConsumptionSelectedTimePeriod={top5ConsumptionSelectedTimePeriod}
      equipmentConsumptionLastMonth={equipmentConsumptionLastMonth}
      equipments={equipments}
      equipmentTypes={equipmentTypes}
      averagesByEquipmentTypeId={averagesByEquipmentTypeId}
    />
  );
};

export default EnergyReportEquipmentTableContainer;
