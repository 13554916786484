import {
  SopEnumValuesToComponentType,
  SopTypes,
} from '@energybox/react-ui-library/dist/types';
import { pathOr } from 'ramda';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resolveSopByResourceIdAndSopType } from '../actions/sops';
import { ApplicationState } from '../reducers';

export function useSopByResourceId<K extends SopTypes>(
  resourceId: string | number,
  sopType: K
  //@ts-ignore
): { isLoading: boolean; sop?: SopEnumValuesToComponentType[K] | null } {
  const dispatch = useDispatch();
  const sopReturn = useSelector(({ sops }: ApplicationState) => {
    const value = pathOr(
      {
        isLoading: true,
      },
      [sopType, String(resourceId)],
      sops.sopComponentTypesByResourceId
    );
    return value;
  });

  useEffect(() => {
    dispatch(resolveSopByResourceIdAndSopType(String(resourceId), sopType));
  }, [resourceId, sopType, dispatch]);

  return sopReturn;
}
