import { accent } from '@energybox/react-ui-library/dist/utils';
import { View, Image, Text } from '@react-pdf/renderer';
import {
  LeftColumnContent,
  RightColumnContent,
} from '../ReportHeading/ReportHeading';
import { SummaryField, Row, sectionBorder } from './utils';

export type PdfHeaderProps = {
  title: string;
  imagePath?: string;
  timeString: string;
  leftColumnContent: LeftColumnContent[];
  rightColumnContent?: RightColumnContent[];
};

export const PdfViewHeader: React.FC<PdfHeaderProps> = ({
  title,
  imagePath,
  timeString,
  leftColumnContent,
  rightColumnContent,
}) => {
  const rowCount = Math.max(
    leftColumnContent.length,
    rightColumnContent?.length || 0
  );
  return (
    <View fixed>
      {imagePath && (
        <Image
          src={{
            uri: imagePath,
            method: 'GET',
            body: '',
            headers: {
              'Cache-Control': 'no-cache',
              authorization:
                'Bearer eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2NzU3NTMyNDUsInN1YiI6ImFuZHkuaG9AZW5lcmd5Ym94LmNvbSIsImlzcyI6IkVuZXJneUJveCIsIm9yZyI6NTc4NiwidWlkIjo0ODkzNzcsImV4cCI6MTY3NjM1ODA0NX0.GxtOZHtOCkfWTAtjScoCBLcMXFv3purQHpBqawTfvrNZoFW3U0hpSymt8BPsuYtRm95gXwCn1u8X95He3EETzA',
            },
          }}
          style={{
            height: 30,
            width: 'auto',
            alignSelf: 'flex-end',
          }}
          debug={process.env.NODE_ENV === 'development'}
        />
      )}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontSize: '16pt',
            fontWeight: 700,
            color: accent.main,
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            fontWeight: 700,
          }}
        >
          {timeString}
        </Text>
      </View>
      <View
        style={{
          borderTop: sectionBorder,
          borderBottom: sectionBorder,
          padding: '10 0',
        }}
      >
        {Array.from(Array(rowCount).keys()).map(idx => {
          return (
            <Row key={idx}>
              {leftColumnContent[idx] ? (
                <SummaryField
                  field={{
                    name: leftColumnContent[idx].fieldName,
                    value: leftColumnContent[idx].fieldValue,
                  }}
                  width="30%"
                  nameWeight={700}
                />
              ) : (
                <View style={{ width: '30%' }} />
              )}
              <View style={{ width: '20%' }} />

              {rightColumnContent?.[idx] ? (
                <SummaryField
                  field={{
                    name: rightColumnContent[idx].fieldName,
                    value: rightColumnContent[idx].fieldValue || ' ',
                  }}
                  width="30%"
                  nameWeight={700}
                />
              ) : (
                <View style={{ width: '30%' }} />
              )}
              <View style={{ width: '20%' }} />
            </Row>
          );
        })}
      </View>
    </View>
  );
};
