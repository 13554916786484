import {
  AnalyticsEnergyResponse,
  Site,
} from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import EnergyReportMonthlyConsumptionTable from '../EnergyReportMonthlyConsumptionTable';

type OwnProps = {
  site: Site;
};

interface Props extends OwnProps {
  fetchedAnalyticsEnergyConsumptionLastMonth: boolean;
  analyticsEnergyLastMonth?: AnalyticsEnergyResponse;
  analyticsEnergyLastMonthDate?: Date;
  fetchedAnalyticsEnergyConsumptionTwoMonthsAgo: boolean;
  analyticsEnergyTwoMonthsAgo?: AnalyticsEnergyResponse;
  analyticsEnergyTwoMonthsAgoDate?: Date;
  fetchedAnalyticsEnergyConsumptionThreeMonthsAgo: boolean;
  analyticsEnergyThreeMonthsAgo?: AnalyticsEnergyResponse;
  analyticsEnergyThreeMonthsAgoDate?: Date;
  errorAnalyticsEnergyThreeMonthsAgo: boolean;
  errorAnalyticsEnergyLastMonth: boolean;
  errorAnalyticsEnergyTwoMonthsAgo: boolean;
}

const EnergyReportMonthlyConsumptionTableContainer: React.FC<Props> = ({
  site,
  fetchedAnalyticsEnergyConsumptionLastMonth,
  analyticsEnergyLastMonth,
  analyticsEnergyLastMonthDate,
  fetchedAnalyticsEnergyConsumptionTwoMonthsAgo,
  analyticsEnergyTwoMonthsAgo,
  analyticsEnergyTwoMonthsAgoDate,
  fetchedAnalyticsEnergyConsumptionThreeMonthsAgo,
  analyticsEnergyThreeMonthsAgo,
  analyticsEnergyThreeMonthsAgoDate,
  errorAnalyticsEnergyLastMonth,
  errorAnalyticsEnergyTwoMonthsAgo,
  errorAnalyticsEnergyThreeMonthsAgo,
}: Props) => {
  const [consumptionLastMonth, setConsumptionLastMonth] = useState({
    consumption: 0,
    fromDate: new Date(),
  });
  const [consumptionTwoMonthsAgo, setConsumptionTwoMonthsAgo] = useState({
    consumption: 0,
    fromDate: new Date(),
  });
  const [consumptionThreeMonthsAgo, setConsumptionThreeMonthsAgo] = useState({
    consumption: 0,
    fromDate: new Date(),
  });

  useEffect(() => {
    if (
      analyticsEnergyLastMonthDate &&
      fetchedAnalyticsEnergyConsumptionLastMonth &&
      !errorAnalyticsEnergyLastMonth &&
      analyticsEnergyLastMonth &&
      Object.keys(analyticsEnergyLastMonth).length > 0
    ) {
      const totalConsumption = analyticsEnergyLastMonth.data?.total_kwh;

      setConsumptionLastMonth({
        consumption: totalConsumption ?? 0,
        fromDate: analyticsEnergyLastMonthDate,
      });
    }
    if (analyticsEnergyLastMonthDate && errorAnalyticsEnergyLastMonth) {
      setConsumptionLastMonth({
        consumption: 0,
        fromDate: analyticsEnergyLastMonthDate,
      });
    }
  }, [
    errorAnalyticsEnergyLastMonth,
    analyticsEnergyLastMonth,
    analyticsEnergyLastMonthDate,
    fetchedAnalyticsEnergyConsumptionLastMonth,
  ]);

  useEffect(() => {
    if (
      analyticsEnergyTwoMonthsAgoDate &&
      fetchedAnalyticsEnergyConsumptionTwoMonthsAgo &&
      !errorAnalyticsEnergyTwoMonthsAgo &&
      analyticsEnergyTwoMonthsAgo &&
      Object.keys(analyticsEnergyTwoMonthsAgo).length > 0
    ) {
      const totalConsumption = analyticsEnergyTwoMonthsAgo.data?.total_kwh;

      setConsumptionTwoMonthsAgo({
        consumption: totalConsumption ?? 0,
        fromDate: analyticsEnergyTwoMonthsAgoDate,
      });
    }
    if (analyticsEnergyTwoMonthsAgoDate && errorAnalyticsEnergyTwoMonthsAgo) {
      setConsumptionTwoMonthsAgo({
        consumption: 0,
        fromDate: analyticsEnergyTwoMonthsAgoDate,
      });
    }
  }, [
    analyticsEnergyTwoMonthsAgo,
    analyticsEnergyTwoMonthsAgoDate,
    errorAnalyticsEnergyTwoMonthsAgo,
    fetchedAnalyticsEnergyConsumptionTwoMonthsAgo,
  ]);

  useEffect(() => {
    if (
      analyticsEnergyThreeMonthsAgoDate &&
      fetchedAnalyticsEnergyConsumptionThreeMonthsAgo &&
      !errorAnalyticsEnergyThreeMonthsAgo &&
      analyticsEnergyThreeMonthsAgo &&
      Object.keys(analyticsEnergyThreeMonthsAgo).length > 0
    ) {
      const totalConsumption = analyticsEnergyThreeMonthsAgo.data?.total_kwh;

      setConsumptionThreeMonthsAgo({
        consumption: totalConsumption ?? 0,
        fromDate: analyticsEnergyThreeMonthsAgoDate,
      });
    }
    if (
      analyticsEnergyThreeMonthsAgoDate &&
      errorAnalyticsEnergyThreeMonthsAgo
    ) {
      setConsumptionThreeMonthsAgo({
        consumption: 0,
        fromDate: analyticsEnergyThreeMonthsAgoDate,
      });
    }
  }, [
    analyticsEnergyThreeMonthsAgo,
    analyticsEnergyThreeMonthsAgoDate,
    errorAnalyticsEnergyThreeMonthsAgo,
    fetchedAnalyticsEnergyConsumptionThreeMonthsAgo,
  ]);

  if (
    !fetchedAnalyticsEnergyConsumptionLastMonth ||
    !fetchedAnalyticsEnergyConsumptionTwoMonthsAgo ||
    !fetchedAnalyticsEnergyConsumptionThreeMonthsAgo ||
    !site
  ) {
    return <div />;
  }
  return (
    <EnergyReportMonthlyConsumptionTable
      site={site}
      consumptionLastMonth={consumptionLastMonth}
      consumptionTwoMonthsAgo={consumptionTwoMonthsAgo}
      consumptionThreeMonthsAgo={consumptionThreeMonthsAgo}
    />
  );
};

const mapStateToProps = ({ energy, energyReport }: ApplicationState) => ({
  analyticsEnergyLastMonth: energy.analyticsEnergyLastMonth,
  analyticsEnergyTwoMonthsAgo: energy.analyticsEnergyTwoMonthsAgo,
  analyticsEnergyThreeMonthsAgo: energy.analyticsEnergyThreeMonthsAgo,

  analyticsEnergyLastMonthDate:
    energy.analyticsEnergyLastMonth && energy.analyticsEnergyLastMonth.fromDate,
  analyticsEnergyTwoMonthsAgoDate:
    energy.analyticsEnergyTwoMonthsAgo &&
    energy.analyticsEnergyTwoMonthsAgo.fromDate,
  analyticsEnergyThreeMonthsAgoDate:
    energy.analyticsEnergyThreeMonthsAgo &&
    energy.analyticsEnergyThreeMonthsAgo.fromDate,

  fetchedAnalyticsEnergyConsumptionLastMonth:
    energyReport.fetched.analyticsEnergyConsumptionLastMonth,
  fetchedAnalyticsEnergyConsumptionTwoMonthsAgo:
    energyReport.fetched.analyticsEnergyConsumptionTwoMonthsAgo,
  fetchedAnalyticsEnergyConsumptionThreeMonthsAgo:
    energyReport.fetched.analyticsEnergyConsumptionThreeMonthsAgo,

  errorAnalyticsEnergyLastMonth:
    energyReport.errors.analyticsEnergyConsumptionLastMonth,
  errorAnalyticsEnergyTwoMonthsAgo:
    energyReport.errors.analyticsEnergyConsumptionTwoMonthsAgo,
  errorAnalyticsEnergyThreeMonthsAgo:
    energyReport.errors.analyticsEnergyConsumptionThreeMonthsAgo,
});

export default connect(mapStateToProps)(
  EnergyReportMonthlyConsumptionTableContainer
);
