import React from 'react';
import {
  CurrentUser,
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  TEMP_UNITS,
  ThermostatWorkingMode,
  ThermostatWorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import { getSensorsTableColumns } from './Sensors';
import {
  getTitle,
  getDetailFields,
  getSummaryFields,
  getSensorsOrActuatorsSummaryFields,
  transformTemperatureValue,
  transformTemperatureUnit,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { ComponentHeader, InspectionComponent } from './InspectionComponent';
import { DeviceIcon } from '../Pdf/Icons';

type Props = {
  data: InspectionDataFieldsByKey;
  currentUser?: CurrentUser;
  isEBThermostat?: boolean;
};

export const VenstarThermostat: React.FC<Props> = ({
  data,
  currentUser,
  isEBThermostat,
}) => {
  if (!currentUser) return null;

  const equipmentId = (data.equipment_id as InspectionDataField)
    ?.field as string;
  const mappedEquipmentPageLink = `${'/equipment'}/${equipmentId}`;
  const mappedEquipmentHvacCardLink = `${mappedEquipmentPageLink}${'#hvac-controls-card'}`;

  // Heat Pump Logic
  const isInspectionDataFieldsByKey = (
    value: any
  ): value is InspectionDataFieldsByKey => {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  };
  const HVAC_type = data.HVAC_type;
  let HeatPump: any = undefined;
  if (isInspectionDataFieldsByKey(HVAC_type)) {
    HeatPump = HVAC_type.field;
  }
  const isHeatPump = () => {
    if (HeatPump === null) {
      return null;
    }
    return HeatPump.includes('Heatpump') ? true : null;
  };

  const renderHumiditySetpoint = humidifySetpoint => {
    const dehumidifySetpoint = (data.dehumidify_setpoint as InspectionDataField)
      ?.field as number;
    const humidityMode = (data.humidity_mode as InspectionDataField)
      ?.field as string;

    if (humidityMode === 'AUTO') {
      return `${humidifySetpoint}% - ${dehumidifySetpoint}%`;
    } else if (humidityMode === 'HUMIDIFY') {
      return `${humidifySetpoint}%`;
    } else if (humidityMode === 'DEHUMIDIFY') {
      return `${dehumidifySetpoint}%`;
    } else {
      return global.NOT_AVAILABLE;
    }
  };

  function toTitleCase(str) {
    if (!isDefined(str) || str == null) {
      return global.NOT_AVAILABLE;
    }
    return str
      .toLowerCase()
      .split(' ')
      .map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  const fields = [
    ...(!isEBThermostat
      ? [
          {
            name: 'IP Address',
            key: 'ip',
          },
          {
            name: 'Tstat Onboard Temp',
            key: 'onboard_temperature',
            transformValue: value =>
              transformTemperatureValue(value, currentUser),
          },
        ]
      : []),
    {
      name: 'MAC Address (UUID)',
      key: 'uuid',
    },
    {
      name: 'Control Temperature',
      key: 'control_temperature',
      transformValue: value => transformTemperatureValue(value, currentUser),
    },
    {
      name: 'Thermostat Model',
      key: 'thermostat_model',
      transformValue: value => (value ? `${value}` : global.NOT_AVAILABLE),
    },
    {
      name: 'Thermostat Set Point',
      key: 'thermostat_cooling_setpoint',
      transformValue: coolingValue => {
        const heatingValue = (data.thermostat_heating_setpoint as InspectionDataField)
          ?.field as string;
        const coolingSetPoint = transformTemperatureValue(
          coolingValue,
          currentUser
        );
        const heatingSetPoint = transformTemperatureValue(
          heatingValue,
          currentUser
        );
        const thermostatMode = (data.thermostat_mode as InspectionDataField)
          ?.field as string;
        if (thermostatMode === ThermostatWorkingMode.HEATING) {
          return heatingSetPoint;
        } else if (thermostatMode === ThermostatWorkingMode.COOLING) {
          return coolingSetPoint;
        } else if (thermostatMode === ThermostatWorkingMode.AUTO) {
          return isEBThermostat
            ? `${heatingSetPoint} - ${coolingSetPoint}`
            : `${coolingSetPoint} - ${heatingSetPoint}`;
        }
        return global.NOT_AVAILABLE;
      },
    },
    {
      name: 'Configured HVAC Type',
      key: 'HVAC_type',
    },
    {
      name: 'Thermostat Mode',
      key: 'thermostat_mode',
      transformValue: value =>
        ThermostatWorkingModeLabel[value] || value || global.NOT_AVAILABLE,
    },
    {
      name: isEBThermostat
        ? isHeatPump() === null || isHeatPump() === true
          ? 'Configured Reversing Valve'
          : 'Configured Heating System'
        : 'Configured Reversing Valve',
      key: isEBThermostat
        ? isHeatPump() === null || isHeatPump() === true
          ? 'reversing_valve'
          : 'system_type'
        : 'reversing_valve',
    },
    {
      name: isEBThermostat ? 'Humidity Setpoint' : 'Control Mode',
      key: isEBThermostat ? 'humidity_setpoint' : 'control_mode',
      transformValue: value =>
        !isEBThermostat ? value : renderHumiditySetpoint(value),
    },
    ...(!isEBThermostat
      ? [
          {
            name: 'Number of Sensors',
            key: 'number_of_sensors_detected',
            transformValue: detectedSensorNum => {
              const configuredSensorNum = (data.number_of_sensors_configured as InspectionDataField)
                ?.field as number;
              return `${
                isDefined(detectedSensorNum)
                  ? detectedSensorNum
                  : global.NOT_AVAILABLE
              } detected / ${
                isDefined(configuredSensorNum)
                  ? configuredSensorNum
                  : global.NOT_AVAILABLE
              } configured`;
            },
          },
        ]
      : [
          {
            name: 'Control Source',
            key: 'thermostat_sensor_source',
            transformValue: value => toTitleCase(value as String),
          },
          {
            name: 'Humidity Mode',
            key: 'humidity_mode',
            transformValue: value => toTitleCase(value as String),
          },
          {
            name: 'Fallback Setting',
            key: 'thermostat_sensor_fallback',
            transformValue: value => toTitleCase(value as String),
          },
          {
            name: 'Control Mode',
            key: 'control_mode',
            link: !!equipmentId ? mappedEquipmentHvacCardLink : undefined,
            transformValue: value => toTitleCase(value as String),
          },
          {
            name: 'Fallback Status',
            key: 'thermostat_sensor_status',
            transformValue: value => value || global.NOT_AVAILABLE,
          },
        ]),
    {
      name: 'Mapped Equipment',
      key: 'equipment_title',
    },
    ...(isEBThermostat
      ? [
          {
            name: 'Signal Strength',
            key: 'signal_strength',
            transformValue: value =>
              value ? `${value} dBm` : global.NOT_AVAILABLE,
          },
          {
            name: 'Display Name',
            key: 'thermostat_display_name',
            transformVale: value => value || global.NOT_AVAILABLE,
          },
        ]
      : []),
  ];
  const subtitle = getTitle(data);
  const thermostatSummaryFields = getSummaryFields(data);
  const sensorList = ((data.sensor_list ||
    []) as InspectionDataFieldsByKey[]).map(sensor => ({
    ...sensor,
    type: {
      field: ['TEMPERATURE'],
      error: null,
      warning: null,
    },
    vendor: {
      field: 'venstar',
      error: null,
      warning: null,
    },
    status: {
      field: 'online',
      error: null,
      warning: null,
    },
  }));
  const sensorsSummaryFields = getSensorsOrActuatorsSummaryFields(sensorList);
  const sensorsColumns = getSensorsTableColumns({
    currentUser,
    showColumns: ['Type', 'Sensor Name', 'Status', 'Last Reading', 'Power'],
    noLastCheckInTime: true,
  });
  return (
    <InspectionComponent
      title={InspectionComponentName.THERMOSTAT}
      titleIcon={<DeviceIcon type={InspectionComponentName.THERMOSTAT} />}
      subtitle={subtitle}
      summaryFields={thermostatSummaryFields}
      detailFields={getDetailFields(fields, data, InspectionDetailLevel.ALL)}
      detailTableHeader={
        <ComponentHeader
          title={InspectionComponentName.EXTERNAL_SENSORS}
          summaryFields={sensorsSummaryFields}
        />
      }
      detailTable={{
        dataIsLoading: false,
        columns: sensorsColumns,
        data: sensorList,
      }}
      noTableDataMessage="No Sensors Configured"
    />
  );
};
