import ReactGA from 'react-ga';

if (process.env.NODE_ENV !== 'test') {
  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GA_TRACKING_ID || '',
    },
  ]);
}

const trackPage = page => {
  ReactGA.set({
    page,
  });
  ReactGA.pageview(page);
};

let currentPage = '';

const googleAnalytics = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
    if (currentPage !== nextPage) {
      currentPage = nextPage;
      trackPage(nextPage);
    }
  }

  return next(action);
};

export default googleAnalytics;
