import { Site } from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

import React, { useMemo, useRef } from 'react';
import ACConsumptionContainer from '../../../containers/GenericReport/ACConsumptionContainer';
import ColdStorageReductionContainer from '../../../containers/GenericReport/ColdStorageReductionContainer';
import DoorAccessContainer from '../../../containers/GenericReport/DoorAccessContainer';
import WeatherAndEnergyConsumptionContainer from '../../../containers/GenericReport/WeatherAndEnergyConsumptionContainer';
import { ReportTimeFormats } from '../../../hooks/utils';
import {
  GenericReportComponent,
  SelectedComponent,
  SelectedComponents,
  SelectedComponentsByPage,
} from '../../../types/genericReport';
import ReportHeading from '../ReportHeading';
import ReportMeasurableElement from '../ReportMeasurableElement';
import reportStyles from '../ReportPage.module.css';
import ReportPageBreakControl from '../ReportPageBreakControl';

type GenericReportSelectedComponents = {
  coldStorageRecommendations?: boolean;
  temperatureComfortRecommendations?: boolean;
  unnecessaryDoorAccess?: boolean;
  energyTrends?: boolean;
  refrigerationCompliance?: boolean;
};

type Props = {
  site?: Site;
  selectedComponentsByPage: SelectedComponentsByPage;
  selectedComponentsFetchedStates: GenericReportSelectedComponents;
  reportTimeFormats: ReportTimeFormats;
};

const GenericReportPage: React.FC<Props> = ({
  site,
  selectedComponentsByPage,
  selectedComponentsFetchedStates,
  reportTimeFormats,
}) => {
  const headerRef = useRef(null);
  const coldStorageRef = useRef(null);
  const doorAccessRef = useRef(null);
  const ACConsumptionRef = useRef(null);
  const energyTrendsRef = useRef(null);
  const refrigerationComplianceRef = useRef(null);
  const emptyComponentRef = useRef(null);
  if (!site) return <div />;

  const {
    fromDateInBrowserTimezone: fromDate,
    toDateInBrowserTimezone: toDate,
  } = reportTimeFormats;

  const headerInfo = useCreateHeaderInfoColumn(site);

  const genericComponentInfo = {
    [GenericReportComponent.COLD_STORAGE_RECOMMENDATIONS]: {
      component: (
        <ColdStorageReductionContainer
          site={site}
          fromDate={fromDate}
          toDate={toDate}
        />
      ),
      ref: coldStorageRef,
      key: 'coldStorage',
    },

    [GenericReportComponent.TEMPERATURE_COMFORT_RECOMMENDATIONS]: {
      component: (
        <ACConsumptionContainer
          site={site}
          fromDate={fromDate}
          toDate={toDate}
        />
      ),
      ref: ACConsumptionRef,
      key: 'ACCons',
    },

    [GenericReportComponent.UNNECESSARY_DOOR_ACCESS]: {
      component: (
        <DoorAccessContainer site={site} fromDate={fromDate} toDate={toDate} />
      ),
      ref: doorAccessRef,
      key: 'doorAccess',
    },

    [GenericReportComponent.ENERGY_TRENDS]: {
      component: (
        <WeatherAndEnergyConsumptionContainer
          site={site}
          reportTimeFormats={reportTimeFormats}
        />
      ),
      ref: energyTrendsRef,
      key: 'energyTrends',
    },
  };

  const renderSelectedComponents = () => {
    const componentsToRender: SelectedComponents = [];

    Object.values(selectedComponentsByPage).forEach(
      (selectedComponentsPerPage: SelectedComponents) => {
        selectedComponentsPerPage.forEach((component: SelectedComponent) => {
          componentsToRender.push(component);
        });
      }
    );

    return componentsToRender
      .filter(c => !!c)
      .map((component: SelectedComponent) => {
        return selectedComponentGenerator(component);
      });
  };

  const selectedComponentGenerator = (selectedComponent: SelectedComponent) => {
    if (selectedComponent) {
      const selectedComponentInfo = genericComponentInfo[selectedComponent];

      return (
        <ReportMeasurableElement
          setRef={selectedComponentInfo.ref}
          key={selectedComponentInfo.key}
        >
          <div className={reportStyles.genericComponentContainer}>
            {selectedComponentInfo.component}
          </div>
        </ReportMeasurableElement>
      );
    }

    return (
      <ReportMeasurableElement setRef={emptyComponentRef} key="emptyComponent">
        <div className={reportStyles.genericComponentContainer} />
      </ReportMeasurableElement>
    );
  };

  const reportHeader = (
    <ReportMeasurableElement setRef={headerRef} key="heading">
      <ReportHeading
        title="Personalized Report"
        leftColumnContent={headerInfo}
        fromDate={fromDate}
        toDate={toDate}
      />
    </ReportMeasurableElement>
  );

  return (
    <ReportPageBreakControl
      fetchedStatuses={selectedComponentsFetchedStates}
      headerForNewPages={reportHeader}
      headerRef={headerRef}
    >
      {reportHeader}

      {renderSelectedComponents()}
    </ReportPageBreakControl>
  );
};

const useCreateHeaderInfoColumn = (site: Site) => {
  return useMemo(() => {
    return [
      {
        fieldName: 'Organization',
        fieldValue: site?.organization?.title || global.NOT_AVAILABLE,
      },
      { fieldName: 'Site', fieldValue: site?.title },
      { fieldName: 'Address', fieldValue: site?.address },
    ];
  }, [site]);
};

export default GenericReportPage;
